import { VFC } from "react"

interface IProjectTitleListItem {
  title: string
}

const ProjectTitleListItem: VFC<IProjectTitleListItem> = ({ title }) => {
  return (
    <div className="booking-ratio-department-list-item-wrapper">
      <div className="calendar-group-text">{title}</div>
    </div>
  )
}

export default ProjectTitleListItem
