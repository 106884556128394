import { IDate } from "./calendar"

export interface IUser {
  id: string
  accountId: string
  availability: number[] | null
  department?: IDepartments
  email: string
  firstName: string
  lastName: string
  seniorityLevel?: SeniorityLevelType
  jira?: IJiraUsersData
  location?: { displayName: string; id: string }
  teams?: { [key: string]: { displayName: string } }
  startDate?: IDate
  endDate?: IDate
}

export interface IJiraUsersData {
  accountId: string
  avatarUrls: { [key: string]: string }
  displayName: string
  lastChanged?: string
  lastName?: string
  accountType?: string
}

export interface IDepartments {
  id: string
  displayName: string
}

export enum SeniorityLevelLabel {
  "Junior",
  "Regular",
  "Senior",
  "Lead",
}

export type SeniorityLevelType = "junior" | "regular" | "senior" | "lead"
