import firebase from "firebase/app"

enum ENTRY_TYPES {
  "project",
  "vacation",
  "sickness",
}

const isNewCreatedEntry = (entry: firebase.firestore.DocumentData) =>
  entry.action === "entryCreated"

const isUpdatePending = (entry: firebase.firestore.DocumentData) =>
  entry.action === "entryUpdated" && entry.after?.approval?.status === "pending"

const isUpdateApproved = (entry: firebase.firestore.DocumentData) =>
  entry.action === "entryUpdated" &&
  entry.after?.approval?.status === "approved"

const isNewCreatedEntryDeclined = (entry: firebase.firestore.DocumentData) =>
  entry.action === "entryDeleted"

const isHardLockRequest = (entry: firebase.firestore.DocumentData) =>
  entry.after?.approval?.type === "hard"

const isDelete = (entry: firebase.firestore.DocumentData) =>
  entry.after?.approval?.type === "delete"

const isDeleteActioned = (entry: firebase.firestore.DocumentData) =>
  entry.before?.approval?.type === "delete"

const extractEntryValues = (
  entry: firebase.firestore.DocumentData,
  getUserName: (id: string) => string | undefined,
) => {
  const entryType =
    (entry.after && !entry.after.deleted) || entry.before
      ? `for ${
          entry.after && !entry.after.deleted
            ? ENTRY_TYPES[entry.after.type as keyof typeof ENTRY_TYPES]
            : entry.before
            ? ENTRY_TYPES[entry.before.type as keyof typeof ENTRY_TYPES]
            : ""
        }`
      : ""
  const name =
    entry.after?.project?.displayName ??
    `for ${entry.after?.user?.lastName}, ${entry.after?.user?.firstName}` ??
    ""
  if (isNewCreatedEntry(entry)) {
    return {
      action: "requested new Entry",
      user: getUserName(entry.after.lastChanged) ?? "",
      name,
      entryType,
    }
  } else if (isUpdatePending(entry)) {
    return {
      action: isHardLockRequest(entry)
        ? "requests hard lock"
        : isDelete(entry)
        ? "requests delete Entry"
        : "requested Entry update",
      user:
        getUserName(entry.after.after?.lastChanged) ??
        getUserName(entry.after.lastChanged) ??
        "",
      name,
      entryType,
    }
  } else if (isUpdateApproved(entry)) {
    return {
      action: "approved Entry",
      user: getUserName(entry.after?.approval?.approvedBy) ?? "",
      name: `by ${getUserName(entry.after?.lastChanged) ?? ""}`,
      entryType,
    }
  } else if (isNewCreatedEntryDeclined(entry)) {
    return {
      action: isDeleteActioned(entry)
        ? "approved delete Entry"
        : "declined new Entry",
      user: getUserName(entry.after.lastChanged) ?? "",
      name: isDeleteActioned(entry)
        ? entry.before?.project?.displayName
        : `by ${getUserName(entry.before?.lastChanged) ?? ""}`,
      entryType,
    }
  } else {
    return {
      action: isDeleteActioned(entry) ? "declined delete" : "declined Update",
      user: getUserName(entry.after.lastChanged) ?? "",
      name: isDeleteActioned(entry)
        ? entry.before?.project?.displayName
        : `by ${getUserName(entry.before?.after?.lastChanged) ?? ""}`,
      entryType,
    }
  }
}

export default extractEntryValues
