import React from "react"
import { connect } from "react-redux"

import { OOO } from "../../data/calendar-data"
import { IDoc, setSelectedEntry } from "../../redux"

import "./calendar-entry.styles.scss"

interface ICalendarEntry {
  entry: IDoc
  setSelectedEntry: (enrty: IDoc) => void
}

const CalendarEntry: React.FunctionComponent<ICalendarEntry> = ({
  entry,
  setSelectedEntry,
}) => {
  const getClassName = () => {
    switch (entry.type) {
      case OOO:
        return entry.approval.status === "pending" &&
          entry.approval.type === "delete"
          ? "oooDeletePending"
          : entry.approval.status === "pending"
          ? "oooPending"
          : "ooo"

      default:
        break
    }

    switch (entry.approval.type) {
      case "delete":
        return "projectDeletePending"
      case "soft":
        return "soft"
      case "hard":
        return entry.approval.status === "approved" ? "hard" : "hardPending"
      case "draft":
        return "draft"
      default:
        break
    }
  }

  return (
    <div
      className={"calendar-entry " + getClassName()}
      onClick={() => setSelectedEntry(entry)}
    />
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedEntry: (entry: IDoc) => dispatch(setSelectedEntry(entry)),
})

export default connect(null, mapDispatchToProps)(CalendarEntry)
