import React from "react"
import { IClient } from "../../redux"

interface IFormatProjectData {
  displayName: string
  client: IClient | null
}

const FormatProjectData: React.FC<IFormatProjectData> = (projectData) => (
  <table id="nested-table">
    <thead>
      <tr>
        <th>Name:</th>
        <td>{projectData.displayName}</td>
      </tr>
      <tr>
        <th>Client:</th>
        <td>{projectData.client ? projectData.client.displayName : "none"}</td>
      </tr>
    </thead>
  </table>
)
export default FormatProjectData
