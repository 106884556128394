import React from "react"
import { connect } from "react-redux"

import "./calendar-backdrop.styles.scss"

interface ICalendarBackdropDay {
  date: Date
}

const CalendarBackdropDay: React.FunctionComponent<ICalendarBackdropDay> = (
  props,
) => {
  const { date } = props
  return (
    <div
      className="calendar-day-backdrop"
      style={
        date.getTime() ===
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
        ).getTime()
          ? {
              background: "#c9e0ff",
            }
          : {}
      }
    ></div>
  )
}

interface ICalendarBackdrop {
  date: Date
  animationClassName: string
  displayedDays: number
}

const CalendarBackdrop: React.FunctionComponent<ICalendarBackdrop> = (
  props,
) => {
  const { date, animationClassName, displayedDays } = props

  if (!date) {
    return null
  }

  const days = Array.from(Array(displayedDays).keys()).map(
    (i: number) => new Date(date.getFullYear(), date.getMonth(), i + 1),
  )

  return (
    <div className={"calendar-backdrop " + animationClassName}>
      {days.map((value, index) => {
        return <CalendarBackdropDay key={index} date={value} />
      })}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  date: state.calendar.startDate,
  displayedDays: state.calendar.displayedDays,
  animationClassName: state.calendar.animationClassName,
})

export default connect(mapStateToProps)(CalendarBackdrop)
