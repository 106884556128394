import { IDoc, IProject, IUser } from "../../../redux"

// TODO: break up into multiple functions
// - use for loop for efficiency here
export const filterDisplayedProjects = (
  projects: IProject[] | null,
  projectsFilter: string,
  entries: IDoc[],
  departmentsFilter: string[],
  users: IUser[],
) =>
  projects?.filter((project) => {
    const usersInProject = users.filter((user) =>
      entries.some(
        (entry) =>
          entry.project?.id === project.id && entry.user.id === user.id,
      ),
    )
    if (projectsFilter || departmentsFilter.length > 0) {
      let projectInFilters = []

      if (projectsFilter) {
        projectInFilters.push(
          project.displayName
            .toLowerCase()
            .includes(projectsFilter.toLowerCase()) ||
            project.client.displayName
              .toLowerCase()
              .includes(projectsFilter.toLowerCase()),
        )
      }

      if (departmentsFilter.length > 0) {
        projectInFilters.push(
          departmentsFilter.some((option) =>
            usersInProject.some((user) => user?.department?.id === option),
          ),
        )
      }

      return projectInFilters.every((value) => value)
    }
    return true
  }) || []
