import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IUser } from "../types"
import { RoleManager } from "../../utils"
import { IAccount, IRole } from "./types"

interface IUserState {
  currentUser: IAccount | null
  selectedUser: IUser | null
  roles: IRole | undefined
  roleManager: RoleManager
  atlassianUser: null
  accounts: IAccount[] | null
  accountRoles: IRole[] | undefined
}

const initialState: IUserState = {
  currentUser: null,
  selectedUser: null,
  roles: undefined,
  roleManager: new RoleManager(undefined),
  atlassianUser: null,
  accounts: null,
  accountRoles: undefined,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      action: PayloadAction<IUserState["currentUser"]>,
    ) => {
      return { ...state, currentUser: action.payload }
    },
    setSelectedUser: (
      state,
      action: PayloadAction<IUserState["selectedUser"]>,
    ) => {
      return { ...state, selectedUser: action.payload }
    },
    setRoles: (state, action: PayloadAction<IUserState["roles"]>) => {
      return {
        ...state,
        roles: action.payload,
        roleManager: new RoleManager(action.payload),
      }
    },
    setAccounts: (state, action: PayloadAction<IUserState["accounts"]>) => {
      return { ...state, accounts: action.payload }
    },
    setAccountRoles: (
      state,
      action: PayloadAction<IUserState["accountRoles"]>,
    ) => {
      return { ...state, accountRoles: action.payload }
    },
  },
})

export const {
  setAccounts,
  setCurrentUser,
  setRoles,
  setSelectedUser,
  setAccountRoles,
} = userSlice.actions

export const userReducer = userSlice.reducer
