import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { IJiraIssue } from "../../atlassian/atlassian.data"
import { EApprovalTypes, IAccount, IRateCard } from "../../redux"
import { IProject } from "../../redux"
import { IDepartments, IDoc, ILocations, IUser } from "../../redux"
import { reduceToDoc } from "../../AuthenticatedApp"
import { Entries } from "../../firebase"
import getRemainingBookedTime, {
  getBookedTime,
} from "./utils/getRemainingBookedTime"
import ProjectPageHeader from "./utils/ProjectPageHeader"
import ProjectRoadmap from "./project-roadmap.component"

import "./project-page.styles.scss"
import "../../components/roadmap/roadmap.styles.scss"

interface IProjectPage {
  calendarEntries: IDoc[]
  match: { params: { projectId: string } }
  projects: IProject[]
  departments: IDepartments[]
  users: IUser[]
  locations: ILocations[]
  currentUser: IAccount
  rateCards: IRateCard[]
}

export interface IUnbookedUser {
  accountId: string
  issues: IJiraIssue[]
}

export interface IUserEntriesByDepartment {
  departmentId: string
  userId: string
  user: IUser
  bookedTime: number
  remainingTime: number
  entries: ({ remainingTime: number } & IDoc)[]
}

export interface IUserJiraIssues {
  accountId: string
  issues: IJiraIssue[]
  totalTime: number
  timeSpent: number
}

export interface IDepartmentTimes {
  id: string
  remainingTime: number
  bookedTime: number
}

const ProjectPage: React.FunctionComponent<IProjectPage> = (props) => {
  const [entries, setEntries] = useState<IDoc[] | null>(null)

  const { projectId } = props.match.params
  const { projects, rateCards } = props

  useEffect(() => {
    const entriesCollectionRef = Entries.queryInProject(projectId)

    return entriesCollectionRef.onSnapshot(async (snapshot: any) => {
      setEntries(
        snapshot.docs
          .reduce(reduceToDoc, [] as IDoc[])
          .map(Entries.convertRawEntry),
      )
    })
  }, [projectId])

  const { departments, users, locations, currentUser } = props
  const project = projects
    ? projects.find((project) => project.id === projectId)
    : null

  if (!project) {
    return <div>Project not found</div>
  }

  let totalRemainingTime = 0
  let totalBookedTime = 0

  let userEntriesByDepartment: IUserEntriesByDepartment[] = []
  let departmentTimes: IDepartmentTimes[] = []

  if (entries && project) {
    entries.forEach((entry) => {
      if (entry.approval?.type === EApprovalTypes[EApprovalTypes.delete]) {
        return
      }

      const userId = entry.user.id || ""
      const user =
        users.find((user) => user.id === userId) ?? (entry.user as IUser)
      const departmentId = user?.department
        ? user.department.id
        : entry.user.department?.id
        ? entry.user.department.id
        : ""

      const departmentUserIndex = userEntriesByDepartment.findIndex(
        (departmentUserEntry) =>
          departmentUserEntry.departmentId === departmentId &&
          departmentUserEntry.userId === userId,
      )

      if (departmentUserIndex === -1) {
        userEntriesByDepartment.push({
          departmentId,
          userId,
          user,
          remainingTime: 0,
          bookedTime: 0,
          entries: [
            {
              ...entry,
              remainingTime: getRemainingBookedTime(
                entry.startDate,
                entry.endDate,
                user,
                entry.bookingRatio || 1,
                locations,
              ),
            },
          ],
        })
      } else {
        userEntriesByDepartment[departmentUserIndex].entries.push({
          ...entry,
          remainingTime:
            (entry &&
              getRemainingBookedTime(
                entry.startDate,
                entry.endDate,
                user,
                entry.bookingRatio || 1,
                locations,
              )) ||
            0,
        })
      }
    })

    if (entries.length > 0) {
      ;[...departments, { displayName: "No Department", id: "none" }].forEach(
        (department) => {
          const usersInDepartment = userEntriesByDepartment.filter(
            (userEntryInDepartment) =>
              userEntryInDepartment.departmentId === department.id,
          )
          if (usersInDepartment.length <= 0) {
            return
          }
          let departmentRemainingTime = 0
          let departmentBookedTime = 0
          usersInDepartment.forEach((userInDepartment) => {
            let userRemainingTime = 0
            let userBookedTime = 0
            userInDepartment.entries.forEach((entry) => {
              userRemainingTime += entry.remainingTime
              userBookedTime += getBookedTime(
                entry.startDate,
                entry.endDate,
                userInDepartment.user,
                entry.bookingRatio,
                locations,
              )
            })
            const departmentIndexOfUser = userEntriesByDepartment.findIndex(
              (userEntryInDepartment) =>
                userEntryInDepartment.departmentId === department.id &&
                userEntryInDepartment.userId === userInDepartment.userId,
            )
            userEntriesByDepartment[departmentIndexOfUser].remainingTime =
              userRemainingTime
            userEntriesByDepartment[departmentIndexOfUser].bookedTime =
              userBookedTime

            departmentRemainingTime += userRemainingTime
            departmentBookedTime += userBookedTime
          })

          departmentTimes.push({
            id: department.id,
            remainingTime: departmentRemainingTime,
            bookedTime: departmentBookedTime,
          })

          totalBookedTime += departmentBookedTime
          totalRemainingTime += departmentRemainingTime
        },
      )
    }
  }

  // Cost
  const rateCard = rateCards?.find(
    (rateCard) => rateCard.id === project.rateCard,
  )

  let totalCost = 0
  let totalUsedBudget = 0
  let budget: number[] = []
  let usedBudget: number[] = []

  if (rateCard) {
    departmentTimes.forEach((departmentTime) => {
      const departmentRate = rateCard.rates?.find(
        (rate) => departmentTime.id === rate.department.id,
      )?.rate
      if (departmentRate) {
        budget.push(departmentTime.bookedTime * departmentRate)
        usedBudget.push(
          (departmentTime.bookedTime - departmentTime.remainingTime) *
            departmentRate,
        )
        totalCost += departmentTime.bookedTime * departmentRate
        totalUsedBudget +=
          (departmentTime.bookedTime - departmentTime.remainingTime) *
          departmentRate
      } else {
        budget.push(0)
        usedBudget.push(0)
      }
    })
  }

  // Create our number formatter.
  var formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return (
    <div className="project-page">
      <ProjectPageHeader currentUser={currentUser} project={project} />
      {userEntriesByDepartment.length > 0 ? (
        <div>
          <h3>Roadmap</h3>
          <ProjectRoadmap
            entries={userEntriesByDepartment}
            totalBookedTime={totalBookedTime}
            project={project}
            users={users}
            locations={locations}
          />
        </div>
      ) : null}
      <div className="project-page-department">
        <h3>Booking Summary</h3>
        <table id="projects" style={{ width: "800px", margin: "50px 0px" }}>
          <tr>
            <th>Department</th>
            <th>Booked Time</th>
            <th>Remaining Time</th>
            <th>Progress (hours)</th>
            <th>Used Budget</th>
            <th>Used Budget %</th>
            <th>Budget</th>
          </tr>
          {departmentTimes.map((departmentTime, i) => {
            return (
              <tr key={i}>
                <td>
                  {
                    departments.find(
                      (department) => department.id === departmentTime.id,
                    )?.displayName
                  }
                </td>
                <td>{+departmentTime.bookedTime.toFixed(2)}h</td>
                <td>{+departmentTime.remainingTime.toFixed(2)}h</td>
                <td>
                  {
                    +(
                      (1 -
                        departmentTime.remainingTime /
                          departmentTime.bookedTime) *
                      100
                    ).toFixed(2)
                  }
                  %
                </td>
                <td>{formatter.format(usedBudget ? usedBudget[i] : 0)}</td>
                <td>
                  {usedBudget && budget && budget[i] > 0
                    ? ((usedBudget[i] / budget[i]) * 100).toFixed(2) + "%"
                    : "N/A"}
                </td>
                <td>{formatter.format(budget ? budget[i] : 0)}</td>
              </tr>
            )
          })}
          <tr>
            <th>Total</th>
            <th>{+totalBookedTime.toFixed(2)}h</th>
            <th>{+totalRemainingTime.toFixed(2)}h</th>
            <th>
              {+((1 - totalRemainingTime / totalBookedTime) * 100).toFixed(2)}%
            </th>
            <th>{formatter.format(totalUsedBudget)}</th>
            <th>{((totalUsedBudget / totalCost) * 100).toFixed(2)}%</th>
            <th>{formatter.format(totalCost)}</th>
          </tr>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  projects: state.projects.projects,
  rateCards: state.projects.rateCards,
  users: state.calendar.users,
  departments: state.calendar.departments,
  locations: state.calendar.locations,
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(ProjectPage)
