import React, { useState } from "react"
import { connect } from "react-redux"

import { addTeam as addFirebaseTeam } from "../../firebase"
import { IAccount } from "../../redux"
import OverlayBox from "../overlay-box/overlay-box.component"
import Select from "react-select"

interface IProps {
  currentUser: IAccount
  onCancel: () => void
  accounts: IAccount[] | null
}

const AddTeam: React.FunctionComponent<IProps> = (props) => {
  const { accounts } = props
  const [displayName, setDisplayName] = useState("")
  const userOptions =
    accounts?.map((user) => ({
      label: user.displayName,
      value: user.id,
    })) ?? []
  const [user, setUser] = useState<{ label: string; value: string } | null>(
    null,
  )
  const { currentUser, onCancel } = props

  const handleUserChange = (event: { label: string; value: string } | null) =>
    setUser(event ? event : null)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!displayName) {
      return
    }

    addFirebaseTeam(
      {
        displayName: displayName,
        teamLead: user ? { displayName: user.label, id: user.value } : null,
      },
      currentUser.id,
    )
    setDisplayName("")
    onCancel()
  }

  return (
    <OverlayBox heading="Add Team" onCancel={() => onCancel()}>
      <form className="add-client-form" onSubmit={handleSubmit}>
        <label>Team Name: </label>
        <input
          id="add-team-name"
          type="text"
          defaultValue={displayName}
          onChange={(e: any) => setDisplayName(e.target.value)}
        />
        <label>Team lead: </label>
        <Select
          options={userOptions}
          onChange={handleUserChange}
          value={user}
        />
        <div />
        <input
          id="add-team-submit"
          type="submit"
          value="Add Team"
          className="submit-button"
        />
      </form>
    </OverlayBox>
  )
}

const mapStateToProps = (state: any) => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(AddTeam)
