import React, { useEffect } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { ToastContainer } from "react-toastify"
import { auth, createUserProfileDocument } from "./firebase"
import SignIn from "./pages/sign-in/sign-in.component"
import Calendar from "./pages/calendar/calendar.component"
import Header from "./components/header/header.component"
import {
  IAccount,
  IClient,
  IDepartments,
  IDoc,
  IEntries,
  ILocations,
  IProject,
  IRateCard,
  IRole,
  ITeams,
  IUser,
  RootState,
  setAccountRoles,
  setAccounts,
  setClients,
  setCurrentUser,
  setDepartments,
  setEntries,
  setLocations,
  setProjects,
  setRateCards,
  setRoles,
  setTeams,
  setUsers,
} from "./redux"
import ProjectPage from "./pages/projects/projects-page.component"
import "react-toastify/dist/ReactToastify.css"
import "./App.css"
import SettingsPage from "./pages/settings/settings.component"
import CollectionPrinter from "./components/collection-printer/collection-printer.component"
import TimesheetsPage from "./pages/timesheets/timesheets-page.component"
import AuthenticatedApp from "./AuthenticatedApp"
import Capacities from "./pages/capacities/Capacities"
import AtlassianPage from "./pages/atlassian/atlassian-page.component"

type TAppProps = {
  pendingEntries: IDoc[] | null
  currentUser: IAccount | null
  currentUserRoles: IRole | undefined
  accounts: IAccount[] | null
  setCurrentUser: (user: IAccount | null) => void
  setUsers: (users: IUser[]) => void
  setDepartments: (departments: IDepartments[]) => void
  setTeams: (teams: ITeams[]) => void
  setEntries: (
    entries: IEntries,
    currentUser: IAccount,
    isSuperAdmin: boolean,
  ) => void
  setProjects: (projects: IProject[]) => void
  setRateCards: (rateCards: IRateCard[]) => void
  setClients: (clients: IClient[]) => void
  setLocations: (locations: ILocations[]) => void
  setRoles: (role: IRole | undefined) => void
  setAccounts: (accounts: IAccount[]) => void
  setAccountRoles: (roles: IRole[] | undefined) => void
}

const App: React.FunctionComponent<TAppProps> = ({
  currentUser,
  currentUserRoles,
  pendingEntries,
  accounts,
  setCurrentUser,
  setUsers,
  setDepartments,
  setTeams,
  setEntries,
  setProjects,
  setRateCards,
  setClients,
  setLocations,
  setRoles,
  setAccounts,
  setAccountRoles,
}) => {
  useEffect(() => {
    return auth.onAuthStateChanged(async (userAuth) => {
      if (currentUser) {
        if (userAuth == null) {
          setCurrentUser(null)
          return
        }

        // don't update the user if we have already have one
        return
      }

      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth)

        userRef?.onSnapshot((snapshot: any) => {
          setCurrentUser({
            id: snapshot.id,
            ...snapshot.data(),
          })
        })
      } else {
        setCurrentUser(null)
      }
    })
  }, [setCurrentUser, currentUser])

  return (
    <div className="App">
      <Header pendingApprovals={pendingEntries?.length ?? 0} />
      {currentUser ? (
        <AuthenticatedApp
          currentUser={currentUser}
          userHasPermission={currentUserRoles?.superadmin}
          setClients={setClients}
          setLocations={setLocations}
          setDepartments={setDepartments}
          setEntries={setEntries}
          setRoles={setRoles}
          setTeams={setTeams}
          setUsers={setUsers}
          setProjects={setProjects}
          setRateCards={setRateCards}
          setAccounts={setAccounts}
          setAccountRoles={setAccountRoles}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/calendar" />
            </Route>
            <Route path="/capacities" component={Capacities} />
            <Route path="/atlassian" component={AtlassianPage} />
            <Route path="/projects" component={ProjectPage} />
            <Route path="/calendar" component={Calendar} />
            <Route path="/settings">
              <SettingsPage
                accounts={accounts}
                currentUser={currentUser}
                currentUserRoles={currentUserRoles}
                pendingEntries={pendingEntries}
              />
            </Route>
            <Route path="/collection" component={CollectionPrinter} />
            <Route path="/timesheets" component={TimesheetsPage} />
            <Route exact path="*">
              <Redirect to="/calendar" />
            </Route>
          </Switch>
        </AuthenticatedApp>
      ) : (
        <Switch>
          <Route path="/login" component={SignIn} />
          <Route exact path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      )}
      <ToastContainer />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  accounts: state.user.accounts,
  pendingEntries: state.calendar.pendingEntries,
  currentUser: state.user.currentUser,
  currentUserRoles: state.user.roles,
  atlassianUser: state.user.atlassianUser,
})

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: IAccount | null) => dispatch(setCurrentUser(user)),
  setDepartments: (departments: IDepartments[]) =>
    dispatch(setDepartments(departments)),
  setTeams: (teams: ITeams[]) => dispatch(setTeams(teams)),
  setUsers: (users: IUser[]) => dispatch(setUsers(users)),
  setEntries: (
    entries: IEntries,
    currentUser: IAccount,
    isSuperadmin: boolean,
  ) => dispatch(setEntries({ entries, currentUser, isSuperadmin })),
  setProjects: (projects: IProject[]) => dispatch(setProjects(projects)),
  setRateCards: (rateCards: IRateCard[]) => dispatch(setRateCards(rateCards)),
  setClients: (clients: IClient[]) => dispatch(setClients(clients)),
  setLocations: (locations: ILocations[]) => dispatch(setLocations(locations)),
  setRoles: (roles: IRole | undefined) => dispatch(setRoles(roles)),
  setAccounts: (accounts: IAccount[]) => dispatch(setAccounts(accounts)),
  setAccountRoles: (roles: IRole[] | undefined) =>
    dispatch(setAccountRoles(roles)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
