import { IAccount, IDoc, IRole, ITeams, IUser } from "../../../redux"

// Define the filter function
export const filterPendingEntries = (
  entries: IDoc[],
  currentUser: IAccount,
  currentUserRole: IRole,
  teams: ITeams[],
  users: IUser[],
) => {
  return entries.filter((entry) => {
    // Check if the entry is pending.
    if (entry.approval.status !== "pending") {
      return false
    }
    if (currentUserRole?.superadmin) {
      // If the user is a superadmin, no further filtering is needed.
      return true
    }

    // Determine if the current user is a team lead and get the teams they lead.
    const teamsLedByCurrentUser = teams
      .filter((team) => team.teamLead?.id === currentUser?.id)
      .map((team) => team.id)

    // Check if the current user is a team lead by checking if they lead any teams.
    const isTeamLead = teamsLedByCurrentUser.length > 0

    if (isTeamLead) {
      // Get the teams that the entry's user belongs to.
      const entryUserTeams = Object.keys(
        users.find((user) => user.id === entry.user.id)?.teams ?? {},
      )

      // Check if any of the entry user's teams is led by the current user.
      return entryUserTeams.some((teamId) =>
        teamsLedByCurrentUser.includes(teamId),
      )
    }

    // If not a team lead or superadmin, no entries should be filtered in.
    return false
  })
}
