import React, { useState, useCallback, useMemo } from "react"
import { IDepartments, IDoc, IProject, IUser } from "../../../../redux"
import { roundToTwoDecimal } from "../../../../utils"
import ContentRowItem from "../ContentRowItem"
import UserContentRowItem from "./UserContentRowItem"

interface IBookedOverviewContentRow {
  users: IUser[]
  department: IDepartments
  userFilter: string
  openedDepartment: string
  entries: IDoc[]
  startDate: Date
  projectFilter: string[]
  statusFilter: string[]
  showHours: boolean
  excludePast: boolean
  projects: IProject[] | null
}

export const CONTENT_MONTHS: (null | Record<IUser["id"], number>)[] =
  Array(12).fill(null)

const BookedOverviewContentRow: React.FC<IBookedOverviewContentRow> = ({
  users,
  department,
  userFilter,
  openedDepartment,
  entries,
  startDate,
  projectFilter,
  statusFilter,
  showHours,
  excludePast,
  projects,
}) => {
  const [departmentSummedRatio, setDepartmentSummedRatio] =
    useState(CONTENT_MONTHS)

  const filteredDepartmentRatio: (null | Record<IUser["id"], number>)[] =
    useMemo(() => {
      return departmentSummedRatio.map((summedRatio) => {
        let filteredRatio = {}
        users.forEach((project) => {
          if (summedRatio && summedRatio.hasOwnProperty(project.id)) {
            filteredRatio = {
              ...filteredRatio,
              [project.id]: summedRatio[project.id],
            }
          }
        })
        return filteredRatio
      })
    }, [users, departmentSummedRatio])

  const onUserRatioPerMonthChanged = useCallback(
    (userid, arg) => {
      setDepartmentSummedRatio((x) => {
        return x.map((oldUsers, month) => ({
          ...oldUsers,
          [userid]: arg[month],
        }))
      })
    },
    [setDepartmentSummedRatio],
  )

  const departmentRatio = useMemo(() => {
    return filteredDepartmentRatio.map((usersMonthRatio) =>
      showHours
        ? usersMonthRatio &&
          Object.values(usersMonthRatio).reduce(
            (acc, cur) =>
              acc + (cur > 0 ? (!showHours && cur > 1 ? 1 : cur) : 0),
            0,
          )
        : usersMonthRatio &&
          Object.values(usersMonthRatio).reduce(
            (acc, cur) =>
              acc + (cur > 0 ? (!showHours && cur > 1 ? 1 : cur) : 0),
            0,
          ) /
            Object.values(usersMonthRatio).filter((ratio) => ratio !== -1)
              .length,
    )
  }, [filteredDepartmentRatio, showHours])

  return (
    <div>
      <div className="booking-ratio-list-content-row">
        {CONTENT_MONTHS.map((val, month) => (
          <ContentRowItem
            key={"ratio-content-months" + month}
            value={
              !isNaN(departmentRatio?.[month] ?? 0) &&
              departmentRatio?.[month] !== Infinity
                ? roundToTwoDecimal(departmentRatio?.[month] ?? 0)
                : 0
            }
            userCount={users.length}
            showHours={showHours}
          />
        ))}
      </div>
      <div
        className="calendar-group-dropdown"
        style={
          userFilter || openedDepartment === department.displayName
            ? { maxHeight: "initial" }
            : { maxHeight: "0" }
        }>
        {users &&
          users.map((user) => (
            <UserContentRowItem
              projectFilter={projectFilter}
              statusFilter={statusFilter}
              startDate={startDate}
              key={"user-list-content-item-" + user.id}
              notCollapsed={
                !!userFilter || openedDepartment === department.displayName
              }
              user={user}
              entries={entries}
              onUserRatioPerMonthChanged={onUserRatioPerMonthChanged}
              showHours={showHours}
              excludePast={excludePast}
              projects={projects}
            />
          ))}
      </div>
    </div>
  )
}

export default BookedOverviewContentRow
