import { IProject as IProjectAction } from "../redux"
import { errorToast, firestore, successToast } from "./firebase.utils"

interface IProject {
  displayName?: string
  client?: { displayName: string; id: string }
  jira?: {
    avatarUrls?: { [key: string]: string }
    id?: string
    key?: string
    name?: string
    self?: string
    status?: string
    strikeGroup?: string
    
  } | null
}

export const addProject = async (project: IProject, uid: string) => {
  try {
    await firestore.collection("projects").add({ ...project, lastChanged: uid })
    successToast("Successfully added Project")
  } catch (err) {
    errorToast("Failed to add Project")
    return
  }
}

export const updateProject = async (
  id: string,
  project: IProject,
  uid: string,
) => {
  try {
    await firestore
      .collection("projects")
      .doc(id)
      .update({ ...project, lastChanged: uid })
    successToast("Successfully updated Project")
  } catch (err) {
    errorToast("Failed to update Project")
    return
  }
}

export const archiveProject = async (project: IProjectAction, uid: string) => {
  try {
    await firestore
      .collection("projects")
      .doc(project.id)
      .set({ ...project, archived: true, lastChanged: uid })
  } catch (err) {
    return
  }
}

export const unarchiveProject = async (
  project: IProjectAction,
  uid: string,
) => {
  try {
    await firestore
      .collection("archivedProjects")
      .doc(project.id)
      .set({ ...project, archived: false, lastChanged: uid })
  } catch (err) {
    return
  }
}

export const deleteProject = async (project: IProjectAction, uid: string) => {
  try {
    await firestore
      .collection("projects")
      .doc(project.id)
      .set({ deleted: true, lastChanged: uid })
    successToast("Successfully deleted Project")
  } catch (err) {
    errorToast("Failed to delete Project")
    return
  }
}
