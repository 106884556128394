import React from "react"
import "./sign-in.styles.scss"
import LogoEffect from "../../components/logo-effect/logo-effect.component"
import MicrosoftSignIn from "../../components/microsoft-sign-in/microsoft-sign-in.component"

const SignIn: React.FunctionComponent = () => (
  <div className="sign-in">
    <div className="sign-in-container">
      <div className="sign-in-logo">
        <LogoEffect />
      </div>
      <h1>Resource Planner</h1>
      <div className="microsoft-sign-in-wrapper">
        <MicrosoftSignIn />
      </div>
    </div>
  </div>
)

export default SignIn
