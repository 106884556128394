import React, { ReactNode } from "react"
import { Link, useLocation } from "react-router-dom"

import "./sidebar-link.styles.scss"

interface SidebarProps {
  isExact?: boolean
  to: string
  children: ReactNode
}

const SidebarLink: React.FC<SidebarProps> = ({
  to = "",
  children = null,
  isExact = false,
}) => {
  const location = useLocation()

  return (
    <div
      className={
        "sidebar-link " +
        (isExact
          ? location.pathname === to
            ? "active"
            : ""
          : location.pathname.includes(to)
          ? "active"
          : "")
      }
    >
      <Link to={to}>{children}</Link>
    </div>
  )
}

export default SidebarLink
