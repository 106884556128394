import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IDoc, IEntries, ILocations, ITeams, SetEntriesSortedBy } from "./types"
import { IDepartments, IUser } from "../types"
import { IProject } from "../projects"
import { IAccount } from "../user"
import { TApprovalStatusOptions } from "../../components/filter-approval-status/FilterApprovalStatus"

interface ICalendarState {
  users: IUser[] | null
  entries: IDoc[] | null
  departments: IDepartments[] | null
  teams: ITeams[] | null
  today: Date
  startDate: Date
  displayedDays: number
  projects: IProject[] | null
  userFilter: string
  teamsFilter: string[]
  locations: ILocations[] | null
  selectedLocation: ILocations | null
  selectedEntry: IDoc | null
  selectedDepartment: IDepartments | null
  addDepartmentVisibility: boolean
  entrySnapshots: IEntries[]
  approvalStatusFilter: TApprovalStatusOptions[]
  pendingEntries: IDoc[]
  entriesSortedBy: SetEntriesSortedBy

  // Animations
  animationClassName: string
}

const initialState: ICalendarState = {
  users: null,
  entries: null,
  departments: null,
  teams: null,
  today: new Date(),
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  displayedDays: 31,
  projects: null,
  userFilter: "",
  teamsFilter: [],
  locations: null,
  selectedLocation: null,
  selectedEntry: null,
  selectedDepartment: null,
  addDepartmentVisibility: false,
  entrySnapshots: [],
  approvalStatusFilter: [],
  pendingEntries: [],
  entriesSortedBy: "nameDesc",

  // Animations
  animationClassName: "",
}

enum ESortDirection {
  ASC = "ascending",
  DESC = "descending",
}

const isNotDocId = (docId: IDoc["id"]) => (doc: IDoc) => doc.id !== docId
const isDocId = (docId: IDoc["id"]) => (doc: IDoc) => doc.id === docId
const sortDocByDate =
  (direction: ESortDirection) => (docA: IDoc, docB: IDoc) => {
    switch (direction) {
      case ESortDirection.ASC:
        return docA.timeStamp.toDate() > docB.timeStamp.toDate() ? 1 : -1
      case ESortDirection.DESC:
        return docA.timeStamp.toDate() < docB.timeStamp.toDate() ? 1 : -1
    }
  }

function getEntries(entrySnapshots: IEntries[]) {
  const uniqueEntries = entrySnapshots
    .map((entrySnapshot) => entrySnapshot.docs)
    .flat()
    .reduce((map, entry) => {
      if (!map.has(entry.id)) {
        map.set(entry.id, entry)
      }
      return map
    }, new Map<string, IDoc>())

  return Array.from(uniqueEntries.values())
}

type SetEntriesPayload = {
  entries: IEntries
  currentUser: IAccount
  isSuperadmin: boolean
}

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<ICalendarState["users"]>) => {
      return { ...state, users: action.payload }
    },
    setEntries: (state, action: PayloadAction<SetEntriesPayload>) => {
      const index = state.entrySnapshots.findIndex(
        (entrySnapshot) =>
          entrySnapshot.startDate.getTime() ===
          action.payload.entries.startDate.getTime(),
      )

      let entrySnapshots = [...state.entrySnapshots] ?? []
      if (index > -1) {
        entrySnapshots[index] = action.payload.entries
      } else {
        entrySnapshots = [...state.entrySnapshots, action.payload.entries]
      }
      const entries: IDoc[] = getEntries(entrySnapshots)

      const pendingEntries = entries
        ?.filter(
          (entry) => entry.approval.status === "pending" /*&&
            (!action.payload.isSuperadmin
              ? entry.lastChanged === action.payload.currentUser.id
              : true)*/,
        )
        .sort(sortDocByDate(ESortDirection.ASC))

      return {
        ...state,
        entrySnapshots: entrySnapshots,
        entries,
        pendingEntries,
      }
    },
    updateEntry: (state, action: PayloadAction<IDoc>) => {
      // We need to keep the sorting order, therefore we need to do deep updates instead of appending the latest state

      const oldDocInEntriesIndex = state.entries?.findIndex(
        isDocId(action.payload.id),
      )
      const oldDocInPendingEntriesIndex = state.pendingEntries?.findIndex(
        isDocId(action.payload.id),
      )

      if (
        !!oldDocInEntriesIndex &&
        oldDocInEntriesIndex > -1 &&
        state.entries?.[oldDocInEntriesIndex]
      ) {
        // we can safely mutate the state because redux-toolkit uses immer!
        state.entries[oldDocInEntriesIndex] = action.payload
      }

      if (
        oldDocInPendingEntriesIndex > -1 &&
        state.pendingEntries[oldDocInPendingEntriesIndex]
      ) {
        // we can safely mutate the state because redux-toolkit uses immer!
        state.pendingEntries[oldDocInPendingEntriesIndex] = action.payload
      }
    },
    deleteEntry: (state, action: PayloadAction<IDoc["id"]>) => {
      const entries: IDoc[] | null =
        state.entries?.filter(isNotDocId(action.payload)) ?? []
      const pendingEntries: IDoc[] | null =
        state.pendingEntries.filter(isNotDocId(action.payload)) ?? []

      return { ...state, entries, pendingEntries }
    },
    deletePendingEntry: (state, action: PayloadAction<IDoc["id"]>) => {
      const pendingEntries: IDoc[] | null =
        state.pendingEntries.filter(isNotDocId(action.payload)) ?? []

      return { ...state, pendingEntries }
    },
    setDepartments: (
      state,
      action: PayloadAction<ICalendarState["departments"]>,
    ) => {
      return { ...state, departments: action.payload }
    },
    setTeams: (state, action: PayloadAction<ICalendarState["teams"]>) => {
      return { ...state, teams: action.payload }
    },
    setStartDate: (
      state,
      action: PayloadAction<ICalendarState["startDate"]>,
    ) => {
      return { ...state, startDate: action.payload }
    },
    setUserFilter: (
      state,
      action: PayloadAction<ICalendarState["userFilter"]>,
    ) => {
      return { ...state, userFilter: action.payload }
    },
    setTeamsFilter: (
      state,
      action: PayloadAction<ICalendarState["teamsFilter"]>,
    ) => {
      return { ...state, teamsFilter: action.payload }
    },
    setLocations: (
      state,
      action: PayloadAction<ICalendarState["locations"]>,
    ) => {
      return { ...state, locations: action.payload }
    },
    setSelectedLocation: (
      state,
      action: PayloadAction<ICalendarState["selectedLocation"]>,
    ) => {
      return { ...state, selectedLocation: action.payload }
    },
    setSelectedEntry: (
      state,
      action: PayloadAction<ICalendarState["selectedEntry"]>,
    ) => {
      return { ...state, selectedEntry: action.payload }
    },
    setSelectedDepartment: (
      state,
      action: PayloadAction<ICalendarState["selectedDepartment"]>,
    ) => {
      return { ...state, selectedDepartment: action.payload }
    },
    setAddDepartmentVisibility: (
      state,
      action: PayloadAction<ICalendarState["addDepartmentVisibility"]>,
    ) => {
      return { ...state, addDepartmentVisibility: action.payload }
    },
    setApprovalStatusFilter: (
      state,
      action: PayloadAction<ICalendarState["approvalStatusFilter"]>,
    ) => {
      return { ...state, approvalStatusFilter: action.payload }
    },
    setAnimationClassName: (
      state,
      action: PayloadAction<ICalendarState["animationClassName"]>,
    ) => {
      return { ...state, animationClassName: action.payload }
    },
    setEntriesSortedBy: (
      state,
      action: PayloadAction<ICalendarState["entriesSortedBy"]>,
    ) => {
      return { ...state, entriesSortedBy: action.payload }
    },
  },
})

export const {
  setDepartments,
  setAnimationClassName,
  setLocations,
  setEntriesSortedBy,
  setAddDepartmentVisibility,
  setApprovalStatusFilter,
  setUserFilter,
  setTeamsFilter,
  setTeams,
  setUsers,
  setSelectedDepartment,
  setSelectedEntry,
  setSelectedLocation,
  setStartDate,
  setEntries,
  updateEntry,
  deleteEntry,
  deletePendingEntry,
} = calendarSlice.actions

export const calendarReducer = calendarSlice.reducer
