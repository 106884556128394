import { getAtlassianCode } from "../atlassian/atlassian.utils"
import { functions } from "../firebase"

export const atlassianSignIn = async () => {
  try {
    const atlassianCode = getAtlassianCode()

    if (!atlassianCode) {
      return "Error: No atlassian code found!"
    }

    const atlassianSignIn = functions.httpsCallable("api-atlassianSignIn")

    const response = await atlassianSignIn({ code: atlassianCode })

    if (response.data.ok) {
      return "Sign-in successful!"
    }

    const messageObj = JSON.parse(response.data)

    if (messageObj.error) {
      throw messageObj
    }

    return messageObj
  } catch (err) {
    console.log(err)
    return "An error happened!"
  }
}

/**
 *
 * @param {string} query
 */
export const getUsers = async (query: string) => {
  try {
    const atlassianUsers = functions.httpsCallable("api-atlassianUsers")

    const response = await atlassianUsers({ query: query })

    return response && response.data ? response.data : response
  } catch (err) {
    return
  }
}

/**
 *
 * @param {string} query
 */
export const getProjects = async (query: string) => {
  try {
    const atlassianProjects = functions.httpsCallable("api-atlassianProjects")

    const response = await atlassianProjects({ query: query })

    return response && response.data ? response.data : response
  } catch (err) {
    return
  }
}

/**
 * Enter JQL query to get issues.
 * @param {string} jql
 */
export const getIssues = async (jql: string) => {
  try {
    const atlassianIssues = functions.httpsCallable("api-atlassianIssues")

    const response = await atlassianIssues({ query: jql })

    return response.data
  } catch (err) {
    return
  }
}

/**
 *
 * @param {number} since Timestamp
 */
export const getWorklogs = async (
  since: number,
  until: number,
): Promise<{ success: boolean } | void> => {
  try {
    const atlassianWorklogs = functions.httpsCallable("api-atlassianWorklogs")

    const response = await atlassianWorklogs({ since: since, until: until })

    return response.data
  } catch (err) {
    return
  }
}

export const getIssueWorklogs = async (issueIds: string[]) => {
  try {
    const issueWorklogs = functions.httpsCallable("api-atlassianIssueWorklogs")

    const response = await issueWorklogs({ issueIds: issueIds })

    return response.data
  } catch (err) {
    return
  }
}

export const syncProjectsTest = async () => {
  try {
    console.log("Sync projects called!")
    const response: any = await functions.httpsCallable("api-syncProjects")({})

    console.log(response)

    return response
  } catch (err) {
    return
  }
}

export const pushProjectsTest = async () => {
  try {
    console.log("Test issue update called!")
    const response: any = await functions.httpsCallable("api-testIssueUpdate")(
      {},
    )

    console.log(response)

    return response
  } catch (err) {
    return
  }
}
