import { IRole } from "../redux"

// TODO!

// Class to easily check if the user has a role. Gets initiated in App.js and then added to redux
export class RoleManager {
  userRoles: IRole | undefined

  constructor(roles: IRole | undefined) {
    this.userRoles = roles
  }

  hasRole(roles: string[]) {
    let roleFound = false
    const userRoles = this.userRoles

    if (roles && userRoles) {
      roles.forEach((role: string) => {
        //@ts-ignore
        if (userRoles.hasOwnProperty(role) && userRoles[role]) {
          roleFound = true
        }
      })
    }

    return roleFound
  }
}
