import { filterUserByDepartment } from "../../../../components/calendar-content/calendarFilters"
import { IDepartments, IDoc, IProject, IUser } from "../../../../redux"
import BookedOverviewContentRow from "./BookedOverviewContentRow"

interface IBookedOverview {
  departments: IDepartments[]
  users: IUser[]
  userFilter: string
  openedDepartment: string
  entries: IDoc[]
  startDate: Date
  projectFilter: string[]
  statusFilter: string[]
  showHours: boolean
  excludePast: boolean
  projects: IProject[] | null
}

const BookedOverview: React.FC<IBookedOverview> = ({
  departments,
  users,
  userFilter,
  openedDepartment,
  entries,
  startDate,
  projectFilter,
  statusFilter,
  showHours,
  projects,
  excludePast,
}) => {
  return (
    <div className="booked-ratio-overview-content">
      {departments?.map((department) => (
        <BookedOverviewContentRow
          projectFilter={projectFilter}
          statusFilter={statusFilter}
          startDate={startDate}
          key={"ratio-content-row" + department.id}
          openedDepartment={openedDepartment}
          users={filterUserByDepartment(users, department)}
          department={department}
          userFilter={userFilter}
          entries={entries}
          showHours={showHours}
          excludePast={excludePast}
          projects={projects}
        />
      ))}
    </div>
  )
}

export default BookedOverview
