import { IDoc, IProject, IUser } from "../../../../redux"
import { CONTENT_MONTHS } from "../BookedRatio/BookedOverviewContentRow"
import ContentRowItem from "../ContentRowItem"
import HeadcountProjectContentRow from "./HeadcountProjectContentRow"
import React, { useCallback, useMemo, useState, VFC } from "react"
import { roundToTwoDecimal } from "../../../../utils"
import {
  TMaybeAllocatedProjectData,
  TOnProjectHeadcountPerMonthChangedFn,
} from "./types"
import "../../tooltip.styles.scss"
import {
  filterAllocatedProjects,
  filterNullable,
  getAllUsersAvailability,
  mapProjectHeadcount,
  mapToHeadcountDepartments,
} from "./dataProcessing"
import { AllocatedHeadcountTooltip, TotalHeadcountTooltip } from "./Tooltips"

type THeadcountContentProps = {
  projects: IProject[]
  users: IUser[]
  entries: IDoc[]
  startDate: Date
}

const HeadcountContent: VFC<THeadcountContentProps> = ({
  projects,
  users,
  entries,
  startDate,
}) => {
  const [allocatedProjectData, setAllocatedProjectData] = useState<
    TMaybeAllocatedProjectData[]
  >(Array(12).fill(null))

  const filteredAllocatedProjectData: TMaybeAllocatedProjectData[] = useMemo(
    () => allocatedProjectData.map(filterAllocatedProjects(projects)),
    [projects, allocatedProjectData],
  )

  const handleProjectHeadcountPerMonthChanged: TOnProjectHeadcountPerMonthChangedFn =
    useCallback(
      (projectId, { departmentsPerMonth, headcountPerMonth }) =>
        setAllocatedProjectData((currentData) =>
          currentData.map((oldProjects, month) => ({
            ...oldProjects,
            [projectId]: {
              headcount: headcountPerMonth[month],
              departments: departmentsPerMonth[month],
            },
          })),
        ),
      [setAllocatedProjectData],
    )

  const projectHeadcount = useMemo(
    () => filterNullable(filteredAllocatedProjectData).map(mapProjectHeadcount),
    [filteredAllocatedProjectData],
  )

  const allocatedHeadcountDepartments = useMemo(
    () =>
      filterNullable(filteredAllocatedProjectData).map(
        mapToHeadcountDepartments,
      ),
    [filteredAllocatedProjectData],
  )

  const allUsersAvailability = useMemo(
    () => getAllUsersAvailability(users),
    [users],
  )

  const totalHeadcount: number[] = useMemo(() => {
    return Array(12).fill(roundToTwoDecimal(allUsersAvailability.total))
  }, [allUsersAvailability.total])

  return (
    <div className="booked-ratio-overview-content">
      <div className="booking-ratio-list-content-row">
        {CONTENT_MONTHS.map((_, index) => (
          <ContentRowItem
            key={"content-rate-" + index}
            value={roundToTwoDecimal(
              ((projectHeadcount?.[index] || 0) / totalHeadcount[index]) * 100,
            )}
            opacity={(projectHeadcount?.[index] || 0) / totalHeadcount[index]}
            type={"rate"}
            showHours={false}
          />
        ))}
      </div>

      <div className="booking-ratio-list-content-row">
        {CONTENT_MONTHS.map((_, index) => (
          <div>
            <TotalHeadcountTooltip
              headcountPerDepartment={allUsersAvailability.departments}
            />

            <ContentRowItem
              key={"content-total-" + index}
              value={roundToTwoDecimal(totalHeadcount[index])}
              opacity={1}
              type={"default"}
              showHours={false}
            />
          </div>
        ))}
      </div>

      <div className="booking-ratio-list-content-row">
        {CONTENT_MONTHS.map((_, index) => (
          <div>
            {!!allocatedHeadcountDepartments[index] && (
              <AllocatedHeadcountTooltip
                headcountPerMonth={allocatedHeadcountDepartments[index]}
              />
            )}
            <ContentRowItem
              key={"content-allocated-" + index}
              value={roundToTwoDecimal(projectHeadcount[index] ?? 0)}
              opacity={(projectHeadcount?.[index] || 0) / totalHeadcount[index]}
              type={"default"}
              showHours={false}
            />
          </div>
        ))}
      </div>

      <div>
        {projects.map((project) => (
          <HeadcountProjectContentRow
            startDate={startDate}
            totalHeadcount={totalHeadcount}
            key={"content" + project.id}
            onProjectHeadcountPerMonthChanged={
              handleProjectHeadcountPerMonthChanged
            }
            project={project}
            users={users}
            entries={entries}
          />
        ))}
      </div>
    </div>
  )
}

export default HeadcountContent
