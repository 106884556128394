import React from "react"

import "../../../firebase"
import { IUser } from "../../../redux"

import AvatarIcon from "../../avatar-icon/avatar-icon.component"
import JiraUserCard from "../../jira-user-card/jira-user-card.component"

import "./user-list-entry.styles.scss"

interface IUserListEntry {
  doc: IUser
  key: number
}

const userListEntry = (props: IUserListEntry) => {
  const { doc, key } = props
  const teams = doc.teams
  return {
    name: (
      <div className="user-list-user">
        <AvatarIcon user={doc} />
        <div>
          {doc.firstName} {doc.lastName}
          <div className="user-list-mail">{doc.email}</div>
        </div>
      </div>
    ),
    department: doc.department ? doc.department.displayName : "",
    teams: teams
      ? Object.keys(teams).map((key, id) => (
          <div key={id}>{teams[key].displayName}</div>
        ))
      : "",
    location: doc.location ? doc.location.displayName : "",
    jira: doc.jira ? <JiraUserCard user={doc.jira} size="small" /> : "",
    tableData: { id: key },
  }
}

export default userListEntry
