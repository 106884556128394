import MaterialTable from "material-table"
import { connect } from "react-redux"
import Select, { OptionsType } from "react-select"
import { tableDefaultOptions } from "../../data/default-data"
import { addRole, updateRole } from "../../firebase"
import { RootState } from "../../redux"
import { IAccount, IRole } from "../../redux"
import SearchBar from "../search-bar/search-bar.component"
import filterUserRoles from "./filter-user-roles"
import React, { useState } from "react"
import FilterRoles from "../filter-roles/FilterRoles"

interface IAdminView {
  accounts: IAccount[] | null
  accountRoles: IRole[] | undefined
}

const isRoleFilterAndNoUserFilter = (
  roleFilter: string[],
  userFilter: string,
  isInRoleFilter: boolean,
) => roleFilter.length > 0 && userFilter.length === 0 && isInRoleFilter

const isUserFilterAndNoRoleFilter = (
  roleFilter: string[],
  userFilter: string,
  isInUserFilter: boolean,
) => roleFilter.length === 0 && userFilter.length > 0 && isInUserFilter

const isUserFilterAndRoleFilter = (
  roleFilter: string[],
  userFilter: string,
  isInRoleFilter: boolean,
  isInUserFilter: boolean,
) =>
  roleFilter.length > 0 &&
  userFilter.length > 0 &&
  isInRoleFilter &&
  isInUserFilter

const accountAppliesToFilters = (
  account: IAccount,
  accountRoles: IRole[] | undefined,
  roleFilter: string[],
  searchField: string,
) => {
  const roles = accountRoles?.find(
    (accountRole) => account.id === accountRole.id,
  )
  const userRoles =
    roles &&
    (Object.keys(roles) as Array<keyof IRole>).filter(
      (role) => role !== "id" && roles.hasOwnProperty(role) && roles[role],
    )

  const hasFilterRole = roleFilter.some((role) =>
    userRoles?.find((userRole) => userRole === role),
  )
  const hasUserFilter = account.displayName
    .toLowerCase()
    .includes(searchField.toLowerCase())
  return (
    isRoleFilterAndNoUserFilter(roleFilter, searchField, hasFilterRole) ||
    isUserFilterAndNoRoleFilter(roleFilter, searchField, hasUserFilter) ||
    isUserFilterAndRoleFilter(
      roleFilter,
      searchField,
      hasFilterRole,
      hasUserFilter,
    )
  )
}

const AdminView: React.FC<IAdminView> = ({ accounts, accountRoles }) => {
  const [searchField, setSearchField] = useState("")
  const [roleFilter, setRoleFilter] = useState<string[]>([])
  const handleFilterChange = (event: any) => setSearchField(event.target.value)
  const filterRole = (roles: IRole) =>
    (Object.keys(roles) as Array<keyof IRole>)
      .filter(
        (role) => role !== "id" && roles.hasOwnProperty(role) && roles[role],
      )
      .map((role) => ({ label: role, value: role }))

  const roleOption: { label: keyof IRole; value: keyof IRole }[] = [
    { label: "admin", value: "admin" },
    { label: "superadmin", value: "superadmin" },
  ]

  const handleSelect = (
    e: OptionsType<{ label: string; value: string }>,
    id: string,
    userRoleProperties: typeof roleOption | undefined,
  ) => {
    const eventRoles = filterUserRoles(e, userRoleProperties)

    if (userRoleProperties && id) {
      updateRole(id, eventRoles)
    } else {
      addRole(eventRoles, id)
    }
  }

  const filteredAccounts =
    searchField.length > 0 || roleFilter.length > 0
      ? accounts?.filter((account) =>
          accountAppliesToFilters(
            account,
            accountRoles,
            roleFilter,
            searchField,
          ),
        )
      : accounts

  return (
    <>
      <div className="users-header">
        <h2>Admin Settings</h2>
        <div />
        <div className="users-filter">
          <div className="user-searchbar">
            <SearchBar
              placeholder="Find users..."
              defaultValue={searchField}
              onChange={handleFilterChange}
              width="100%"
            />
          </div>
          <div className="users-filter-item">
            <FilterRoles values={roleFilter} onChange={setRoleFilter} />
          </div>
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className="users-list">
        <MaterialTable
          columns={[
            { title: "Name", field: "name" },
            { title: "Role", field: "role", sorting: false },
          ]}
          data={
            filteredAccounts?.map((account, index) => {
              const roles = accountRoles?.find(
                (accountRole) => account.id === accountRole.id,
              )
              const userRoles = roles && filterRole(roles)
              return {
                name: account.displayName,
                role: (
                  <Select
                    id="update-user-roles"
                    isMulti
                    placeholder={"Roles..."}
                    onChange={(e) => handleSelect(e, account?.id, userRoles)}
                    options={roleOption}
                    value={userRoles?.filter((role) => role.value)}
                  />
                ),
              }
            }) ?? []
          }
          options={tableDefaultOptions}
        />
      </div>
    </>
  )
}
const mapStateToProps = (state: RootState) => ({
  accounts: state.user.accounts,
  accountRoles: state.user.accountRoles,
})

export default connect(mapStateToProps, () => ({}))(AdminView)
