import React, { useState } from "react"

import "./dropdown-menu.styles.scss"

const DropdownMenu: React.FunctionComponent = (props) => {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => setExpanded(!expanded)

  return (
    <div className="dropdown-container">
      <div
        className="dropdown-button"
        style={
          expanded ? { background: "rgb(220, 220, 220, 1)", zIndex: 2 } : {}
        }
        onClick={handleClick}
      >
        <div className="dropdown-dot" />
        <div className="dropdown-dot" />
        <div className="dropdown-dot" />
      </div>
      {expanded ? (
        <div className="dropdown-background" onClick={handleClick} />
      ) : null}
      <div
        className="dropdown"
        style={expanded ? { opacity: 1 } : { pointerEvents: "none" }}
      >
        {props.children}
      </div>
    </div>
  )
}

export default DropdownMenu
