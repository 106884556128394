import React, { useState } from "react"
import { connect, RootStateOrAny } from "react-redux"

import { IDepartments, setAddRateCardVisibility } from "../../redux"
import { addRateCard } from "../../firebase"
import OverlayBox from "../../components/overlay-box/overlay-box.component"
import { IAccount } from "../../redux"

interface IAddRateCard {
  currentUser: IAccount
  setAddRateCardVisibility: (visility: boolean) => void
  addRateCardVisibility: boolean
  departments: IDepartments[]
}

const AddProject: React.FunctionComponent<IAddRateCard> = (props) => {
  const [displayName, setDisplayName] = useState("")

  const handleDisplayNameChange = (e: any) => setDisplayName(e.target.value)

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const { currentUser } = props

    if (!displayName) {
      return
    }

    addRateCard({ displayName: displayName }, currentUser.id)

    setDisplayName("")
    toggleOverlay()
  }

  const toggleOverlay = () => {
    const { setAddRateCardVisibility, addRateCardVisibility } = props
    setAddRateCardVisibility(!addRateCardVisibility)
  }

  return (
    <OverlayBox heading="Add Rate Card" onCancel={toggleOverlay}>
      <form className="add-project-form" onSubmit={handleSubmit}>
        <label>Rate Card Name: </label>
        <input
          id="add-project-name"
          type="text"
          defaultValue={displayName}
          onChange={handleDisplayNameChange}
        />
        <div />
        <input
          id="add-project-submit"
          type="submit"
          value="Add Rate Card"
          className="submit-button"
        />
      </form>
    </OverlayBox>
  )
}

const mapStateToProps = (state: RootStateOrAny) => ({
  addRateCardVisibility: state.projects.addRateCardVisibility,
  currentUser: state.user.currentUser,
  departments: state.calendar.departments,
})

const mapDispatchToProps = (dispatch: any) => ({
  setAddRateCardVisibility: (visibility: boolean) =>
    dispatch(setAddRateCardVisibility(visibility)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProject)
