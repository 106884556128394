import React, { useState } from "react"
import { connect } from "react-redux"

import CustomButton from "../custom-button/custom-button.component"
import {
  IDepartments as IDepartmentsAction,
  setAddDepartmentVisibility,
  setSelectedDepartment,
} from "../../redux"
import AddDepartment from "../add-department/add-department.component"
import CustomButtonDropdown from "../custom-button-dropdown/custom-button-dropdown.component"
import DropdownMenu from "../dropdown-menu/dropdown-menu.component"
import UpdateDepartment from "../update-department/update-department.component"
import Sidebar from "../sidebar/sidebar-component"
import CancelButton from "../cancel-button/cancel-button.component"
import SearchBar from "../search-bar/search-bar.component"
import { deleteDepartment } from "../../firebase"
import RoleBased from "../role-based/role-based.component"
import { ROLES, tableDefaultOptions } from "../../data/default-data"

import "./departments.styles.scss"
import { IAccount } from "../../redux"
import MaterialTable from "material-table"

interface IDepartments {
  setAddDepartmentVisibility: (visibility: boolean) => void
  addDepartmentVisibility: boolean
  setSelectedDepartment: (department: IDepartmentsAction | null) => void
  selectedDepartment: IDepartmentsAction
  currentUser: IAccount
  departments: IDepartmentsAction[]
}

const Departments: React.FunctionComponent<IDepartments> = (props) => {
  const [filter, setFilter] = useState("")

  const toggleAddDepartmentVisibility = () =>
    props.setAddDepartmentVisibility(!props.addDepartmentVisibility)

  const handleFilterChange = (e: { target: { value: string } }) =>
    setFilter(e.target.value)

  const closeSidebar = () => props.setSelectedDepartment(null)

  const handleDeleteDepartment = () => {
    const { selectedDepartment, currentUser } = props
    deleteDepartment(selectedDepartment, currentUser.id)
    closeSidebar()
  }

  const handleClick = (department: IDepartmentsAction) =>
    props.setSelectedDepartment(department)

  const { addDepartmentVisibility, departments, selectedDepartment } = props

  let filteredDepartments = [] as IDepartmentsAction[]

  if (departments) {
    filteredDepartments = departments.filter((department) =>
      department.displayName.toLowerCase().includes(filter.toLowerCase()),
    )
  }

  const permissionRoles = [ROLES.superadmin]

  return (
    <span>
      {selectedDepartment ? (
        <Sidebar position="right" width="500px">
          <div className="sidebar-cancel-button">
            <div className="sidebar-dropdown-menu">
              <RoleBased roles={permissionRoles}>
                <DropdownMenu>
                  <CustomButtonDropdown
                    onClick={() => handleDeleteDepartment()}>
                    Delete Department
                  </CustomButtonDropdown>
                </DropdownMenu>
              </RoleBased>
            </div>
            <CancelButton onCancel={() => closeSidebar()} />
          </div>
          <UpdateDepartment
            onUpdate={() => closeSidebar()}
            permissionRoles={permissionRoles}
          />
        </Sidebar>
      ) : null}

      {addDepartmentVisibility ? <AddDepartment /> : null}
      <div className="users-header">
        <h2>Departments</h2>
        <RoleBased roles={permissionRoles}>
          <CustomButton onClick={toggleAddDepartmentVisibility}>
            Add Department
          </CustomButton>
        </RoleBased>
        <SearchBar
          onChange={handleFilterChange}
          placeholder="Search departments"
          width="80%"
        />
        <div>Total: {departments ? departments.length : 0}</div>
      </div>
      <div className="users-list">
        <MaterialTable
          columns={[{ title: "Department", field: "department" }]}
          data={
            filteredDepartments.map((department, key) => ({
              department: department.displayName,
              tableData: { id: key },
            })) ?? []
          }
          onRowClick={(
            event,
            data: { tableData: { id: number }; department: string } | undefined,
          ) => data && handleClick(filteredDepartments[data.tableData.id])}
          options={tableDefaultOptions}
        />
      </div>
    </span>
  )
}

const mapStateToProps = (state: any) => ({
  addDepartmentVisibility: state.calendar.addDepartmentVisibility,
  departments: state.calendar.departments,
  selectedDepartment: state.calendar.selectedDepartment,
  currentUser: state.user.currentUser,
})

const mapDispatchToProps = (dispatch: any) => ({
  setAddDepartmentVisibility: (visibility: boolean) =>
    dispatch(setAddDepartmentVisibility(visibility)),
  setSelectedDepartment: (department: IDepartmentsAction | null) =>
    dispatch(setSelectedDepartment(department)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Departments)
