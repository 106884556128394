import React from "react"

import "./custom-button-dropdown.styles.scss"

interface ICustomButtonDropdown {
  onClick: (event: any) => void
}

const CustomButtonDropdown: React.FunctionComponent<ICustomButtonDropdown> = (
  props,
) => {
  const { children, onClick } = props
  return (
    <button className="custom-button-dropdown" onClick={onClick}>
      {children}
    </button>
  )
}

export default CustomButtonDropdown
