import "./booked-ratio.styles.scss"
import { useState, VFC } from "react"
import { endOfYear } from "date-fns"
import { IDepartments, IDoc, IProject, ITeams, IUser } from "../../redux"
import { entryIsInTimeframe } from "../../utils"
import {
  BookedOverview,
  BookingRatioHeader,
  UserListSideView,
} from "./components/BookedRatio"
import { filterDisplayedUsers } from "../../components/calendar-content/calendarFilters"

interface IBookedRatio {
  departments: IDepartments[]
  users: IUser[]
  entries: IDoc[]
  teams: ITeams[]
  projects: IProject[] | null
  setStartDate: (date: Date) => void
  startDate: Date
  queryStatusFilter: string[]
  queryTeamsFilter: string[]
  queryProjectsFilter: string[]
  queryShowHours: boolean
  queryExcludePast: boolean
}

const BookedRatio: VFC<IBookedRatio> = ({
  departments,
  entries,
  users,
  teams,
  projects,
  setStartDate,
  startDate,
  queryStatusFilter,
  queryProjectsFilter,
  queryTeamsFilter,
  queryShowHours,
  queryExcludePast,
}) => {
  const [userFilter, setUserFilter] = useState("")
  const [teamsFilter, setTeamsFilter] = useState<string[]>(queryTeamsFilter)
  const [projectsFilter, setProjectsFilter] =
    useState<string[]>(queryProjectsFilter)
  const [statusFilter, setStatusFilter] = useState<string[]>(queryStatusFilter)
  const [openedDepartment, setOpenedDepartment] = useState("")
  const [showHours, setShowHours] = useState(queryShowHours)
  const [excludePast, setExcludePast] = useState(queryExcludePast)
  const filteredEntries = entries.filter((entry) =>
    entryIsInTimeframe(entry, startDate, endOfYear(startDate)),
  )

  const filteredUsers = filterDisplayedUsers(
    users,
    projectsFilter,
    userFilter,
    teamsFilter,
    filteredEntries,
    [],
    startDate,
  )

  return (
    <div className="booked-ratio-page-wrapper">
      <BookingRatioHeader
        projects={projects}
        setProjectsFilter={setProjectsFilter}
        projectsFilter={projectsFilter}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setUserFilter={setUserFilter}
        setTeamsFilter={setTeamsFilter}
        userFilter={userFilter}
        teamsFilter={teamsFilter}
        teams={teams}
        setStartDate={setStartDate}
        startDate={startDate}
        showHours={showHours}
        setShowHours={setShowHours}
        excludePast={excludePast}
        setExcludePast={setExcludePast}
      />
      <div className="booked-ratio-content">
        <UserListSideView
          departments={departments}
          users={filteredUsers}
          userFilter={userFilter}
          setOpenedDepartment={setOpenedDepartment}
          openedDepartment={openedDepartment}
        />
        <BookedOverview
          projectFilter={projectsFilter}
          statusFilter={statusFilter}
          startDate={startDate}
          departments={departments}
          users={filteredUsers}
          userFilter={userFilter}
          openedDepartment={openedDepartment}
          entries={filteredEntries}
          showHours={showHours}
          projects={projects}
          excludePast={excludePast}
        />
      </div>
    </div>
  )
}

export default BookedRatio
