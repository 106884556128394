import {
  DocumentData,
  EFirestoreCollection,
  errorToast,
  FieldValue,
  firestore,
  Query,
  successToast,
} from "./firebase.utils"
import {
  add as addDuration,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
} from "date-fns"
import { EApprovalStatus, EApprovalTypes, IDoc } from "../redux"
import { IUserBase } from "./users"

interface IEntryData<D extends string | Date = Date> {
  user: IUserBase
  before: null | IEntryData
  project: {
    id?: string | null | undefined
    displayName?: string | null | undefined
    client: { displayName: string; id: string } | null
  } | null
  startDate: D
  endDate: D
  lastChanged: string
  bookingRatio: number | null
  bookedTime: number
  type: number
}

interface IEntry extends IEntryData {
  approval: {
    type: keyof typeof EApprovalTypes
    status: keyof typeof EApprovalStatus
    approvedBy: null | string
  }
  createdBy: string
}

type TEntryQuery = { base: Query<DocumentData>; changed: Query<DocumentData> }

//#region crud
const add = async (entry: IEntry) => {
  console.log(entry)
  try {
    await firestore
      .collection(EFirestoreCollection.ENTRIES)
      .add({ ...toServerSideEntry(entry), timeStamp: new Date() })
    successToast("Successfully added Entry")
  } catch (err) {
    errorToast("Failed to add Entry")
    return
  }
}

const update = async (
  id: string,
  entry: IEntry,
  lastChanged: string,
  noChanges?: boolean,
) => {
  try {
    if (noChanges) {
      errorToast("No changes have been set")
      return
    }
    await firestore
      .collection(EFirestoreCollection.ENTRIES)
      .doc(id)
      .update({
        ...toServerSideEntry(entry),
        timeStamp: FieldValue.serverTimestamp(),
        lastChanged,
      })
    successToast("Successfully updated Entry")
  } catch (err) {
    console.log(err)
    errorToast("Failed to update Entry")
    return
  }
}

const remove = async (entry: IDoc, uid: string) => {
  try {
    await firestore
      .collection(EFirestoreCollection.ENTRIES)
      .doc(entry?.id)
      .set({
        deleted: true,
        lastChanged: uid,
      })
    successToast("Successfully deleted Entry")
  } catch (err) {
    console.log(err)
    errorToast("Failed to delete Entry")
    return
  }
}
//#endregion

//#region date handling
const formatDate = (date: Date): `${string}-${string}-${string}` =>
  format(date, "yyyy-MM-dd") as `${string}-${string}-${string}`

/**
 * `getDate` converts a string from the expected format of `yyyy-MM-dd`
 * to a Date.
 * @param {string} dateStr
 * @returns {Date} Date
 */
const getDate = (dateStr: string): Date => {
  return startOfDay(new Date(dateStr))
}

const convertRawEntry = (entry: IDoc): IDoc => {
  let newEntry: IDoc = {
    ...entry,
    before: entry.before ? { ...entry.before } : null,
  }

  if (entry.before != null) {
    newEntry.before = {
      ...entry.before,
      startDate: startOfDay(new Date(entry.before.startDate)),
      endDate: startOfDay(new Date(entry.before.endDate)),
    }
  }

  return {
    ...newEntry,
    startDate: startOfDay(new Date(newEntry.startDate)),
    endDate: startOfDay(new Date(newEntry.endDate)),
  }
}

type TEntryParam = IEntry & { before: IEntryData | null }
type TServerSideEntry = Omit<IEntry, "startDate" | "endDate" | "before"> & {
  startDate: string
  endDate: string
} & { before: IEntryData<string> | null }
const toServerSideEntry = (entry: TEntryParam): TServerSideEntry => {
  let newEntry = {
    ...entry,
    startDate: formatDate(entry.startDate),
    endDate: formatDate(entry.endDate),
  }

  if (entry.before != null) {
    newEntry.before = {
      ...entry.before,
      // @ts-ignore
      startDate: formatDate(entry.before.startDate),
      // @ts-ignore
      endDate: formatDate(entry.before.endDate),
    }
  }

  if (entry.before != null) {
    newEntry.before = {
      ...entry.before,
      // @ts-ignore
      startDate: formatDate(entry.before.startDate),
      // @ts-ignore
      endDate: formatDate(entry.before.endDate),
    }
  }

  return newEntry as TServerSideEntry
}
//#endregion

//#region queries
const queryInMonthOf = (date: Date): TEntryQuery => {
  const startOfM = formatDate(startOfMonth(date))
  const endOfM = formatDate(endOfMonth(date))

  return {
    base: firestore
      .collection(EFirestoreCollection.ENTRIES)
      .where("endDate", ">=", startOfM)
      .where("endDate", "<=", endOfM)
      .orderBy("endDate")
      .orderBy("timeStamp"),
    changed: firestore
      .collection(EFirestoreCollection.ENTRIES)
      .where("after.endDate", ">=", startOfM)
      .where("after.endDate", "<=", endOfM)
      .orderBy("after.endDate")
      .orderBy("timeStamp"),
  }
}

const queryStartOfThisMonth = (): TEntryQuery => {
  const startOfThisMonth = formatDate(startOfMonth(new Date()))

  return {
    base: firestore
      .collection(EFirestoreCollection.ENTRIES)
      .where("endDate", ">", startOfThisMonth)
      .orderBy("endDate")
      .orderBy("timeStamp"),

    changed: firestore
      .collection(EFirestoreCollection.ENTRIES)
      .where("after.endDate", ">", startOfThisMonth)
      .orderBy("after.endDate")
      .orderBy("timeStamp"),
  }
}

const queryInProject = (projectId: string): Query<DocumentData> => {
  return firestore
    .collection(EFirestoreCollection.ENTRIES)
    .where("project.id", "==", projectId)
}

const queryYear = (startDate: Date): TEntryQuery => {
  const startOfNextYear = formatDate(addDuration(startDate, { years: 1 }))

  return {
    base: firestore
      .collection(EFirestoreCollection.ENTRIES)
      .where("startDate", "<", startOfNextYear)
      .orderBy("startDate")
      .orderBy("timeStamp"),

    changed: firestore
      .collection(EFirestoreCollection.ENTRIES)
      .where("after.startDate", "<", startOfNextYear)
      .orderBy("after.startDate")
      .orderBy("timeStamp"),
  }
}
//#endregion

export const Entries = {
  add,
  update,
  remove,
  queryInMonthOf,
  queryStartOfThisMonth,
  queryNextYear: queryYear,
  queryInProject,
  formatDate,
  getDate,
  convertRawEntry,
}
