import DropdownArrow from "../../../../components/dropdown-arrow/dropdown-arrow.component"
import { IDepartments, IUser } from "../../../../redux"
import React from "react"
import AvatarIcon from "../../../../components/avatar-icon/avatar-icon.component"

interface IUserListItem {
  users: IUser[]
  department: IDepartments
  userFilter: string
  setOpenedDepartment: (value: string) => void
  openedDepartment: string
}

const UserListItem: React.FC<IUserListItem> = ({
  users,
  department,
  userFilter,
  openedDepartment,
  setOpenedDepartment,
}) => {
  const handleListItemClicked = () =>
    setOpenedDepartment(
      openedDepartment === department.displayName ? "" : department.displayName,
    )

  return (
    <div>
      <div
        className="booking-ratio-department-list-item-wrapper"
        onClick={handleListItemClicked}>
        <div className="calendar-group-text">{department.displayName}</div>
        {users && users.length > 0 ? (
          <div className="booking-ratio-user-list-dropdown">
            <DropdownArrow
              isActive={openedDepartment === department.displayName}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="calendar-group-dropdown"
        style={
          openedDepartment === department.displayName || userFilter
            ? { maxHeight: "initial" }
            : { maxHeight: "0" }
        }>
        {users &&
          users.map((user, key) => (
            <div
              key={"user-list-item-" + key}
              className="booking-ratio-user-list-item-wrapper">
              <AvatarIcon user={user} />
              <div className="calendar-row-text">
                {user.firstName} {user.lastName}{" "}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default UserListItem
