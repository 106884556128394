import { IClient } from "../projects"
import { IDepartments } from "../types"

export interface IDate {
  seconds: number
  nanoseconds: number
  toDate: () => Date
}

export enum EApprovalTypes {
  "draft",
  "soft",
  "hard",
  "delete",
}

export enum EApprovalStatus {
  "pending",
  "approved",
  "declined",
}

export interface IEntryProject {
  client: IClient
  displayName: string
  id: string
}

export interface IDocData {
  bookedTime: number
  bookingRatio: number
  lastChanged: string
  endDate: Date
  startDate: Date
  project: IEntryProject | null
  timeStamp: IDate
  type: number
  user: {
    firstName: string
    lastName: string
    id: string
    department: IDepartments | null
  }
}

export interface IDoc extends IDocData {
  id: string
  before: IDocData | null
  approval: {
    type: keyof typeof EApprovalTypes
    status: keyof typeof EApprovalStatus
  }
  createdBy: string
}

export interface IEntries {
  startDate: Date
  docs: IDoc[]
}

export interface ITeams {
  id: string
  displayName: string
  teamLead?: { displayName: string; id: string }
  lastChanged: string
}

export interface IBankHoliday {
  date: string
  displayName: string
}

export interface ILocations {
  id: string
  bankHolidays?: IBankHoliday[]
  displayName: string
}

export type SetEntriesSortedBy =
  | "nameDesc"
  | "nameAsc"
  | "availabilityDesc"
  | "availabilityAsc"
