import React, { useState } from "react"
import { connect, RootStateOrAny } from "react-redux"

import CustomButton from "../custom-button/custom-button.component"
import { IClient, setAddClientVisibility, setSelectedClient } from "../../redux"
import AddClientComponent from "../add-client/add-client.component"
import ClientsList from "../clients-list/clients-list.component"
import CustomButtonDropdown from "../custom-button-dropdown/custom-button-dropdown.component"
import DropdownMenu from "../dropdown-menu/dropdown-menu.component"
import UpdateClient from "../update-client/update-client.component"
import Sidebar from "../sidebar/sidebar-component"
import CancelButton from "../cancel-button/cancel-button.component"
import SearchBar from "../search-bar/search-bar.component"
import { deleteClient } from "../../firebase"
import RoleBased from "../role-based/role-based.component"
import { ROLES } from "../../data/default-data"

import "./clients.styles.scss"
import { IAccount } from "../../redux"

interface IClients {
  setAddClientVisibility: (visibility: boolean) => void
  addClientVisibility: boolean
  setSelectedClient: (client: IClient | null) => void
  selectedClient: IClient
  currentUser: IAccount
  clients: IClient[]
}

const Clients: React.FunctionComponent<IClients> = (props) => {
  const [filter, setFilter] = useState("")

  const toggleAddClientVisibility = () =>
    props.setAddClientVisibility(!props.addClientVisibility)

  const handleFilterChange = (e: any) => setFilter(e.target.value)

  const closeSidebar = () => props.setSelectedClient(null)

  const handleDeleteClient = () => {
    const { selectedClient, currentUser } = props
    deleteClient(selectedClient, currentUser.id)
    closeSidebar()
  }

  const { addClientVisibility, clients, selectedClient } = props

  const permissionRoles = [ROLES.admin, ROLES.superadmin]

  return (
    <span>
      {selectedClient ? (
        <Sidebar position="right" width="500px">
          <div className="sidebar-cancel-button">
            <div className="sidebar-dropdown-menu">
              <RoleBased roles={permissionRoles}>
                <DropdownMenu>
                  <CustomButtonDropdown onClick={() => handleDeleteClient()}>
                    Delete Client
                  </CustomButtonDropdown>
                </DropdownMenu>
              </RoleBased>
            </div>
            <CancelButton onCancel={() => closeSidebar()} />
          </div>
          <UpdateClient
            onUpdate={() => closeSidebar()}
            permissionRoles={permissionRoles}
          />
        </Sidebar>
      ) : null}

      {addClientVisibility ? <AddClientComponent /> : null}
      <div className="users-header">
        <h2>Clients</h2>
        <RoleBased roles={permissionRoles}>
          <CustomButton onClick={toggleAddClientVisibility}>
            Add Client
          </CustomButton>
        </RoleBased>
        <SearchBar
          onChange={handleFilterChange}
          placeholder="Search clients"
          width="80%"
        />
        <div>Total: {clients ? clients.length : 0}</div>
      </div>
      <div className="users-list">
        <ClientsList filter={filter} />
      </div>
    </span>
  )
}

const mapStateToProps = (state: RootStateOrAny) => ({
  addClientVisibility: state.projects.addClientVisibility,
  clients: state.projects.clients,
  selectedClient: state.projects.selectedClient,
  currentUser: state.user.currentUser,
})

const mapDispatchToProps = (dispatch: any) => ({
  setAddClientVisibility: (visibility: boolean) =>
    dispatch(setAddClientVisibility(visibility)),
  setSelectedClient: (client: IClient | null) =>
    dispatch(setSelectedClient(client)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Clients)
