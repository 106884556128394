import "./headcount.styles.scss"
import { endOfYear } from "date-fns"
import { useState, VFC } from "react"
import { IDoc, IProject, IUser } from "../../redux"
import { entryIsInTimeframe } from "../../utils"
import { filterDisplayedProjects } from "./components/filterDisplayedProjects"
import {
  HeadcountContent,
  HeadcountHeader,
  ProjectSideView,
} from "./components/Headcount"

interface IHeadcount {
  entries: IDoc[]
  users: IUser[]
  projects: IProject[] | null
  setStartDate: (date: Date) => void
  startDate: Date
}

const Headcount: VFC<IHeadcount> = ({
  entries,
  users,
  projects,
  setStartDate,
  startDate,
}) => {
  const [projectsFilter, setProjectsFilter] = useState<string>("")
  const [departmentsFilter, setDepartmentsFilter] = useState<string[]>([])
  const filteredEntries = entries.filter((entry) =>
    entryIsInTimeframe(entry, startDate, endOfYear(startDate)),
  )

  const filteredProjects = filterDisplayedProjects(
    projects,
    projectsFilter,
    filteredEntries,
    departmentsFilter,
    users,
  )

  const filteredUsers =
    // TODO: Extract into pure function
    // - use for loop for efficiency here
    projectsFilter.length > 0 || departmentsFilter.length > 0
      ? users
          .filter((user) =>
            filteredEntries
              .filter((entry) => entry.user.id === user.id)
              .some((entry) =>
                filteredProjects.some(
                  (project) => project.id === entry.project?.id,
                ),
              ),
          )
          .filter((user) => {
            const userDepartmentId = user.department?.id
            if (!userDepartmentId) {
              return false
            }
            return departmentsFilter.includes(userDepartmentId)
          })
      : users

  return (
    <div className="booked-ratio-page-wrapper">
      <HeadcountHeader
        setProjectsFilter={setProjectsFilter}
        projectsFilter={projectsFilter}
        setDepartmentFilter={setDepartmentsFilter}
        setStartDate={setStartDate}
        startDate={startDate}
      />
      <div className="booked-ratio-content">
        <ProjectSideView projects={filteredProjects} />
        <HeadcountContent
          startDate={startDate}
          projects={filteredProjects}
          users={filteredUsers}
          entries={filteredEntries}
        />
      </div>
    </div>
  )
}

export default Headcount
