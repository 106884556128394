import { IUser } from "../redux"
import { errorToast, firestore, successToast } from "./firebase.utils"

export interface IUserBase {
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  email?: string | null
  id?: string | null | undefined
  department?: { id?: string | null; displayName?: string | null } | null
  location?: { id?: string | null; displayName?: string | null } | null
  teams?: { id?: string | null; displayName?: string | null } | null
  availability?: number[] | null
  accountId?: string | null
  seniorityLevel?: string | null
  startDate?: Date | null
  endDate?: Date | null
}

export const addUser = async (user: IUserBase, uid: string) => {
  try {
    await firestore.collection("users").add({
      ...user,
      lastChanged: uid,
    })
    successToast("Successfully added User")
  } catch (err) {
    console.log(err)
    errorToast("Failed to add User")
    return
  }
}

interface IJiraUser extends IUserBase {
  jira?: {
    id?: string
    accountId?: string
    avatarUrls?: { [key: string]: string }
    displayName?: string
  } | null
}

export const updateUser = async (id: string, user: IJiraUser, uid: string) => {
  try {
    await firestore
      .collection("users")
      .doc(id)
      .update({ ...user, lastChanged: uid })
    successToast("Successfully updated User")
  } catch (err) {
    console.log(err)
    errorToast("Failed to update User")
    return
  }
}

export const deleteUser = async (user: IUser | null, uid: string) => {
  try {
    await firestore
      .collection("users")
      .doc(user?.id)
      .set({ deleted: true, lastChanged: uid })
    successToast("Successfully deleted User")
  } catch (err) {
    console.log(err)
    errorToast("Failed to delete User")
    return
  }
}

// Creating the user account from the auth data
export const createUserProfileDocument = async (
  userAuth: any,
  additionalData?: any,
) => {
  if (!userAuth) return

  const userRef = firestore.doc(`accounts/${userAuth.uid}`)

  const snapShot = await userRef.get()

  if (!snapShot.exists) {
    const { displayName, email } = userAuth
    const createdAt = new Date()
    try {
      await userRef.set({ displayName, email, createdAt, ...additionalData })
    } catch (err) {
      console.log(err)
      return
    }
  }

  return userRef
}
