import React, { ReactNode } from "react"
import { connect, RootStateOrAny } from "react-redux"

const hasRole = (userRoles: { [key: string]: boolean }, roles: string[]) =>
  userRoles && roles ? roles.some((role) => userRoles[role]) : false

interface IRoleBased {
  children: ReactNode
  roles: string[]
  userRoles: { [key: string]: boolean }
}

const RoleBased = ({ children, roles, userRoles }: IRoleBased) => {
  return <div>{hasRole(userRoles, roles) ? children : null}</div>
}

const mapStateToProps = (state: RootStateOrAny) => ({
  userRoles: state.user.roles,
})

export default connect(mapStateToProps)(RoleBased)
