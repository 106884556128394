import React, { ReactNode } from "react"
import CancelButton from "../cancel-button/cancel-button.component"

import "./overlay-box.styles.scss"

interface IOverlayBox {
  onCancel: () => void
  children: ReactNode
  heading?: string
}

const OverlayBox: React.FC<IOverlayBox> = ({ onCancel, children, heading }) => (
  <div className="overlay-box-backdrop">
    <div className="clickable-backdrop" onClick={() => onCancel()} />
    <div className="overlay-box">
      <div className="overlay-box-left-border" />
      <h3 className="overlay-box-heading">{heading}</h3>
      <div className="overlay-box-cancel-button">
        <CancelButton onCancel={onCancel} />
      </div>
      {children}
    </div>
  </div>
)

export default OverlayBox
