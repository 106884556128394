import getActionText from "../../pages/pending-approvals/getActionText"
import {
  getAfterData,
  getBeforeData,
} from "../../pages/pending-approvals/utils/getEntryData"
import getUsernameOfEntry from "../../pages/pending-approvals/utils/getUsernameOfEntry"
import { IDoc } from "../../redux"
import { IAccount } from "../../redux"
import { diff } from "deep-object-diff"
import arrowIosForwardOutline from "@iconify/icons-eva/arrow-ios-forward-outline"
import Icon from "@iconify/react"
import { LIST_TITLES } from "../../pages/pending-approvals/EntryChangesTable"

interface IPopUpWindowEntry {
  popUpEntry: IDoc
  accounts: IAccount[]
}

const formatValue = (key: string, value: string | undefined) => {
  if (!value) {
    return ""
  }

  switch (key) {
    case "bookedTime":
      return +parseFloat(value).toFixed(2)
    default:
      return value
  }
}

const PopUpWindowEntry: React.FC<IPopUpWindowEntry> = ({
  popUpEntry,
  accounts,
}) => {
  const username = accounts
    ? getUsernameOfEntry(popUpEntry, accounts)
    : undefined
  const newEntry =
    popUpEntry.approval.status === "pending" && !popUpEntry.before
  const afterEntries = getAfterData(popUpEntry)
  const beforeEntries = getBeforeData(popUpEntry) ?? afterEntries
  const changes = Object.keys(diff(beforeEntries, afterEntries))
  const changesKeys = (
    changes.length > 0 ? changes : Object.keys(beforeEntries)
  ) as (keyof typeof beforeEntries)[]
  return (
    <>
      <div className="approval-item-header">
        <span className="approval-item-header-user">{username}</span>
        {` wants to ${getActionText(popUpEntry)} for `}
        <span className="approval-item-header-reason">{`${afterEntries.user}`}</span>{" "}
        {afterEntries.project ? "for " : ""}
        <span className="approval-item-header-reason">
          {afterEntries.project}
        </span>
      </div>
      <div className="notification-item-content">
        {changesKeys.map((changedValue, key) =>
          beforeEntries[changedValue] || afterEntries[changedValue] ? (
            <div className="notification-item-row-wrapper" key={key}>
              <div className="notification-item-row-title">
                {LIST_TITLES[changedValue]}
              </div>
              <div className="notification-item-row-changes">
                <div className="notification-item-before-entry">
                  {!newEntry || popUpEntry.approval.type === "delete"
                    ? beforeEntries[changedValue]
                    : ""}
                </div>
                <Icon icon={arrowIosForwardOutline} />
                <div>
                  {popUpEntry.approval.type !== "delete"
                    ? formatValue(changedValue, afterEntries[changedValue])
                    : ""}
                </div>
              </div>
            </div>
          ) : (
            <div />
          ),
        )}
      </div>
    </>
  )
}

export default PopUpWindowEntry
