import React, { useState } from "react"
import { connect } from "react-redux"
import CalendarRow from "../calendar-row/calendar-row.component"
import DropdownArrow from "../dropdown-arrow/dropdown-arrow.component"
import { DEFAULT_AVAILABILITY } from "../../data/default-data"
import { getDifferenceInDays, getUTCDate } from "../../utils"
import "./calendar-group.styles.scss"
import CalendarSummaryRow from "../calendar-summary-row/calendar-summary-row.component"
import {
  IBankHoliday,
  IDepartments,
  IDoc,
  IUser,
  SetEntriesSortedBy,
} from "../../redux"

interface ICalendarGroup {
  users: IUser[]
  department: IDepartments
  filteredEntries: IDoc[]
  userFilter: string
  startDate: Date
  displayedDays: number
  filteredBankHolidays: { [key: string]: IBankHoliday[] | null }
  entriesSortedBy: SetEntriesSortedBy
}

const sum = (a: number, b: number): number => a + b

const CalendarGroup: React.FunctionComponent<ICalendarGroup> = ({
  users,
  department,
  filteredEntries,
  userFilter,
  startDate,
  displayedDays,
  filteredBankHolidays,
  entriesSortedBy,
}) => {
  const [expand, setExpand] = useState(false)

  const handleClick = () => setExpand(!expand)

  const addDays = (date: Date, days: number) => {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }

  const getAvailability = (
    startDate: Date,
    displayedDays: number,
    entries: IDoc[],
    originalAvailability: number[],
  ) => {
    let availability = [...originalAvailability]

    if (startDate) {
      const endDate = addDays(startDate, displayedDays)

      if (entries) {
        entries.forEach((entry) => {
          if (!entry) {
            return
          }

          const entryStartDate = entry.startDate
          const entryEndDate = entry.endDate
          const start =
            getUTCDate(entryStartDate) < getUTCDate(startDate)
              ? 0
              : getDifferenceInDays(startDate, entryStartDate)
          const end =
            getUTCDate(entryEndDate) > getUTCDate(endDate)
              ? displayedDays
              : getDifferenceInDays(startDate, entryEndDate) + 1

          for (let i = start; i < end; i++) {
            if (i === availability.length) {
              break
            }
            availability[i] =
              availability[i] - originalAvailability[i] * entry.bookingRatio
          }
          if (isNaN(availability[availability.length - 1])) {
            availability.pop()
          }
        })
      }
    }
    return availability
  }

  const filterEntriesByUser = (
    user: IUser | undefined,
    entries: IDoc[] | undefined,
  ) => {
    if (user && entries) {
      return entries.filter((entry) => entry.user.id === user.id)
    }

    return []
  }

  const remainingAvailabilities = new Map<IUser["id"], number[]>()
  const originalAvailabilities = new Map<IUser["id"], number[]>()
  const userFilteredEntries = new Map<IUser["id"], IDoc[]>()

  users.forEach((user) => {
    // Calculating original availability for the displayed days for each user
    const tmpOriginalAvailability = []

    for (let h = 0; h < displayedDays; h++) {
      if (
        (user.startDate &&
          getUTCDate(addDays(startDate, h)) <
            getUTCDate(user.startDate.toDate())) ||
        (user.endDate &&
          getUTCDate(addDays(startDate, h)) > getUTCDate(user.endDate.toDate()))
      ) {
        tmpOriginalAvailability.push(0)
      } else {
        tmpOriginalAvailability.push(
          user.availability
            ? user.availability[(startDate.getDay() + h) % 7]
            : DEFAULT_AVAILABILITY[(startDate.getDay() + h) % 7],
        )
      }
    }
    const userLocation = user.location?.id
    // Subtracting bank holidays from original availability
    if (userLocation && filteredBankHolidays?.[userLocation]) {
      ;(filteredBankHolidays[userLocation] ?? []).forEach(
        (holiday: IBankHoliday) => {
          tmpOriginalAvailability[
            getDifferenceInDays(startDate, new Date(holiday.date))
          ] = 0
        },
      )
    }

    const availabilityForUser = [...tmpOriginalAvailability]
    originalAvailabilities.set(user.id, availabilityForUser)

    // Creating array of filtered entries per user
    const entriesForUser = filterEntriesByUser(user, filteredEntries)
    userFilteredEntries.set(user.id, entriesForUser)

    // Calculating remaining availabilities per user according to calendar entries
    const remainingAvailabilitiesForUser = getAvailability(
      startDate,
      displayedDays,
      entriesForUser,
      availabilityForUser,
    )
    remainingAvailabilities.set(user.id, remainingAvailabilitiesForUser)
  })

  // Calculating summaries
  let originalAvailabilitySummary = [] as number[]
  let availabilitiesSummary = [] as number[]

  for (let i = 0; i < displayedDays; i++) {
    originalAvailabilitySummary.push(0)
    availabilitiesSummary.push(0)
  }

  remainingAvailabilities.forEach((availability, userId) => {
    availability.forEach((day, i) => {
      const originalAvailability = originalAvailabilities.get(userId)
      if (!originalAvailability) {
        return
      }

      availabilitiesSummary[i] = availabilitiesSummary[i] + day

      originalAvailabilitySummary[i] =
        originalAvailabilitySummary[i] + originalAvailability[i]
    })
  })

  // Don't go into negative values for summary
  availabilitiesSummary.forEach((availability, i) => {
    availabilitiesSummary[i] =
      originalAvailabilitySummary[i] > 0
        ? availability / originalAvailabilitySummary[i]
        : 0
  })

  let departmentSummary = 0
  remainingAvailabilities.forEach((availability) => {
    departmentSummary += availability.reduce((a, b) => a + b, 0)
  })

  const sortUsersByAvailabilityOrNameDesc = (a: IUser, b: IUser) => {
    if (entriesSortedBy.includes("name")) {
      if (entriesSortedBy === "nameDesc") {
        return a.firstName + a.lastName > b.firstName + b.lastName ? 1 : -1
      }
      return a.firstName + a.lastName > b.firstName + b.lastName ? -1 : 1
    }

    const userAAvailability = remainingAvailabilities.get(a.id)?.reduce(sum)
    const userBAvailability = remainingAvailabilities.get(b.id)?.reduce(sum)

    if (!userAAvailability || !userBAvailability) {
      return 0
    }

    if (entriesSortedBy === "availabilityDesc") {
      return userAAvailability > userBAvailability ? 1 : -1
    }
    return userAAvailability > userBAvailability ? -1 : 1
  }

  return (
    <div>
      <div className="calendar-group">
        <div className="calendar-group-left-side">
          <div className="calendar-group-text">{department.displayName}</div>
          <div className="calendar-group-hours">
            {Math.round(departmentSummary * 10) / 10}h
          </div>
          {users && users.length > 0 ? (
            <DropdownArrow handleClick={handleClick} isActive={expand} />
          ) : null}
        </div>

        <CalendarSummaryRow
          availability={availabilitiesSummary}
          originalAvailability={originalAvailabilitySummary}
          group={true}
        />
      </div>

      <div
        className="calendar-group-dropdown"
        style={
          expand || userFilter ? { maxHeight: "initial" } : { maxHeight: "0" }
        }>
        {users
          .slice()
          .sort(sortUsersByAvailabilityOrNameDesc)
          .map((user) => {
            const entries = userFilteredEntries.get(user.id)
            const availability = remainingAvailabilities.get(user.id)
            const originalAvailability = originalAvailabilities.get(user.id)
            if (!entries || !availability || !originalAvailability) {
              console.error(
                `Something seems to be missing: \n\tentries: ${entries}, \n\tavailability: ${availability}, \n\toriginalAvailability: ${originalAvailability}`,
              )
              return null
            }

            return (
              <CalendarRow
                user={user}
                key={user.id}
                entries={entries}
                availability={availability}
                originalAvailability={originalAvailability}
              />
            )
          })}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  entriesSortedBy: state.calendar.entriesSortedBy,
  departments: state.calendar.departments,
  entries: state.calendar.entries,
  userFilter: state.calendar.userFilter,
  startDate: state.calendar.startDate,
  displayedDays: state.calendar.displayedDays,
  locations: state.calendar.locations,
})

export default connect(mapStateToProps)(CalendarGroup)
