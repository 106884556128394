import React from "react"

import "./custom-button-plus.styles.scss"

interface ICustomButtonPlus {
  onClick: () => void
}

const CustomButtonPlus: React.FunctionComponent<ICustomButtonPlus> = (
  props,
) => (
  <div className="custom-button-plus" onClick={props.onClick}>
    <div className="plus-wrapper">
      <div className="plus-horizontal" />
      <div className="plus-vertical" />
    </div>
  </div>
)

export default CustomButtonPlus
