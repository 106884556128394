import React, { useState, useEffect } from "react"

import { getUsers } from "../../api/api.utils"
import { connect } from "react-redux"
import { updateUser } from "../../firebase"
import AtlassianSignIn from "../../atlassian/atlassian-sign-in.component"

import "./jira-users-selector.styles.scss"
import WithSpinner from "../with-spinner/with-spinner.component"
import { IJiraUsersData } from "../../redux"

interface IJiraUserSearch {
  users: { success: boolean; users: IJiraUsersData[] }
  setQuery: (key: string) => void
  onUserSelected: (user: IJiraUsersData) => void
}

const JiraUserSearch = ({
  users,
  setQuery,
  onUserSelected,
}: IJiraUserSearch) => {
  return users.success ? (
    <div>
      <input
        className="jira-project-search"
        placeholder="Search for Atlassian users..."
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="jira-projects" id="inner-scrollbar">
        {users.users
          ? users.users.map((user, key) =>
              user.accountType !== "app" ? (
                <div
                  className="jira-project"
                  key={key}
                  onClick={() => onUserSelected(user)}>
                  <div className="jira-project-thumb-border">
                    <img
                      className="jira-project-thumb"
                      alt="Jira project thumbnail"
                      src={user.avatarUrls["16x16"]}
                    />
                  </div>
                  <div>{user.displayName}</div>
                </div>
              ) : null,
            )
          : null}
      </div>
    </div>
  ) : (
    <AtlassianSignIn />
  )
}

const JiraUsersSelection = () => {
  return <div>Loading</div>
}

const JiraUserSearchWithSpinner = WithSpinner(
  JiraUserSearch as React.FunctionComponent,
)
const JiraUserSelectionWithSpinner = WithSpinner(JiraUsersSelection)

const JiraUsersSelector = ({ onCancel, user, currentUser }: any) => {
  const [users, setUsers] = useState(null)
  const [query, setQuery] = useState("")
  const [selectedUser, setSelectedUser] = useState(null as any)

  useEffect(() => {
    async function fetchData() {
      const users = await getUsers(query)
      setUsers(users)
    }

    if (selectedUser) {
      updateUser(
        user.id,
        {
          jira: {
            accountId: selectedUser.accountId,
            avatarUrls: selectedUser.avatarUrls,
            displayName: selectedUser.displayName,
          },
        },
        currentUser.id,
      )

      onCancel()
      return
    }

    if (!users) {
      fetchData()
    } else {
      const timeoutId = setTimeout(() => {
        fetchData()
      }, 1000)
      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selectedUser, users])

  return (
    <div>
      {selectedUser ? (
        <JiraUserSelectionWithSpinner isLoading={true} />
      ) : (
        <JiraUserSearchWithSpinner
          isLoading={!users}
          users={users}
          setQuery={setQuery}
          onUserSelected={setSelectedUser}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(JiraUsersSelector)
