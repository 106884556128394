import MaterialTable from "material-table"
import React from "react"
import { connect } from "react-redux"
import { tableDefaultOptions } from "../../data/default-data"
import { IRateCard, setSelectedRateCard } from "../../redux"

interface IRateCardsList {
  setSelectedRateCard: (rateCard: IRateCard) => void
  rateCards: IRateCard[]
  filter: string
}

const RateCardsList: React.FunctionComponent<IRateCardsList> = (props) => {
  const handleClick = (rateCard: IRateCard) =>
    props.setSelectedRateCard(rateCard)

  const { rateCards, filter } = props

  if (!rateCards) {
    return null
  }

  const filteredRateCards = rateCards.filter((rateCard) =>
    rateCard.displayName.toLowerCase().includes(filter.toLowerCase()),
  )

  return (
    <MaterialTable
      columns={[{ title: "Rate Card", field: "rateCard" }]}
      data={
        filteredRateCards
          ? filteredRateCards.map((rateCard, key) => ({
              rateCard: rateCard.displayName,
              tableData: { id: key },
            }))
          : []
      }
      onRowClick={(
        event,
        data:
          | {
              rateCard: string
              tableData: { id: number }
            }
          | undefined,
      ) => data && handleClick(filteredRateCards[data.tableData.id])}
      options={tableDefaultOptions}
    />
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedRateCard: (rateCard: IRateCard) =>
    dispatch(setSelectedRateCard(rateCard)),
})

export default connect(null, mapDispatchToProps)(RateCardsList)
