import { VFC } from "react"
import { IAccount, IProject } from "../../../redux"

interface IProjectPageHeaderProps {
  project: IProject
  currentUser: IAccount
}

const ProjectPageHeader: VFC<IProjectPageHeaderProps> = ({ project }) => {
  return (
    <div className="project-page-heading">
      <h2>
        <a href={project.jira?.self} target="_blank" rel="noreferrer">
          {project
            ? "" +
              (project.client ? project.client.displayName + " - " : "") +
              project.displayName
            : null}
        </a>
      </h2>
    </div>
  )
}
export default ProjectPageHeader
