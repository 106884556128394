import React from "react"
import { connect } from "react-redux"
import { ITeams as ITeamsAction, IUser as IUsersAction } from "../../../redux"
import TeamChart from "./teams-chart.component"

interface ITeamsChartOverview {
  teams: ITeamsAction[]
  users: IUsersAction[]
}

const TeamsChartOverview: React.FunctionComponent<ITeamsChartOverview> = (
  props,
) => {
  const filterUsersByTeam = (id: string) => {
    return users.filter((user) => (user.teams ? !!user.teams?.[id] : false))
  }

  const { teams, users } = props

  const sortedTeams = teams
    ? teams.sort(function (a, b) {
        return a.displayName.localeCompare(b.displayName)
      })
    : []

  return (
    <div className="users-list">
      {sortedTeams.map((team) =>
        filterUsersByTeam(team.id).length > 0 ? (
          <TeamChart
            team={team}
            users={filterUsersByTeam(team.id)}
            key={team.id}
          />
        ) : null,
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  teams: state.calendar.teams,
  users: state.calendar.users,
})

export default connect(mapStateToProps)(TeamsChartOverview)
