import { FC, useEffect } from "react"
import { Entries, firestore } from "./firebase"
import {
  EApprovalStatus,
  EApprovalTypes,
  IAccount,
  IBankHoliday,
  IClient,
  IDate,
  IDepartments,
  IDoc,
  IDocData,
  IEntries,
  IEntryProject,
  IJira,
  IJiraUsersData,
  ILocations,
  IProject,
  IRateCard,
  IRole,
  ITeams,
  IUser,
} from "./redux"
import firebase from "firebase/app"
import { startOfMonth, sub } from "date-fns"

type TAuthenticatedAppProps = {
  currentUser: IAccount
  setUsers: (users: IUser[]) => void
  setDepartments: (departments: IDepartments[]) => void
  setTeams: (teams: ITeams[]) => void
  setEntries: (
    entries: IEntries,
    currentUser: IAccount,
    isSuperAdmin: boolean,
  ) => void
  setProjects: (projects: IProject[]) => void
  setRateCards: (rateCards: IRateCard[]) => void
  setClients: (clients: IClient[]) => void
  setLocations: (locations: ILocations[]) => void
  setRoles: (role: IRole | undefined) => void
  setAccounts: (accounts: IAccount[]) => void
  userHasPermission: boolean | undefined
  setAccountRoles: (roles: IRole[] | undefined) => void
}

const AuthenticatedApp: FC<TAuthenticatedAppProps> = ({
  currentUser,
  setUsers,
  setDepartments,
  setTeams,
  setEntries,
  setProjects,
  setRateCards,
  setLocations,
  setClients,
  setRoles,
  setAccounts,
  children,
  userHasPermission,
  setAccountRoles,
}) => {
  useEffect(() => {
    // Roles
    return firestore
      .collection("roles")
      .doc(currentUser.id)
      .onSnapshot(async (doc) => {
        setRoles(typeGuardRole(doc))
      })
  }, [setRoles, currentUser])

  useEffect(() => {
    // Users
    return firestore
      .collection("users")
      .orderBy("firstName")
      .onSnapshot(async (snapshot) => {
        setUsers(snapshot.docs.reduce(reduceToUsers, [] as IUser[]))
      })
  }, [setUsers])

  useEffect(() => {
    // Departments
    return firestore
      .collection("departments")
      .orderBy("displayName")
      .onSnapshot(async (snapshot) => {
        setDepartments(
          snapshot.docs.reduce(reduceToDepartments, [] as IDepartments[]),
        )
      })
  }, [setDepartments])

  useEffect(() => {
    // Teams
    return firestore
      .collection("teams")
      .orderBy("displayName")
      .onSnapshot(async (snapshot) => {
        setTeams(snapshot.docs.reduce(reduceToTeams, [] as ITeams[]))
      })
  }, [setTeams])

  useEffect(() => {
    // Entries update - only takes entries that end after the beginning of the current month.
    // If we go back in time, new entries are synced as well - See calendar-header.component.jsx
    const { base, changed } = Entries.queryStartOfThisMonth()

    return base.onSnapshot(async (snapshot) => {
      changed.onSnapshot((changedEntriesSnapshot) => {
        setEntries(
          {
            startDate: sub(startOfMonth(new Date()), { days: 1 }),
            docs: [...changedEntriesSnapshot.docs, ...snapshot.docs]
              .reduce(reduceToDoc, [] as IDoc[])
              .map(Entries.convertRawEntry),
          },
          currentUser,
          !!userHasPermission,
        )
      })
    })
  }, [setEntries, currentUser, userHasPermission])

  useEffect(() => {
    // Clients
    return firestore
      .collection("clients")
      .orderBy("displayName")
      .onSnapshot(async (snapshot) => {
        setClients(snapshot.docs.reduce(reduceToClient, [] as IClient[]))
      })
  }, [setClients])

  useEffect(() => {
    // Locations
    return firestore
      .collection("locations")
      .orderBy("displayName")
      .onSnapshot(async (snapshot) => {
        setLocations(
          snapshot.docs.reduce(reduceToLocations, [] as ILocations[]),
        )
      })
  }, [setLocations])

  useEffect(() => {
    // Projects
    return firestore
      .collection("projects")
      .orderBy("displayName")
      .onSnapshot(async (snapshot) => {
        setProjects(snapshot.docs.reduce(reduceToProjects, [] as IProject[]))
      })
  }, [setProjects])

  useEffect(() => {
    // RateCards
    return firestore
      .collection("rateCards")
      .orderBy("displayName")
      .onSnapshot(async (snapshot) => {
        setRateCards(snapshot.docs.reduce(reduceToRateCards, [] as IRateCard[]))
      })
  }, [setRateCards])

  useEffect(() => {
    // Accounts
    
    const accountsCollectionRef = firestore
      .collection("accounts")
      .orderBy("displayName")
    return accountsCollectionRef.onSnapshot(async (snapshot) => {
      setAccounts(snapshot.docs.reduce(reduceToAccount, [] as IAccount[]))
    })
  }, [setAccounts, userHasPermission])

  useEffect(() => {
    // Account Roles
    if (!userHasPermission) {
      return
    }
    return firestore.collection("roles").onSnapshot(async (snapshot) => {
      setAccountRoles(snapshot.docs.reduce(reduceToRoles, [] as IRole[]))
    })
  }, [setAccountRoles, userHasPermission])

  return <div>{children}</div>
}

export default AuthenticatedApp
// typescript guards for specific fields and helper functions
type FirestoreDocument =
  firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
type FirestoreSingleDocument =
  firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
// Project
const hasId = (data: Record<string, any>): data is { id: string } =>
  !!data.id && typeof data.id === "string"
const hasDisplayName = (
  data: Record<string, any>,
): data is { displayName: string } =>
  data.displayName && typeof data.displayName === "string"
const isClient = (data: any): data is IClient =>
  typeof data === "object" && hasId(data) && hasDisplayName(data)
const hasLastChanged = (
  data: Record<string, any>,
): data is { lastChanged: string } =>
  data.lastChanged
    ? !!data.lastChanged && typeof data.lastChanged === "string"
    : true

const hasAccountId = (
  data: Record<string, any>,
): data is { accountId: string } =>
  data.accountId ? !!data.accountId && typeof data.accountId === "string" : true
const hasAvatarUrls = (
  data: Record<string, any>,
): data is { avatarUrls: { [size: string]: string } } =>
  !!data.avatarUrls && typeof data.avatarUrls === "object"
const hasKey = (data: Record<string, any>): data is { key: string } =>
  !!data.key && typeof data.key === "string"
const hasName = (data: Record<string, any>): data is { name: string } =>
  !!data.name && typeof data.name === "string"
const isJira = (data: any): data is IJira =>
  typeof data === "object" &&
  hasAccountId(data) &&
  hasId(data) &&
  hasKey(data) &&
  hasName(data)

const hasJira = (data: Record<string, any>): data is { jira: IJira } =>
  !data.jira ? true : !!data.jira && isJira(data.jira)

const hasIsArchived = (
  data: Record<string, any>,
): data is { isArchived: boolean } =>
  !data.isArchived
    ? true
    : !!data.isArchived && typeof data.isArchived === "string"

const isProject = (data: any): data is IProject =>
  typeof data === "object" &&
  hasId(data) &&
  hasDisplayName(data) &&
  hasLastChanged(data) &&
  hasJira(data) &&
  hasIsArchived(data)

const isRateCard = (data: any): data is IRateCard =>
  typeof data === "object" &&
  hasId(data) &&
  hasDisplayName(data) &&
  hasLastChanged(data)

export const reduceToProjects = (
  accumulator: IProject[],
  doc: FirestoreDocument,
) => {
  const data = { ...doc.data(), id: doc.id }
  return isProject(data) ? [...accumulator, data] : accumulator
}

export const reduceToRateCards = (
  accumulator: IRateCard[],
  doc: FirestoreDocument,
) => {
  const data = { ...doc.data(), id: doc.id }
  return isRateCard(data) ? [...accumulator, data] : accumulator
}

// Role
const hasAdmin = (data: Record<string, any>): data is { admin: boolean } =>
  !data.admin ? true : !!data.admin && typeof data.admin === "boolean"
const hasSuperadmin = (
  data: Record<string, any>,
): data is { superadmin: boolean } =>
  !data.superadmin
    ? true
    : !!data.superadmin && typeof data.superadmin === "boolean"

const isRole = (data: any): data is IRole =>
  typeof data === "object" &&
  hasAdmin(data) &&
  hasSuperadmin(data) &&
  hasId(data)

const typeGuardRole = (doc: FirestoreSingleDocument) => {
  const data = { ...doc.data(), id: doc.id }
  return isRole(data) ? data : undefined
}
const reduceToRoles = (accumulator: IRole[], doc: FirestoreDocument) => {
  const data = { ...doc.data(), id: doc.id }
  return isRole(data) ? [...accumulator, data] : accumulator
}
// Users
const isAvailability = (data: any): data is number[] =>
  typeof data === "object" &&
  Array.isArray(data) &&
  data.every((item) => typeof item === "number")
const hasAvailability = (
  data: Record<string, any>,
): data is { availability: number[] } =>
  !data.availability
    ? true
    : !!data.availability && isAvailability(data.availability)
const isDepartment = (data: any): data is IDepartments =>
  typeof data === "object" && hasId(data) && hasDisplayName(data)
const hasDepartment = (
  data: Record<string, any>,
): data is { department: IDepartments } =>
  data.department ? !!data.department && isDepartment(data.department) : true
const hasEmail = (data: Record<string, any>): data is { email: string } =>
  data.email ? !!data.email && typeof data.email === "string" : true
const hasFirstName = (
  data: Record<string, any>,
): data is { firstName: string } =>
  !!data.firstName && typeof data.firstName === "string"
const hasLastName = (data: Record<string, any>): data is { lastName: string } =>
  !!data.lastName && typeof data.lastName === "string"
const hasAccountType = (
  data: Record<string, any>,
): data is { accountType: string } =>
  !data.accountType
    ? true
    : !!data.accountType && typeof data.accountType === "string"
const isJiraUser = (data: any): data is IJiraUsersData =>
  typeof data === "object" &&
  hasAccountId(data) &&
  hasAvatarUrls(data) &&
  hasDisplayName(data) &&
  ((data as any).lastChanged ? hasLastChanged(data) : true) &&
  ((data as any).lastName ? hasLastName(data) : true) &&
  ((data as any).accountType ? hasAccountType(data) : true)
const hasJiraUser = (
  data: Record<string, any>,
): data is { jira: IJiraUsersData } =>
  data.jira ? !!data.jira && isJiraUser(data.jira) : true
const isLocation = (data: any): data is { id: string; displayName: string } =>
  typeof data === "object" && hasId(data) && hasDisplayName(data)
// eslint-disable-next-line
const hasLocation = (
  data: Record<string, any>,
): data is { location: { id: string; displayName: string } } =>
  !!data.location && isLocation(data.location)
const isTeams = (data: any): data is ITeams =>
  typeof data === "object" &&
  hasId(data) &&
  hasDisplayName(data) &&
  hasLastChanged(data)
const isTeamsObject = (
  data: any,
): data is { [key: string]: { displayName: string } } =>
  typeof data === "object" &&
  Object.values(data).every(
    (team: any): team is { displayName: string } =>
      typeof team === "object" && hasDisplayName(team),
  )
const hasTeams = (
  data: Record<string, any>,
): data is { teams: { [key: string]: ITeams } } =>
  !data.teams ? true : !!data.teams && isTeamsObject(data.teams)

const isUsers = (data: any): data is IUser =>
  typeof data === "object" &&
  hasId(data) &&
  hasAccountId(data) &&
  hasAvailability(data) &&
  hasDepartment(data) &&
  hasEmail(data) &&
  hasFirstName(data) &&
  hasLastName(data) &&
  hasJiraUser(data) &&
  //hasSeniorityLevel(data) &&
  hasTeams(data)

const reduceToUsers = (accumulator: IUser[], doc: FirestoreDocument) => {
  const data = { ...doc.data(), id: doc.id }
  return isUsers(data) ? [...accumulator, data] : accumulator
}

// Departments
const reduceToDepartments = (
  accumulator: IDepartments[],
  doc: FirestoreDocument,
) => {
  const data = { ...doc.data(), id: doc.id }
  return isDepartment(data) ? [...accumulator, data] : accumulator
}

// Teams
const reduceToTeams = (accumulator: ITeams[], doc: FirestoreDocument) => {
  const data = { ...doc.data(), id: doc.id }
  return isTeams(data) ? [...accumulator, data] : accumulator
}

// Doc
const hasBookedTime = (
  data: Record<string, any>,
): data is { bookedTime: number } =>
  data.hasOwnProperty("bookedTime") && typeof data.bookedTime === "number"
const hasBookingRatio = (
  data: Record<string, any>,
): data is { bookingRatio: number } =>
  data.hasOwnProperty("bookingRatio") && typeof data.bookingRatio === "number"
const hasSeconds = (data: Record<string, any>): data is { seconds: number } =>
  data.hasOwnProperty("seconds") && typeof data.seconds === "number"
const hasNanoseconds = (
  data: Record<string, any>,
): data is { nanoseconds: number } =>
  data.hasOwnProperty("nanoseconds") && typeof data.nanoseconds === "number"
const hasToDate = (data: Record<string, any>): data is { toDate: () => Date } =>
  !!data.toDate &&
  typeof data.toDate === "function" &&
  typeof data.toDate() === typeof new Date()

export const isDate = (data: any): data is IDate =>
  typeof data === "object" &&
  hasSeconds(data) &&
  hasNanoseconds(data) &&
  hasToDate(data)

const hasEndDate = (data: Record<string, any>): data is { endDate: string } =>
  !!data.endDate && typeof data.endDate === "string"
const hasTimeStamp = (
  data: Record<string, any>,
): data is { timeStamp: IDate } => !!data.timeStamp && isDate(data.timeStamp)
const hasStartDate = (
  data: Record<string, any>,
): data is { startDate: string } =>
  !!data.startDate && typeof data.endDate === "string"
const hasProject = (data: Record<string, any>): data is { project: IProject } =>
  data.project ? !!data.project && isProject(data.project) : true
const hasType = (data: Record<string, any>): data is { type: number } =>
  data.hasOwnProperty("type") && typeof data.type === "number"
const isUser = (
  data: any,
): data is { firstName: string; lastName: string; id: string } =>
  typeof data === "object" &&
  hasId(data) &&
  hasFirstName(data) &&
  hasLastName(data)
const hasUser = (
  data: Record<string, any>,
): data is { user: { firstName: string; lastName: string; id: string } } =>
  !!data.user && isUser(data.user)

const hasApprovalType = (
  data: Record<string, any>,
): data is { type: EApprovalTypes; status: EApprovalStatus } =>
  !!data.type &&
  Object.keys(EApprovalTypes).includes(data.type) &&
  typeof data.type === "string"

const hasApprovalStatus = (
  data: Record<string, any>,
): data is { type: EApprovalTypes; status: EApprovalStatus } =>
  !!data.status &&
  Object.keys(EApprovalStatus).includes(data.status) &&
  typeof data.status === "string"

const isApproval = (
  data: any,
): data is { type: EApprovalTypes; status: EApprovalStatus } =>
  typeof data === "object" && hasApprovalStatus(data) && hasApprovalType(data)

const hasApproval = (
  data: Record<string, any>,
): data is {
  approval: {
    type: EApprovalTypes
    status: EApprovalStatus
  }
} => data.approval && isApproval(data.approval)

const isEntryProject = (data: any): data is IEntryProject =>
  typeof data === "object" && hasId(data) && hasDisplayName(data) //&&
//hasClient(data)

const hasEntryProject = (
  data: Record<string, any>,
): data is { project: IEntryProject } =>
  data.project ? !!data.project && isEntryProject(data.project) : true

const isDocData = (
  data: any,
): data is {
  bookedTime: number
  bookingRatio: number
  lastChanged: string
  endDate: string
  startDate: string
  project: IEntryProject | null
  type: number
  user: { firstName: string; lastName: string; id: string }
} =>
  typeof data === "object" &&
  hasBookedTime(data) &&
  hasBookingRatio(data) &&
  hasLastChanged(data) &&
  hasEndDate(data) &&
  hasStartDate(data) &&
  hasEntryProject(data) &&
  hasType(data) &&
  hasUser(data)

const hasBefore = (
  data: Record<string, any>,
): data is {
  before: IDocData
} => (!data.before ? true : !!data.before && isDocData(data.before))

const isNotDocData = (
  data: any,
): data is {
  approval: {
    type: EApprovalTypes
    status: EApprovalStatus
  }
  after: IDocData
  id: string
} =>
  typeof data === "object" &&
  !hasBookedTime(data) &&
  !hasBookingRatio(data) &&
  !hasLastChanged(data) &&
  !hasEndDate(data) &&
  !hasStartDate(data) &&
  !hasProject(data) &&
  !hasType(data) &&
  !hasUser(data) &&
  hasBefore(data)

const hasCreatedBy = (
  data: Record<string, any>,
): data is { createdBy: string } =>
  data.hasOwnProperty("createdBy") && typeof data.createdBy === "string"

const hasDocData = (data: Record<string, any>): data is IDocData =>
  typeof data === "object" && (isDocData(data) || isNotDocData(data))

const isDoc = (data: any): data is IDoc => {
  return (
    typeof data === "object" &&
    !!hasApproval(data) &&
    !!hasBefore(data) &&
    !!hasDocData(data) &&
    !!hasCreatedBy(data) &&
    !!hasTimeStamp(data)
  )
}

export const reduceToDoc = (accumulator: IDoc[], doc: FirestoreDocument) => {
  const data = { ...doc.data(), id: doc.id }
  if (!!accumulator.find((item) => item.id === data.id)) {
    return accumulator
  }
  return isDoc(data) ? [...accumulator, data] : accumulator
}

// Client
const reduceToClient = (accumulator: IClient[], doc: FirestoreDocument) => {
  const data = { ...doc.data(), id: doc.id }
  return isClient(data) ? [...accumulator, data] : accumulator
}

// Locations
const hasHolidayDate = (data: Record<string, any>): data is { date: string } =>
  !!data.date && typeof data.date === "string"
const isBankHolidays = (data: any): data is IBankHoliday[] =>
  typeof data === "object" &&
  Array.isArray(data) &&
  data.every(
    (bankHoliday) =>
      typeof bankHoliday === "object" &&
      hasHolidayDate(bankHoliday) &&
      hasDisplayName(bankHoliday),
  )
const hasBankHolidays = (
  data: Record<string, any>,
): data is { bankHolidays: IBankHoliday } =>
  data.bankHolidays
    ? !!data.bankHolidays && isBankHolidays(data.bankHolidays)
    : true

const isLocations = (data: any): data is ILocations =>
  typeof data === "object" &&
  hasId(data) &&
  hasBankHolidays(data) &&
  hasDisplayName(data)

const reduceToLocations = (
  accumulator: ILocations[],
  doc: FirestoreDocument,
) => {
  const data = { ...doc.data(), id: doc.id }
  return isLocations(data) ? [...accumulator, data] : accumulator
}

// Accounts
const isCreatedAt = (
  data: any,
): data is { nanoseconds: number; seconds: number } =>
  typeof data === "object" && hasNanoseconds(data) && hasSeconds(data)
const hasCreatedAt = (
  data: Record<string, any>,
): data is { createdAt: { nanoseconds: number; seconds: number } } =>
  !!data.createdAt && isCreatedAt(data.createdAt)

const isAccount = (data: any): data is IAccount =>
  typeof data === "object" &&
  hasId(data) &&
  hasEmail(data) &&
  hasDisplayName(data) &&
  hasCreatedAt(data)

const reduceToAccount = (accumulator: IAccount[], doc: FirestoreDocument) => {
  const data = { ...doc.data(), id: doc.id }
  return isAccount(data) ? [...accumulator, data] : accumulator
}
