import { differenceInBusinessDays, endOfMonth } from "date-fns"

const entryStartedBeforeMonthEndsInCurrent = (
  entryStart: Date,
  entryEnd: Date,
  entryInMonth: Date,
) => entryEnd <= endOfMonth(entryInMonth) && entryStart < entryInMonth

const entryStartsInMonthEndsInLater = (
  entryStart: Date,
  entryEnd: Date,
  entryInMonth: Date,
) => entryStart >= entryInMonth && entryEnd > endOfMonth(entryInMonth)

const entryStartsBeforeMonthEndsInLater = (
  entryStart: Date,
  entryEnd: Date,
  entryInMonth: Date,
) => entryStart < entryInMonth && entryEnd > endOfMonth(entryInMonth)

const getBusinessDays = (
  entryStart: Date,
  entryEnd: Date,
  entryInMonth: Date,
) => {
  if (
    entryStartedBeforeMonthEndsInCurrent(entryStart, entryEnd, entryInMonth)
  ) {
    return differenceInBusinessDays(entryEnd, entryInMonth)
  } else if (
    entryStartsInMonthEndsInLater(entryStart, entryEnd, entryInMonth)
  ) {
    return differenceInBusinessDays(endOfMonth(entryInMonth), entryStart)
  } else if (
    entryStartsBeforeMonthEndsInLater(entryStart, entryEnd, entryInMonth)
  ) {
    return differenceInBusinessDays(endOfMonth(entryInMonth), entryInMonth)
  } else {
    //date is in month
    return differenceInBusinessDays(entryEnd, entryStart)
  }
}

export default getBusinessDays
