import React from "react"
import { Link } from "react-router-dom"

const Logo = ({ width, height }: { width?: string; height?: string }) => (
  <div className="logo">
    <Link to="/">
      <svg
        style={{ width, height }}
        xmlns="http://www.w3.org/2000/svg"
        width="29.249"
        height="29.248"
        viewBox="0 0 29.249 29.248"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#237bff" />
            <stop offset="1" stopColor="#4ef3ff" />
          </linearGradient>
        </defs>
        <path
          id="Icon_ionic-ios-paper-plane"
          data-name="Icon ionic-ios-paper-plane"
          d="M31.788,3.445,3.677,18.654A.588.588,0,0,0,3.72,19.7l4.8,1.807a1.134,1.134,0,0,1,.633.591l3.727,7.671c.07.337.7.429.7.084l-.57-6.342a2.26,2.26,0,0,1,.513-1.28L28.1,7.952a.352.352,0,0,1,.316-.091A.334.334,0,0,1,28.6,8.4L16.031,23.766a2.809,2.809,0,0,0-.492,1.266l-.752,5.477c.063.478.436.661.738.232l2.707-3.178a.567.567,0,0,1,.795-.134L26,32.513a.562.562,0,0,0,.886-.345l5.723-28.09A.571.571,0,0,0,31.788,3.445Z"
          transform="translate(-3.376 -3.376)"
          fill="url(#linear-gradient)"
        />
      </svg>
    </Link>
  </div>
)

export default Logo
