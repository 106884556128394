import React from "react"
import { IDepartments } from "../../redux"

const FormatDepartmentData: React.FC<IDepartments> = (departmentData) => (
  <table id="nested-table">
    <thead>
      <tr>
        <th>Name:</th>
        <td>{departmentData.displayName}</td>
      </tr>
    </thead>
  </table>
)

export default FormatDepartmentData
