import Select, { OptionsType } from "react-select"

interface IFilterRoles {
  onChange: (value: string[]) => void
  values: string[]
}

const FilterRoles: React.FC<IFilterRoles> = ({ values, onChange }) => {
  const filterProjectOptions = [
    {
      value: "admin",
      label: "admin",
    },
    {
      value: "superadmin",
      label: "superadmin",
    },
  ].filter((option) => !values.find((value) => value === option.label))

  const usedValues = values.map((value) => ({ value, label: value }))

  const handleChange = (e: OptionsType<{ value: string }>) =>
    onChange(e?.map((eventValue) => eventValue.value) || [])

  return (
    <Select
      id="calendar-filter-project"
      isMulti
      options={filterProjectOptions}
      onChange={handleChange}
      placeholder="Role..."
      value={usedValues}
      classNamePrefix="filter-project-select"
    />
  )
}

export default FilterRoles
