import MaterialTable from "material-table"
import React from "react"
import { connect } from "react-redux"
import { tableDefaultOptions } from "../../data/default-data"
import { IClient, setSelectedClient } from "../../redux"

interface IClientList {
  setSelectedClient: (client: IClient) => void
  clients: IClient[]
  filter: string
}

const ClientsList: React.FunctionComponent<IClientList> = (props) => {
  const handleClick = (client: IClient) => {
    props.setSelectedClient(client)
  }

  const { clients, filter } = props

  let filteredClients = [] as IClient[]

  if (clients) {
    filteredClients = clients.filter((client) =>
      client.displayName.toLowerCase().includes(filter.toLowerCase()),
    )
  }

  return (
    <MaterialTable
      columns={[{ title: "Client", field: "client" }]}
      data={filteredClients.map((client, key) => ({
        client: client.displayName,
        tableData: { id: key },
      }))}
      onRowClick={(
        event,
        data: { client: string; tableData: { id: number } } | undefined,
      ) => data && handleClick(filteredClients[data.tableData.id])}
      options={tableDefaultOptions}
    />
  )
}

const mapStateToProps = (state: any) => ({
  clients: state.projects.clients,
})

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedClient: (client: IClient) => dispatch(setSelectedClient(client)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList)
