import { connect } from "react-redux"
import Select, { OptionTypeBase } from "react-select"
import { ITeams } from "../../redux"

import "./filter-team.styles.scss"

interface IFilterTeam {
  teams?: ITeams[]
  onChange: (value: string[]) => void
  value?: OptionTypeBase
  height?: string
}

const FilterTeam = ({ teams, onChange, value, height }: IFilterTeam) => {
  const handleTeamFilterChange = (event: any) => {
    const teamFilter = event
      ? event.map((eventValue: { value: string }) => eventValue.value)
      : []
    onChange(teamFilter)
  }
  const teamFilterOptions = teams
    ? teams.map((team) => ({
        value: team.id,
        label: team.displayName,
      }))
    : []

  return (
    <Select
      id="calendar-filter-team"
      isMulti
      options={teamFilterOptions}
      onChange={handleTeamFilterChange}
      placeholder="Team..."
      value={value}
      classNamePrefix="filter-team-select"
    />
  )
}

const mapStateToProps = (state: any) => ({
  teams: state.calendar.teams,
})

export default connect(mapStateToProps)(FilterTeam)
