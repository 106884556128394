import React from "react"
import { connect } from "react-redux"
import { IUser } from "../../redux"

const CollectionPrinter = ({ users }: { users: IUser[] }) => {
  return (
    <div
      className="collection-printer"
      style={{ top: "60px", position: "relative" }}>
      {users
        ? users.map((doc, id) => (
            <div key={id}>
              {"{"}department: "{doc.department ? doc.department.id : "null"}
              ",email: "{doc.email}", firstName: "{doc.firstName}
              ", lastName: "{doc.lastName}", location: "
              {doc.location ? doc.location.id : "null"}"{"},"}
            </div>
          ))
        : null}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  users: state.calendar.users,
})

export default connect(mapStateToProps)(CollectionPrinter)
