import Select, { OptionTypeBase, OptionsType } from "react-select"
import { IProject } from "../../redux"
import "./FilterProject.styles.scss"

interface IFilterProject {
  onChange: (value: string[]) => void
  projects: IProject[]
  value: OptionTypeBase
}

const FilterProject: React.FC<IFilterProject> = ({
  onChange,
  projects,
  value,
}) => {
  const filterProjectOptions =
    projects?.map((project) => ({
      value: project.id,
      label: `${project.client ? project.client.displayName + " - " : ""}${
        project.displayName
      }`,
    })) || []

  const handleChange = (e: OptionsType<{ value: string }>) =>
    onChange(e?.map((eventValue) => eventValue.value) || [])

  return (
    <Select
      id="calendar-filter-project"
      isMulti
      options={filterProjectOptions}
      onChange={handleChange}
      placeholder="Project..."
      value={value}
      classNamePrefix="filter-project-select"
    />
  )
}

export default FilterProject
