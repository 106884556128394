import React, { useEffect, useState } from "react"
import { connect, RootStateOrAny } from "react-redux"

import { updateDepartment } from "../../firebase"
import { IDepartments } from "../../redux"
import { IAccount } from "../../redux"
import RoleBased from "../role-based/role-based.component"

import "./update-department.styles.scss"

interface IUpdateDepartment {
  onUpdate: () => void
  currentUser: IAccount
  roleManager: {
    userRoles: { [key: string]: string }
    hasRole: (permissionsRoles: string[]) => boolean
  }
  permissionRoles: string[]
  selectedDepartment: IDepartments
}

const UpdateDepartment: React.FunctionComponent<IUpdateDepartment> = (
  props,
) => {
  const [displayName, setDisplayName] = useState("")

  useEffect(() => {
    setDisplayName(props.selectedDepartment.displayName)
  }, [props.selectedDepartment])
  const handleDisplayNameChange = (e: { target: { value: string } }) =>
    setDisplayName(e.target.value)

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    const { onUpdate, currentUser } = props

    if (!displayName) {
      return
    }

    updateDepartment(
      props.selectedDepartment.id,
      {
        displayName: displayName,
      },
      currentUser.id,
    )

    if (onUpdate) {
      onUpdate()
    }
  }

  const { roleManager, permissionRoles } = props

  const inputDisabled = !roleManager.hasRole(permissionRoles)

  return (
    <div>
      <div className="update-user-heading">
        <h2>Department</h2>
      </div>
      <form className="update-user-form" onSubmit={handleSubmit}>
        <label>Department Name: </label>
        <input
          id="update-derpartment-name"
          type="text"
          value={displayName}
          onChange={handleDisplayNameChange}
          disabled={inputDisabled}
        />
        <div />
        <RoleBased roles={permissionRoles}>
          <input
            id="update-derpartment-submit"
            type="submit"
            value="Update Department"
            className="submit-button"
          />
        </RoleBased>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootStateOrAny) => ({
  selectedDepartment: state.calendar.selectedDepartment,
  roleManager: state.user.roleManager,
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(UpdateDepartment)
