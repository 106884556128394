import React from "react"

import "./dropdown-arrow.styles.scss"

interface IDropdownArrow {
  handleClick?: () => void
  isActive: boolean
}

const DropdownArrow: React.FunctionComponent<IDropdownArrow> = ({
  isActive,
  handleClick,
}) => {
  const style = isActive
    ? { transform: "translate(-50%, -50%) rotate(-90deg) scale(0.6)" }
    : {}
  return (
    <div className="dropdown-arrow-container" onClick={handleClick}>
      <svg
        className="dropdown-arrow"
        style={style}
        xmlns="http://www.w3.org/2000/svg"
        width="13.503"
        height="23.616"
        viewBox="0 0 13.503 23.616">
        <path
          id="Icon_ionic-ios-arrow-forward"
          data-name="Icon ionic-ios-arrow-forward"
          d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z"
          transform="translate(-11.246 -6.196)"
          fill="#707070"
        />
      </svg>
    </div>
  )
}

export default DropdownArrow
