import { IDoc } from "../../redux"
import { IAccount } from "../../redux"
import EntryChangesTable from "./EntryChangesTable"
import "./pending-approval.scss"
import CustomButton from "../../components/custom-button/custom-button.component"
import ClearIcon from "@material-ui/icons/Clear"
import CheckIcon from "@material-ui/icons/Check"
import getActionText from "./getActionText"
import getUsernameOfEntry from "./utils/getUsernameOfEntry"
import { getAfterData, getBeforeData } from "./utils/getEntryData"
import { format } from "date-fns"

type TPendingApprovalEntry = {
  entry: IDoc
  accounts: IAccount[] | null
  currentUser: IAccount
  onApprove: VoidFunction
  onDecline: VoidFunction
}

const pendingApprovalEntry = ({
  entry,
  accounts,
  onApprove,
  onDecline,
}: TPendingApprovalEntry) => {
  const username = accounts ? getUsernameOfEntry(entry, accounts) : undefined
  const newEntry = entry.approval.status === "pending" && !entry.before
  const beforeEntries = getBeforeData(entry)
  const afterEntries = getAfterData(entry) ?? beforeEntries

  return {
    date: format(entry.timeStamp.toDate(), "dd.MM.yyyy, H:mm:ss"),
    user: username,
    action: getActionText(entry),
    before:
      !newEntry || (entry.approval.type === "delete" && afterEntries) ? (
        <EntryChangesTable entries={afterEntries} />
      ) : (
        ""
      ),
    after:
      entry.approval.type !== "delete" ? (
        <EntryChangesTable entries={afterEntries} />
      ) : (
        ""
      ),
    acceptDecline: (
      <div className="button-action">
        <div className="entry-accept-wrapper">
          <CustomButton color="green" onClick={onApprove}>
            <div className="entry-accept">
              <CheckIcon />
            </div>
          </CustomButton>
        </div>
        <div>
          <CustomButton color="red" onClick={onDecline}>
            <div className="entry-decline">
              <ClearIcon />
            </div>
          </CustomButton>
        </div>
      </div>
    ),
  }
}

export default pendingApprovalEntry
