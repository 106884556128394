import React from "react"
import {
  IDate,
  IDoc,
  ILocations,
  IProject,
  IRateCard,
  IUser,
} from "../../../../redux"
import CSVDownload from "../../../../components/csv-download/csv-download.component"
import { ENTRY_TYPES, MONTHS } from "../../../../data/calendar-data"
import { DEFAULT_AVAILABILITY } from "../../../../data/default-data"
import { addDays } from "../../../../utils/calendar-utils"

interface ICapacitiesExport {
  entries: IDoc[]
  users: IUser[] | null
  projects: IProject[] | null
  rateCards: IRateCard[] | null
  locations: ILocations[] | null
}

interface IProjectTypeMap {
  [id: string]: {
    projectType: string | null
    key: string | null
    rateCard: IRateCard | null
  }
}

const getBookedHoursInMonth = (
  startDate: Date,
  entry: IDoc,
  availability: number[],
  location: ILocations,
) => {
  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)

  if (entry.endDate < startDate) {
    return 0
  }

  if (entry.startDate > endDate) {
    return 0
  }

  const actualEndDate = entry.endDate < endDate ? entry.endDate : endDate

  let filteredBankHolidays = []

  if (location.bankHolidays && location.bankHolidays.length > 0) {
    for (const bankHoliday of location.bankHolidays) {
      if (
        new Date(bankHoliday.date) >= startDate &&
        new Date(bankHoliday.date) < endDate
      ) {
        filteredBankHolidays.push(bankHoliday)
      }
    }
  }

  let bookedHours = 0
  for (
    var d =
      entry.startDate > startDate
        ? new Date(entry.startDate)
        : new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    d <= actualEndDate;
    d.setDate(d.getDate() + 1)
  ) {
    if (
      filteredBankHolidays.find(
        (bankHoliday) =>
          new Date(bankHoliday.date).toDateString() === d.toDateString(),
      )
    ) {
      continue
    } else {
      bookedHours += availability[d.getDay()] * entry.bookingRatio
    }
  }

  return bookedHours
}

const getAvailabilityInMonth = (
  userStartDate: IDate | undefined,
  userEndDate: IDate | undefined,
  startDate: Date,
  originalAvailability: number[],
  location: ILocations,
) => {
  let availability = 0

  const endOfMonth = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 1,
    1,
  )
  const endDate =
    userEndDate && endOfMonth > userEndDate.toDate()
      ? addDays(userEndDate.toDate(), 1)
      : endOfMonth

  var d = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  d = userStartDate && userStartDate.toDate() > d ? userStartDate.toDate() : d

  let filteredBankHolidays = []

  if (location.bankHolidays && location.bankHolidays.length > 0) {
    for (const bankHoliday of location.bankHolidays) {
      if (
        new Date(bankHoliday.date) >= d &&
        new Date(bankHoliday.date) < endDate
      ) {
        filteredBankHolidays.push(bankHoliday)
      }
    }
  }

  for (d; d < endDate; d.setDate(d.getDate() + 1)) {
    if (
      filteredBankHolidays.find(
        (bankHoliday) =>
          new Date(bankHoliday.date).toDateString() === d.toDateString(),
      )
    ) {
      continue
    } else {
      availability += originalAvailability[d.getDay()]
    }
  }

  return availability
}

const generateUserRow = (
  user: IUser,
  entries: IDoc[],
  startDate: Date,
  months: number,
  projects: IProjectTypeMap,
  location: ILocations | null,
) => {
  let rows: any[][] = []

  entries.forEach((entry) => {
    if (entry.endDate < startDate) {
      return
    }

    const endDate = new Date(
      entry.endDate.getFullYear(),
      entry.endDate.getMonth() + 1,
      0,
    )

    if (entry.startDate > endDate) {
      return
    }

    for (
      var d = new Date(
        entry.startDate.getFullYear(),
        entry.startDate.getMonth(),
        1,
      );
      d <= endDate;
      d.setMonth(d.getMonth() + 1)
    ) {
      let availabilityInMonth = getAvailabilityInMonth(
        user.startDate,
        user.endDate,
        d,
        user.availability ? user.availability : DEFAULT_AVAILABILITY,
        location ? location : { id: "", bankHolidays: [], displayName: "" },
      )

      const bookedHoursInMonth = getBookedHoursInMonth(
        d,
        entry,
        user.availability ? user.availability : DEFAULT_AVAILABILITY,
        location ? location : { id: "", bankHolidays: [], displayName: "" },
      )

      const getRate = () => {
        if (entry.project?.id && projects[entry.project.id]) {
          const rateCard = projects[entry.project.id].rateCard
          if (rateCard && user.department) {
            const departmentRate = rateCard.rates.find(
              (rate) => rate.department.id === user.department.id,
            )
            if (departmentRate) {
              return departmentRate.rate
            }
          }
        }
        return null
      }

      const getBookedBudget = () => {
        const rate = getRate()
        if (rate) {
          return bookedHoursInMonth * rate
        }
        return null
      }

      rows.push([
        user.firstName + " " + user.lastName,
        user.department?.displayName ?? "",
        user.teams?.[Object.keys(user.teams)[0]]?.displayName ?? "",
        ENTRY_TYPES[entry.type]?.displayName,
        entry.project?.client?.displayName ?? "",
        entry.project?.displayName ?? "",
        d.getFullYear() + " " + MONTHS[d.getMonth()],
        availabilityInMonth > 0 ? bookedHoursInMonth / availabilityInMonth : 0,
        bookedHoursInMonth,
        getBookedBudget(),
        entry.project?.id ? projects[entry.project.id]?.projectType : "",
        entry.project?.id ? projects[entry.project.id]?.key : "",
      ])
    }
  })

  if (rows.length === 0) {
    rows.push([
      user.firstName + " " + user.lastName,
      user.department ? user.department.displayName : "",
    ])
  }

  return rows
}

const getExportData = (
  users: IUser[],
  entries: IDoc[],
  projects: IProject[],
  rateCards: IRateCard[],
  locations: ILocations[],
  startDate: Date,
  months: number,
): string[][] => {
  let exportData: string[][] = [
    [
      "Name",
      "Discipline",
      "Team",
      "Type",
      "Client",
      "Project",
      "Month",
      "Ratio",
      "Booked Hours",
      "Booked Budget",
      "Project Type",
      "Jira Key",
    ],
  ]

  const projectTypeMap = projects.reduce<IProjectTypeMap>(
    (accumulator, project) => {
      accumulator[project.id] = {
        projectType: project.jira?.projectType || null,
        key: project.jira?.key || null,
        rateCard:
          rateCards.find((rateCard) => rateCard.id === project.rateCard) ||
          null,
      }
      return accumulator
    },
    {},
  )

  users.forEach((user) => {
    const location = user.location
      ? locations.find((location) => location.id === user.location.id)
      : null

    exportData.push(
      ...generateUserRow(
        user,
        entries.filter((entry) => entry.user.id === user.id),
        startDate,
        months,
        projectTypeMap,
        location ? location : null,
      ),
    )
  })

  return exportData
}

const CapacitiesExport: React.FunctionComponent<ICapacitiesExport> = ({
  entries,
  users,
  projects,
  rateCards,
  locations,
}) => {
  return users != null &&
    projects != null &&
    rateCards != null &&
    locations != null ? (
    <div className="csv-download">
      <CSVDownload
        csvData={getExportData(
          users,
          entries,
          projects,
          rateCards,
          locations,
          new Date("2022-01-01"),
          36,
        )}
        fileName={
          "CapacitiesExport" + new Date().toISOString().split("T")[0] + ".csv"
        }>
        Download filter as CSV
      </CSVDownload>
    </div>
  ) : null
}

export default CapacitiesExport
