import MaterialTable from "material-table"
import { tableDefaultOptions } from "../../data/default-data"
import { IAccount, IDoc, IRole, ITeams, IUser } from "../../redux"
import pendingApprovalEntry from "./PendingApprovalEntry"
import { firestore } from "../../firebase"
import React, { useEffect, useState } from "react"
import approvalActions from "./approvalActions"
import { connect } from "react-redux"
import { filterPendingEntries } from "./utils/filterPendingEntries"

interface IPendingApprovals {
  pendingEntries: IDoc[] | null
  accounts: IAccount[] | null
  currentUser: IAccount
  isSuperAdmin: boolean
  users: IUser[]
  teams: ITeams[]
  currentUserRole: IRole
}

const indexPendingEntriesById = (pendingEntries: IDoc[] | null) => {
  const entries = {} as { [key: string]: IDoc }
  pendingEntries?.forEach((entry) => {
    entries[entry.id] = entry
  })
  return entries
}

const entryHistory = (entryId: string) =>
  firestore
    .collection("history")
    .where("after.id", "==", entryId)
    .orderBy("date", "desc")
    .limit(1)
    .get()

const PendingApprovals: React.FC<IPendingApprovals> = ({
  pendingEntries,
  accounts,
  currentUser,
  isSuperAdmin,
  users,
  teams,
  currentUserRole,
}) => {
  const [entriesWithUsername, setEntriesWithUsername] = useState(
    pendingEntries ? indexPendingEntriesById(pendingEntries) : {},
  )

  useEffect(() => {
    if (!pendingEntries || !currentUser) {
      return
    }

    const tmpFilteredEntries = filterPendingEntries(
      pendingEntries,
      currentUser,
      currentUserRole,
      teams,
      users,
    )

    if (
      tmpFilteredEntries &&
      tmpFilteredEntries.length !== Object.keys(entriesWithUsername).length
    ) {
      setEntriesWithUsername(indexPendingEntriesById(tmpFilteredEntries))
    }
    if (tmpFilteredEntries.length > 0) {
      if (Object.keys(entriesWithUsername).length <= 0) {
        setEntriesWithUsername(indexPendingEntriesById(tmpFilteredEntries))
      }
      Object.keys(entriesWithUsername)?.forEach(async (entryId) => {
        if (entriesWithUsername[entryId].lastChanged === "server")
          entryHistory(entryId).then((historyEntries) => {
            const lastChangedUser = historyEntries.docs.map(
              (historyEntry) => historyEntry.data().after.lastChanged,
            )[0]
            setEntriesWithUsername({
              ...entriesWithUsername,
              [entryId]: {
                ...entriesWithUsername[entryId],
                lastChanged: lastChangedUser,
              },
            })
          })
      })
    }
  }, [
    pendingEntries,
    currentUser,
    currentUserRole,
    teams,
    users,
    entriesWithUsername,
  ])

  return (
    <span>
      <div className="users-header">
        <h2>Pending approvals</h2>
      </div>
      <div className="users-list">
        <MaterialTable
          columns={[
            { title: "Date", field: "date", cellStyle: { width: "5%" } },
            { title: "User", field: "user" },
            { title: "Action", field: "action" },
            { title: "Before", field: "before", sorting: false },
            { title: "After", field: "after", sorting: false },
            {
              title: "Accept / Decline",
              field: "acceptDecline",
              hidden: false,
              sorting: false,
            },
          ]}
          data={
            pendingEntries
              ? Object.keys(entriesWithUsername).map((entryId, key) => {
                  const entry = entriesWithUsername[entryId]
                  return pendingApprovalEntry({
                    entry,
                    accounts,
                    currentUser,
                    onApprove: () => {
                      approvalActions({ action: "approve", entry, currentUser })
                    },
                    onDecline: () => {
                      approvalActions({ action: "decline", entry, currentUser })
                    },
                  })
                })
              : []
          }
          options={{ ...tableDefaultOptions }}
        />
      </div>
    </span>
  )
}

const mapStateToProps = (state: any) => ({
  pendingEntries: state.calendar.pendingEntries,
  currentUser: state.user.currentUser,
  teams: state.calendar.teams,
  users: state.calendar.users,
  currentUserRole: state.user.roles,
})

export default connect(mapStateToProps)(PendingApprovals)
