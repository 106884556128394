import { IDoc, IAccount } from "../../../redux"

const getUsernameOfEntry = (entry: IDoc, accounts: IAccount[]) =>
  entry.lastChanged !== "server"
    ? accounts?.find((account) => account.id === entry.lastChanged)?.displayName
    : entry.lastChanged !== "server"
    ? accounts?.find((account) => account.id === entry.lastChanged)?.displayName
    : ""

export default getUsernameOfEntry
