import React, { useEffect, useState } from "react"
import { getIssues } from "../../api/api.utils"
import AtlassianSignIn from "../../atlassian/atlassian-sign-in.component"
import OverlayBox from "../overlay-box/overlay-box.component"
import Select from "react-select"
import { atlassianBaseUrl } from "../../atlassian/atlassian.utils"
import { format } from "date-fns/esm"
import { Calendar, momentLocalizer, EventPropGetter } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"

import "./deliveries.styles.scss"

const localizer = momentLocalizer(moment)

interface IDeliveries {}
type CPCIssue = {
  fields: {
    summary: string
    assignee: {
      // Producer
      displayName: string
    } | null
    customfield_10050: {
      // Customer Success Manager
      displayName: string
    } | null
    customfield_10057: string | null // Start Date
    customfield_10376: string | null // External full scope
    customfield_10377: string | null // Internal full scope
    customfield_10378: string | null // Next external review
    customfield_10379: string | null // Next internal review
    customfield_10060: string | null // Scheduled External Delivery Date
    customfield_10102: string | null // Scheduled Internal Delivery Date
    customfield_10375: string | null // Strike Group
    status: {
      name: string
    }
    priority: {
      name: string
    }
  }
  key: string
}

type Delivery = {
  summary: string
  key: string
  delivery: string
  deliveryDate: Date
  status: string
  producer: string
  csm: string
  priority: string
  strikeGroup: string
}

type filterOption = {
  label: string
  value: string
}

function getDeliveriesFromIssues(issues: CPCIssue[]) {
  let deliveries: Delivery[] = []
  issues.forEach((issue) => {
    if (issue.fields.customfield_10376) {
      deliveries.push({
        summary: issue.fields.summary,
        key: issue.key,
        delivery: "External Full Scope",
        deliveryDate: new Date(issue.fields.customfield_10376),
        status: issue.fields.status.name,
        csm: issue.fields.customfield_10050
          ? issue.fields.customfield_10050.displayName
          : "",
        producer: issue.fields.assignee
          ? issue.fields.assignee.displayName
          : "",
        priority: issue.fields.priority.name,
        strikeGroup: issue.fields.customfield_10375
          ? issue.fields.customfield_10375
          : "",
      })
    }

    if (issue.fields.customfield_10377) {
      deliveries.push({
        summary: issue.fields.summary,
        key: issue.key,
        delivery: "Internal Full Scope",
        deliveryDate: new Date(issue.fields.customfield_10377),
        status: issue.fields.status.name,
        csm: issue.fields.customfield_10050
          ? issue.fields.customfield_10050.displayName
          : "",
        producer: issue.fields.assignee
          ? issue.fields.assignee.displayName
          : "",
        priority: issue.fields.priority.name,
        strikeGroup: issue.fields.customfield_10375
          ? issue.fields.customfield_10375
          : "",
      })
    }

    if (issue.fields.customfield_10060) {
      deliveries.push({
        summary: issue.fields.summary,
        key: issue.key,
        delivery: "Final External Delivery",
        deliveryDate: new Date(issue.fields.customfield_10060),
        status: issue.fields.status.name,
        csm: issue.fields.customfield_10050
          ? issue.fields.customfield_10050.displayName
          : "",
        producer: issue.fields.assignee
          ? issue.fields.assignee.displayName
          : "",
        priority: issue.fields.priority.name,
        strikeGroup: issue.fields.customfield_10375
          ? issue.fields.customfield_10375
          : "",
      })
    }

    if (issue.fields.customfield_10379) {
      deliveries.push({
        summary: issue.fields.summary,
        key: issue.key,
        delivery: "Next Internal Review",
        deliveryDate: new Date(issue.fields.customfield_10379),
        status: issue.fields.status.name,
        csm: issue.fields.customfield_10050
          ? issue.fields.customfield_10050.displayName
          : "",
        producer: issue.fields.assignee
          ? issue.fields.assignee.displayName
          : "",
        priority: issue.fields.priority.name,
        strikeGroup: issue.fields.customfield_10375
          ? issue.fields.customfield_10375
          : "",
      })
    }

    if (issue.fields.customfield_10378) {
      deliveries.push({
        summary: issue.fields.summary,
        key: issue.key,
        delivery: "Next External Review",
        deliveryDate: new Date(issue.fields.customfield_10378),
        status: issue.fields.status.name,
        csm: issue.fields.customfield_10050
          ? issue.fields.customfield_10050.displayName
          : "",
        producer: issue.fields.assignee
          ? issue.fields.assignee.displayName
          : "",
        priority: issue.fields.priority.name,
        strikeGroup: issue.fields.customfield_10375
          ? issue.fields.customfield_10375
          : "",
      })
    }

    if (issue.fields.customfield_10102) {
      deliveries.push({
        summary: issue.fields.summary,
        key: issue.key,
        delivery: "Final Internal Delivery",
        deliveryDate: new Date(issue.fields.customfield_10102),
        status: issue.fields.status.name,
        csm: issue.fields.customfield_10050
          ? issue.fields.customfield_10050.displayName
          : "",
        producer: issue.fields.assignee
          ? issue.fields.assignee.displayName
          : "",
        priority: issue.fields.priority.name,
        strikeGroup: issue.fields.customfield_10375
          ? issue.fields.customfield_10375
          : "",
      })
    }
  })

  deliveries.sort((a, b) => a.deliveryDate.getTime() - b.deliveryDate.getTime())

  return deliveries
}

function getCsmFilterFromDeliveries(deliveries: Delivery[]): filterOption[] {
  let csms: Set<string> = new Set()
  deliveries.forEach((delivery) => {
    csms.add(delivery.csm)
  })

  let filterOptions: filterOption[] = []

  let csmsArray = Array.from(csms)
  csmsArray.sort()

  csmsArray.forEach((csm) => {
    filterOptions.push({ label: csm, value: csm })
  })
  return Array.from(filterOptions)
}

function getDeliveryTypeFromDeliveries(deliveries: Delivery[]): filterOption[] {
  let types: Set<string> = new Set()
  deliveries.forEach((delivery) => {
    types.add(delivery.delivery)
  })

  let filterOptions: filterOption[] = []

  let deliveryArray = Array.from(types)
  deliveryArray.sort()

  deliveryArray.forEach((delivery) => {
    filterOptions.push({ label: delivery, value: delivery })
  })
  return Array.from(filterOptions)
}

function getProducerFilterFromDeliveries(
  deliveries: Delivery[],
): filterOption[] {
  let producers: Set<string> = new Set()
  deliveries.forEach((delivery) => {
    producers.add(delivery.producer)
  })

  let producersArray = Array.from(producers)
  producersArray.sort()

  let filterOptions: filterOption[] = []

  producersArray.forEach((producer) => {
    filterOptions.push({ label: producer, value: producer })
  })
  return Array.from(filterOptions)
}

const timeFilterOptions = [
  { label: "7 days", value: 7 },
  { label: "14 days", value: 14 },
  { label: "This month", value: -2 },
]

function getStrikeGroupFilterFromDeliveries(
  deliveries: Delivery[],
): filterOption[] {
  let strikeGroups: Set<string> = new Set()
  deliveries.forEach((delivery) => {
    strikeGroups.add(delivery.strikeGroup)
  })

  let strikeGroupArray = Array.from(strikeGroups)
  strikeGroupArray.sort()

  let filterOptions: filterOption[] = []

  strikeGroupArray.forEach((strikeGroup) => {
    filterOptions.push({ label: strikeGroup, value: strikeGroup })
  })
  return Array.from(filterOptions)
}

function getStatusFilterFromDeliveries(deliveries: Delivery[]): filterOption[] {
  let stati: Set<string> = new Set()
  deliveries.forEach((delivery) => {
    stati.add(delivery.status)
  })

  let statusArray = Array.from(stati)
  statusArray.sort()

  let filterOptions: filterOption[] = []

  statusArray.forEach((status) => {
    filterOptions.push({ label: status, value: status })
  })
  return Array.from(filterOptions)
}

const Deliveries: React.FunctionComponent<IDeliveries> = (props) => {
  const [fetching, setFetching] = useState(false)
  const [issues, setIssues] = useState<CPCIssue[]>()
  const [atlassianTokenInvalid, setAtlassianTokenInvalid] = useState(false)
  const [deliveries, setDeliveries] = useState<Delivery[]>()
  const [csmFilterOptions, setCsmFilterOptions] = useState<filterOption[]>()
  const [deliveryFilterOptions, setDeliveryFilterOptions] =
    useState<filterOption[]>()
  const [strikeGroupFilterOptions, setStrikeGroupFilterOptions] =
    useState<filterOption[]>()
  const [producerFilterOptions, setProducerFilterOptions] =
    useState<filterOption[]>()
  const [csmFilter, setCsmFilter] = useState<string[]>([])
  const [producerFilter, setProducerFilter] = useState<string[]>([])
  const [strikeGroupFilter, setStrikeGroupFilter] = useState<string[]>([])
  const [timeFilter, setTimeFilter] = useState(-1)
  const [statusFilterOptions, setStatusFilterOptions] =
    useState<filterOption[]>()
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [deliveryFilter, setDeliveryFilter] = useState<string[]>([])

  useEffect(() => {
    const fetchIssues = async () => {
      setFetching(true)

      const fetchedIssues = await getIssues(
        "project=CPC&status!=done&status!=Delivered&status!=Cancelled&status!='On Hold'",
      )

      if (
        fetchedIssues &&
        fetchedIssues.success &&
        !fetchedIssues.errorMessages
      ) {
        setIssues(fetchedIssues.issues)
      } else {
        setAtlassianTokenInvalid(true)
      }
    }

    if (!fetching) {
      fetchIssues()
    }

    if (issues && !deliveries) {
      const filteredDeliveries = getDeliveriesFromIssues(issues) /*?.filter(
        (delivery) => delivery.deliveryDate > today && delivery.deliveryDate,
      )
      */

      setProducerFilterOptions(
        getProducerFilterFromDeliveries(filteredDeliveries),
      )
      setCsmFilterOptions(getCsmFilterFromDeliveries(filteredDeliveries))
      setStrikeGroupFilterOptions(
        getStrikeGroupFilterFromDeliveries(filteredDeliveries),
      )
      setStatusFilterOptions(getStatusFilterFromDeliveries(filteredDeliveries))
      setDeliveryFilterOptions(
        getDeliveryTypeFromDeliveries(filteredDeliveries),
      )
      setDeliveries(filteredDeliveries)
    }
  }, [fetching, issues, deliveries])

  const handleCsmFilterChange = (event: any) => {
    setCsmFilter(
      event?.map((item: { label: string; value: string }) => item.value) || "",
    )
  }

  const handleDeliveryTypeFilterChange = (event: any) => {
    setDeliveryFilter(
      event?.map((item: { label: string; value: string }) => item.value) || "",
    )
  }

  const handleProducerFilterChange = (event: any) => {
    setProducerFilter(
      event?.map((item: { label: string; value: string }) => item.value) || "",
    )
  }

  const handleStatusFilterChange = (event: any) => {
    setStatusFilter(
      event?.map((item: { label: string; value: string }) => item.value) || "",
    )
  }

  const handleStrikeGroupFilterChange = (event: any) => {
    setStrikeGroupFilter(
      event?.map((item: { label: string; value: string }) => item.value) || "",
    )
  }

  const handleTimeFilterChange = (event: any) => {
    setTimeFilter(event?.value || -1)
  }

  const areDatesEqual = (date1: Date, date2: Date) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    )
  }

  let filteredDeliveries = deliveries

  if (filteredDeliveries) {
    if (producerFilter.length > 0) {
      filteredDeliveries = filteredDeliveries.filter((delivery) =>
        producerFilter.includes(delivery.producer),
      )
    }

    if (csmFilter.length > 0) {
      filteredDeliveries = filteredDeliveries.filter((delivery) =>
        csmFilter.includes(delivery.csm),
      )
    }

    if (strikeGroupFilter.length > 0) {
      filteredDeliveries = filteredDeliveries.filter((delivery) =>
        strikeGroupFilter.includes(delivery.strikeGroup),
      )
    }

    if (statusFilter.length > 0) {
      filteredDeliveries = filteredDeliveries.filter((delivery) =>
        statusFilter.includes(delivery.status),
      )
    }

    if (deliveryFilter.length > 0) {
      filteredDeliveries = filteredDeliveries.filter((delivery) =>
        deliveryFilter.includes(delivery.delivery),
      )
    }

    if (timeFilter > 0) {
      const today = new Date()
      filteredDeliveries = filteredDeliveries.filter(
        (delivery) =>
          delivery.deliveryDate <
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + timeFilter,
            ) && delivery.deliveryDate >= today,
      )
    }
    if (timeFilter < -1) {
      const today = new Date()
      filteredDeliveries = filteredDeliveries.filter(
        (delivery) =>
          delivery.deliveryDate <
            new Date(today.getFullYear(), today.getMonth() + 1, 0) &&
          delivery.deliveryDate >
            new Date(today.getFullYear(), today.getMonth(), 1),
      )
    }
  }

  const handleEventClick = (event: any) => {
    const url = `${atlassianBaseUrl}browse/${event.key}` // Generate the URL dynamically
    window.open(url, "_blank") // Open the URL in a new tab
  }

  const events = filteredDeliveries
    ? filteredDeliveries.map((delivery) => ({
        title: delivery.summary,
        start: delivery.deliveryDate,
        end: delivery.deliveryDate,
        allDay: true,
        key: delivery.key,
        delivery: delivery.delivery,
      }))
    : []

  const customEventPropGetter: EventPropGetter<any> = (event: any) => {
    let backgroundColor = "#fff"
    switch (event.delivery) {
      case "Next Internal Review":
        backgroundColor = "#97b7e6"
        break
      case "Internal Full Scope":
        backgroundColor = "#4882d9"
        break
      case "Final Internal Delivery":
        backgroundColor = "#024cba"
        break
      case "Next External Review":
        backgroundColor = "#d68181"
        break
      case "External Full Scope":
        backgroundColor = "#d12e2e"
        break
      case "Final External Delivery":
        backgroundColor = "#910909"
        break
      default:
        break
    }
    return {
      style: {
        backgroundColor,
      },
    }
  }

  console.log(issues)

  return (
    <div>
      {atlassianTokenInvalid ? (
        <OverlayBox onCancel={() => setAtlassianTokenInvalid(false)}>
          <AtlassianSignIn />
        </OverlayBox>
      ) : null}
      <div className="delivery-page">
        <div className="users-header">
          <h2>Deliveries</h2>
        </div>
        <div className="users-list">
          {filteredDeliveries ? (
            <div>
              <div className="delivery-filter-options">
                <Select
                  isMulti
                  options={producerFilterOptions}
                  onChange={handleProducerFilterChange}
                  placeholder="Producers..."
                />
                <Select
                  isMulti
                  options={csmFilterOptions}
                  onChange={handleCsmFilterChange}
                  placeholder="CSM..."
                />
                <Select
                  isMulti
                  options={strikeGroupFilterOptions}
                  onChange={handleStrikeGroupFilterChange}
                  placeholder="Strike Group..."
                />
                <Select
                  isMulti
                  options={statusFilterOptions}
                  onChange={handleStatusFilterChange}
                  placeholder="Status..."
                />
                <Select
                  isClearable
                  options={timeFilterOptions}
                  onChange={handleTimeFilterChange}
                  placeholder="Timeframe..."
                />
                <Select
                  isMulti
                  options={deliveryFilterOptions}
                  onChange={handleDeliveryTypeFilterChange}
                  placeholder="Delivery type..."
                />{" "}
                <div />
                <div>Deliveries: {filteredDeliveries?.length}</div>
              </div>
              <div>
                <div style={{ height: "1000px" }}>
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    eventPropGetter={customEventPropGetter}
                    onSelectEvent={handleEventClick}
                    style={{ height: 800 }}
                  />
                </div>
              </div>
              <table id="deliveries">
                <thead>
                  <tr>
                    <th>Delivery</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Producer</th>
                    <th>CSM</th>
                    <th>Status</th>
                    <th>Priority</th>
                    <th>Strike Group</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDeliveries.map((delivery, i) => (
                    <tr key={i}>
                      <td>
                        <a href={atlassianBaseUrl + "browse/" + delivery.key}>
                          {delivery.summary}
                        </a>
                      </td>
                      <td>{delivery.delivery}</td>
                      <td>{format(delivery.deliveryDate, "dd.MM.yyyy")}</td>
                      <td>{delivery.producer}</td>
                      <td>{delivery.csm}</td>
                      <td>{delivery.status}</td>
                      <td>{delivery.priority}</td>
                      <td>{delivery.strikeGroup}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <table id="deliveries">
                <thead>
                  <tr>
                    <th>Delivery</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Producer</th>
                    <th>CSM</th>
                    <th>Status</th>
                    <th>Priority</th>
                  </tr>
                </thead>
              </table>
              <div className="projects-list-skeleton">
                {[...Array(5)].map((value, i) => (
                  <div className="projects-list-skeleton-element" key={i} />
                ))}
              </div>
            </div>
          )}{" "}
        </div>
      </div>
    </div>
  )
}

export default Deliveries
