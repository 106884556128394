import { ReactNode, useState, VFC } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { ROLES, tableDefaultOptions } from "../../data/default-data"
import { deleteUser } from "../../firebase"
import AddUser from "../add-user/add-user.component"
import CancelButton from "../cancel-button/cancel-button.component"
import CustomButtonDropdown from "../custom-button-dropdown/custom-button-dropdown.component"
import DropdownMenu from "../dropdown-menu/dropdown-menu.component"
import FilterDepartment from "../filter-department/filter-department.component"
import FilterTeam from "../filter-team/filter-team.component"
import RoleBased from "../role-based/role-based.component"
import SearchBar from "../search-bar/search-bar.component"
import Sidebar from "../sidebar/sidebar-component"
import UpdateUser from "../update-user/update-user.component"
import userListEntry from "./user-list-entry/user-list-entry.component"
import MaterialTable from "material-table"
import "./users.styles.scss"
import CSVDownload from "../csv-download/csv-download.component"
import { RootState, useAppDispatch } from "../../redux"
import {
  IAccount,
  IDepartments,
  ILocations,
  IUser,
  setSelectedUser,
} from "../../redux"

const getUsersAsCSVArray = (filteredUsers: IUser[]) => {
  let csvArray: string[][] = [
    ["First Name", "Last Name", "E-Mail", "Team", "Department", "Location"],
  ]
  filteredUsers.forEach((user) => {
    let userArray: string[] = []
    userArray.push(user.firstName)
    userArray.push(user.lastName)
    userArray.push(user.email)
    userArray.push(
      user.teams &&
        Object.keys(user.teams) &&
        Object.keys(user.teams).length > 0
        ? user.teams[Object.keys(user.teams)[0]].displayName
        : "",
    )
    userArray.push(user.department ? user.department.displayName : "")
    userArray.push(user.location ? user.location.displayName : "")

    csvArray.push(userArray)
  })

  return csvArray
}

type TUsersProps = {
  selectedUser: IUser | null
  currentUser: IAccount | null
  users: IUser[] | null
  departments: IDepartments[] | null
  locations: ILocations[] | null
}

const Users: VFC<TUsersProps> = ({
  selectedUser,
  currentUser,
  departments,
  locations,
  users,
}) => {
  const dispatch = useAppDispatch()
  const [searchField, setSearchField] = useState("")
  const [locationFilter, setLocationFilter] = useState<string[]>([])
  const [departmentFilter, setDepartmentFilter] = useState<string[]>([])
  const [teamsFilter, setTeamsFilter] = useState<string[]>([])
  const [availabilityFilter, setAvailabilityFilter] = useState<string[]>([])

  const handleLocationFilterChange = (event: any) =>
    setLocationFilter(
      event?.map((item: { label: string; value: string }) => item.value) || "",
    )

  const handleAvailabilityFilterChange = (event: any) =>
    setAvailabilityFilter(
      event?.map((item: { label: string; value: string }) => item.value) || "",
    )

  const handleFilterChange = (event: any) => setSearchField(event.target.value)

  const handleDeleteUser = () => {
    if (currentUser) {
      deleteUser(selectedUser, currentUser.id)
    }

    closeSidebar()
  }

  const closeSidebar = () => {
    dispatch(setSelectedUser(null))
  }

  let departmentFilterOptions = []
  if (departments) {
    departments.forEach((department) => {
      departmentFilterOptions.push({
        value: department.id,
        label: department.displayName,
      })
    })
  }

  let locationFilterOptions = [] as { value: string; label: string }[]
  if (locations) {
    locations.forEach((location) => {
      locationFilterOptions.push({
        value: location.id,
        label: location.displayName,
      })
    })
  }

  let availabilityFilterOptions = [] as { value: string; label: string }[]
  const availabilityTypes = [
    { id: "0", displayName: "Full-Time" },
    { id: "1", displayName: "Part-Time" },
  ]
  if (availabilityTypes) {
    availabilityTypes.forEach((availabilityType) => {
      availabilityFilterOptions.push({
        value: availabilityType.id,
        label: availabilityType.displayName,
      })
    })
  }

  if (!users) {
    return null
  }

  let filteredUsers = users

  if (locationFilter.length > 0) {
    filteredUsers = filteredUsers.filter((user) =>
      user.location ? locationFilter.includes(user.location.id) : false,
    )
  }

  if (departmentFilter.length > 0) {
    filteredUsers = filteredUsers.filter((user) =>
      user.department ? departmentFilter.includes(user.department.id) : false,
    )
  }

  if (teamsFilter.length > 0) {
    filteredUsers = filteredUsers.filter((user) =>
      user.teams ? teamsFilter.some((id) => !!user.teams?.[id]) : false,
    )
  }

  const filterByAvailability = () => {
    if (availabilityFilter.length > 0) {
      // Selected full & part time
      if (availabilityFilter.length > 1) {
        return filteredUsers
      }

      return filteredUsers.filter((user) => {
        // No availability == default availability -> Full-time
        if (!user.availability) {
          return availabilityFilter[0] === "0" ? 1 : 0
        }

        const availability = user.availability.reduce((a, b) => a + b)

        return availability >= 40 && availabilityFilter[0] === "0"
          ? true
          : availability < 40 && availabilityFilter[0] === "1"
      })
    }

    return filteredUsers
  }

  filteredUsers = filterByAvailability()

  filteredUsers = filteredUsers.filter((user) =>
    (user.firstName + " " + user.lastName)
      .toLowerCase()
      .includes(searchField.toLowerCase()),
  )

  return (
    <span>
      {selectedUser ? (
        <Sidebar position="right" width="500px">
          <div className="sidebar-cancel-button">
            <RoleBased roles={[ROLES.superadmin]}>
              <div className="sidebar-dropdown-menu">
                <DropdownMenu>
                  <CustomButtonDropdown onClick={handleDeleteUser}>
                    Delete User
                  </CustomButtonDropdown>
                </DropdownMenu>
              </div>
            </RoleBased>
            <CancelButton onCancel={closeSidebar} />
          </div>
          <UpdateUser onUpdate={closeSidebar} />
        </Sidebar>
      ) : null}
      <div className="users-header">
        <h2>Users</h2>
        <RoleBased roles={[ROLES.superadmin]}>
          <AddUser />
        </RoleBased>
        <div className="users-filter">
          <div className="user-searchbar">
            <SearchBar
              placeholder="Find users..."
              defaultValue={searchField}
              onChange={handleFilterChange}
              width="100%"
            />
          </div>
          <div className="users-filter-item">
            <FilterDepartment onChange={setDepartmentFilter} />
          </div>
          <div className="users-filter-item">
            <FilterTeam onChange={(e: any) => setTeamsFilter(e)} />
          </div>
          <div className="users-filter-item" id="users-filter-location">
            <Select
              isMulti
              options={locationFilterOptions}
              onChange={handleLocationFilterChange}
              placeholder="Location..."
            />
          </div>
          <div className="users-filter-item" id="users-filter-availability">
            <Select
              isMulti
              options={availabilityFilterOptions}
              onChange={handleAvailabilityFilterChange}
              placeholder="Availability..."
            />
          </div>
        </div>
        <div>Total: {filteredUsers ? filteredUsers.length : 0}</div>
      </div>
      <RoleBased roles={[ROLES.admin, ROLES.superadmin]}>
        <div className="csv-download">
          <CSVDownload
            csvData={getUsersAsCSVArray(filteredUsers)}
            fileName={
              "UserExport_" + new Date().toISOString().split("T")[0] + ".csv"
            }>
            Download filter as CSV
          </CSVDownload>
        </div>
      </RoleBased>
      <div className="users-list">
        <MaterialTable
          columns={[
            { title: "Name", field: "name" },
            { title: "Department", field: "department" },
            { title: "Teams", field: "teams" },
            { title: "Location", field: "location" },
            { title: "Jira", field: "jira" },
          ]}
          data={
            filteredUsers
              ? filteredUsers.map((doc, key) => {
                  return userListEntry({ doc, key })
                })
              : []
          }
          onRowClick={(
            event,
            data:
              | {
                  tableData: { id: number }
                  name: ReactNode
                  department: string
                  teams: string | ReactNode[]
                  location: string
                  jira: string | ReactNode
                }
              | undefined,
          ) =>
            data && dispatch(setSelectedUser(filteredUsers[data.tableData.id]))
          }
          options={tableDefaultOptions}
        />
      </div>
    </span>
  )
}

const mapStateToProps = (state: RootState) => ({
  users: state.calendar.users,
  departments: state.calendar.departments,
  selectedUser: state.user.selectedUser,
  currentUser: state.user.currentUser,
  locations: state.calendar.locations,
})

export default connect(mapStateToProps)(Users)
