import React, { useEffect, useState } from "react"
import { DAYS } from "../../data/calendar-data"
import { DEFAULT_AVAILABILITY } from "../../data/default-data"
import UpdateAvailabilityInput from "./update-availability-input.component"

import "./update-availability.styles.scss"

interface IUpdateAvailability {
  onChange: (availability: number[]) => void
  isDisabled: boolean
  user: { availability: number[] | null }
}

const UpdateAvailability: React.FunctionComponent<IUpdateAvailability> = ({
  onChange,
  user,
  isDisabled,
}) => {
  const [availability, setAvailability] = useState([...DEFAULT_AVAILABILITY])

  useEffect(() => {
    setAvailability(user?.availability ?? [...DEFAULT_AVAILABILITY])
  }, [user])

  const handleChange = (id: number, value: number) => {
    if (value != null && value >= 0) {
      setAvailability((a) => [
        ...a.slice(0, id),
        value,
        ...a.slice(id + 1, a.length),
      ])
    }
  }

  useEffect(() => {
    onChange(availability)
  }, [availability]) // don't include `onChange`!

  return (
    <div className="update-availability">
      {DAYS.map((day, i) => (
        <UpdateAvailabilityInput
          key={i}
          label={day}
          value={availability?.[i]}
          onChange={handleChange}
          id={i}
          isDisabled={isDisabled}
        />
      ))}
    </div>
  )
}

export default UpdateAvailability
