import { format } from "date-fns"
import { ChangeEvent, VFC } from "react"
import CancelButton from "../cancel-button/cancel-button.component"
import dayjs from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import "./bank-holiday-input.styles.scss"
import { DatePicker } from "@mui/x-date-pickers"

interface IValue {
  displayName: string
  date: string
}

type TBankHolidayInputProps = {
  onChange: (index: number, value: IValue) => void
  index: number
  value: IValue
  onDelete: (index: number) => void
  isDisabled: boolean
}

const BankHolidayInput: VFC<TBankHolidayInputProps> = ({
  onChange,
  index,
  isDisabled,
  value,
  onDelete,
}) => {
  const handleNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(index, { displayName: e.target.value, date: value.date })
  }

  const handleDateChange = (date: Date | null) => {
    if (date && dayjs(date).isValid()) {
      console.log(date)
      onChange(index, {
        displayName: value.displayName,
        date: format(new Date(date), "yyyy-MM-dd"),
      })
    }
  }

  return (
    <>
      <div className="bank-holiday-input">
        <div className="bank-holiday-input-text">
          <label>Name</label>
          <div>
            <input
              type="text"
              value={value.displayName}
              onChange={handleNameChanged}
              className="bank-holiday-input-field"
              disabled={isDisabled}
            />
          </div>
        </div>
        <div className="bank-holiday-input-text">
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={dayjs(value.date)}
                onChange={handleDateChange}
                disabled={isDisabled}
              />
            </LocalizationProvider>
          </div>
        </div>
        {!isDisabled ? <CancelButton onCancel={() => onDelete(index)} /> : null}
      </div>
    </>
  )
}

export default BankHolidayInput
