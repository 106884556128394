import React, { FC } from "react"
import { DAYS } from "../../data/calendar-data"
import "./calendar-day.styles.scss"

interface ICalendarDay {
  date: Date
}

const CalendarDay: FC<ICalendarDay> = (props) => {
  const bIsWeekend = () => {
    return props.date.getDay() === 0 || props.date.getDay() === 6
  }

  const isToday =
    props.date.getTime() ===
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    ).getTime()

  return (
    <div
      className="calendar-day"
      style={
        isToday
          ? {
              background:
                "linear-gradient(#ffffff 0%, #c9e0ff 70%, #c9e0ff 100%)",
            }
          : { background: bIsWeekend() ? "" : "#ffffff" }
      }>
      <div className="calendar-day-wrapper">
        <div className="calendar-day-text">{DAYS[props.date.getDay()]}</div>
        <div className="calendar-day-number">{props.date.getDate()}</div>
        <div className="calendar-day-border" />
      </div>
    </div>
  )
}

export default CalendarDay
