import React, { useState, useEffect } from "react"
import HistoryEntry, {
  IHistoryEntry,
} from "../../components/history-entry/history-entry.component"
import { firestore } from "../../firebase"
import firebase from "firebase/app"
import { tableDefaultOptions } from "../../data/default-data"

import "./history.styles.scss"
import { IAccount } from "../../redux"
import MaterialTable from "material-table"

const historyCollectionDesc = firestore
  .collection("history")
  .orderBy("date", "desc")

const HistoryPage: React.FunctionComponent<{ accounts: IAccount[] | null }> = ({
  accounts,
}) => {
  const [history, setHistory] = useState<
    firebase.firestore.DocumentSnapshot[] | null
  >(null)
  const [receivedFullHistory, setFullHistory] = useState(false)
  const [historyReceivingSize, setHistoryReceivingSize] = useState(20)
  // load initial history on mount
  useEffect(() => {
    historyCollectionDesc
      .limit(40)
      .get()
      .then((snapshot) => setHistory(snapshot.docs))
  }, [])

  const getNextHistoryEntries = () => {
    if (!history || receivedFullHistory) {
      return
    }
    historyCollectionDesc
      .startAfter(history[history.length - 1])
      .limit(historyReceivingSize)
      .get()
      .then((snapshot) => {
        if (snapshot.size < historyReceivingSize) {
          setFullHistory(true)
        }
        setHistory((history) => [...(history ?? []), ...snapshot.docs])
      })
  }

  return (
    <span>
      <div className="users-header">
        <h2>History</h2>
      </div>
      <div className="users-list">
        <MaterialTable
          columns={[
            { title: "Date", field: "date", width: "5%" },
            { title: "Action", field: "action" },
            { title: "Before", field: "before", sorting: false },
            { title: "After", field: "after", sorting: false },
          ]}
          data={
            history?.map((historyEntry) =>
              HistoryEntry({
                historyEntry: historyEntry as any as IHistoryEntry,
                accounts,
              }),
            ) as []
          }
          onChangePage={getNextHistoryEntries}
          onChangeRowsPerPage={(e) => setHistoryReceivingSize(e)}
          options={{
            ...tableDefaultOptions,
          }}
        />
      </div>
    </span>
  )
}

export default HistoryPage
