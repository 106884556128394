import React, { useState } from "react"
import { connect } from "react-redux"
import OverlayBox from "../overlay-box/overlay-box.component"
import { addLocation } from "../../firebase"
import { IAccount } from "../../redux"

interface IAddLocation {
  currentUser: IAccount
  onCancel: () => void
}

const AddLocation: React.FunctionComponent<IAddLocation> = (props) => {
  const [displayName, setDisplayName] = useState("")

  const handleSubmit = (e: any) => {
    e.preventDefault()

    addLocation({ displayName: displayName }, props.currentUser.id)

    setDisplayName("")
    props.onCancel()
  }

  const handleDisplayNameChange = (e: any) => setDisplayName(e.target.value)

  return (
    <OverlayBox heading="Add Location" onCancel={props.onCancel}>
      <form className="add-client-form" onSubmit={handleSubmit}>
        <label>Location Name: </label>
        <input
          id="add-location-name"
          type="text"
          value={displayName}
          onChange={handleDisplayNameChange}
          required
        />
        <div />
        <input
          id="add-location-submit"
          type="submit"
          value="Add Location"
          className="submit-button"
        />
      </form>
    </OverlayBox>
  )
}

const mapStateToProps = (state: any) => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(AddLocation)
