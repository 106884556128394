import { VFC } from "react"
import { startOfYear, sub, add } from "date-fns"
import nextIcon from "../../../../images/ionic-ios-arrow-forward.svg"

type RoadmapMonthSelectionProps = {
  setStartDate: (date: Date) => void
  startDate: Date
}

const RoadmapMonthSelection: VFC<RoadmapMonthSelectionProps> = ({
  startDate,
  setStartDate,
}) => {
  return (
    <div className="roadmap-month-selection">
      <button
        className="calendar-month-selection-today roadmap-button"
        onClick={() => setStartDate(startOfYear(new Date()))}>
        Current Year
      </button>
      <div className="roadmap-year-selection-item-wrapper">
        <button
          className="calendar-button"
          onClick={() => setStartDate(sub(startDate, { years: 1 }))}>
          <img
            src={nextIcon}
            className="previous-icon"
            alt="Previous month button"
          />
        </button>
      </div>
      <div className="roadmap-year-selection-item-wrapper">
        <button
          className="calendar-button"
          onClick={() => setStartDate(add(startDate, { years: 1 }))}>
          <img src={nextIcon} className="next-icon" alt="Next month button" />
        </button>
      </div>
      <div className="roadmap-year-selection-item-wrapper">
        <div className="calendar-month-text" style={{ minWidth: 0 }}>
          {startDate.getFullYear()}
        </div>
      </div>
    </div>
  )
}

export { RoadmapMonthSelection }
