import React from "react"
import { connect } from "react-redux"

import "./calendar-summary-row.styles.scss"

interface ICalendarSummaryRow {
  group?: boolean
  availability: number[]
  originalAvailability: number[]
  animationClassName?: string
}

const CalendarSummaryRow: React.FunctionComponent<ICalendarSummaryRow> = (
  props,
) => {
  const getSummaryStyling = (value: number, origValue: number) => {
    const { group } = props

    if (value < 0) {
      return { background: "red" }
    }

    const height = (1 - value / (group ? 1 : origValue)) * 100 + "%"
    return { background: "#4285F4", height: height }
  }

  const { availability, originalAvailability, animationClassName } = props
  return (
    <div className={"calendar-summary-row " + animationClassName}>
      {availability.map((value, key) => (
        <div className="summary-element-wrapper" key={key}>
          {originalAvailability[key] > 0 ? (
            <div
              className="summary-element"
              style={getSummaryStyling(value, originalAvailability[key])}
            />
          ) : (
            <div className="summary-element-unavailable" />
          )}
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  animationClassName: state.calendar.animationClassName,
})

export default connect(mapStateToProps)(CalendarSummaryRow)
