import React from "react"

import "./custom-button.styles.scss"

interface ICustomButton {
  onClick: () => void
  color?: "red" | "green" | "gray"
  weight?: "bold"
  disabled?: boolean
  type?: "button" | "submit" | "reset"
  fontSize?: "font14"
}

const CustomButton: React.FunctionComponent<ICustomButton> = (props) => {
  const { children, onClick, disabled, type, color, weight, fontSize } = props
  return (
    <button
      className={`custom-button${
        color ? `-${color}` : ""
      } ${weight} ${fontSize}`}
      id="custom-button-click-action"
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  )
}

export default CustomButton
