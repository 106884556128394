import { combineReducers } from "redux"
import { configureStore } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { userReducer } from "./user"
import { projectsReducer } from "./projects"
import { calendarReducer } from "./calendar"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
}

const rootReducer = combineReducers({
  user: userReducer,
  projects: projectsReducer,
  calendar: calendarReducer,
})

// FIXME: undo
// const middleware = process.env.NODE_ENV === "development" ? [logger] : []
const middleware = process.env.NODE_ENV === "development" ? [] : []

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export const persistor = persistStore(store)
