import React, { useState } from "react"
import { connect } from "react-redux"

import { setAddDepartmentVisibility } from "../../redux"
import { addDepartment } from "../../firebase"
import OverlayBox from "../overlay-box/overlay-box.component"

import "./add-department.styles.scss"
import { IAccount } from "../../redux"

interface IAddDepartment {
  currentUser: IAccount
  setAddDepartmentVisibility: (visibility: boolean) => void
  addDepartmentVisibility: boolean
}

const AddDepartment: React.FunctionComponent<IAddDepartment> = (props) => {
  const [displayName, setDisplayName] = useState("")
  const { currentUser, setAddDepartmentVisibility, addDepartmentVisibility } =
    props

  const handleDisplayNameChange = (e: any) => {
    setDisplayName(e.target.value)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()

    if (!displayName) {
      return
    }

    addDepartment({ displayName }, currentUser.id)
    setDisplayName("")
    toggleOverlay()
  }

  const toggleOverlay = () => {
    setAddDepartmentVisibility(!addDepartmentVisibility)
  }

  return (
    <OverlayBox
      heading="Add Department"
      onCancel={() => setAddDepartmentVisibility(!addDepartmentVisibility)}>
      <form className="add-client-form" onSubmit={handleSubmit}>
        <label>Department Name: </label>
        <input
          id="add-department-name"
          type="text"
          defaultValue={displayName}
          onChange={handleDisplayNameChange}
        />
        <div />
        <input
          id="add-department-submit"
          type="submit"
          value="Add Department"
          className="submit-button"
        />
      </form>
    </OverlayBox>
  )
}

const mapStateToProps = (state: any) => ({
  addDepartmentVisibility: state.calendar.addDepartmentVisibility,
  currentUser: state.user.currentUser,
})

const mapDispatchToProps = (dispatch: any) => ({
  setAddDepartmentVisibility: (visibility: boolean) =>
    dispatch(setAddDepartmentVisibility(visibility)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDepartment)
