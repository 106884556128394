import { OOO } from "../../data/calendar-data"
import { IDoc } from "../../redux"

const getActionText = (entry: IDoc) => {
  if (entry.approval.type === "delete") {
    if (entry.type === OOO) {
      return "Delete out of office entry"
    } else {
      return "Delete entry"
    }
  } else if (entry.approval.type === "hard") {
    return "Requests hard lock"
  } else if (entry.approval.type === "draft") {
    if (entry.type === OOO) {
      return "Requests out of office"
    } else if (entry.approval.status === "pending" && !!entry.before) {
      return "Update entry"
    } else {
      return "Create entry"
    }
  }
  return ""
}

export default getActionText
