import React from "react"
import { ENTRY_TYPES, PROJECT } from "../../data/calendar-data"
import { IDoc } from "../../redux"

const isLegacyDate = (arg: any): arg is { toDate(): Date } =>
  "toDate" in arg != null && typeof arg["toDate"] === "function"

const FormatEntryData: React.FC<IDoc> = ({
  startDate,
  endDate,
  ...entryData
}) => {
  const formattedStartDate =
    typeof startDate === "string"
      ? startDate
      : (isLegacyDate(startDate)
          ? startDate.toDate()
          : startDate
        ).toLocaleDateString()
  const formattedEndDate =
    typeof startDate === "string"
      ? startDate
      : (isLegacyDate(endDate)
          ? endDate.toDate()
          : endDate
        ).toLocaleDateString()

  return (
    <table id="nested-table">
      <thead>
        <tr>
          <th>Type:</th>
          <td>{entryData && ENTRY_TYPES[entryData.type].displayName}</td>
        </tr>
        {entryData.type === PROJECT ? (
          <tr>
            <th>Project:</th>
            <td>
              {entryData.project
                ? (entryData.project.client
                    ? entryData.project.client.displayName + " - "
                    : "") + entryData.project.displayName
                : "none"}
            </td>
          </tr>
        ) : null}
        <tr>
          <th>Start Date:</th>
          <td>{formattedStartDate}</td>
        </tr>
        <tr>
          <th>End Date:</th>
          <td>{formattedEndDate}</td>
        </tr>
        <tr>
          <th>Booking Ratio:</th>
          <td>{entryData.bookingRatio}</td>
        </tr>
        <tr>
          <th>Booked Time:</th>
          <td>{entryData.bookedTime}</td>
        </tr>
      </thead>
    </table>
  )
}

export default FormatEntryData
