import React, { VFC } from "react"

interface IUpdateAvailabilityInput {
  onChange: (id: number, value: number) => void
  id: number
  label: string
  value: number
  isDisabled: boolean
}

const UpdateAvailabilityInput: VFC<IUpdateAvailabilityInput> = ({
  onChange,
  id,
  value,
  isDisabled,
  label,
}) => {
  const handleChange = (e: { target: { value: string } }) => {
    const value = e.target.value
      ? parseFloat(e.target.value.replace(",", "."))
      : 0

    onChange(id, value)
  }

  return (
    <div>
      <div>{label}</div>
      <input
        className="availability-input"
        type="number"
        value={value}
        max="10"
        min="0"
        onChange={handleChange}
        disabled={isDisabled}
      />
    </div>
  )
}

export default UpdateAvailabilityInput
