import React, { useState } from "react"
import { connect, RootStateOrAny } from "react-redux"

import { setAddClientVisibility } from "../../redux"
import { addClient as addFirebaseClient } from "../../firebase"
import OverlayBox from "../overlay-box/overlay-box.component"

import "./add-client.styles.scss"
import { IAccount } from "../../redux"

interface IProps {
  currentUser: IAccount
  setAddClientVisibility: (visibility: boolean) => void
  addClientVisibility: boolean
}

const AddClient: React.FunctionComponent<IProps> = (props) => {
  const [displayName, setDisplayName] = useState("")

  const { currentUser, setAddClientVisibility, addClientVisibility } = props

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!displayName) {
      return
    }
    addFirebaseClient(
      {
        displayName: displayName,
      },
      currentUser.id,
    )
    setDisplayName("")
    setAddClientVisibility(!addClientVisibility)
  }

  return (
    <OverlayBox
      heading="Add Client"
      onCancel={() => setAddClientVisibility(!addClientVisibility)}>
      <form className="add-client-form" onSubmit={handleSubmit}>
        <label>Client Name: </label>
        <input
          id="add-client-name"
          type="text"
          defaultValue={displayName}
          onChange={(e: any) => setDisplayName(e.target.value)}
        />
        <div />
        <input
          id="add-client-submit"
          type="submit"
          value="Add Client"
          className="submit-button"
        />
      </form>
    </OverlayBox>
  )
}

const mapStateToProps = (state: RootStateOrAny) => ({
  addClientVisibility: state.projects.addClientVisibility,
  currentUser: state.user.currentUser,
})

const mapDispatchToProps = (dispatch: any) => ({
  setAddClientVisibility: (visibility: boolean) =>
    dispatch(setAddClientVisibility(visibility)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddClient)
