import { FC } from "react"

interface IMonthField {
  month: string
}

const MonthField: FC<IMonthField> = ({ month }) => {
  return (
    <div className="calendar-day">
      <div className="calendar-day-wrapper">
        <div className="calendar-day-number">{month}</div>
        <div className="calendar-day-border" />
      </div>
    </div>
  )
}

export default MonthField
