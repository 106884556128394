import { IAccount, IDoc } from "../../redux"
import CustomButton from "../custom-button/custom-button.component"
import approvalActions from "../../pages/pending-approvals/approvalActions"
import PopUpWindowEntry from "./PopUpWindowEntry"
import { Link } from "react-router-dom"
import { VFC } from "react"

interface IPendingApprovalsPopUpWindowEntry {
  pendingEntry: IDoc
  accounts: IAccount[]
  currentUser: IAccount
  setSelectedEntry: (entry: IDoc) => void
  showPopUp: (value: boolean) => void
}

const PendingApprovalsPopUpWindowEntry: VFC<IPendingApprovalsPopUpWindowEntry> =
  ({ pendingEntry, accounts, currentUser, setSelectedEntry, showPopUp }) => {
    return (
      <div className="popup-item-wrapper">
        <PopUpWindowEntry accounts={accounts} popUpEntry={pendingEntry} />
        <div className="popup-approval-actions">
          <CustomButton
            weight={"bold"}
            fontSize="font14"
            onClick={() =>
              approvalActions({
                action: "approve",
                entry: pendingEntry,
                currentUser,
              })
            }>
            Approve
          </CustomButton>
          <div />
          <CustomButton
            weight={"bold"}
            fontSize="font14"
            color="gray"
            onClick={() =>
              approvalActions({
                action: "decline",
                entry: pendingEntry,
                currentUser,
              })
            }>
            <div className="entry-decline">Decline</div>
          </CustomButton>
          <div />
          <Link
            className="go-to-entry-link"
            to={`/calendar/${pendingEntry.id}`}
            onClick={() => {
              setSelectedEntry(pendingEntry)
              showPopUp(false)
            }}>
            <span className="go-to-entry">Go to entry</span>
          </Link>
        </div>
      </div>
    )
  }

export default PendingApprovalsPopUpWindowEntry
