import { IRole } from "../redux"
import { errorToast, firestore, successToast } from "./firebase.utils"

export const addRole = async (
  role: {
    superadmin?: boolean
    admin?: boolean
  },
  id: string,
) => {
  try {
    role && (await firestore.collection("roles").doc(id).set(role))
    successToast("Successfully added Role")
  } catch (err) {
    errorToast("Failed to add Role")
    return
  }
}

export const updateRole = async (
  id: string,
  role: { superadmin?: boolean; admin?: boolean },
) => {
  try {
    role && (await firestore.collection("roles").doc(id).update(role))
    successToast("Successfully updated Role")
  } catch (err) {
    errorToast("Failed to update Role")
    return
  }
}

export const deleteRole = async (role: IRole | null) => {
  try {
    await firestore.collection("roles").doc(role?.id).set({ deleted: true })
    successToast("Successfully deleted Role")
  } catch (err) {
    errorToast("Failed to delete Role")
    return
  }
}
