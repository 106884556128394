import React from "react"
import { Link, useRouteMatch } from "react-router-dom"

import "./header-link.styles.scss"
import classNames from "classnames"

interface IHeaderLink {
  link: string
}

const HeaderLink: React.FunctionComponent<IHeaderLink> = ({
  link,
  children,
}) => {
  const match = useRouteMatch({ path: link })

  return (
    <div
      className={classNames({
        "header-link": true,
        "header-link-active": !!match,
      })}
    >
      <Link to={link}>{children}</Link>
    </div>
  )
}

export default HeaderLink
