import { IDepartments } from "../redux"
import { errorToast, firestore, successToast } from "./firebase.utils"

export const addDepartment = async (
  department: { displayName: string },
  uid: string,
) => {
  try {
    await firestore
      .collection("departments")
      .add({ ...department, lastChanged: uid })
    successToast("Successfully added Department")
  } catch (err) {
    errorToast("Failed to add Department")
    return
  }
}

export const updateDepartment = async (
  id: string,
  department: { displayName: string },
  uid: string,
) => {
  try {
    await firestore
      .collection("departments")
      .doc(id)
      .update({ ...department, lastChanged: uid })
    successToast("Successfully update Department")
  } catch (err) {
    errorToast("Failed to update Department")
    return
  }
}

export const deleteDepartment = async (
  department: IDepartments,
  uid: string,
) => {
  try {
    await firestore
      .collection("departments")
      .doc(department.id)
      .set({ deleted: true, lastChanged: uid })
    successToast("Successfully delete Department")
  } catch (err) {
    errorToast("Failed to delete Department")
    return
  }
}
