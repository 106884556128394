import React, {
  useEffect,
  useMemo,
  useReducer,
  useState,
  MouseEvent,
} from "react"
import { connect } from "react-redux"
import Select, { NamedProps } from "react-select"
import { ROLES } from "../../data/default-data"
import { updateUser } from "../../firebase"
import JiraUsersSelector from "../jira-users-selector/jira-users-selector.component"
import RoleBased from "../role-based/role-based.component"
import RoleBasedComponent from "../role-based/role-based.component"
import UpdateAvailability from "../update-availability/update-availability.component"
import CustomButton from "../custom-button/custom-button.component"
import OverlayBox from "../overlay-box/overlay-box.component"
import DropdownMenu from "../dropdown-menu/dropdown-menu.component"
import CustomButtonDropdown from "../custom-button-dropdown/custom-button-dropdown.component"
import "./update-user.styles.scss"
import JiraUserCard from "../jira-user-card/jira-user-card.component"
import FilterTeam from "../filter-team/filter-team.component"
import { toCamelcase } from "../../utils"
import { IDate } from "../../redux/calendar/types"
import { isDate } from "../../AuthenticatedApp"
import {
  IAccount,
  IDepartments,
  ILocations,
  ITeams,
  IUser,
  SeniorityLevelLabel,
} from "../../redux"
import { Calendar } from "react-date-range"
import { format } from "date-fns"
import { createPortal } from "react-dom"

interface IUpdateUser {
  selectedUser: IUser
  currentUser: IAccount
  onUpdate: () => void
  departments: IDepartments[]
  teams: ITeams[]
  locations: ILocations[]
  roleManager: {
    userRoles: { [key: string]: string }
    hasRole: (permissionsRoles: string[]) => boolean
  }
}

interface IState {
  firstName: string
  lastName: string
  email: string
  availability: number[]
  seniorityLevel: { label: string; value: string }
  department: { label: string; value: string }
  location: { label: string; value: string }
  selectedTeams: { label: string; value: string }[]
}

const reducer = (
  localState: IState,
  action: {
    [key: string]:
      | string
      | number[]
      | null
      | { value: string; label: string }
      | { value: string; label: string }[]
  },
) => {
  return { ...localState, ...action }
}
const UpdateUser: React.FunctionComponent<IUpdateUser> = (props) => {
  const { selectedUser } = props
  const initialState = useMemo(
    () =>
      ({
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
        availability: selectedUser.availability,
        department: selectedUser.department
          ? {
              value: selectedUser.department.id,
              label: selectedUser.department.displayName,
            }
          : null,
        location: selectedUser.location
          ? {
              value: selectedUser.location.id,
              label: selectedUser.location.displayName,
            }
          : null,
        selectedTeams: [],
        seniorityLevel: selectedUser.seniorityLevel
          ? {
              label: toCamelcase(selectedUser.seniorityLevel),
              value: selectedUser.seniorityLevel,
            }
          : null,
      } as IState),
    [selectedUser],
  )

  type TDatePickerState = {
    show: boolean
    position: {
      x: number
      y: number
    } | null
  }
  const initialDisplayDatePickerState: TDatePickerState = {
    show: false,
    position: null,
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const [showJiraOverlay, setShowJiraOverlay] = useState(false)
  const [startDate, setStartDate] = useState<Date | null>()
  const [endDate, setEndDate] = useState<Date | null>()
  const [displayDatePicker, setDisplayDatePicker] = useState<TDatePickerState>(
    initialDisplayDatePickerState,
  )
  const [displayStartDatePicker, setDisplayStartDatePicker] =
    useState<TDatePickerState>(initialDisplayDatePickerState)

  const resetDisplayDatePicker = () => {
    setDisplayDatePicker(initialDisplayDatePickerState)
  }

  const resetDisplayStartDatePicker = () => {
    setDisplayStartDatePicker(initialDisplayDatePickerState)
  }

  const handleDateClicked = (event: MouseEvent<HTMLInputElement>) => {
    const target = event.target
    if (!(target instanceof HTMLInputElement)) {
      return
    }

    const { x, y } = target.getBoundingClientRect()

    setDisplayDatePicker(({ show }) => ({
      show: !show,
      position: { x, y },
    }))
  }

  const handleStartDateClicked = (event: MouseEvent<HTMLInputElement>) => {
    const target = event.target
    if (!(target instanceof HTMLInputElement)) {
      return
    }

    const { x, y } = target.getBoundingClientRect()

    setDisplayStartDatePicker(({ show }) => ({
      show: !show,
      position: { x, y },
    }))
  }

  useEffect(() => {
    const teamsOfUser = selectedUser.teams
    console.log(selectedUser)
    console.log(selectedUser.startDate)
    console.log(selectedUser.endDate)
    console.log(typeof selectedUser.endDate)
    if (selectedUser.startDate && isDate(selectedUser.startDate)) {
      setStartDate(selectedUser.startDate.toDate())
    }
    if (selectedUser.endDate && isDate(selectedUser.endDate)) {
      setEndDate(selectedUser.endDate.toDate())
    }
    const teams = teamsOfUser
      ? Object.keys(teamsOfUser).map((key) => ({
          label: teamsOfUser[key].displayName,
          value: key,
        }))
      : []
    dispatch({ ...initialState, selectedTeams: teams })
  }, [selectedUser, initialState])

  const handleFirstNameChange = (e: any) =>
    dispatch({ firstName: e.target.value || "" })

  const handleLastNameChange = (e: any) =>
    dispatch({ lastName: e.target.value || "" })

  const handleEmailChange = (e: any) =>
    dispatch({ email: e.target.value || "" })

  const handleDepartmentChange = (e: any) =>
    dispatch({
      department: e ? { label: e.label || "", value: e.value || "" } : null,
    })

  const handleTeamsChange = (e: any) => {
    const teams = e.map((teamId: string) => {
      const team = props.teams.find(
        (value: { id: string }) => value.id === teamId,
      )
      return { label: team?.displayName || "", value: team?.id || "" }
    })
    dispatch({ selectedTeams: teams })
  }

  const handleLocationChange = (e: any) =>
    dispatch({ location: { label: e.label || "", value: e.value || "" } })

  const handleSeniorityLevelChange: NamedProps["onChange"] = (e) =>
    dispatch({
      seniorityLevel: e ? { label: e.label || "", value: e.value || "" } : null,
    })

  const handleAvailabilityChange = (e: any) =>
    dispatch({ availability: e || [] })

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const { currentUser, teams } = props

    let sanitizedAvailability = null
    if (state.availability) {
      sanitizedAvailability = [...state.availability]
      sanitizedAvailability.forEach((element, i) => {
        sanitizedAvailability[i] = element ? element : 0
      })

      console.log(sanitizedAvailability)
    }

    let teamsObject = {} as {
      [key: string]: { displayName: string | undefined }
    }
    if (state.selectedTeams.length > 0) {
      state.selectedTeams.forEach((team) => {
        teamsObject[team.value] = {
          displayName: teams?.find((element) => element.id === team.value)
            ?.displayName,
        }
      })
    }

    updateUser(
      selectedUser.id,
      {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        availability: state.availability ? sanitizedAvailability : null,
        department: state.department
          ? { id: state.department.value, displayName: state.department.label }
          : null,
        teams: teamsObject,
        location: state.location
          ? { id: state.location.value, displayName: state.location.label }
          : null,
        seniorityLevel: state.seniorityLevel ? state.seniorityLevel.value : "",
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
      },
      currentUser.id,
    )

    props.onUpdate()
  }

  const { departments, locations, currentUser } = props

  const options = [] as { label: string; value: string }[]

  if (departments) {
    departments.forEach((department) => {
      options.push({
        value: department.id,
        label: department.displayName,
      })
    })
  }

  const locationOptions = [] as { label: string; value: string }[]

  if (locations) {
    locations.forEach((location) => {
      locationOptions.push({
        label: location.displayName,
        value: location.id,
      })
    })
  }

  const seniorityLevelOption = Object.keys(SeniorityLevelLabel)
    .filter((level, key) => SeniorityLevelLabel[key])
    .map((level: string, key: number) => ({
      label: SeniorityLevelLabel[key],
      value: SeniorityLevelLabel[key].toLowerCase(),
    }))

  const disableInput = !props.roleManager.hasRole([ROLES.superadmin])

  return (
    <div>
      <div className="update-user-heading">
        <h2>User</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="update-user-form">
          <label>First Name: </label>
          <input
            id="update-user-firstname"
            type="text"
            value={state.firstName}
            onChange={handleFirstNameChange}
            disabled={disableInput}
            required
          />
          <label>Last Name: </label>
          <input
            id="update-user-lastname"
            type="text"
            value={state.lastName}
            onChange={handleLastNameChange}
            disabled={disableInput}
            required
          />
          <label>E-Mail: </label>
          <input
            id="update-user-email"
            type="email"
            value={state.email}
            onChange={handleEmailChange}
            disabled={disableInput}
          />
          <label>Department</label>
          <Select
            isClearable={true}
            id="update-user-select-department"
            options={options}
            value={state.department}
            onChange={handleDepartmentChange}
            isDisabled={disableInput}
          />
          <label>Seniority level</label>
          <Select
            isClearable={true}
            id="update-user-select-seniority-level"
            options={seniorityLevelOption}
            value={state.seniorityLevel}
            onChange={handleSeniorityLevelChange}
            isDisabled={disableInput}
          />
          <label>Teams</label>
          <FilterTeam
            onChange={handleTeamsChange}
            value={state.selectedTeams}
          />
          <label>Location</label>
          <Select
            id="update-user-select-location"
            options={locationOptions}
            value={state.location}
            onChange={handleLocationChange}
            isDisabled={disableInput}
          />
          {displayStartDatePicker.show &&
            displayStartDatePicker.position &&
            createPortal(
              <>
                <div
                  className="bank-holiday-click-outside"
                  onClick={resetDisplayStartDatePicker}
                />
                <div
                  className="bank-holiday-date-input-wrapper"
                  style={{
                    left: displayStartDatePicker.position.x - 370,
                    top: displayStartDatePicker.position.y - 30,
                  }}>
                  <Calendar
                    date={startDate ? startDate : new Date()}
                    onChange={(date) => {
                      setStartDate(new Date(date.toString()))
                      resetDisplayStartDatePicker()
                    }}
                  />
                </div>
              </>,
              document.body,
            )}
          {displayDatePicker.show &&
            displayDatePicker.position &&
            createPortal(
              <>
                <div
                  className="bank-holiday-click-outside"
                  onClick={resetDisplayDatePicker}
                />
                <div
                  className="bank-holiday-date-input-wrapper"
                  style={{
                    left: displayDatePicker.position.x - 370,
                    top: displayDatePicker.position.y - 30,
                  }}>
                  <Calendar
                    date={endDate ? endDate : new Date()}
                    onChange={(date) => {
                      setEndDate(new Date(date.toString()))
                      resetDisplayDatePicker()
                    }}
                  />
                </div>
              </>,
              document.body,
            )}
          <label>Start date:</label>
          <input
            id="update-project-select-startDate"
            value={startDate ? format(startDate, "dd.MM.yyyy") : ""}
            onClick={handleStartDateClicked}
            onChange={() => setStartDate(null)}
          />
          <label>End date:</label>
          <input
            id="update-project-select-endDate"
            value={endDate ? format(endDate, "dd.MM.yyyy") : ""}
            onClick={handleDateClicked}
            onChange={() => setStartDate(null)}
          />
        </div>
        <div className="update-user-lower-form">
          <UpdateAvailability
            user={selectedUser}
            onChange={handleAvailabilityChange}
            isDisabled={disableInput}
          />
          <RoleBased roles={[ROLES.superadmin]}>
            <input
              id="update-user-submit"
              type="submit"
              value="Update User"
              className="submit-button"
            />
          </RoleBased>
        </div>
        <div className="update-user-jira">
          <RoleBasedComponent roles={[ROLES.admin, ROLES.superadmin]}>
            {selectedUser && selectedUser.jira ? (
              <JiraUserCard user={selectedUser.jira} />
            ) : !disableInput ? (
              <CustomButton
                type="button"
                onClick={() => setShowJiraOverlay(true)}>
                Connect with Atlassian User
              </CustomButton>
            ) : null}
            {showJiraOverlay ? (
              <OverlayBox onCancel={() => setShowJiraOverlay(false)}>
                <JiraUsersSelector
                  user={selectedUser}
                  onCancel={() => setShowJiraOverlay(false)}
                />
              </OverlayBox>
            ) : null}
          </RoleBasedComponent>
          <RoleBasedComponent roles={[ROLES.admin, ROLES.superadmin]}>
            {selectedUser && selectedUser.jira ? (
              <DropdownMenu>
                <CustomButtonDropdown
                  onClick={() =>
                    updateUser(selectedUser.id, { jira: null }, currentUser.id)
                  }>
                  Disconnect Atlassian User
                </CustomButtonDropdown>
              </DropdownMenu>
            ) : null}
          </RoleBasedComponent>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  selectedUser: state.user.selectedUser,
  departments: state.calendar.departments,
  teams: state.calendar.teams,
  locations: state.calendar.locations,
  roleManager: state.user.roleManager,
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(UpdateUser)
