import { atlassianBaseUrl } from "../../atlassian/atlassian.utils"
import { IJiraUsersData } from "../../redux"

interface IJiraUserCard {
  user: IJiraUsersData
  size?: string
}

const JiraUserCard = ({ user, size }: IJiraUserCard) => (
  <a
    href={atlassianBaseUrl + "/jira/people/" + user.accountId}
    className="jira-project"
    target="_blank"
    rel="noopener noreferrer"
    style={size === "small" ? { margin: 0, width: "auto" } : {}}>
    <div
      className="jira-project-thumb-border"
      style={
        size === "small"
          ? { width: "32px", height: "32px" }
          : { width: "36px", height: "36px" }
      }>
      <img
        className="jira-project-thumb"
        style={
          size === "small"
            ? { width: "32px", height: "32px" }
            : { width: "36px", height: "36px" }
        }
        alt="Jira project thumbnail"
        src={user.avatarUrls["48x48"]}
      />
    </div>
    {user.displayName}
  </a>
)

export default JiraUserCard
