import MaterialTable from "material-table"
import { useState } from "react"
import { connect } from "react-redux"
import { Route, useHistory, useLocation } from "react-router-dom"
import Deliveries from "../../components/deliveries/deliveries.component"
import ProjectBoard from "../../components/project-board/project-board.component"
import PreProd from "../../components/deliveries/preprod.component"

import SearchBar from "../../components/search-bar/search-bar.component"
import SidebarLink from "../../components/sidebar-link/sidebar-link.component"
import { tableDefaultOptions } from "../../data/default-data"
import { IProject } from "../../redux"
import ProjectPage from "../project/project-page.component"

import "./projects-page.styles.scss"

interface IProjectPage {
  projects: IProject[]
  match: { path: string }
}

const ProjectsPage = ({ projects, match }: IProjectPage) => {
  const [projectFilter, setProjectFilter] = useState("")
  const history = useHistory()
  const location = useLocation()

  const handleFilterChange = (e: any) => {
    setProjectFilter(e.target.value)
  }

  const handleProjectClick = (projectId: string) => {
    history.push(`${match.path}/${projectId}`)
  }

  const filteredProjects = projects
    ? projects.filter((project) =>
        (project.client
          ? project.client.displayName + " " + project.displayName
          : project.displayName
        )
          .toLowerCase()
          .includes(projectFilter.toLowerCase()),
      )
    : []

  return (
    <div className="projects-page">
      <div className="projects-header">
        <SidebarLink to="/projects" isExact={true}>
          Projects
        </SidebarLink>
        <div />
        <SidebarLink to="/projects/preprod" isExact={true}>
          Pre-Production Dates
        </SidebarLink>
        <div />
        <SidebarLink to="/projects/deliveries" isExact={true}>
          Deliveries
        </SidebarLink>
        <div />
        <SidebarLink to="/projects/project-board" isExact={true}>
          Project Board
        </SidebarLink>
        <div />
      </div>
      <Route exact path="/projects/preprod">
        <PreProd />
      </Route>
      <Route exact path="/projects/deliveries">
        <Deliveries />
      </Route>
      <Route exact path="/projects/project-board">
        <ProjectBoard />
      </Route>
      <Route exact path={match.path}>
        <div className="users-header">
          <h2>Projects</h2>
          <div />
          <SearchBar
            placeholder="Find projects..."
            defaultValue={projectFilter}
            onChange={(e: any) => handleFilterChange(e)}
            width="80%"
          />
          <div>Total: {projects ? projects.length : 0}</div>
        </div>
        <div className="users-list">
          <MaterialTable
            columns={[
              { title: "Project", field: "project" },
              {
                title: "Status",
                field: "status",
              },
              {
                title: "Jira Link",
                field: "jiraLink",
                render: (rowData) => (
                  <a href={rowData.jiraLink} target="_blank" rel="noreferrer">
                    {rowData.id}
                  </a>
                ),
              },
            ]}
            data={
              filteredProjects
                ? filteredProjects.map((project, key) => {
                    return {
                      id: project.jira?.key,
                      project: project.displayName,
                      status: project.jira?.status,
                      jiraLink: project.jira?.self,
                      tableData: { id: key },
                    }
                  })
                : []
            }
            onRowClick={(
              event,
              data:
                | {
                    project: string
                    tableData: { id: number }
                  }
                | undefined,
            ) =>
              data && handleProjectClick(filteredProjects[data.tableData.id].id)
            }
            options={tableDefaultOptions}
          />
        </div>
      </Route>
      {location.pathname !== "/projects/roadmap" &&
      location.pathname !== "/projects/deliveries" &&
      location.pathname !== "/projects/project-board" &&
      location.pathname !== "/projects/preprod" ? (
        <Route path={`${match.path}/:projectId`} component={ProjectPage} />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  projects: state.projects.projects,
})

export default connect(mapStateToProps)(ProjectsPage)
