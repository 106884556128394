import { connect } from "react-redux"
import Select, { OptionsType } from "react-select"
import { IDepartments, RootState } from "../../redux"

interface IFilterDepartment {
  departments: IDepartments[] | null
  onChange: (department: string[]) => void
}

const FilterDepartment = ({ departments, onChange }: IFilterDepartment) => {
  const handleDepartmentFilterChange = (
    event:
      | OptionsType<{
          value: string
          label: string
        }>
      | undefined,
  ) => {
    const departmentFilter = event
      ? event.map((eventValue: { value: string }) => eventValue.value)
      : []
    onChange(departmentFilter)
  }

  const departmentFilterOptions = departments
    ? departments.map((department) => ({
        value: department.id,
        label: department.displayName,
      }))
    : []

  return (
    <Select
      id="calendar-filter-department"
      isMulti
      options={departmentFilterOptions}
      onChange={handleDepartmentFilterChange}
      placeholder="Department..."
      classNamePrefix="filter-team-select"
    />
  )
}

const mapStateToProps = (state: RootState) => ({
  departments: state.calendar.departments,
})

export default connect(mapStateToProps)(FilterDepartment)
