import { TAllocatedProjectData, TMaybeAllocatedProjectData } from "../types"
import { IProject } from "../../../../../redux"

export const mapProjectHeadcount = (projectData: TAllocatedProjectData) =>
  projectData &&
  Object.values(projectData).reduce((acc, { headcount }) => acc + headcount, 0)

export const filterAllocatedProjects = (projects: IProject[]) => {
  return (allocatedProject: TMaybeAllocatedProjectData) => {
    let filteredData: TAllocatedProjectData = {}
    for (const project of projects) {
      if (allocatedProject && allocatedProject.hasOwnProperty(project.id)) {
        filteredData = {
          ...filteredData,
          [project.id]: allocatedProject[project.id],
        }
      }
    }

    return filteredData
  }
}

export const mapToHeadcountDepartments = (
  projectData: TAllocatedProjectData,
): Record<string, number> =>
  Object.values(projectData).reduce((acc, { departments }) => {
    const departmentKeys = Object.keys(departments)
    if (departmentKeys.length === 0) {
      // projectData doesn't include any departments, no need to count
      return acc
    }

    for (const departmentKey of departmentKeys) {
      const existingCount = acc[departmentKey]
      const currentCount = departments[departmentKey].count

      if (existingCount) {
        acc[departmentKey] = existingCount + currentCount
      } else {
        acc[departmentKey] = currentCount
      }
    }

    return acc
  }, {} as Record<string, number>)
