import { Options } from "material-table"

export const DEFAULT_AVAILABILITY = [0, 8, 8, 8, 8, 8, 0]

export const ROLES = {
  admin: "admin",
  superadmin: "superadmin",
}

// any is necessary in this case because the table
// takes wrong type if it is added by an not direct object
export const tableDefaultOptions: Options<any> = {
  sorting: true,
  search: false,
  showTitle: false,
  toolbar: false,
  draggable: false,
  headerStyle: { fontWeight: "bold" },
  pageSizeOptions: [10, 20, 50, 100],
  pageSize: 20,
}
