import React, { useState } from "react"
import { connect, RootStateOrAny } from "react-redux"

import CustomButton from "../../components/custom-button/custom-button.component"
import {
  IRateCard,
  setAddRateCardVisibility,
  setSelectedRateCard,
} from "../../redux"
import SearchBar from "../../components/search-bar/search-bar.component"
import CustomButtonDropdown from "../../components/custom-button-dropdown/custom-button-dropdown.component"
import DropdownMenu from "../../components/dropdown-menu/dropdown-menu.component"
import UpdateRateCard from "./update-rate-card.component"
import Sidebar from "../../components/sidebar/sidebar-component"
import CancelButton from "../../components/cancel-button/cancel-button.component"
import { deleteRateCard } from "../../firebase"
import RoleBased from "../../components/role-based/role-based.component"
import { ROLES } from "../../data/default-data"

import "./rate-cards-page.styles.scss"
import { IAccount } from "../../redux"
import RateCardsList from "./rate-cards-list.component"
import AddRateCard from "./add-rate-card.component"

interface IRateCardsPage {
  setAddRateCardVisibility: (visibility: boolean) => void
  addRateCardVisibility: boolean
  setSelectedRateCard: (rateCard: IRateCard | null) => void
  selectedRateCard: IRateCard
  currentUser: IAccount
  rateCards: IRateCard[]
}

const RateCardsPage: React.FunctionComponent<IRateCardsPage> = (props) => {
  const [rateCardFilter, setRateCardFilter] = useState("")

  const handleFilterChange = (e: any) => setRateCardFilter(e.target.value)

  const closeSidebar = () => props.setSelectedRateCard(null)

  const toggleAddRateCardVisibility = () =>
    props.setAddRateCardVisibility(!props.addRateCardVisibility)

  const handleDeleteRateCard = () => {
    const { currentUser, selectedRateCard } = props
    if (selectedRateCard) {
      deleteRateCard(selectedRateCard, currentUser.id)
    }
    closeSidebar()
  }

  const { rateCards, selectedRateCard, addRateCardVisibility } = props

  const permissionRoles = [ROLES.superadmin]

  return (
    <span>
      {selectedRateCard ? (
        <Sidebar position="right" width="500px">
          <div className="sidebar-cancel-button">
            <RoleBased roles={permissionRoles}>
              <div className="sidebar-dropdown-menu">
                <DropdownMenu>
                  <CustomButtonDropdown onClick={() => handleDeleteRateCard()}>
                    Delete Rate Card
                  </CustomButtonDropdown>
                </DropdownMenu>
              </div>
            </RoleBased>
            <CancelButton onCancel={() => closeSidebar()} />
          </div>
          <UpdateRateCard
            onUpdate={() => closeSidebar()}
            permissionRoles={permissionRoles}
          />
        </Sidebar>
      ) : null}
      {addRateCardVisibility ? <AddRateCard /> : null}

      <div className="projects-filter">
        <SearchBar
          placeholder="Find rate cards..."
          defaultValue={rateCardFilter}
          onChange={handleFilterChange}
        />
        <div>Total: {rateCards ? rateCards.length : 0}</div>
        <RoleBased roles={permissionRoles}>
          <CustomButton onClick={toggleAddRateCardVisibility}>
            Add Rate Card
          </CustomButton>
        </RoleBased>
      </div>
      <div className="users-list">
        <RateCardsList filter={rateCardFilter} rateCards={rateCards} />
      </div>
    </span>
  )
}

const mapStateToProps = (state: RootStateOrAny) => ({
  addRateCardVisibility: state.projects.addRateCardVisibility,
  rateCards: state.projects.rateCards,
  selectedRateCard: state.projects.selectedRateCard,
  currentUser: state.user.currentUser,
})

const mapDispatchToProps = (dispatch: any) => ({
  setAddRateCardVisibility: (visibility: boolean) =>
    dispatch(setAddRateCardVisibility(visibility)),
  setSelectedRateCard: (rateCard: IRateCard | null) =>
    dispatch(setSelectedRateCard(rateCard)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RateCardsPage)
