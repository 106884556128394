import { IUser } from "../../../../../redux"
import { add } from "./utils"
import { DEFAULT_AVAILABILITY } from "../../../../../data/default-data"

const DEFAULT_WEEKLY_AVAILABILITY = DEFAULT_AVAILABILITY.reduce(add, 0)
export const WORKDAYS_PER_WEEK = 5
export const DEFAULT_AVERAGE_AVAILABILITY =
  DEFAULT_WEEKLY_AVAILABILITY / WORKDAYS_PER_WEEK

/**
 * The users weekly available hours.
 * Returns 0 if data is not available for user
 * @param availability
 */
export const getWeeklyAvailability = ({ availability }: IUser): number =>
  availability ? availability.reduce(add, 0) : DEFAULT_WEEKLY_AVAILABILITY

const UNKNOWN_DEPARTMENT = "unknown department"
type TAvailability = {
  total: number
  departments: Record<string | typeof UNKNOWN_DEPARTMENT, number>
}

export const getAllUsersAvailability = (users: IUser[]): TAvailability => {
  const initialValue: TAvailability = {
    total: 0,
    departments: {
      [UNKNOWN_DEPARTMENT]: 0,
    },
  }

  return users.reduce((acc, user) => {
    const userAvailability =
      getWeeklyAvailability(user) /
      WORKDAYS_PER_WEEK /
      DEFAULT_AVERAGE_AVAILABILITY

    const departmentName = user.department?.displayName

    if (departmentName) {
      if (!acc.departments[departmentName]) acc.departments[departmentName] = 0

      acc.departments[departmentName] += userAvailability
    } else {
      acc.departments[UNKNOWN_DEPARTMENT] += userAvailability
    }

    acc.total += userAvailability
    return acc
  }, initialValue)
}
