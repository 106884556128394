import { roundToTwoDecimal } from "../../../../utils"
import ContentRowItem from "../ContentRowItem"
import React, { useEffect, useMemo } from "react"
import { IDoc, IProject, IUser } from "../../../../redux"
import { CONTENT_MONTHS } from "./BookedOverviewContentRow"
import {
  /*getUserRatioAndProjectsPerMonth, */ getYearlyBookedRatio,
} from "../getUserRatioAndProjectsPerMonth"
//import { Tooltip } from "@material-ui/core"
import "../../tooltip.styles.scss"

interface IUserContentRowItem {
  user: IUser
  entries: IDoc[]
  onUserRatioPerMonthChanged: (userid: string, arg: number[]) => void
  notCollapsed: boolean
  startDate: Date
  projectFilter: string[]
  statusFilter: string[]
  showHours: boolean
  excludePast: boolean
  projects: IProject[] | null
}

const UserContentRowItem: React.FC<IUserContentRowItem> = ({
  user,
  entries,
  onUserRatioPerMonthChanged,
  //notCollapsed,
  startDate,
  projectFilter,
  statusFilter,
  showHours,
  excludePast,
  projects,
}) => {
  const { userRatioPerMonth, hoursPerMonth } = useMemo(() => {
    let userInEntries = entries.filter((entry) => {
      const projectStatus = projects?.find(
        (project) => project.id === entry.project?.id,
      )?.jira?.status

      return (
        entry.user.id === user.id &&
        (projectFilter.length > 0
          ? projectFilter.find((filter) => filter === entry.project?.id)
          : true) &&
        (projectStatus && statusFilter.length > 0
          ? statusFilter.find((filter) => filter === projectStatus)
          : true)
      )
    })
    return getYearlyBookedRatio(user, userInEntries, startDate, excludePast)
    // return getUserRatioAndProjectsPerMonth(userInEntries, startDate)
  }, [
    entries,
    user,
    startDate,
    projectFilter,
    statusFilter,
    projects,
    excludePast,
  ])

  useEffect(() => {
    onUserRatioPerMonthChanged(
      user.id,
      showHours ? hoursPerMonth : userRatioPerMonth,
    )
  }, [
    userRatioPerMonth,
    onUserRatioPerMonthChanged,
    user.id,
    hoursPerMonth,
    showHours,
  ])

  /*
  const hoverTitle = (pos: number) => (
    <ul className="tooltip-list">
      {userInProjectsPerMonth[pos].map((userProjects) => (
        <li>{userProjects}</li>
      ))}
    </ul>
  )
  */

  return (
    <div className="booking-ratio-list-content-row">
      {CONTENT_MONTHS.map((val, pos) => (
        <div>
          {/*notCollapsed && userInProjectsPerMonth[pos].length > 0 && (
            <Tooltip title={hoverTitle(pos)} arrow>
              <div className="tooltip-helper" />
            </Tooltip>
          )*/}
          <ContentRowItem
            key={"user-list-content-item-" + user.id + pos}
            value={
              !isNaN(showHours ? hoursPerMonth[pos] : userRatioPerMonth[pos])
                ? roundToTwoDecimal(
                    showHours ? hoursPerMonth[pos] : userRatioPerMonth[pos],
                  )
                : 0
            }
            showHours={showHours}
          />
        </div>
      ))}
    </div>
  )
}

export default UserContentRowItem
