import React from "react"
import { connect } from "react-redux"

import CancelButton from "../cancel-button/cancel-button.component"
import CustomButtonDropdown from "../custom-button-dropdown/custom-button-dropdown.component"
import DropdownMenu from "../dropdown-menu/dropdown-menu.component"
import Sidebar from "../sidebar/sidebar-component"
import { IDoc, setSelectedEntry } from "../../redux"
import { Entries } from "../../firebase"
import UpdateEntry from "../update-entry/update-entry.component"
import { ROLES } from "../../data/default-data"
import RoleBased from "../role-based/role-based.component"
import { IAccount } from "../../redux"
import "./calendar-sidebar.styles.scss"
import { Redirect } from "react-router-dom"

interface ICalendarSidebar {
  selectedEntry: IDoc
  currentUser: IAccount
  setSelectedEntry: (entry: IDoc | null) => void
}

const CalendarSidebar: React.FunctionComponent<ICalendarSidebar> = (props) => {
  const handleDeleteEntry = () => {
    const { selectedEntry, currentUser } = props
    void Entries.update(
      selectedEntry.id,
      {
        ...selectedEntry,
        before: null,
        approval: {
          type: "delete",
          status: "pending",
          approvedBy: null,
        },
      },
      currentUser.id,
    )
    closeSidebar()
  }

  const closeSidebar = () => {
    props.setSelectedEntry(null)
  }

  const { selectedEntry } = props

  const permissionRoles = [ROLES.admin, ROLES.superadmin]

  return (
    <span>
      {selectedEntry ? (
        <div>
          <Redirect to={`/calendar/${selectedEntry.id}`} />
          <div className="sidebar-click-outside" onClick={closeSidebar} />
          <Sidebar position="right" width="500px">
            <div className="sidebar-cancel-button">
              <RoleBased roles={permissionRoles}>
                <div className="sidebar-dropdown-menu">
                  <DropdownMenu>
                    <CustomButtonDropdown onClick={() => handleDeleteEntry()}>
                      Delete Entry
                    </CustomButtonDropdown>
                  </DropdownMenu>
                </div>
              </RoleBased>
              <CancelButton onCancel={() => closeSidebar()} />
            </div>
            <UpdateEntry
              permissionRoles={permissionRoles}
              onUpdate={() => closeSidebar()}
            />
          </Sidebar>
        </div>
      ) : (
        <Redirect to={`/calendar`} />
      )}
    </span>
  )
}

const mapStateToProps = (state: any) => ({
  selectedEntry: state.calendar.selectedEntry,
  currentUser: state.user.currentUser,
})

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedEntry: (entry: any) => dispatch(setSelectedEntry(entry)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSidebar)
