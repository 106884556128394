import SidebarLink from "../../components/sidebar-link/sidebar-link.component"
import { Route, Switch, useLocation } from "react-router-dom"
import BookedRatio from "./BookedRatio"
import {
  IDepartments,
  IDoc,
  ILocations,
  IProject,
  IRateCard,
  ITeams,
  IUser,
  RootState,
} from "../../redux"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Entries } from "../../firebase"
import { startOfYear } from "date-fns"
import { reduceToDoc } from "../../AuthenticatedApp"
import Headcount from "./Headcount"
import CapacitiesExport from "./components/CapacitiesExport/capacities-export.component"

interface ICapacities {
  departments: IDepartments[] | null
  users: IUser[] | null
  teams: ITeams[] | null
  projects: IProject[] | null
  rateCards: IRateCard[] | null
  locations: ILocations[] | null
}

const Capacities: React.FC<ICapacities> = ({
  departments,
  users,
  teams,
  projects,
  rateCards,
  locations,
}) => {
  const [entries, setEntries] = useState<IDoc[]>([])
  const [startDate, setStartDate] = useState(startOfYear(new Date()))

  useEffect(() => {
    const { base, changed } = Entries.queryNextYear(startDate)

    return base.onSnapshot(async (snapshot) => {
      return changed.onSnapshot((changedEntriesSnapshot) => {
        const entries = [...snapshot.docs, ...changedEntriesSnapshot.docs]
          .reduce(reduceToDoc, [] as IDoc[])
          .map(Entries.convertRawEntry)

        setEntries(entries)
      })
    })
  }, [startDate])

  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  return (
    <div className="projects-page">
      <div className="teams-header">
        <SidebarLink to="/capacities" isExact={true}>
          Booked ratio
        </SidebarLink>
        <div />
        <SidebarLink to="/capacities/headcount">Headcount</SidebarLink>
        <div />
        <SidebarLink to="/capacities/export">Export</SidebarLink>
      </div>
      {!!departments && !!users && !!teams ? (
        <Switch>
          <Route exact path="/capacities">
            <>
              <div className="users-header">
                <h2>Booked ratio</h2>
              </div>
              <BookedRatio
                setStartDate={setStartDate}
                startDate={startDate}
                projects={projects}
                entries={entries}
                departments={departments}
                users={users}
                teams={teams}
                queryStatusFilter={query.getAll("statusFilter")}
                queryProjectsFilter={query.getAll("projectsFilter")}
                queryTeamsFilter={query.getAll("teamsFilter")}
                queryExcludePast={query.get("excludePast") === "true"}
                queryShowHours={query.get("showHours") === "true"}
              />
            </>
          </Route>
          <Route path="/capacities/headcount">
            <>
              <div className="users-header">
                <h2>Headcount</h2>
              </div>
              <Headcount
                setStartDate={setStartDate}
                startDate={startDate}
                projects={projects}
                entries={entries}
                users={users}
              />
            </>
          </Route>
          <Route path="/capacities/export">
            <CapacitiesExport
              entries={entries}
              users={users}
              projects={projects}
              rateCards={rateCards}
              locations={locations}
            />
          </Route>
        </Switch>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  teams: state.calendar.teams,
  departments: state.calendar.departments,
  users: state.calendar.users,
  userFilter: state.calendar.userFilter,
  rateCards: state.projects.rateCards,
  teamsFilter: state.calendar.teamsFilter,
  projectsFilter: state.projects.projectsFilter,
  projects: state.projects.projects,
  locations: state.calendar.locations,
})

export default connect(mapStateToProps)(Capacities)
