import React, { useEffect, useState } from "react"
import { MONTHS } from "../../data/calendar-data"
import { ILocations, IProject, IUser } from "../../redux"
import { IUserEntriesByDepartment } from "./project-page.component"
import {
  addDays,
  filterBankHolidays,
  getDifferenceInDays,
  getUserAvailability,
  getUTCDate,
} from "../../utils"

import "./project-roadmap.styles.scss"
import ProjectRoadmapMilestone from "./project-roadmap-milestone.component"

interface IProjectRoadmap {
  entries: IUserEntriesByDepartment[]
  project: IProject
  totalBookedTime: number
  users: IUser[]
  locations: ILocations[]
}

interface IMonth {
  month: number
  startDay: number
  endDay: number
}

type Summary = Record<IUserEntriesByDepartment["userId"], Week>
type Week = Record<number, number>

const getMonthsInTimeframe = (
  displayStartDate: Date,
  startDate: Date,
  endDate: Date,
): IMonth[] => {
  // months
  let months: IMonth[] = []

  for (
    let i: Date = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    i < new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    i = new Date(i.getFullYear(), i.getMonth() + 1, 1)
  ) {
    let month: IMonth = {
      month: i.getMonth(),
      startDay: getDifferenceInDays(displayStartDate, i) + 1,
      endDay:
        getDifferenceInDays(
          displayStartDate,
          new Date(i.getFullYear(), i.getMonth() + 1, 0),
        ) + 2,
    }

    months.push(month)
  }
  return months
}

// // Provides an object with all user IDs and their workload per week
// {
//   "userID": {
//     "1": 0.5 // Week 1 is booked for 50% of the time
//     "3": 1
//   }
// }
const getWorkWeeklySummary = (
  entries: IUserEntriesByDepartment[],
  displayStartDate: Date,
  users: IUser[],
  locations: ILocations[],
): Summary => {
  let summary: Summary = {}
  entries.forEach((userEntry) => {
    let tmpWeeks: Week = {}
    userEntry.entries.forEach((entry) => {
      const startWeek: number = Math.floor(
        getDifferenceInDays(displayStartDate, entry.startDate) / 7,
      )
      const endWeek: number = Math.floor(
        getDifferenceInDays(displayStartDate, entry.endDate) / 7,
      )

      for (var i: number = startWeek; i <= endWeek; i++) {
        const weekStartDay: Date = new Date(
          displayStartDate.getFullYear(),
          displayStartDate.getMonth(),
          displayStartDate.getDate() + i * 7,
        )

        const weekEndDay: Date = new Date(
          displayStartDate.getFullYear(),
          displayStartDate.getMonth(),
          displayStartDate.getDate() + i * 7 + 6,
        )

        const startDay: Date =
          getUTCDate(entry.startDate) > getUTCDate(weekStartDay)
            ? entry.startDate
            : weekStartDay
        const endDay: Date =
          getUTCDate(entry.endDate) < getUTCDate(weekEndDay)
            ? entry.endDate
            : weekEndDay

        let availability = 0

        const user = users.find((User) => User.id === userEntry.userId)

        const userAvailability = getUserAvailability(
          user,
          startDay,
          getDifferenceInDays(startDay, endDay),
          filterBankHolidays(
            startDay,
            getDifferenceInDays(startDay, endDay),
            locations,
          ),
        )

        let k = 0
        for (
          let i = getUTCDate(startDay);
          i <= getUTCDate(endDay);
          i = getUTCDate(addDays(new Date(i), 1))
        ) {
          availability += userAvailability[k] * entry.bookingRatio
          k++
        }

        if (tmpWeeks[i]) {
          tmpWeeks[i] += availability
        } else {
          tmpWeeks[i] = availability
        }
      }
    })
    summary[userEntry.userId] = tmpWeeks
  })

  return summary
}

const ProjectRoadmap: React.FunctionComponent<IProjectRoadmap> = ({
  entries,
  project,
  totalBookedTime,
  users,
  locations,
}) => {
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [months, setMonths] = useState<IMonth[]>()
  const [summary, setSummary] = useState<Summary>({})
  const [weeks, setWeeks] = useState<number>(0)
  const [days, setDays] = useState<Number>()

  useEffect(() => {
    if (entries.length < 1) {
      return
    }

    // Determine start date & end date by going through all entries and determining
    // the earliest start and latest end date also taking project start and end date into account
    let tmpStartDate: Date =
      project && project.startDate && project.startDate.toDate() <= new Date()
        ? project.startDate.toDate()
        : new Date()
    let tmpEndDate: Date =
      project && project.endDate
        ? project.endDate.toDate()
        : entries[0].entries[0].endDate

    entries.forEach((userEntries) => {
      userEntries.entries.forEach((entry) => {
        if (entry.startDate < tmpStartDate) {
          tmpStartDate = entry.startDate
        }
        if (entry.endDate > tmpEndDate) {
          tmpEndDate = entry.endDate
        }
      })
    })

    // Calculating display start date to always display the last Monday of the previous month
    // Exception: The first day of the month is a Monday

    const displayStartDate: Date = new Date(
      tmpStartDate.getFullYear(),
      tmpStartDate.getMonth(),
      2 -
        (new Date(
          tmpStartDate.getFullYear(),
          tmpStartDate.getMonth(),
          1,
        ).getDay() +
          1),
    )

    // Calculating display end date to always be the first Monday of the following month
    const displayEndDate: Date = new Date(
      tmpEndDate.getFullYear(),
      tmpEndDate.getMonth() + 1,
      8 -
        new Date(
          tmpEndDate.getFullYear(),
          tmpEndDate.getMonth() + 1,
          0,
        ).getDay(),
    )

    setStartDate(displayStartDate)
    setMonths(getMonthsInTimeframe(displayStartDate, tmpStartDate, tmpEndDate))
    setDays(getDifferenceInDays(displayStartDate, displayEndDate))

    // GetDifferenceInDays is not 100% reliable. With some ranges, we would need to add 1 day. Rounding it as a workaround
    setWeeks(
      Math.round(getDifferenceInDays(displayStartDate, displayEndDate) / 7),
    )
    setSummary(
      getWorkWeeklySummary(entries, displayStartDate, users, locations),
    )
  }, [entries, project, locations, users])

  const sortedEntries = entries.sort(function (a, b) {
    if (a.departmentId > b.departmentId) {
      return -1
    }
    if (a.departmentId < b.departmentId) {
      return 1
    }
    return 0
  })

  const offset = 3
  const gridRowEnd = Object.keys(summary).length + offset

  return (
    <div>
      <div className="project-roadmap">
        <div className="roadmap-user-grid">
          {sortedEntries.map((user, i) => (
            <div style={{ gridRow: i + offset }}>
              <div className="project-roadmap-left-side">
                <div className="roadmap-user">
                  {user.user.firstName +
                    " " +
                    user.user.lastName +
                    " (" +
                    user.user.department?.displayName +
                    ")"}
                </div>
                <div className="roadmap-user">
                  {Math.round(user.bookedTime) + "h"}
                </div>
              </div>
            </div>
          ))}
          <div style={{ gridRow: gridRowEnd }}>
            <div className="project-roadmap-left-side">
              <div className="roadmap-user project-roadmap-text-bold">
                {" "}
                Total
              </div>
              <div className="roadmap-user project-roadmap-text-bold">
                {Math.round(totalBookedTime) + "h"}
              </div>
            </div>
          </div>
        </div>
        <div
          className="project-roadmap-grid"
          style={{
            gridTemplateColumns: `repeat(${days}, 5px)`,
            gridTemplateRows: `repeat(${gridRowEnd}, 25px)`,
          }}>
          <div
            className="roadmap-today"
            style={{
              gridColumnStart: getDifferenceInDays(startDate, new Date()) + 1,
              gridRowStart: 1,
              gridRowEnd: gridRowEnd,
            }}
          />
          {project.milestones?.map((milestone, index) => (
            <ProjectRoadmapMilestone
              gridColumn={
                getDifferenceInDays(startDate, new Date(milestone.date)) + 1
              }
              gridRowStart={1}
              gridRowEnd={gridRowEnd}
              milestone={milestone}
              key={index}
            />
          ))}
          {project.startDate ? (
            <ProjectRoadmapMilestone
              gridColumn={
                getDifferenceInDays(startDate, project.startDate.toDate()) + 1
              }
              gridRowStart={1}
              gridRowEnd={gridRowEnd}
              milestone={{
                displayName: "Project Start",
                date: project.startDate.toDate().toString(),
              }}
            />
          ) : null}
          {project.endDate ? (
            <ProjectRoadmapMilestone
              gridColumn={
                getDifferenceInDays(startDate, project.endDate.toDate()) + 1
              }
              gridRowStart={1}
              gridRowEnd={gridRowEnd}
              milestone={{
                displayName: "Project End",
                date: project.endDate.toDate().toString(),
              }}
            />
          ) : null}
          {[...Array(weeks)].map((e, i) => (
            <div
              className="project-roadmap-week-header"
              key={"week-header-" + i}
              style={{
                gridColumnStart: i * 7 + 1,
                gridColumnEnd: i * 7 + 7 + 1,
                gridRow: 2,
              }}>
              {i + 1}
            </div>
          ))}
          {[...Array(weeks)].map((e, i) => (
            <div
              className="project-roadmap-week-body"
              key={"week-body-" + i}
              style={{
                gridColumnStart: i * 7 + 1,
                gridColumnEnd: i * 7 + 7 + 1,
                gridRowStart: 3,
                gridRowEnd: gridRowEnd,
              }}
            />
          ))}
          {months?.map((month, i) => {
            return (
              <div
                key={"roadmap-month-" + i}
                className="roadmap-month"
                style={{
                  gridColumnStart: month.startDay,
                  gridColumnEnd: month.endDay,
                  gridRow: 1,
                }}>
                {MONTHS[month.month]}
              </div>
            )
          })}
          {Object.keys(summary).map((key, i) =>
            Object.keys(summary[key]).map((innerKey: string, k) => {
              const innerKeyInt: number = parseInt(innerKey)

              return (
                <div
                  className="project-roadmap-week"
                  key={"project-roadmap-week-" + i + "-" + k}
                  style={{
                    gridColumnStart: innerKeyInt * 7 + 1,
                    gridColumnEnd: innerKeyInt * 7 + 8,
                    gridRow: i + offset,
                    backgroundColor:
                      summary[key][innerKeyInt] > 40
                        ? "red"
                        : summary[key][innerKeyInt] < 40
                        ? "green"
                        : "",
                  }}>
                  {Math.round(summary[key][innerKeyInt] * 10) / 10}
                </div>
              )
            }),
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectRoadmap
