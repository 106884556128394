import React from "react"
import { connect, RootStateOrAny } from "react-redux"

import { setUserFilter, setTeamsFilter, ITeams } from "../../redux"
import FilterTeam from "../filter-team/filter-team.component"
import SearchBar from "../search-bar/search-bar.component"

import "./calendar-filter.styles.scss"

interface ICalendarFilter {
  userFilter: string
  teamsFilter: string[]
  setUserFilter: (userFilter: string) => void
  setTeamsFilter: (teamsFilter: string[]) => void
  teams: ITeams[]
}

const CalendarFilter: React.FunctionComponent<ICalendarFilter> = (props) => {
  const { userFilter, setUserFilter, teamsFilter, setTeamsFilter, teams } =
    props

  let teamsValue = [] as {
    value: string | undefined
    label: string | undefined
  }[]
  teamsFilter.forEach((filter) => {
    const tmpTeam = teams.find((team: { id: string }) => team.id === filter)
    teamsValue.push({ value: tmpTeam?.id, label: tmpTeam?.displayName })
  })

  return (
    <div className="calendar-filter">
      <div className="calendar-filter-options">
        <div className="search-bar-wrapper">
          <SearchBar
            defaultValue={userFilter}
            placeholder="Find users..."
            onChange={(e: any) => setUserFilter(e.target.value)}
            width="100%"
          />
        </div>
        <div className="team-filter-wrapper">
          <FilterTeam
            onChange={(e: any) => setTeamsFilter(e)}
            value={teamsValue}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootStateOrAny) => ({
  userFilter: state.calendar.userFilter,
  teamsFilter: state.calendar.teamsFilter,
  teams: state.calendar.teams,
})

const mapDispatchToProps = (dispatch: any) => ({
  setUserFilter: (userFilter: string) => dispatch(setUserFilter(userFilter)),
  setTeamsFilter: (teamsFilter: string[]) =>
    dispatch(setTeamsFilter(teamsFilter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarFilter)
