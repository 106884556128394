import React, { useState } from "react"
import { connect, RootStateOrAny } from "react-redux"

import ProjectsList from "../projects-list/projects-list.component"
import CustomButton from "../custom-button/custom-button.component"
import {
  IProject,
  setAddProjectVisibility,
  setSelectedProject,
} from "../../redux"
import SearchBar from "../search-bar/search-bar.component"
import AddProject from "../add-project/add-project.component"
import CustomButtonDropdown from "../custom-button-dropdown/custom-button-dropdown.component"
import DropdownMenu from "../dropdown-menu/dropdown-menu.component"
import UpdateProject from "../update-project/update-project.component"
import Sidebar from "../sidebar/sidebar-component"
import CancelButton from "../cancel-button/cancel-button.component"
import { deleteProject, archiveProject, unarchiveProject } from "../../firebase"
import RoleBased from "../role-based/role-based.component"
import { ROLES } from "../../data/default-data"

import "./projects.styles.scss"
import { IAccount } from "../../redux"
import SidebarLink from "../sidebar-link/sidebar-link.component"
import { Route, Switch } from "react-router-dom"
import ArchivedProjectsList from "../projects-list/archived-projects.list.component"
import { syncProjectsTest } from "../../api/api.utils"

interface IProjects {
  setAddProjectVisibility: (visibility: boolean) => void
  addProjectVisibility: boolean
  setSelectedProject: (project: IProject | null) => void
  selectedProject: IProject
  currentUser: IAccount
  projects: IProject[]
}

const Projects: React.FunctionComponent<IProjects> = (props) => {
  const [projectFilter, setProjectFilter] = useState("")

  const toggleAddProjectVisibility = () =>
    props.setAddProjectVisibility(!props.addProjectVisibility)

  const handleFilterChange = (e: any) => setProjectFilter(e.target.value)

  const closeSidebar = () => props.setSelectedProject(null)

  const handleArchiveProject = () => {
    const { selectedProject, currentUser } = props
    archiveProject(selectedProject, currentUser.id)
    closeSidebar()
  }

  const handleUnarchiveProject = () => {
    const { selectedProject, currentUser } = props
    unarchiveProject(selectedProject, currentUser.id)
    closeSidebar()
  }

  const handleDeleteProject = () => {
    const { selectedProject, currentUser } = props
    deleteProject(selectedProject, currentUser.id)
    closeSidebar()
  }

  const { addProjectVisibility, projects, selectedProject } = props

  const permissionRoles = [ROLES.admin, ROLES.superadmin]

  return (
    <span>
      {selectedProject ? (
        <Sidebar position="right" width="500px">
          <div className="sidebar-cancel-button">
            <RoleBased roles={permissionRoles}>
              <div className="sidebar-dropdown-menu">
                {!selectedProject.archived ? (
                  <DropdownMenu>
                    <CustomButtonDropdown
                      onClick={() => handleArchiveProject()}>
                      Archive Project
                    </CustomButtonDropdown>
                    <CustomButtonDropdown onClick={() => handleDeleteProject()}>
                      Delete Project
                    </CustomButtonDropdown>
                  </DropdownMenu>
                ) : (
                  <DropdownMenu>
                    <CustomButtonDropdown
                      onClick={() => handleUnarchiveProject()}>
                      Unarchive Project
                    </CustomButtonDropdown>
                  </DropdownMenu>
                )}
              </div>
            </RoleBased>
            <CancelButton onCancel={() => closeSidebar()} />
          </div>
          <UpdateProject
            onUpdate={() => closeSidebar()}
            permissionRoles={!selectedProject.archived ? permissionRoles : []}
          />
        </Sidebar>
      ) : null}
      {addProjectVisibility ? <AddProject /> : null}
      <div className="teams-header">
        <SidebarLink to="/settings/projects" isExact={true}>
          Projects
        </SidebarLink>
        <div />
        <SidebarLink to="/settings/projects/archive">
          Archived Projects
        </SidebarLink>
        <div />
        <RoleBased roles={[ROLES.superadmin]}>
          <CustomButton onClick={() => syncProjectsTest()}>
            Sync Projects
          </CustomButton>
        </RoleBased>
      </div>
      <Switch>
        <Route exact path="/settings/projects">
          <div className="projects-filter">
            <SearchBar
              placeholder="Find projects..."
              defaultValue={projectFilter}
              onChange={handleFilterChange}
            />
            <div>Total: {projects ? projects.length : 0}</div>
            <RoleBased roles={permissionRoles}>
              <CustomButton onClick={toggleAddProjectVisibility}>
                Add Project
              </CustomButton>
            </RoleBased>
          </div>
          <div className="users-list">
            <ProjectsList filter={projectFilter} projects={projects} />
          </div>
        </Route>
        <Route path="/settings/projects/archive">
          <div className="projects-filter">
            <SearchBar
              placeholder="Find projects..."
              defaultValue={projectFilter}
              onChange={handleFilterChange}
            />
          </div>
          <div className="users-list">
            <ArchivedProjectsList filter={projectFilter} />
          </div>
        </Route>
      </Switch>
    </span>
  )
}

const mapStateToProps = (state: RootStateOrAny) => ({
  addProjectVisibility: state.projects.addProjectVisibility,
  projects: state.projects.projects,
  selectedProject: state.projects.selectedProject,
  currentUser: state.user.currentUser,
})

const mapDispatchToProps = (dispatch: any) => ({
  setAddProjectVisibility: (visibility: boolean) =>
    dispatch(setAddProjectVisibility(visibility)),
  setSelectedProject: (project: IProject | null) =>
    dispatch(setSelectedProject(project)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
