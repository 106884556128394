import React from "react"
import { IUser } from "../../redux"

import "./avatar-icon.styles.scss"

interface IAvatarIcon {
  user: IUser
}

const AvatarIcon: React.FunctionComponent<IAvatarIcon> = (props) => {
  const { user } = props

  return (
    <div className="avatar-icon">
      <div className="avatar-icon-text">
        {user.firstName.length > 0 ? user.firstName.charAt(0) : null}
        {user.lastName.length > 0 ? user.lastName.charAt(0) : null}
      </div>
    </div>
  )
}

export default AvatarIcon
