import { filterUserByDepartment } from "../../../../components/calendar-content/calendarFilters"
import { IDepartments, IUser } from "../../../../redux"
import React from "react"
import UserListItem from "./UserListItem"

interface IUserListSideView {
  departments: IDepartments[]
  users: IUser[]
  userFilter: string
  setOpenedDepartment: (value: string) => void
  openedDepartment: string
}

const UserListSideView: React.FC<IUserListSideView> = ({
  departments,
  users,
  userFilter,
  setOpenedDepartment,
  openedDepartment,
}) => {
  return (
    <div className="booked-ratio-user-list">
      {departments?.map((department) => (
        <UserListItem
          key={"userlist" + department.id}
          users={filterUserByDepartment(users, department)}
          department={department}
          userFilter={userFilter}
          openedDepartment={openedDepartment}
          setOpenedDepartment={setOpenedDepartment}
        />
      ))}
    </div>
  )
}

export default UserListSideView
