import { useEffect, useState, VFC } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { RangeWithKey } from "react-date-range"
import { format } from "date-fns"
import { ENTRY_TYPES } from "../../data/calendar-data"
import { Entries } from "../../firebase"
import {
  IAccount,
  IDoc,
  ILocations,
  IProject,
  IUser,
  RootState,
} from "../../redux"
import {
  DeepNonNullable,
  filterBankHolidays,
  getArraySum,
  getDifferenceInDays,
  getUserAvailability,
  RoleManager,
} from "../../utils"
import RoleBased from "../role-based/role-based.component"

import "./update-entry.styles.scss"
import DateRangeSelector from "../date-range-selector/DateRangeSelector"
import { Route, Switch } from "react-router-dom"

type TUpdateEntryWrapperProps = {
  onUpdate: () => void
  projects: IProject[] | null
  users: IUser[] | null
  currentUser: IAccount | null
  locations: ILocations[] | null
  roleManager: RoleManager
  permissionRoles: string[]
  selectedEntry: IDoc | null
  accounts: IAccount[] | null
}

type TUpdateEntryProps = DeepNonNullable<
  Omit<TUpdateEntryWrapperProps, "accounts">
> & { accounts: IAccount[] | null }

// Wrapper is needed to ensure all data is present when rendering `UpdateEntry`
const UpdateEntryWrapper: VFC<TUpdateEntryWrapperProps> = ({
  onUpdate,
  projects,
  users,
  currentUser,
  locations,
  roleManager,
  permissionRoles,
  selectedEntry,
  accounts,
}) => {
  if (
    !onUpdate ||
    !projects ||
    !users ||
    !currentUser ||
    !locations ||
    !roleManager ||
    !permissionRoles ||
    !selectedEntry
  ) {
    return null
  }

  return (
    <UpdateEntry
      onUpdate={onUpdate}
      projects={projects}
      users={users}
      currentUser={currentUser}
      locations={locations}
      roleManager={roleManager}
      permissionRoles={permissionRoles}
      selectedEntry={selectedEntry}
      accounts={accounts}
    />
  )
}

const bookingRatioOptions = [
  { label: "1", value: 1.0 },
  { label: "0.75", value: 0.75 },
  { label: "0.5", value: 0.5 },
  { label: "0.25", value: 0.25 },
]

const UpdateEntry: VFC<TUpdateEntryProps> = (props) => {
  const { selectedEntry } = props
  const [noChanges, setNoChanges] = useState(true)
  const [displayDateRange, setDisplayDateRange] = useState(false)
  const [dateRange, setDateRange] = useState<RangeWithKey[]>([
    {
      startDate: selectedEntry?.startDate,
      endDate: selectedEntry?.endDate,
      key: "selection",
    },
  ])
  const startDate = dateRange?.[0]?.startDate
  const endDate = dateRange?.[0]?.endDate
  const [user, setUser] = useState(
    selectedEntry.user
      ? {
          label: `${selectedEntry.user.firstName} ${selectedEntry.user.lastName}`,
          value: selectedEntry.user.id,
        }
      : null,
  )
  const [type, setType] = useState(0)

  const [bookingRatio, setBookingRatio] = useState<{
    label: string
    value: number
  }>({
    label: "" + selectedEntry.bookingRatio,
    value: selectedEntry.bookingRatio ?? 0,
  })

  const [project, setProject] = useState(
    selectedEntry.project
      ? {
          label: selectedEntry.project?.client
            ? `${selectedEntry.project?.client.displayName} - ${selectedEntry.project?.displayName}`
            : selectedEntry.project?.displayName,
          value: selectedEntry.project?.id,
        }
      : null,
  )

  useEffect(() => {
    setUser(
      selectedEntry.user
        ? {
            label: `${selectedEntry.user.firstName} ${selectedEntry.user.lastName}`,
            value: selectedEntry.user.id,
          }
        : null,
    )
    setType(selectedEntry.type)
    setDateRange([
      {
        startDate: selectedEntry.startDate,
        endDate: selectedEntry.endDate,
        key: "selection",
      },
    ])
    setBookingRatio({
      label: "" + selectedEntry.bookingRatio,
      value: selectedEntry.bookingRatio,
    })
    setProject(
      selectedEntry.project
        ? {
            label: selectedEntry.project?.client
              ? `${selectedEntry.project?.client.displayName} - ${selectedEntry.project?.displayName}`
              : selectedEntry.project?.displayName,
            value: selectedEntry.project?.id,
          }
        : null,
    )
  }, [selectedEntry])

  const handleProjectChange = (
    event: { label: string; value: string } | null,
  ) => {
    setProject(event)
    setNoChanges(false)
  }

  const handleUserChange = (event: { label: string; value: string } | null) => {
    setUser(event)
    setNoChanges(false)
  }

  const handleBookingRatioChange = (event: any) => {
    setBookingRatio(event)
    setNoChanges(false)
  }

  const handleEntryTypeChange = (
    event: { label: string; value: number } | null,
  ) => {
    setType(event?.value ?? 0)
    setNoChanges(false)
  }

  const handleSubmit = (event: any) => {
    const { onUpdate, projects, users, currentUser, selectedEntry } = props
    if (!users || !currentUser) {
      return
    }

    event.preventDefault()

    const projectData =
      type === 0 && project
        ? projects?.find((tmpProject) => tmpProject.id === project.value)
        : null

    const userData = users.find((tmpUser) => tmpUser.id === user?.value)

    if (!userData || !startDate || !endDate) {
      // cannot update the entry due to a lack of user data
      return
    }

    void Entries.update(
      selectedEntry.id,
      {
        ...selectedEntry,
        before: { ...selectedEntry, before: null },
        user: {
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          id: user?.value,
        },
        project:
          type === 0 && projectData
            ? {
                displayName: projectData.displayName,
                id: project?.value,
                client: projectData.client
                  ? {
                      displayName: projectData.client.displayName,
                      id: projectData.client.id,
                    }
                  : null,
              }
            : null,
        startDate,
        endDate,
        bookingRatio: bookingRatio.value,
        bookedTime: bookedTime,
        type: type,
        lastChanged: currentUser.id,
        approval: {
          type: "draft",
          status: "pending",
          approvedBy: null,
        },
      },
      props.currentUser.id,
      noChanges,
    )

    if (onUpdate) {
      onUpdate()
    }
  }

  const { users, projects, locations, roleManager, permissionRoles } = props

  const disableInput =
    !roleManager.hasRole(permissionRoles) ||
    props.selectedEntry.approval.type === "hard"

  // Generating select options
  let userOptions = [] as { label: string; value: string }[]

  if (users) {
    users.forEach((user) => {
      userOptions.push({
        label: user.firstName + " " + user.lastName,
        value: user.id,
      })
    })
  }

  const projectOptions = [] as { label: string; value: string }[]

  if (projects) {
    projects.forEach((project) => {
      if (!project.archived) {
        projectOptions.push({
          label: project.client
            ? project.client.displayName + " - " + project.displayName
            : project.displayName,
          value: project.id,
        })
      }
    })
  }

  const entryOptions = [] as { label: string; value: number }[]

  ENTRY_TYPES.forEach((type) => {
    entryOptions.push({
      label: type.displayName,
      value: type.type,
    })
  })

  let userAvailability = [] as number[]

  if (startDate && endDate && user && bookingRatio && users) {
    const filteredBankHolidays = filterBankHolidays(
      new Date(startDate),
      getDifferenceInDays(new Date(startDate), new Date(endDate)),
      locations,
    )

    userAvailability = getUserAvailability(
      users.find((u) => u.id === user.value),
      new Date(startDate),
      getDifferenceInDays(new Date(startDate), new Date(endDate)),
      filteredBankHolidays,
    )
  }

  const bookedTime = getArraySum(userAvailability) * bookingRatio.value
  const createdBy =
    props.accounts?.find((account) => account.id === selectedEntry.createdBy)
      ?.displayName ?? ""
  const lastChangedBy =
    props.accounts?.find((account) => account.id === selectedEntry.lastChanged)
      ?.displayName ?? ""

  return (
    <Switch>
      <Route exact path={`/calendar/${props.selectedEntry.id}`}>
        <div>
          {displayDateRange && (
            <div className="update-date-picker">
              <DateRangeSelector
                range={dateRange}
                setRange={(value) => {
                  setDateRange(value)
                  setNoChanges(false)
                }}
                onClickOutside={setDisplayDateRange}
              />
            </div>
          )}
          <div className="update-user-heading">
            <h2>Entry</h2>
          </div>
          <form className="update-entry-form" onSubmit={handleSubmit}>
            <label>User:</label>
            <Select
              id="update-entry-select-user"
              options={userOptions}
              onChange={handleUserChange}
              value={user}
              isDisabled={disableInput}
            />
            <label>Entry Type:</label>
            <Select
              id="update-entry-select-type"
              options={entryOptions}
              onChange={handleEntryTypeChange}
              value={entryOptions[type]}
              isDisabled={disableInput}
            />
            {type === 0 ? <label>Project:</label> : null}
            {type === 0 ? (
              <Select
                id="update-entry-select-project"
                options={projectOptions}
                onChange={handleProjectChange}
                value={project}
                isDisabled={disableInput}
              />
            ) : null}
            <label>Start date:</label>
            <input
              id="update-entry-select-startDate"
              value={startDate ? format(startDate, "dd.MM.yyyy") : ""}
              readOnly={true}
              onClick={() => setDisplayDateRange(!displayDateRange)}
            />
            <label>End date:</label>
            <input
              id="update-entry-endDate"
              value={endDate ? format(endDate, "dd.MM.yyyy") : ""}
              readOnly={true}
              onClick={() => setDisplayDateRange(!displayDateRange)}
            />
            <label>Booking Ratio:</label>
            <Select
              id="add-entry-booking-ratio"
              options={bookingRatioOptions}
              defaultValue={bookingRatio}
              onChange={handleBookingRatioChange}
            />
            <label>Booked Time:</label>
            <div>{+bookedTime.toFixed(2)}h</div>
            <label>Created by:</label>
            <div>{createdBy || ""}</div>
            <label>Last changed by:</label>
            <div>{lastChangedBy || ""}</div>
            {props.selectedEntry.approval.type !== "hard" && (
              <>
                <div />
                <RoleBased roles={permissionRoles}>
                  <input
                    id="update-entry-submit"
                    type="submit"
                    value="Update Entry"
                    className="submit-button"
                  />
                </RoleBased>
                <div />
              </>
            )}
          </form>
        </div>
      </Route>
    </Switch>
  )
}

const mapStateToProps = (state: RootState) => ({
  selectedEntry: state.calendar.selectedEntry,
  projects: state.projects.projects,
  users: state.calendar.users,
  locations: state.calendar.locations,
  roleManager: state.user.roleManager,
  currentUser: state.user.currentUser,
  accounts: state.user.accounts,
})

export default connect(mapStateToProps)(UpdateEntryWrapper)
