import React from "react"

import CalendarHeader from "../../components/calendar-header/calendar-header.component"
import CalendarContent from "../../components/calendar-content/calendar-content.component"
import CalendarBackdrop from "../../components/calendar-backdrop/calendar-backdrop.component"

import "./calendar.styles.scss"
import CalendarSidebar from "../../components/calendar-sidebar/calendar-sidebar.component"

const Calendar: React.FunctionComponent = () => {
  return (
    <div className="calendar">
      <div className="calendar-header">
        <CalendarSidebar />
        <CalendarHeader />
      </div>
      <div className="calendar-table-content">
        <CalendarContent />
        <CalendarBackdrop />
      </div>
    </div>
  )
}

export default Calendar
