import {
  differenceInBusinessDays,
  endOfMonth,
  eachMonthOfInterval,
  getMonth,
  endOfYear,
  addDays,
} from "date-fns"
import { DEFAULT_AVAILABILITY } from "../../../data/default-data"
import { IDoc, IUser } from "../../../redux"
import { getDifferenceInDays } from "../../../utils"
import getBusinessDays from "./getBusinessDays"

function getDaysInYear(year: number) {
  return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365
}

export const getYearlyBookedRatio = (
  user: IUser,
  entries: IDoc[],
  startDate: Date,
  excludePast: boolean,
) => {
  const daysInYear = getDaysInYear(startDate.getFullYear())
  const endDate = endOfYear(startDate)
  const userRatioPerDay = Array(daysInYear).fill(0)
  const userRatioPerMonth = Array(12).fill(0)
  const hoursPerDay = Array(daysInYear).fill(0)
  const hoursPerMonth = Array(12).fill(0)

  entries.forEach((entry) => {
    if (entry.startDate > endDate || entry.endDate < startDate) return

    const start =
      entry.startDate < startDate
        ? 0
        : getDifferenceInDays(startDate, entry.startDate)
    const end =
      entry.endDate > endDate
        ? daysInYear
        : getDifferenceInDays(startDate, entry.endDate) + 1

    for (let i = start; i < end; i++) {
      if (i === userRatioPerDay.length) {
        break
      }
      userRatioPerDay[i] = userRatioPerDay[i] + entry.bookingRatio
    }
  })

  userRatioPerDay.forEach((day, index) => {
    if (user.startDate && user.startDate.toDate() > addDays(startDate, index)) {
      userRatioPerDay[index] = -1
      return
    }

    if (user.endDate && user.endDate.toDate() < addDays(startDate, index)) {
      userRatioPerDay[index] = -1
      return
    }

    if (excludePast && new Date() > addDays(startDate, index)) {
      userRatioPerDay[index] = -1
      return
    }

    if (
      (user.availability
        ? user.availability[(startDate.getDay() + index) % 7]
        : DEFAULT_AVAILABILITY[(startDate.getDay() + index) % 7]) === 0
    ) {
      userRatioPerDay[index] = -1
    }

    hoursPerDay[index] =
      (1 - day) *
      (user.availability
        ? user.availability[(startDate.getDay() + index) % 7]
        : DEFAULT_AVAILABILITY[(startDate.getDay() + index) % 7])
  })

  let dayIndex = 0
  userRatioPerMonth.forEach((month, index) => {
    const daysInMonth = new Date(
      startDate.getFullYear(),
      index + 1,
      0,
    ).getDate()

    let nonWorkingDays = 0
    let bookingSum = 0
    for (let k = dayIndex; k < dayIndex + daysInMonth; k++) {
      hoursPerMonth[index] += hoursPerDay[k]
      if (userRatioPerDay[k] < 0) {
        ++nonWorkingDays
      } else {
        bookingSum += userRatioPerDay[k]
      }
    }
    userRatioPerMonth[index] =
      daysInMonth - nonWorkingDays > 0
        ? bookingSum / (daysInMonth - nonWorkingDays)
        : -1
    dayIndex += daysInMonth
  })

  return {
    userRatioPerMonth,
    userInProjectsPerMonth: [[""]],
    hoursPerMonth,
  }
}

const getUserRatioAndProjectsPerMonth = (entries: IDoc[], startDate: Date) => {
  const userRatioPerMonth = Array(12).fill(0)
  const userInProjectsPerMonth: string[][] = Array(12).fill([])

  entries.forEach((entry) => {
    const entryRatio = entry.bookingRatio
    const entryStart = entry.startDate
    const entryEnd = entry.endDate

    if (entryStart > entryEnd) {
      return
    }
    const earliestStart =
      entryStart.getFullYear() < startDate.getFullYear()
        ? startDate
        : entryStart

    const latestEntryEnding =
      entryEnd.getFullYear() > startDate.getFullYear()
        ? endOfYear(startDate)
        : entryEnd
    const entryInMonths = eachMonthOfInterval({
      start: earliestStart,
      end: latestEntryEnding,
    })
    entryInMonths.forEach((entryInMonth) => {
      const month = getMonth(entryInMonth)

      const businessDays = getBusinessDays(
        earliestStart,
        latestEntryEnding,
        entryInMonth,
      )

      userInProjectsPerMonth[month] = [
        ...userInProjectsPerMonth[month],
        entry.project?.displayName ?? "Vacation",
      ]
      userRatioPerMonth[month] +=
        (businessDays * entryRatio) /
        differenceInBusinessDays(endOfMonth(entryInMonth), entryInMonth)
    })
  })
  return {
    userRatioPerMonth,
    userInProjectsPerMonth: userInProjectsPerMonth.map((monthList) =>
      Array.from(new Set(monthList)),
    ),
  }
}

export default getUserRatioAndProjectsPerMonth
