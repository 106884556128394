import React, { FunctionComponent } from "react"
import { IJiraIssue } from "../../atlassian/atlassian.data"
import { IJiraUsersData, IUser } from "../../redux"
import { IJira, IProject } from "../../redux"

import { SpinnerContainer, SpinnerOverlay } from "./with-spinner.styles"

interface IOtherProps {
  projects?: IProject[] | null
  setQuery?: (key: string) => void
  onProjectSelected?: (project: IJira) => void
  users?: IUser[] | null
  onUserSelected?: (user: IJiraUsersData) => void
  issues?: IJiraIssue[] | null
}

interface IWithSpinner extends IOtherProps {
  isLoading: boolean
}

const WithSpinner: (
  component: FunctionComponent,
) => FunctionComponent<IWithSpinner> =
  (WrappedComponent) =>
  ({ isLoading, ...otherProps }) => {
    return isLoading ? (
      <SpinnerOverlay>
        <SpinnerContainer />
      </SpinnerOverlay>
    ) : (
      <WrappedComponent {...otherProps} />
    )
  }

export default WithSpinner
