import React, { useState } from "react"
import { connect } from "react-redux"
import CalendarEntriesContainer from "../calendar-entries-container/calendar-entries-container.component"
import DropdownArrow from "../dropdown-arrow/dropdown-arrow.component"
import "./calendar-row.styles.scss"
import CalendarSummaryRow from "../calendar-summary-row/calendar-summary-row.component"
import { PROJECT, OOO } from "../../data/calendar-data"
import AvatarIcon from "../avatar-icon/avatar-icon.component"
import { IDoc, IUser } from "../../redux"
import { Link } from "react-router-dom"

interface ICalendarRow {
  user: IUser
  availability: number[]
  originalAvailability: number[]
  entries: IDoc[]
}

const CalendarRow: React.FunctionComponent<ICalendarRow> = ({
  user,
  availability,
  originalAvailability,
  entries,
}) => {
  const [expand, setExpand] = useState(false)

  const handleClick = () => setExpand(!expand)

  const getAvailabilitySum = (availability: number[]) => {
    return availability.length > 0 ? availability.reduce((a, b) => a + b, 0) : 0
  }

  const getCalendarEntryLeftSideName = (entry: IDoc) => {
    switch (entry.type) {
      case PROJECT:
        return entry.project
          ? entry.project.client
            ? entry.project.client.displayName +
              " - " +
              entry.project.displayName
            : entry.project.displayName
          : ""
      case OOO:
        return "Out of office"

      default:
        break
    }
  }

  return user ? (
    <div>
      <div className="calendar-row">
        <div className="calendar-row-left-side">
          <AvatarIcon user={user} />
          <div className="calendar-row-text">
            {user.firstName} {user.lastName}{" "}
          </div>
          <div className="user-availability-wrapper">
            <div className="user-availability">
              {Math.round(getAvailabilitySum(availability) * 10) / 10}h
            </div>
          </div>
          {entries.length > 0 ? (
            <DropdownArrow handleClick={handleClick} isActive={expand} />
          ) : (
            ""
          )}
        </div>
        <CalendarSummaryRow
          availability={availability}
          originalAvailability={originalAvailability}
        />
      </div>

      {entries ? (
        <div
          className="calendar-row-dropdown"
          style={expand ? { maxHeight: "none" } : { maxHeight: "0px" }}>
          {entries.map((value, index) => (
            <div className="calendar-row" key={index}>
              <div className="calendar-entry-left-side">
                <div className="calendar-entry-wrapper">
                  <Link
                    className="calendar-entry-text"
                    to={
                      value.project?.id ? `projects/${value.project?.id}` : ""
                    }>
                    {getCalendarEntryLeftSideName(value)}
                  </Link>
                </div>
              </div>
              <CalendarEntriesContainer key={index} entry={value} />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    <div>0</div>
  )
}

const mapStateToProps = (state: any) => ({
  startDate: state.calendar.startDate,
  displayedDays: state.calendar.displayedDays,
})

export default connect(mapStateToProps)(CalendarRow)
