import MaterialTable from "material-table"
import React from "react"
import { connect } from "react-redux"
import { tableDefaultOptions } from "../../data/default-data"
import { IProject, IRateCard, setSelectedProject } from "../../redux"

import "./projects-list.styles.scss"

interface IProjectsList {
  setSelectedProject: (project: IProject) => void
  projects: IProject[]
  filter: string
  rateCards: IRateCard[]
}

const ProjectsList: React.FunctionComponent<IProjectsList> = (props) => {
  const handleClick = (project: IProject) => props.setSelectedProject(project)

  const { projects, filter, rateCards } = props

  if (!projects) {
    return null
  }

  const filteredProjects = projects.filter((project) =>
    (project.client
      ? project.client.displayName + " " + project.displayName
      : project.displayName
    )
      .toLowerCase()
      .includes(filter.toLowerCase()),
  )

  return (
    <MaterialTable
      columns={[
        { title: "Project", field: "project" },
        { title: "Status", field: "status" },
        { title: "Rate Card", field: "rateCard" },
        {
          title: "Jira Link",
          field: "jiraLink",
          render: (rowData) => (
            <a href={rowData.jiraLink} target="_blank" rel="noreferrer">
              {rowData.id}
            </a>
          ),
        },
      ]}
      data={
        filteredProjects
          ? filteredProjects.map((project, key) => ({
              id: project.jira?.key,
              project: project.displayName,
              status: project.jira?.status ? project.jira.status : "",
              rateCard:
                project.rateCard && rateCards
                  ? rateCards.find(
                      (rateCard) => rateCard.id === project.rateCard,
                    )?.displayName
                  : "",
              jiraLink: project.jira?.self ? project.jira.self : "",
              tableData: { id: key },
            }))
          : []
      }
      onRowClick={(
        event,
        data:
          | {
              project: string
              status: string
              jiraLink: string
              tableData: { id: number }
            }
          | undefined,
      ) => data && handleClick(filteredProjects[data.tableData.id])}
      options={tableDefaultOptions}
    />
  )
}

const mapStateToProps = (state: any) => ({
  rateCards: state.projects.rateCards,
})
const mapDispatchToProps = (dispatch: any) => ({
  setSelectedProject: (project: IProject) =>
    dispatch(setSelectedProject(project)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList)
