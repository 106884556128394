export const PROJECT = 0
export const OOO = 1

export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
export const DAYS_MO_SU = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const ENTRY_TYPES = [
  { type: PROJECT, displayName: "Project" },
  { type: OOO, displayName: "Out of office" },
]
