import { IAccount, IDoc } from "../../redux"
import { isToday, isYesterday } from "date-fns"
import PendingApprovalsPopUpWindowEntry from "./PendingApprovalsPopUpWindowEntry"
import { VFC } from "react"

type TPopupNotificationsProps = {
  accounts: IAccount[] | null
  currentUser: IAccount
  pendingEntries: IDoc[] | null
  setSelectedEntry: (entry: IDoc) => void
  showPopUp: (value: boolean) => void
}

const PopupPendingEntries: VFC<TPopupNotificationsProps> = ({
  pendingEntries,
  accounts,
  currentUser,
  setSelectedEntry,
  showPopUp,
}) => {
  const sortedPendingEntries = pendingEntries
    ?.slice()
    .sort((a, b) => (a.timeStamp.toDate() > b.timeStamp.toDate() ? -1 : 1))

  const todayPending = sortedPendingEntries
    ?.slice()
    .filter((entry) => isToday(entry.timeStamp.toDate()))

  const yesterdayPending = sortedPendingEntries?.filter((entry) =>
    isYesterday(entry.timeStamp.toDate()),
  )
  const olderPending = sortedPendingEntries?.filter(
    (entry) =>
      !isYesterday(entry.timeStamp.toDate()) &&
      !isToday(entry.timeStamp.toDate()),
  )
  return (
    <div>
      {accounts && pendingEntries && pendingEntries.length > 0 ? (
        <div>
          {todayPending && todayPending.length > 0 && (
            <div className="notification-time-range-title">TODAY</div>
          )}
          {todayPending?.map((entry, key) => (
            <PendingApprovalsPopUpWindowEntry
              showPopUp={showPopUp}
              setSelectedEntry={setSelectedEntry}
              key={"approval-" + key}
              currentUser={currentUser}
              accounts={accounts}
              pendingEntry={entry}
            />
          ))}
          {yesterdayPending && yesterdayPending.length > 0 && (
            <div className="notification-time-range-title">YESTERDAY</div>
          )}
          {yesterdayPending?.map((entry, key) => (
            <PendingApprovalsPopUpWindowEntry
              setSelectedEntry={setSelectedEntry}
              showPopUp={showPopUp}
              key={"approval-" + key}
              currentUser={currentUser}
              accounts={accounts}
              pendingEntry={entry}
            />
          ))}
          {olderPending && olderPending.length > 0 && (
            <div className="notification-time-range-title">OLDER</div>
          )}
          {olderPending?.map((entry, key) => (
            <PendingApprovalsPopUpWindowEntry
              showPopUp={showPopUp}
              setSelectedEntry={setSelectedEntry}
              key={"approval-" + key}
              currentUser={currentUser}
              accounts={accounts}
              pendingEntry={entry}
            />
          ))}
        </div>
      ) : (
        <div className="no-item-to-display">No pending approvals</div>
      )}
    </div>
  )
}

export default PopupPendingEntries
