import React, { FormEvent, useEffect, useState, VFC } from "react"
import { errorToast, updateLocation } from "../../firebase"
import { IAccount, IBankHoliday, ILocations } from "../../redux"
import BankHolidayInput from "../bank-holiday-input/bank-holiday-input.component"
import CustomButtonPlus from "../custom-button-plus/custom-button-plus.component"
import RoleBased from "../role-based/role-based.component"

import "./update-location.styles.scss"
import { RoleManager } from "../../utils"

type TUpdateLocationProps = {
  onUpdate: () => void
  currentUser: IAccount
  permissionRoles: string[]
  roleManager: RoleManager
  selectedLocation: ILocations
}

const UpdateLocation: VFC<TUpdateLocationProps> = (props) => {
  const [displayName, setDisplayName] = useState(
    props.selectedLocation.displayName,
  )
  const [bankHolidays, setBankHolidays] = useState<IBankHoliday[]>([])

  useEffect(() => {
    setBankHolidays(
      props.selectedLocation.bankHolidays
        ? props.selectedLocation.bankHolidays
        : [],
    )
    setDisplayName(props.selectedLocation.displayName)
  }, [props.selectedLocation])

  const addBankHoliday = (bankHoliday: { displayName: string; date: string }) =>
    setBankHolidays(bankHolidays.concat([bankHoliday]))

  const handleDeleteBankHoliday = (index: number) => {
    const holidays = [...bankHolidays]
    holidays.splice(index, 1)
    setBankHolidays(holidays)
  }

  const handleDisplayNameChange = (e: any) => setDisplayName(e.target.value)

  const handleBankHolidayChange = (
    index: number,
    bankHoliday: { displayName: string; date: string },
  ) => {
    const holidays = [...bankHolidays]
    holidays[index] = bankHoliday
    setBankHolidays(holidays)
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { onUpdate, currentUser } = props

    if (!displayName) {
      return
    }

    let valid = true
    bankHolidays.forEach((bankholiday) => {
      if (!bankholiday.date || !bankholiday.displayName) {
        valid = false
        return
      }
    })

    if (!valid) {
      errorToast("Please enter a name and date for each bank holiday.")
      return
    }

    const sanitizedBankHoliday = bankHolidays.map(
      (bankHoliday: { displayName: string; date: string }) => ({
        displayName: bankHoliday.displayName,
        date: bankHoliday.date,
      }),
    )

    updateLocation(
      props.selectedLocation.id,
      {
        displayName: displayName,
        bankHolidays: sanitizedBankHoliday,
      },
      currentUser.id,
    )

    if (onUpdate) {
      onUpdate()
    }
  }

  const { permissionRoles, roleManager } = props

  const inputDisabled = !roleManager.hasRole(permissionRoles)

  return (
    <div>
      <div className="update-user-heading">
        <h2>Location</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="update-location-form">
          <label>Location Name: </label>
          <input
            id="update-location-name"
            type="text"
            value={displayName}
            onChange={handleDisplayNameChange}
            disabled={inputDisabled}
          />
        </div>
        <div className="bank-holidays">
          <div className="bank-holidays-header">
            <h4 className="bank-holidays-heading">Bank Holidays</h4>
            <RoleBased roles={permissionRoles}>
              <CustomButtonPlus
                onClick={() => addBankHoliday({ displayName: "", date: "" })}
              />
            </RoleBased>
          </div>
          <div className="horizontal-divider" />
          <div className="bank-holiday-list" id="inner-scrollbar">
            {bankHolidays.map((bankHoliday, index) => (
              <BankHolidayInput
                key={index}
                index={index}
                onChange={handleBankHolidayChange}
                onDelete={handleDeleteBankHoliday}
                value={bankHoliday}
                isDisabled={inputDisabled}
              />
            ))}
          </div>
        </div>
        <RoleBased roles={permissionRoles}>
          <input
            id="update-location-submit"
            type="submit"
            value="Update Location"
            className="submit-button update-location-button"
          />
        </RoleBased>
      </form>
    </div>
  )
}

export default UpdateLocation
