import { VFC } from "react"
import { Link } from "react-router-dom"
import { IProject } from "../../../../redux"

interface IProjectListItem {
  project: IProject
}

const ProjectListItem: VFC<IProjectListItem> = ({ project }) => {
  return (
    <div>
      <div className="booking-ratio-department-list-item-wrapper">
        <div className="headcount-project-list-item">
          <div className="headcount-project-list-item-wrapper">
            <div className="headcount-project-list-item-text">
              <Link
                className={"project-list-item-link"}
                to={`/projects/${project.id}`}>
                {project.displayName}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectListItem
