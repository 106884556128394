import { Entries } from "../../firebase"
import {
  deleteEntry as deleteEntryAction,
  deletePendingEntry as deletePendingEntryAction,
  dispatch,
  IAccount,
  IDoc,
  updateEntry as updateEntryAction,
} from "../../redux"

interface IApprovalActions {
  action: "approve" | "decline"
  entry: IDoc
  currentUser: IAccount
}

type TEntry = Parameters<typeof Entries["update"]>[1]

const HARD_APPROVAL: IDoc["approval"] = {
  type: "hard",
  status: "approved",
}

const SOFT_APPROVAL: IDoc["approval"] = {
  type: "soft",
  status: "approved",
}

const withApproval = (entry: IDoc, approval: IDoc["approval"]): IDoc => ({
  ...entry,
  approval,
})
const withExtendedApproval = (
  entry: IDoc,
  approval: IDoc["approval"],
  approvedBy: TEntry["approval"]["approvedBy"],
): TEntry => ({
  ...entry,
  before: null,
  approval: { ...approval, approvedBy },
})

const deleteEntry = async (entry: IDoc, userId: string) => {
  dispatch(deleteEntryAction(entry.id))
  await Entries.remove(entry, userId)
}

const updateEntryWithChangedApproval = async (
  entry: IDoc,
  approval: IDoc["approval"],
  userId: string,
) => {
  dispatch(updateEntryAction(withApproval(entry, approval)))
  dispatch(deletePendingEntryAction(entry.id))
  await Entries.update(
    entry.id,
    withExtendedApproval(entry, approval, userId),
    entry.lastChanged,
  )
}

// Optimistically updates UI and sends request to backend
const approvalActions = async ({
  action,
  entry,
  currentUser,
}: IApprovalActions) => {
  switch (action) {
    case "approve":
      if (entry.approval.type === "hard") {
        await updateEntryWithChangedApproval(
          entry,
          HARD_APPROVAL,
          currentUser.id,
        )
      } else if (entry.approval.type === "delete") {
        await deleteEntry(entry, currentUser.id)
      } else {
        await updateEntryWithChangedApproval(
          entry,
          SOFT_APPROVAL,
          currentUser.id,
        )
      }
      break
    case "decline":
      {
        const updatedEntry = entry.before
          ? ({
              ...entry.before,
              createdBy: entry.createdBy,
              id: entry.id,
              before: null,
              approval: {
                type: "soft",
                status: "declined",
                approvedBy: currentUser.id,
              },
            } as const)
          : ({
              ...entry,
              before: null,
              approval: {
                type: "soft",
                status: "declined",
                approvedBy: currentUser.id,
              },
            } as const)
        dispatch(updateEntryAction(updatedEntry))
        dispatch(deletePendingEntryAction(updatedEntry.id))
        await Entries.update(entry.id, updatedEntry, entry.lastChanged)
      }
      break
    default:
      break
  }
}

export default approvalActions
