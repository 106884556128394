import React, { useState } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { addUser as firebaseAddUser } from "../../firebase"
import OverlayBox from "../overlay-box/overlay-box.component"
import CustomButton from "../custom-button/custom-button.component"
import { DEFAULT_AVAILABILITY } from "../../data/default-data"
import FilterTeam from "../filter-team/filter-team.component"
import "./add-user.styles.scss"
import {
  IAccount,
  IDepartments,
  ILocations,
  ITeams,
  SeniorityLevelLabel,
} from "../../redux"

interface IAddUser {
  locations: ILocations[]
  departments: IDepartments[]
  teams: ITeams[]
  currentUser: IAccount
  accounts: IAccount[] | null
}

const AddUser: React.FunctionComponent<IAddUser> = (props) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [department, setDepartment] = useState("")
  const [selectedTeams, setSelectedTeams] = useState([])
  const [addUser, setAddUser] = useState(false)
  const [location, setLocation] = useState("")
  const [seniorityLevel, setSeniorityLevel] = useState<string | null>(null)

  const handleLastNameChange = (event: any) => setLastName(event.target.value)

  const handleFirstNameChange = (event: any) => setFirstName(event.target.value)

  const handleEmailChange = (event: any) => setEmail(event.target.value)

  const handleDepartmentChange = (event: any) => setDepartment(event?.value)

  const handleLocationChange = (event: any) => setLocation(event?.value)

  const handleSeniorityLevelChange = (event: any) => {
    console.log(event)
    setSeniorityLevel(event ? event.value : null)
  }

  const handleTeamsChange = (event: any) => {
    setSelectedTeams(event)
  }

  const handleSubmit = (event: any) => {
    const { locations, departments, currentUser, teams } = props

    let departmentObject = null
    if (department) {
      departmentObject = {
        id: department,
        displayName: departments?.find((element) => element.id === department)
          ?.displayName,
      }
    }

    let locationObject = null
    if (location) {
      locationObject = {
        id: location,
        displayName: locations?.find((element) => element.id === location)
          ?.displayName,
      }
    }

    let teamsObject = null
    if (selectedTeams.length > 0) {
      teamsObject = {}
      selectedTeams.forEach((team) => {
        teamsObject[team] = {
          displayName: teams?.find((element) => element.id === team)
            ?.displayName,
        }
      })
    }

    if (process.env.NODE_ENV === "development")
      console.log({
        accountId:
          props.accounts?.filter((account) => account.email === email)?.[0]
            ?.id ?? null,
        firstName: firstName,
        lastName: lastName,
        email: email,
        department: departmentObject,
        location: locationObject,
        teams: teamsObject,
        availability: DEFAULT_AVAILABILITY,
        seniorityLevel: seniorityLevel,
      })

    firebaseAddUser(
      {
        accountId:
          props.accounts?.filter((account) => account.email === email)?.[0]
            ?.id ?? null,
        firstName: firstName,
        lastName: lastName,
        email: email,
        department: departmentObject,
        location: locationObject,
        teams: teamsObject,
        availability: DEFAULT_AVAILABILITY,
        seniorityLevel: seniorityLevel,
      },
      currentUser.id,
    )

    setAddUser(false)
    setFirstName("")
    setLastName("")
    setEmail("")
    setDepartment("")
    setLocation("")

    event.preventDefault()
  }

  const { departments, locations } = props

  const options = departments
    ? departments.map((department) => ({
        value: department.id,
        label: department.displayName,
      }))
    : []

  const locationOptions = locations
    ? locations.map((location) => ({
        label: location.displayName,
        value: location.id,
      }))
    : []

  const seniorityLevelOption = Object.keys(SeniorityLevelLabel)
    .filter((level, key) => SeniorityLevelLabel[key])
    .map((level: string, key: number) => ({
      label: SeniorityLevelLabel[key],
      value: SeniorityLevelLabel[key].toLowerCase(),
    }))

  return (
    <div className="create-user-container">
      {/* Add user button */}
      <CustomButton onClick={() => setAddUser(!addUser)}>Add User</CustomButton>
      {addUser ? (
        <OverlayBox onCancel={() => setAddUser(!addUser)} heading="Add User">
          <form className="create-user-form" onSubmit={handleSubmit}>
            <label>First name: </label>
            <input
              id="add-user-firstname"
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
              required
            />
            <label>Last name: </label>
            <input
              id="add-user-lastname"
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              required
            />
            <label>E-Mail: </label>
            <input
              id="add-user-email"
              type="email"
              value={email}
              onChange={handleEmailChange}
            />
            <label>Department</label>
            <Select
              isClearable={true}
              id="add-user-select-department"
              options={options}
              onChange={handleDepartmentChange}
            />
            <label>Seniority level</label>
            <Select
              isClearable={true}
              id="add-user-select-seniority-level"
              options={seniorityLevelOption}
              onChange={handleSeniorityLevelChange}
              value={
                seniorityLevel
                  ? {
                      label:
                        seniorityLevel.charAt(0).toUpperCase() +
                        seniorityLevel.slice(1),
                      value: seniorityLevel,
                    }
                  : null
              }
            />
            <label>Teams</label>
            <FilterTeam onChange={handleTeamsChange} />
            <label>Location</label>
            <Select
              isClearable={true}
              id="add-user-select-location"
              options={locationOptions}
              onChange={handleLocationChange}
            />
            <input
              id="add-user-submit"
              type="submit"
              value="Create User"
              className="submit-button"
            />
          </form>
        </OverlayBox>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  departments: state.calendar.departments,
  locations: state.calendar.locations,
  currentUser: state.user.currentUser,
  teams: state.calendar.teams,
  accounts: state.user.accounts,
})

export default connect(mapStateToProps)(AddUser)
