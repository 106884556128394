import React from "react"
import { connect, RootStateOrAny } from "react-redux"
import { IDepartments, IDoc, ILocations, IProject, IUser } from "../../redux"
import { addDays, filterBankHolidays } from "../../utils"

import CalendarGroup from "../calendar-group/calendar-group.component"

import "./calendar-content.styles.scss"
import { filterDisplayedUsers, filterEntries } from "./calendarFilters"

interface ICalendarContent {
  departments: IDepartments[]
  entries: IDoc[]
  startDate: Date
  users: IUser[] | null
  userFilter: string
  teamsFilter: string[]
  locations: ILocations[]
  displayedDays: number
  projectsFilter: string[]
  projectStatusFilter: string[]
  projects: IProject[] | null
}

const CalendarContent: React.FunctionComponent<ICalendarContent> = ({
  departments,
  entries,
  startDate,
  users,
  userFilter,
  locations,
  displayedDays,
  teamsFilter,
  projectsFilter,
  projectStatusFilter,
  projects,
}) => {
  const filterUserByDepartment = (users: IUser[], department: IDepartments) =>
    users.filter(
      (user) => user.department && user.department.id === department.id,
    )

  const endDate = addDays(startDate, displayedDays)

  const filteredEntries = entries
    ? filterEntries(entries, projects, projectStatusFilter, startDate, endDate)
    : []

  // Filtering user per search bar
  const filteredUsers = filterDisplayedUsers(
    users,
    projectsFilter,
    userFilter,
    teamsFilter,
    filteredEntries,
    [],
    startDate,
  )

  // Filtering all bank holidays by displayed days
  const filteredBankHolidays = filterBankHolidays(
    startDate,
    displayedDays - 1,
    locations,
  )

  return (
    <div className="calendar-content">
      <div className="left-panel-container">
        <div className="left-panel" />
        <div className="calendar-rows-container">
          {departments
            ? departments.map((department) => {
                return (
                  <CalendarGroup
                    key={department.id}
                    users={filterUserByDepartment(filteredUsers, department)}
                    filteredEntries={filteredEntries}
                    filteredBankHolidays={filteredBankHolidays}
                    department={department}
                  />
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootStateOrAny) => ({
  departments: state.calendar.departments,
  users: state.calendar.users,
  startDate: state.calendar.startDate,
  entries: state.calendar.entries,
  userFilter: state.calendar.userFilter,
  teamsFilter: state.calendar.teamsFilter,
  locations: state.calendar.locations,
  displayedDays: state.calendar.displayedDays,
  projects: state.projects.projects,
  projectsFilter: state.projects.projectsFilter,
  projectStatusFilter: state.projects.projectStatusFilter,
})

export default connect(mapStateToProps)(CalendarContent)
