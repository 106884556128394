import { format, getDayOfYear as getDayOfYearFNS } from "date-fns"
import { DEFAULT_AVAILABILITY } from "../data/default-data"
import { IBankHoliday, IDate, IDoc, ILocations, IUser } from "../redux"

export const roundToOneDecimal = (value: number) => Math.round(value * 10) / 10
export const roundToTwoDecimal = (value: number) =>
  Math.round(value * 100) / 100

export const entryIsInTimeframe = (
  entry: IDoc,
  startDate: Date,
  endDate: Date,
) => !(entry.startDate > endDate || entry.endDate < startDate)

export function timestampToInputDate(timestamp: IDate) {
  return format(timestamp.toDate(), "yyyy-MM-dd")
}

export function dateToTimestamp(date: string) {
  const timestamp = Date.parse(date)
  return timestamp / 1000
}

export function addDays(date: Date, days: number) {
  const result = new Date(date)
  result.setDate(date.getDate() + days)
  return result
}

export function getDifferenceInDays(startDate: Date, endDate: Date) {
  return (
    (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
      Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
      )) /
    (24 * 60 * 60 * 1000)
  )
}

export const getDayOfYear = (date: Date) => {
  return getDayOfYearFNS(date)
}

// Filtering all bank holidays by displayed days
// This results in an easy to use object a la
// locationId: {displayName: "Bank Holiday", date: "yyyy-mm-dd"}
// Later we can get the location id from the user and get all bank holidays with a single call.

export const getUTCDate = (date: Date) => {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
}

export const filterBankHolidays = (
  startDate: Date,
  displayedDays: number,
  locations: ILocations[],
): { [key: string]: IBankHoliday[] | null } => {
  let filteredBankHolidays = {} as { [key: string]: IBankHoliday[] | null }

  if (locations) {
    locations.forEach((location) => {
      filteredBankHolidays[location.id] = location.bankHolidays
        ? location.bankHolidays.filter((bankHolidayEntry) => {
            const bankHolidayDate = new Date(bankHolidayEntry.date)
            return (
              getUTCDate(bankHolidayDate) >= getUTCDate(startDate) &&
              getUTCDate(bankHolidayDate) <=
                getUTCDate(addDays(startDate, displayedDays))
            )
          })
        : null
    })
  }

  return filteredBankHolidays
}

export function getUserAvailability(
  user: IUser | undefined,
  startDate: Date,
  displayedDays: number,
  filteredBankHolidays: { [key: string]: IBankHoliday[] | null },
) {
  let availability = [] as number[]

  if (!startDate || !filteredBankHolidays) {
    return availability
  }

  for (let h = 0; h <= displayedDays; h++) {
    if (
      (user &&
        user.startDate &&
        addDays(startDate, h) < user.startDate.toDate()) ||
      (user && user.endDate && addDays(startDate, h) > user.endDate.toDate())
    ) {
      availability.push(0)
    } else {
      availability.push(
        user && user.availability
          ? user.availability[(startDate.getDay() + h) % 7]
          : DEFAULT_AVAILABILITY[(startDate.getDay() + h) % 7],
      )
    }
  }

  // Subtracting bank holidays from original availability
  if (user && user.location && filteredBankHolidays[user.location.id]) {
    filteredBankHolidays?.[user.location.id]?.forEach((holiday) => {
      availability[getDifferenceInDays(startDate, new Date(holiday.date))] = 0
    })
  }

  return availability
}

export function getArraySum(array: number[]) {
  return array.length > 0 ? array.reduce((a, b) => a + b, 0) : 0
}

export const toCamelcase = (value: string) =>
  value.slice(0, 1).toUpperCase() + value.slice(1)

/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export function getWeekNumber(d: Date) {
  // Return array of year and week number
  return [d.getUTCFullYear(), format(d, "I")]
}
