import React, { useState } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { ENTRY_TYPES } from "../../data/calendar-data"
import { Entries } from "../../firebase"
import OverlayBox from "../overlay-box/overlay-box.component"
import {
  filterBankHolidays,
  getArraySum,
  getDifferenceInDays,
  getUserAvailability,
} from "../../utils"
import "./add-entry.styles.scss"
import { IAccount, ILocations, IProject, IUser } from "../../redux"
import { RangeWithKey } from "react-date-range"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import DateRangeSelector from "../date-range-selector/DateRangeSelector"
import { format } from "date-fns"

interface IAddEntry {
  projects: IProject[]
  users: IUser[]
  currentUser: IAccount
  onCancel: () => void
  locations: ILocations[]
  visible: boolean
}

const bookingRatioOptions = [
  { label: "1", value: 1.0 },
  { label: "0.75", value: 0.75 },
  { label: "0.5", value: 0.5 },
  { label: "0.25", value: 0.25 },
]

const AddEntry: React.FunctionComponent<IAddEntry> = (props) => {
  const [dateRange, setDateRange] = useState<RangeWithKey[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ])
  const startDate = dateRange?.[0]?.startDate
  const endDate = dateRange?.[0]?.endDate
  const [user, setUser] = useState<{ label: string; value: string } | null>(
    null,
  )
  const [project, setProject] = useState<{
    label: string
    value: string
  } | null>(null)
  const [bookingRatio, setBookingRatio] = useState<{
    label: string
    value: number
  }>(bookingRatioOptions[0])
  const [entryType, setEntryType] = useState(0)
  const [displayDateRange, setDisplayDateRange] = useState(false)
  let bookedTime = 0

  const handleProjectChange = (event: any) => setProject(event)

  const handleUserChange = (event: any) => setUser(event)

  const handleBookingRatioChange = (event: any) => setBookingRatio(event)

  const handleEntryTypeChange = (event: any) => setEntryType(event.value)

  const handleSubmit = (event: any) => {
    const { projects, users, currentUser } = props

    event.preventDefault()

    if (!(startDate && endDate && user && bookingRatio)) {
      // Insert some error message here
      return
    }

    const projectData =
      entryType === 0
        ? projects?.find((tmpProject) => tmpProject.id === project?.value)
        : null

    const userData = users?.find((tmpUser) => tmpUser.id === user.value)

    void Entries.add({
      before: null,
      user: {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        id: user.value,
      },
      project:
        entryType === 0
          ? {
              displayName: projectData?.displayName,
              id: project?.value,
              client: projectData?.client
                ? {
                    displayName: projectData.client.displayName,
                    id: projectData.client.id,
                  }
                : null,
            }
          : null,
      startDate,
      endDate,
      bookingRatio: bookingRatio.value,
      bookedTime,
      type: entryType,
      lastChanged: currentUser.id,
      createdBy: currentUser.id,
      approval: {
        type: "draft",
        status: "pending",
        approvedBy: null,
      },
    })

    props.onCancel()
  }

  const { visible, users, projects, onCancel, locations } = props

  const userOptions = users
    ? users.map((user) => ({
        label: user.firstName + " " + user.lastName,
        value: user.id,
      }))
    : []

  const projectOptions = projects?.reduce((res, currentProject) => {
    if (!currentProject.archived) {
      res.push({
        label: currentProject.client
          ? currentProject.client.displayName +
            " - " +
            currentProject.displayName
          : currentProject.displayName,
        value: currentProject.id,
      })
    }
    return res
  }, [] as any)

  const entryOptions = ENTRY_TYPES.map((entryType) => ({
    label: entryType.displayName,
    value: entryType.type,
  }))

  if (!!startDate && !!endDate && user && bookingRatio && users) {
    const filteredBankHolidays = filterBankHolidays(
      new Date(startDate),
      getDifferenceInDays(new Date(startDate), new Date(endDate)),
      locations,
    )

    const userAvailability = getUserAvailability(
      users.find((u) => u.id === user.value),
      new Date(startDate),
      getDifferenceInDays(new Date(startDate), new Date(endDate)),
      filteredBankHolidays,
    )
    bookedTime = getArraySum(userAvailability) * bookingRatio.value
  }

  return visible ? (
    <OverlayBox onCancel={onCancel} heading="Add Entry">
      {displayDateRange && (
        <div className="add-entry-date-picker">
          <DateRangeSelector
            range={dateRange}
            setRange={setDateRange}
            onClickOutside={setDisplayDateRange}
          />
        </div>
      )}
      <form className="add-entry-form" onSubmit={handleSubmit}>
        <label>User:</label>
        <Select
          id="add-entry-select-user"
          options={userOptions}
          onChange={handleUserChange}
          value={user}
        />
        <label>Entry Type:</label>
        <Select
          id="add-entry-select-type"
          options={entryOptions}
          onChange={handleEntryTypeChange}
          defaultValue={entryOptions[0]}
          value={entryOptions[entryType]}
        />
        {entryType === 0 ? <label>Project:</label> : null}
        {entryType === 0 ? (
          <Select
            id="add-entry-project-selection"
            options={projectOptions}
            onChange={handleProjectChange}
            value={project}
          />
        ) : null}
        <label>Start date:</label>
        <input
          id="add-entry-select-startDate"
          value={startDate ? format(startDate, "dd.MM.yyyy") : ""}
          onClick={() => setDisplayDateRange(!displayDateRange)}
          readOnly={true}
        />
        <label>End date:</label>
        <input
          id="add-entry-select-endDate"
          value={endDate ? format(endDate, "dd.MM.yyyy") : ""}
          onClick={() => setDisplayDateRange(!displayDateRange)}
          readOnly={true}
        />
        <label>Booking Ratio:</label>
        <Select
          id="add-entry-booking-ratio"
          options={bookingRatioOptions}
          defaultValue={bookingRatio}
          onChange={handleBookingRatioChange}
        />
        <div>Booked Time:</div>
        <div>{+bookedTime.toFixed(2)}h</div>
        <div />
        <input
          id="add-entry-submit-button"
          type="submit"
          value="Add Entry"
          className="submit-button"
        />
      </form>
    </OverlayBox>
  ) : null
}

const mapStateToProps = (state: any) => ({
  users: state.calendar.users,
  projects: state.projects.projects,
  locations: state.calendar.locations,
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(AddEntry)
