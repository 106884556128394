import { OptionsType } from "react-select"

const filterUserRoles = (
  presentRoles: OptionsType<{ label: string; value: string }>,
  userRoleProperties: { label: string; value: string }[] | undefined,
) => {
  const eventRoles = {} as { [key: string]: boolean }
  if (!presentRoles) {
    userRoleProperties?.forEach((role: { label: string; value: string }) => {
      eventRoles[role.value] = false
    })
  } else {
    presentRoles.forEach((role: { label: string; value: string }) => {
      eventRoles[role.value] = true
    })
    userRoleProperties?.forEach((role: { label: string; value: string }) => {
      eventRoles[role.value] = presentRoles.some(
        (eventRole: { label: string; value: string }) =>
          eventRole.value === role.value,
      )
    })
  }
  return { ...eventRoles }
}

export default filterUserRoles
