import { IDepartments, IDoc, IProject, IUser } from "../../redux"
import { entryIsInTimeframe } from "../../utils"

const isProjectOfEntryInStatusFilter = (
  entry: IDoc,
  statusFilter: string[],
  projects: IProject[],
) =>
  entry.project
    ? statusFilter.length > 0
      ? statusFilter.find(
          (filter) =>
            filter ===
            projects?.find((project) => project.id === entry.project?.id)?.jira
              ?.status,
        )
      : true
    : true

export const filterEntries = (
  entries: IDoc[],
  projects: IProject[] | null,
  projectStatusFilter: string[],
  startDate: Date,
  endDate: Date,
) =>
  entries.filter((entry) =>
    entryIsInTimeframe(entry, startDate, endDate)
      ? projects
        ? isProjectOfEntryInStatusFilter(entry, projectStatusFilter, projects)
        : 1
      : 0,
  )

export const filterUserByDepartment = (
  users: IUser[],
  department: IDepartments,
) =>
  users.filter(
    (user) => user.department && user.department.id === department.id,
  )

export const filterDisplayedUsers = (
  users: IUser[] | null,
  projectsFilter: string[],
  userFilter: string,
  teamsFilter: string[],
  entries: IDoc[],
  departmentsFilter?: string[],
  startDate?: Date,
) =>
  users?.filter((user) => {
    if (startDate && user.endDate && user.endDate.toDate() < startDate) {
      return false
    }

    const teams = user.teams
    if (
      teamsFilter.length > 0 ||
      projectsFilter.length > 0 ||
      userFilter ||
      (departmentsFilter && departmentsFilter.length > 0)
    ) {
      let userInFilters = []

      if (teamsFilter.length > 0 && teams) {
        userInFilters.push(
          Object.keys(teams).some((team) => teamsFilter.includes(team)),
        )
      }
      if (projectsFilter.length > 0) {
        userInFilters.push(
          entries.some(
            (entry) =>
              entry?.user.id === user.id &&
              projectsFilter?.some((option) => entry?.project?.id === option),
          ),
        )
      }

      if (userFilter) {
        userInFilters.push(
          (user.firstName + " " + user.lastName)
            .toLowerCase()
            .includes(userFilter.toLowerCase()),
        )
      }

      if (departmentsFilter && departmentsFilter.length > 0) {
        userInFilters.push(
          departmentsFilter.some((option) => user?.department?.id === option),
        )
      }

      return userInFilters.every((value) => value)
    }
    return true
  }) || []
