import { useEffect, useState } from "react"
import { connect } from "react-redux"

import "./timesheets-page.styles.scss"

import nextbutton from "../../images/ionic-ios-arrow-forward.svg"
import { OOO } from "../../data/calendar-data"
import {
  getWeekNumber,
  addDays,
  getUserAvailability,
  getDifferenceInDays,
  filterBankHolidays,
  entryIsInTimeframe,
  roundToOneDecimal,
} from "../../utils"
import { getWorklogs } from "../../api/api.utils"
import JiraUserCard from "../../components/jira-user-card/jira-user-card.component"
import SearchBar from "../../components/search-bar/search-bar.component"
import FilterDepartment from "../../components/filter-department/filter-department.component"
import FilterTeam from "../../components/filter-team/filter-team.component"
import { IBankHoliday, IDoc, ILocations, IUser } from "../../redux"
import OverlayBox from "../../components/overlay-box/overlay-box.component"
import AtlassianSignIn from "../../atlassian/atlassian-sign-in.component"
import MaterialTable from "material-table"
import { tableDefaultOptions } from "../../data/default-data"

const tableCellStyle = { padding: "16px 0px 16px 0px" }

const convertSunFirstToMonFirst = (day: number) => {
  return day === 0 ? 6 : day === 6 ? 0 : day - 1
}

interface ISortData {
  [key: string]: { props?: { children: string } }
}

interface IUserWorkLog {
  worklog: { [key: string]: number[] }
  user: IUser
  fetching: boolean
  startDate: Date
  endDate: Date
  filteredBankHolidays: { [key: string]: IBankHoliday[] | null }
  entries: number[]
  fetchingError: boolean
}

const UserWorklog = ({
  worklog,
  user,
  fetching,
  startDate,
  endDate,
  filteredBankHolidays,
  entries,
  fetchingError,
}: IUserWorkLog) => {
  const userAvailability =
    startDate && endDate && user
      ? getUserAvailability(
          user,
          startDate,
          getDifferenceInDays(startDate, endDate),
          filteredBankHolidays,
        )
      : []

  var styles = {} as any

  if (entries) {
    entries.forEach((entry, key) => {
      let style = {}

      if (entry === 1) {
        style = {
          background: "var(--rp-green)",
          color: "black",
          opacity: "0.5",
        }
      }

      if (entry === 2) {
        style = {
          background: "var(--rp-yellow)",
          color: "black",
          opacity: "0.5",
        }
      }

      styles[key] = style
    })
  }

  const tableDayEntryData = (id: number) => {
    const loggedTime = roundToOneDecimal(
      (worklog?.["loggedTime"]?.[id] ?? 0) / 3600,
    )
    return fetching || fetchingError ? (
      fetchingError ? (
        "Error fetching data"
      ) : (
        "Loading..."
      )
    ) : worklog ? (
      <div
        style={
          userAvailability[id] === 0
            ? {
                background: "var(--rp-grey)",
                opacity: "0.5",
                width: `calc(12px + ${loggedTime.toString().length} * 8px)`,
              }
            : worklog["loggedTime"][id] < 6
            ? {
                color: "red",
                width: `calc(12px + ${loggedTime.toString().length} * 8px)`,
                ...styles[id],
              }
            : {
                width: `calc(12px + ${loggedTime.toString().length} * 8px)`,
                ...styles[id],
              }
        }>
        {loggedTime}h
      </div>
    ) : (
      <div
        style={
          userAvailability[id] === 0
            ? { background: "var(--rp-grey)", opacity: "0.5", width: "20px" }
            : { color: "red", ...styles[id] }
        }>
        {"0h"}
      </div>
    )
  }

  const tableWorklog = {
    mon: tableDayEntryData(0),
    tue: tableDayEntryData(1),
    wed: tableDayEntryData(2),
    thu: tableDayEntryData(3),
    fri: tableDayEntryData(4),
    sat: tableDayEntryData(5),
    sun: tableDayEntryData(6),
  }
  return {
    user: user.jira ? (
      <JiraUserCard key={Math.random()} user={user.jira} size={"small"} />
    ) : (
      user.firstName + " " + user.lastName
    ),
    department: user.department ? user.department.displayName : "No department",
    ...tableWorklog,
  }
}

interface ITimeSheetsPage {
  users: IUser[]
  locations: ILocations[]
  entries: IDoc[]
}

const TimesheetsPage = ({ users, locations, entries }: ITimeSheetsPage) => {
  const today = new Date()
  const [atlassianTokenInvalid, setAtlassianTokenInvalid] = useState(false)
  const [worklogs, setWorklogs] = useState<{
    [key: string]: { worklogs: number[] }
  }>({})
  const [startDate, setStartDate] = useState(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 1,
    ),
  )
  const [fetching, setFetching] = useState(false)
  const [fetchingError, setFetchingError] = useState(false)
  const [filter, setFilter] = useState("")
  const [departmentFilter, setDepartmentFilter] = useState<string[]>([])
  const [teamFilter, setTeamFilter] = useState<string[]>([])

  const endDate = addDays(
    new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() - startDate.getDay() + 1,
    ),
    6,
  )

  var filteredUsers = users
    ? users.filter((user) =>
        (user.firstName + " " + user.lastName)
          .toLowerCase()
          .includes(filter.toLowerCase()),
      )
    : null

  if (departmentFilter.length > 0 && filteredUsers) {
    filteredUsers = filteredUsers.filter((user) =>
      user.department ? departmentFilter.includes(user.department.id) : false,
    )
  }
  if (teamFilter.length > 0 && filteredUsers) {
    filteredUsers = filteredUsers.filter((user) =>
      user.teams ? teamFilter.some((id) => !!user.teams?.[id]) : false,
    )
  }

  useEffect(() => {
    const fetch = async () => {
      let updatedWorklogs = { ...worklogs } as any
      updatedWorklogs[+startDate] = await getWorklogs(
        +startDate,
        +addDays(
          new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() - startDate.getDay() + 1,
          ),
          6,
        ),
      )
      setWorklogs(updatedWorklogs)

      if (updatedWorklogs[+startDate] && !updatedWorklogs[+startDate].success) {
        setAtlassianTokenInvalid(true)
        setFetchingError(true)
      }
      setFetching(false)
    }

    if (!worklogs[+startDate] && !fetching) {
      setFetching(true)
      fetch()
    }
  }, [worklogs, startDate, fetching])

  let worklogData = {} as any

  if (worklogs[+startDate] && worklogs[+startDate].worklogs) {
    worklogs[+startDate].worklogs.forEach((worklog: any) => {
      const worklogStarted = new Date(worklog.started)
      if (worklogStarted < startDate || worklogStarted > endDate) {
        return
      }

      if (!worklogData[worklog.author.accountId]) {
        worklogData[worklog.author.accountId] = {}
        worklogData[worklog.author.accountId]["worklogs"] = []
        worklogData[worklog.author.accountId]["loggedTime"] = [
          0, 0, 0, 0, 0, 0, 0,
        ]
      }
      worklogData[worklog.author.accountId]["worklogs"].push(worklog)
      worklogData[worklog.author.accountId]["loggedTime"][
        convertSunFirstToMonFirst(worklogStarted.getDay())
      ] += worklog.timeSpentSeconds
    })
  }

  // Filter bank holidays for the given timeframe
  const filteredBankHolidays = filterBankHolidays(
    startDate,
    getDifferenceInDays(startDate, endDate),
    locations,
  )

  // Filter entries for the given timeframe
  let userEntries = {} as { [key: string]: number[] }
  if (entries) {
    entries.forEach((entry) => {
      if (entryIsInTimeframe(entry, startDate, endDate)) {
        // Only vacation and sickness types are relevant
        if (entry.type === OOO) {
          const id = entry.user.id

          if (!userEntries[id]) {
            userEntries[id] = []

            for (
              var d = new Date(startDate);
              d <= endDate;
              d.setDate(d.getDate() + 1)
            ) {
              userEntries[id].push(0)
            }
          }

          const start =
            entry.startDate > startDate
              ? getDifferenceInDays(startDate, entry.startDate)
              : 0
          const end =
            entry.endDate < endDate
              ? getDifferenceInDays(startDate, entry.endDate)
              : userEntries[id].length - 1
          for (var i = start; i <= end; i++) {
            userEntries[id][i] = entry.type
          }
        }
      }
    })
  }
  const valuePerDayString = (data: ISortData, day: string) =>
    data[day]?.props?.children.slice(0, -1) ?? data[day]

  const sortByDays = (data1: ISortData, data2: ISortData, day: string) => {
    const data1Value = valuePerDayString(data1, day)
    const data2Value = valuePerDayString(data2, day)

    if (data1Value < data2Value) {
      return -1
    }
    return 1
  }

  return (
    <div className="timesheets-page">
      <div>
        {atlassianTokenInvalid ? (
          <OverlayBox onCancel={() => setAtlassianTokenInvalid(false)}>
            <AtlassianSignIn />
          </OverlayBox>
        ) : null}
      </div>
      <div className="timesheet-wrapper">
        <div className="timesheets-header">
          <button
            className="calendar-month-selection-today"
            onClick={() =>
              setStartDate(
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - today.getDay() + 1,
                ),
              )
            }>
            This Week
          </button>
          <button
            className="calendar-button"
            onClick={() => setStartDate(addDays(startDate, -7))}>
            <img
              src={nextbutton}
              className="previous-icon"
              alt="Previous month button"
            />
          </button>
          <button
            className="calendar-button"
            onClick={() => setStartDate(addDays(startDate, 7))}>
            <img
              src={nextbutton}
              className="next-icon"
              alt="Next month button"
            />
          </button>
          <div className="calendar-month-text">
            <span style={{ fontWeight: 500 }}>
              {"Week " + getWeekNumber(startDate)[1] + ": "}
            </span>
            {startDate.getDate() +
              "." +
              (startDate.getMonth() + 1 < 10
                ? "0" + (startDate.getMonth() + 1)
                : startDate.getMonth() + 1) +
              "." +
              startDate.getFullYear() +
              " - " +
              endDate.getDate() +
              "." +
              (endDate.getMonth() + 1 < 10
                ? "0" + (endDate.getMonth() + 1)
                : endDate.getMonth() + 1) +
              "." +
              startDate.getFullYear()}
          </div>
          <button
            className="calendar-month-selection-today"
            onClick={() => setWorklogs({})}>
            Refresh
          </button>
        </div>
        <div className="timesheet-filters">
          <SearchBar
            placeholder="Search for users..."
            onChange={(e: any) => setFilter(e.target.value)}
          />
          <FilterTeam onChange={setTeamFilter} />
          <FilterDepartment onChange={setDepartmentFilter} />
        </div>
        <div>
          <MaterialTable
            columns={[
              { title: "User", field: "user", width: "25%" },
              { title: "Department", field: "department", width: "25%" },
              {
                title: "Mon",
                field: "mon",
                customSort: (data1, data2) => sortByDays(data1, data2, "mon"),
                cellStyle: tableCellStyle,
                headerStyle: tableCellStyle,
              },
              {
                title: "Tue",
                field: "tue",
                customSort: (data1, data2) => sortByDays(data1, data2, "tue"),
                cellStyle: tableCellStyle,
                headerStyle: tableCellStyle,
              },
              {
                title: "Wed",
                field: "wed",
                customSort: (data1, data2) => sortByDays(data1, data2, "wed"),
                cellStyle: tableCellStyle,
                headerStyle: tableCellStyle,
              },
              {
                title: "Thu",
                field: "thu",
                customSort: (data1, data2) => sortByDays(data1, data2, "thu"),
                cellStyle: tableCellStyle,
                headerStyle: tableCellStyle,
              },
              {
                title: "Fri",
                field: "fri",
                customSort: (data1, data2) => sortByDays(data1, data2, "fri"),
                cellStyle: tableCellStyle,
                headerStyle: tableCellStyle,
              },
              {
                title: "Sat",
                field: "sat",
                customSort: (data1, data2) => sortByDays(data1, data2, "sat"),
                cellStyle: tableCellStyle,
                headerStyle: tableCellStyle,
              },
              {
                title: "Sun",
                field: "sun",
                customSort: (data1, data2) => sortByDays(data1, data2, "sun"),
                cellStyle: tableCellStyle,
                headerStyle: tableCellStyle,
              },
            ]}
            data={
              filteredUsers?.map((user) =>
                UserWorklog({
                  entries: userEntries[user.id],
                  worklog:
                    worklogData && user.jira && worklogData[user.jira.accountId]
                      ? worklogData[user.jira.accountId]
                      : null,
                  startDate,
                  user,
                  fetching,
                  endDate,
                  filteredBankHolidays,
                  fetchingError,
                }),
              ) || []
            }
            options={tableDefaultOptions}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  users: state.calendar.users,
  departments: state.calendar.departments,
  locations: state.calendar.locations,
  entries: state.calendar.entries,
})
export default connect(mapStateToProps)(TimesheetsPage)
