import { ITeams } from "../redux"
import { errorToast, firestore, successToast } from "./firebase.utils"

export const addTeam = async (
  team: {
    displayName: string
    teamLead?: { displayName: string; id: string } | null
  },
  uid: string,
) => {
  try {
    await firestore.collection("teams").add({ ...team, lastChanged: uid })
    successToast("Successfully added Team")
  } catch (err) {
    errorToast("Failed to add Team")
    return
  }
}

export const updateTeam = async (
  id: string,
  team: {
    displayName: string
    teamLead?: { displayName: string; id: string } | null
  },
  uid: string,
) => {
  try {
    await firestore
      .collection("teams")
      .doc(id)
      .update({ ...team, lastChanged: uid })
    successToast("Successfully updated Team")
  } catch (err) {
    errorToast("Failed to update Team")
    return
  }
}

export const deleteTeam = async (team: ITeams | null, uid: string) => {
  try {
    await firestore
      .collection("teams")
      .doc(team?.id)
      .set({ deleted: true, lastChanged: uid })
    successToast("Successfully deleted Team")
  } catch (err) {
    errorToast("Failed to delete Team")
    return
  }
}
