import React, { ChangeEvent } from "react"

import "./search-bar.styles.scss"

interface ISearchBar {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  defaultValue?: string
  placeholder?: string
  width?: string
}

const SearchBar: React.FunctionComponent<ISearchBar> = ({
  onChange,
  defaultValue,
  placeholder,
  width,
}) => (
  <input
    id="filter-search-bar"
    type="search"
    className="search-bar"
    placeholder={placeholder}
    onChange={onChange}
    defaultValue={defaultValue}
    style={{ width }}
  />
)

export default SearchBar
