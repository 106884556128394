import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IClient, IProject, IRateCard } from "./types"

interface IProjectsState {
  projects: IProject[] | null
  rateCards: IRateCard[] | null
  clients: IClient[] | null
  addProjectVisibility: boolean
  addClientVisibility: boolean
  addRateCardVisibility: boolean
  selectedProject: IProject | null
  selectedClient: IClient | null
  projectsFilter: string[]
  projectStatusFilter: string[]
  selectedRateCard: IRateCard | null
}

const initialState: IProjectsState = {
  projects: null,
  rateCards: null,
  clients: null,
  addProjectVisibility: false,
  addClientVisibility: false,
  addRateCardVisibility: false,
  projectsFilter: [],
  projectStatusFilter: [],
  selectedProject: null,
  selectedClient: null,
  selectedRateCard: null,
}

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    // projects
    setProjects: (state, action: PayloadAction<IProjectsState["projects"]>) => {
      return { ...state, projects: action.payload }
    },
    setRateCards: (
      state,
      action: PayloadAction<IProjectsState["rateCards"]>,
    ) => {
      return { ...state, rateCards: action.payload }
    },
    setSelectedProject: (
      state,
      action: PayloadAction<IProjectsState["selectedProject"]>,
    ) => {
      return { ...state, selectedProject: action.payload }
    },
    setSelectedRateCard: (
      state,
      action: PayloadAction<IProjectsState["selectedRateCard"]>,
    ) => {
      return { ...state, selectedRateCard: action.payload }
    },
    setAddProjectVisibility: (
      state,
      action: PayloadAction<IProjectsState["addProjectVisibility"]>,
    ) => {
      return { ...state, addProjectVisibility: action.payload }
    },
    setProjectsFilter: (
      state,
      action: PayloadAction<IProjectsState["projectsFilter"]>,
    ) => {
      return { ...state, projectsFilter: action.payload }
    },
    setProjectStatusFilter: (
      state,
      action: PayloadAction<IProjectsState["projectStatusFilter"]>,
    ) => {
      return { ...state, projectStatusFilter: action.payload }
    },
    // clients
    setClients: (state, action: PayloadAction<IProjectsState["clients"]>) => {
      return { ...state, clients: action.payload }
    },
    setSelectedClient: (
      state,
      action: PayloadAction<IProjectsState["selectedClient"]>,
    ) => {
      return { ...state, selectedClient: action.payload }
    },
    setAddClientVisibility: (
      state,
      action: PayloadAction<IProjectsState["addClientVisibility"]>,
    ) => {
      return { ...state, addClientVisibility: action.payload }
    },
    setAddRateCardVisibility: (
      state,
      action: PayloadAction<IProjectsState["addRateCardVisibility"]>,
    ) => {
      return { ...state, addRateCardVisibility: action.payload }
    },
  },
})

export const {
  setProjects,
  setRateCards,
  setSelectedProject,
  setProjectsFilter,
  setProjectStatusFilter,
  setAddProjectVisibility,
  setClients,
  setSelectedClient,
  setSelectedRateCard,
  setAddClientVisibility,
  setAddRateCardVisibility,
} = projectsSlice.actions

export const projectsReducer = projectsSlice.reducer
