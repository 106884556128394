import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"

import { updateTeam } from "../../firebase"
import { ITeams } from "../../redux"
import { IAccount } from "../../redux"
import RoleBased from "../role-based/role-based.component"
import Select from "react-select"

interface IUpdateTeam {
  selectedTeam: ITeams
  onUpdate?: () => void
  currentUser: IAccount
  roleManager: {
    userRoles: { [key: string]: string }
    hasRole: (permissionsRoles: string[]) => boolean
  }
  permissionRoles: string[]
  accounts: IAccount[] | null
}

const UpdateTeam: React.FunctionComponent<IUpdateTeam> = (props) => {
  const { accounts, selectedTeam } = props
  const [displayName, setDisplayName] = useState("")
  let prevDisplayName = useRef() as { current: string }
  const userOptions =
    accounts?.map((user) => ({
      label: user.displayName,
      value: user.id,
    })) ?? []
  const [user, setUser] = useState<{ label: string; value: string } | null>(
    selectedTeam.teamLead
      ? {
          label: selectedTeam.teamLead.displayName,
          value: selectedTeam.teamLead.id,
        }
      : null,
  )
  const handleUserChange = (event: any) => setUser(event)

  useEffect(() => {
    setUser(
      selectedTeam.teamLead
        ? {
            label: selectedTeam.teamLead.displayName,
            value: selectedTeam.teamLead.id,
          }
        : null,
    )
    if (prevDisplayName.current !== selectedTeam.displayName) {
      setDisplayName(selectedTeam.displayName)
      prevDisplayName.current = selectedTeam.displayName
    }
  }, [selectedTeam])

  const handleDisplayNameChange = (e: any) => {
    setDisplayName(e.target.value)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const { onUpdate, currentUser } = props

    if (!displayName) {
      return
    }

    updateTeam(
      props.selectedTeam.id,
      {
        displayName: displayName,
        teamLead: user ? { displayName: user.label, id: user.value } : null,
      },
      currentUser.id,
    )

    if (onUpdate) {
      onUpdate()
    }
  }

  const { roleManager, permissionRoles } = props

  const inputDisabled = !roleManager.hasRole(permissionRoles)

  return (
    <div>
      <div className="update-user-heading">
        <h2>Team</h2>
      </div>
      <form className="update-user-form" onSubmit={handleSubmit}>
        <label>Team Name: </label>
        <input
          id="update-team-name"
          type="text"
          value={displayName}
          onChange={handleDisplayNameChange}
          disabled={inputDisabled}
        />
        <label>Team lead: </label>
        <Select
          isClearable={true}
          options={userOptions}
          onChange={handleUserChange}
          value={user}
        />
        <div />
        <RoleBased roles={permissionRoles}>
          <input
            id="update-team-submit"
            type="submit"
            value="Update Team"
            className="submit-button"
          />
        </RoleBased>
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  roleManager: state.user.roleManager,
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(UpdateTeam)
