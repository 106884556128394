import React, { useEffect, useState } from "react"
import { connect, RootStateOrAny } from "react-redux"

import { updateClient } from "../../firebase"
import { IClient } from "../../redux"
import { IAccount } from "../../redux"
import RoleBased from "../role-based/role-based.component"

import "./update-client.styles.scss"

interface IUpdateClient {
  onUpdate: () => void
  currentUser: IAccount
  permissionRoles: string[]
  roleManager: {
    userRoles: { [key: string]: string }
    hasRole: (permissionsRoles: string[]) => boolean
  }
  selectedClient: IClient
}

const UpdateClient: React.FunctionComponent<IUpdateClient> = (props) => {
  const [displayName, setDisplayName] = useState("")

  const handleDisplayNameChange = (e: { target: { value: string } }) =>
    setDisplayName(e.target.value)

  useEffect(() => {
    setDisplayName(props.selectedClient.displayName)
  }, [props.selectedClient])

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    const { onUpdate, currentUser } = props

    if (!displayName) {
      return
    }

    updateClient(
      props.selectedClient.id,
      {
        displayName: displayName,
      },
      currentUser.id,
    )

    if (onUpdate) {
      onUpdate()
    }
  }

  const { permissionRoles, roleManager } = props

  const inputDisabled = !roleManager.hasRole(permissionRoles)

  return (
    <div>
      <div className="update-user-heading">
        <h2>Client</h2>
      </div>
      <form className="update-user-form" onSubmit={handleSubmit}>
        <label>Client Name: </label>
        <input
          id="update-client-name"
          type="text"
          value={displayName}
          onChange={handleDisplayNameChange}
          disabled={inputDisabled}
        />
        <div />
        <RoleBased roles={permissionRoles}>
          <input
            id="update-client-submit"
            type="submit"
            value="Update Client"
            className="submit-button"
          />
        </RoleBased>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootStateOrAny) => ({
  selectedClient: state.projects.selectedClient,
  roleManager: state.user.roleManager,
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(UpdateClient)
