// import { QueryDocumentSnapshot } from "@google-cloud/firestore";
import React from "react"
import extractEntryValues from "./extractEntryValues"

import { IDate, IDepartments, IDoc, ILocations, IUser } from "../../redux"
import { IClient, IProject } from "../../redux"
import { IAccount } from "../../redux"
import FormatClientData from "./FormatClientData"
import FormatDepartmentData from "./FormatDepartmentsData"
import FormatEntryData from "./FormatEntryData"
import FormatLocationData from "./FormatLocation"
import FormatProjectData from "./FormatProjectData"
import FormatUserData from "./FormatUserData"

const USER_CREATED = "userCreated"
const USER_UPDATED = "userUpdated"
const USER_DELETED = "userDeleted"
const DEPARTMENT_CREATED = "departmentCreated"
const DEPARTMENT_UPDATED = "departmentUpdated"
const DEPARTMENT_DELETED = "departmentDeleted"
const PROJECT_CREATED = "projectCreated"
const PROJECT_UPDATED = "projectUpdated"
const PROJECT_ARCHIVED = "projectArchived"
const PROJECT_DELETED = "projectDeleted"
const CLIENT_CREATED = "clientCreated"
const CLIENT_UPDATED = "clientUpdated"
const CLIENT_DELETED = "clientDeleted"
const ENTRY_CREATED = "entryCreated"
const ENTRY_UPDATED = "entryUpdated"
const ENTRY_DELETED = "entryDeleted"
const LOCATION_CREATED = "locationCreated"
const LOCATION_UPDATED = "locationUpdated"
const LOCATION_DELETED = "locationDeleted"

export interface IHistoryEntry {
  data: () => {
    action: string
    after: IClient & IUser & IDepartments & IProject & IDoc & ILocations
    before: IClient & IUser & IDepartments & IProject & IDoc & ILocations
    date: IDate
    user: { displayName: string }
  }
}

/**
 * Evaluates the history entry based on the action. This needs to be in sync with denormalizationUpdates.js.
 * The function returns JSX.
 * @param {QueryDocumentSnapshot} historyEntry - the history doc to evaluate
 */
const evaluateEntry = (
  historyEntry: IHistoryEntry,
  accounts: IAccount[] | null,
) => {
  if (!historyEntry) {
    return null
  }

  const entry = historyEntry.data()

  let output = {} as any
  output["date"] = entry.date.toDate().toLocaleString()

  output["user"] = (
    <span style={{ fontWeight: 700 }}>{entry.user.displayName} </span>
  )

  let action = "" as any
  let before = "" as any
  let after = "" as any

  const FONT_WEIGHT = 700
  const getUserName = (id: string) =>
    accounts?.[accounts?.findIndex((account) => account.id === id)]?.displayName
  const {
    user,
    action: extractedAction,
    entryType,
    name,
  } = extractEntryValues(entry, getUserName)
  switch (entry.action) {
    case USER_CREATED:
      action = (
        <span>
          created new <span style={{ fontWeight: FONT_WEIGHT }}>user: </span>{" "}
          {entry.after.firstName} {entry.after.lastName}
        </span>
      )

      after = FormatUserData(entry.after)

      break
    case USER_UPDATED:
      action = (
        <span>
          updated <span style={{ fontWeight: FONT_WEIGHT }}>user: </span>{" "}
          {entry.after.firstName} {entry.after.lastName}
        </span>
      )
      before = FormatUserData(entry.before)
      after = FormatUserData(entry.after)
      break
    case USER_DELETED:
      action = (
        <span>
          deleted <span style={{ fontWeight: FONT_WEIGHT }}>user: </span>{" "}
          {entry.before.firstName} {entry.before?.lastName}
        </span>
      )
      before = FormatUserData(entry.before)
      break
    case DEPARTMENT_CREATED:
      action = (
        <span>
          created new{" "}
          <span style={{ fontWeight: FONT_WEIGHT }}>department: </span>{" "}
          {entry.after.displayName}
        </span>
      )
      after = FormatDepartmentData(entry.after)
      break
    case DEPARTMENT_UPDATED:
      action = (
        <span>
          updated <span style={{ fontWeight: FONT_WEIGHT }}>department: </span>{" "}
          {entry.after.displayName}
        </span>
      )
      before = FormatDepartmentData(entry.before)
      after = FormatDepartmentData(entry.after)

      break
    case DEPARTMENT_DELETED:
      action = (
        <span>
          deleted <span style={{ fontWeight: FONT_WEIGHT }}>department: </span>{" "}
          {entry.before.displayName}
        </span>
      )
      before = FormatDepartmentData(entry.before)
      break
    case PROJECT_CREATED:
      action = (
        <span>
          created <span style={{ fontWeight: FONT_WEIGHT }}>project: </span>{" "}
          {entry.after.displayName}
        </span>
      )
      after = FormatProjectData(entry.after)
      break
    case PROJECT_UPDATED:
      action = (
        <span>
          updated <span style={{ fontWeight: FONT_WEIGHT }}>project: </span>{" "}
          {entry.after.displayName}
        </span>
      )
      before = FormatProjectData(entry.before)
      after = FormatProjectData(entry.after)
      break
    case PROJECT_ARCHIVED:
      action = (
        <span>
          archived <span style={{ fontWeight: FONT_WEIGHT }}>project: </span>{" "}
          {entry.after.displayName}
        </span>
      )
      before = FormatProjectData(entry.before)
      after = FormatProjectData(entry.after)
      break
    case PROJECT_DELETED:
      action = action = (
        <span>
          deleted <span style={{ fontWeight: FONT_WEIGHT }}>project: </span>{" "}
          {entry.before.displayName}
        </span>
      )
      before = FormatProjectData(entry.before)
      break
    case CLIENT_CREATED:
      action = (
        <span>
          created <span style={{ fontWeight: FONT_WEIGHT }}>client: </span>{" "}
          {entry.after.displayName}
        </span>
      )
      after = FormatClientData(entry.after)
      break
    case CLIENT_UPDATED:
      action = (
        <span>
          updated <span style={{ fontWeight: FONT_WEIGHT }}>client: </span>{" "}
          {entry.after.displayName}
        </span>
      )
      before = FormatClientData(entry.before)
      after = FormatClientData(entry.after)
      break
    case CLIENT_DELETED:
      action = (
        <span>
          deleted <span style={{ fontWeight: FONT_WEIGHT }}>client: </span>{" "}
          {entry.before.displayName}
        </span>
      )
      before = FormatClientData(entry.before)
      break
    case ENTRY_CREATED:
      output.user = <span style={{ fontWeight: 700 }}>{user} </span>
      action = (
        <span>
          {extractedAction}{" "}
          <span style={{ fontWeight: FONT_WEIGHT }}>{entryType}</span> {name}
        </span>
      )
      after = FormatEntryData(entry.after)
      break
    case ENTRY_UPDATED:
      output.user = <span style={{ fontWeight: 700 }}>{user} </span>
      action = (
        <span>
          {extractedAction}{" "}
          <span style={{ fontWeight: FONT_WEIGHT }}>{entryType}</span> {name}
        </span>
      )
      before = FormatEntryData(entry.before)
      after = FormatEntryData(entry.after)
      break
    case ENTRY_DELETED:
      output.user = <span style={{ fontWeight: 700 }}>{user} </span>
      action = entry.before ? (
        <span>
          {extractedAction}{" "}
          <span style={{ fontWeight: FONT_WEIGHT }}>{entryType}</span> {name}
        </span>
      ) : (
        <span>{extractedAction}</span>
      )
      before = entry.before ? FormatEntryData(entry.before) : ""
      break
    case LOCATION_CREATED:
      action = (
        <span>
          created <span style={{ fontWeight: FONT_WEIGHT }}>location: </span>{" "}
          {entry.after.displayName}
        </span>
      )
      after = FormatLocationData(entry.after)
      break
    case LOCATION_UPDATED:
      action = (
        <span>
          updated <span style={{ fontWeight: FONT_WEIGHT }}>location: </span>{" "}
          {entry.after.displayName}
        </span>
      )
      before = FormatLocationData(entry.before)
      after = FormatLocationData(entry.after)

      break
    case LOCATION_DELETED:
      action = (
        <span>
          deleted <span style={{ fontWeight: FONT_WEIGHT }}>location: </span>{" "}
          {entry.before.displayName}
        </span>
      )
      before = FormatLocationData(entry.before)
      break

    default:
      break
  }

  output["action"] = action
  output["before"] = before
  output["after"] = after
  return {
    date: output.date,
    action: (
      <div>
        {output.user} {output.action}
      </div>
    ),
    before: output.before,
    after: output.after,
  }
}

const historyEntry = ({
  historyEntry,
  accounts,
}: {
  historyEntry: IHistoryEntry
  accounts: IAccount[] | null
}) => {
  return evaluateEntry(historyEntry, accounts)
}

export default historyEntry
