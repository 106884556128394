import React, { useState, useEffect } from "react"
import { firestore } from "../../firebase"

import ProjectsList from "./projects-list.component"
import { reduceToProjects } from "../../AuthenticatedApp"
import { IProject } from "../../redux"

const archivedProjectsCollectionDesc = firestore.collection("archivedProjects")

interface IArchivedProjectsList {
  filter: string
}

const ArchivedProjectsList: React.FunctionComponent<IArchivedProjectsList> = ({
  filter,
}) => {
  const [archivedProjects, setArchivedProjects] = useState<IProject[]>([])
  // load initial history on mount
  useEffect(() => {
    return archivedProjectsCollectionDesc.onSnapshot(async (snapshot) => {
      setArchivedProjects(
        snapshot.docs.reduce(reduceToProjects, [] as IProject[]),
      )
    })
  }, [])

  return <ProjectsList filter={filter} projects={archivedProjects} />
}

export default ArchivedProjectsList
