import React from "react"

import "./sidebar.styles.scss"
import { createPortal } from "react-dom"

interface ISidebar {
  position?: string
  width?: string
}

const Sidebar: React.FunctionComponent<ISidebar> = (props) => {
  const { position, width, children } = props
  return createPortal(
    <div className={"sidebar " + position} style={width ? { width } : {}}>
      {children}
    </div>,
    document.body,
  )
}

export default Sidebar
