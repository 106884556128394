import { DateRange, RangeWithKey } from "react-date-range"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import "./date-range-selector.scss"

interface IDateRangeSelector {
  range: RangeWithKey[]
  setRange: (range: RangeWithKey[]) => void
  onClickOutside: (clickedOutside: boolean) => void
}

const DateRangeSelector: React.FC<IDateRangeSelector> = ({
  range,
  setRange,
  onClickOutside,
}) => {
  return (
    <>
      <div className="date-backdrop" onClick={() => onClickOutside(false)} />
      <div className="date-range">
        <DateRange
          editableDateInputs={true}
          ranges={range}
          onChange={(e: any) => {
            if (range?.[0]?.endDate) {
              if (
                e.selection.startDate === e.selection.endDate &&
                e.selection.startDate < range[0].endDate
              ) {
                setRange([{ ...e.selection, endDate: range[0].endDate }])
              } else {
                setRange([e.selection])
              }
            } else {
              setRange([e.selection])
            }
          }}
        />
      </div>
    </>
  )
}

export default DateRangeSelector
