import { IProject } from "../../../../redux"
import ProjectListItem from "./ProjectListItem"
import ProjectTitleListItem from "./ProjectTitleListItem"
import { VFC } from "react"

interface IProjectSideView {
  projects: IProject[] | null
}

export const HEADCOUNT_TITLES = [
  "Occupation Rate Real",
  "Total Headcount",
  "Allocated Headcount",
]

const ProjectSideView: VFC<IProjectSideView> = ({ projects }) => {
  return (
    <div className="booked-ratio-user-list">
      {HEADCOUNT_TITLES.map((title) => (
        <ProjectTitleListItem key={"list" + title} title={title} />
      ))}
      {projects?.map((project) => (
        <ProjectListItem key={"list" + project.id} project={project} />
      ))}
    </div>
  )
}

export default ProjectSideView
