import React from "react"
import { ILocations } from "../../redux"

const FormatLocationData: React.FC<ILocations> = (locationData) => (
  <table id="nested-table">
    <thead>
      <tr>
        <th>Name:</th>
        <td>{locationData.displayName}</td>
      </tr>
      <tr>
        <th>Bank Holidays:</th>
        <td>
          <table>
            <tbody>
              {locationData.bankHolidays
                ? locationData.bankHolidays.map((bankHoliday, key) => (
                    <tr key={key}>
                      <td>{bankHoliday.displayName}</td>
                      <td>{bankHoliday.date}</td>
                    </tr>
                  ))
                : "none"}
            </tbody>
          </table>
        </td>
      </tr>
    </thead>
  </table>
)

export default FormatLocationData
