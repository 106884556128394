import React, { VFC } from "react"
import { Tooltip } from "@material-ui/core"
import { roundToTwoDecimal } from "../../../../utils"

export const AllocatedHeadcountTooltip: VFC<{
  headcountPerMonth: Record<string, number>
}> = ({ headcountPerMonth }) => (
  <Tooltip
    title={
      <ul className="tooltip-list">
        {Object.keys(headcountPerMonth).map((department) => (
          <li>
            {department}: {roundToTwoDecimal(headcountPerMonth[department])}
          </li>
        ))}
      </ul>
    }
    arrow>
    <div className="tooltip-helper" />
  </Tooltip>
)

export const TotalHeadcountTooltip: VFC<{
  headcountPerDepartment: Record<string, number>
}> = ({ headcountPerDepartment }) => (
  <Tooltip
    title={
      <ul className="tooltip-list">
        {Object.keys(headcountPerDepartment).map((department) => {
          const headcount = headcountPerDepartment[department]
          if (headcount === 0) {
            return null
          }

          return (
            <li>
              {department}: {roundToTwoDecimal(headcount)}
            </li>
          )
        })}
      </ul>
    }
    arrow>
    <div className="tooltip-helper" />
  </Tooltip>
)
