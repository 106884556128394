import React, { useEffect, useState } from "react"

import { auth } from "../../firebase"

import HeaderLink from "./header-link/header-link.component"
import Logo from "../logo/logo.component"
import { Icon } from "@iconify/react"
import notificationIcon from "@iconify/icons-carbon/notification"
import "./header.styles.scss"
import NotificationsPopUp from "./NotificationsPopUp"
import { flipBoolean } from "../../utils"
import { IAccount, IDoc, IRole, ITeams, IUser, RootState } from "../../redux"
import { connect } from "react-redux"
import { filterPendingEntries } from "../../pages/pending-approvals/utils/filterPendingEntries"

interface IHeader {
  pendingEntries: IDoc[] | null
  currentUser: IAccount | null
  users: IUser[]
  teams: ITeams[]
  currentUserRole: IRole
}

const Header: React.FC<IHeader> = ({
  pendingEntries,
  currentUser,
  users,
  teams,
  currentUserRole,
}) => {
  const [showPopUp, setShowPopUp] = useState(false)
  const [pendingApprovalsNumber, setPendingApprovalsNumber] = useState(0)
  const [filteredPendingEntries, setFilteredPendingEntries] = useState<IDoc[]>(
    [],
  )

  const signOut = () => auth.signOut()
  const togglePopUp = () => setShowPopUp(flipBoolean)

  useEffect(() => {
    if (!pendingEntries || !currentUser) {
      return
    }

    const tmpFilteredEntries = filterPendingEntries(
      pendingEntries,
      currentUser,
      currentUserRole,
      teams,
      users,
    )

    setFilteredPendingEntries(tmpFilteredEntries ?? [])
    setPendingApprovalsNumber(tmpFilteredEntries?.length ?? 0)
  }, [pendingEntries])

  return (
    <div>
      <div className="header">
        <div className="header-logo">
          <Logo />
        </div>
        <HeaderLink link="/timesheets">Timesheets</HeaderLink>
        <HeaderLink link="/capacities">Capacities</HeaderLink>
        <HeaderLink link="/projects">Projects</HeaderLink>
        <HeaderLink link="/calendar">Calendar</HeaderLink>
        <HeaderLink link="/settings">Settings</HeaderLink>
        {/*<a href={getAtlassianSignInUrl()}>Atlassian</a>*/}
        <div className="notification-icon-wrapper">
          {showPopUp && (
            <NotificationsPopUp
              showPopUp={setShowPopUp}
              pendingEntries={filteredPendingEntries}
            />
          )}
          <div className="header-pending-number-oval" onClick={togglePopUp}>
            <div className="header-pending-number">
              {pendingApprovalsNumber}
            </div>
          </div>
          <div className="notification-icon" onClick={togglePopUp}>
            <Icon icon={notificationIcon} />
          </div>
        </div>
        <div className="header-sign-out" onClick={signOut}>
          Sign out
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  pendingEntries: state.calendar.pendingEntries,
  currentUser: state.user.currentUser,
  teams: state.calendar.teams,
  users: state.calendar.users,
  currentUserRole: state.user.roles,
})

export default connect(mapStateToProps)(Header)
