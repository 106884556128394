import { IRate } from "../redux"
import { IRateCard as IRateCardAction } from "../redux"
import { errorToast, firestore, successToast } from "./firebase.utils"

interface IRateCard {
  displayName?: string
  rates?: IRate[]
}

export const addRateCard = async (rateCard: IRateCard, uid: string) => {
  try {
    await firestore
      .collection("rateCards")
      .add({ ...rateCard, lastChanged: uid })
    successToast("Successfully added rate card")
  } catch (err) {
    errorToast("Failed to add rate card")
    return
  }
}

export const updateRateCard = async (
  id: string,
  rateCard: IRateCard,
  uid: string,
) => {
  try {
    await firestore
      .collection("rateCards")
      .doc(id)
      .update({ ...rateCard, lastChanged: uid })
    successToast("Successfully updated rate card")
  } catch (err) {
    errorToast("Failed to update rate card")
    return
  }
}

export const deleteRateCard = async (
  rateCard: IRateCardAction,
  uid: string,
) => {
  try {
    await firestore
      .collection("rateCards")
      .doc(rateCard.id)
      .set({ deleted: true, lastChanged: uid })
    successToast("Successfully deleted rate card")
  } catch (err) {
    errorToast("Failed to delete rate card")
    return
  }
}
