import { IClient } from "../redux"
import { errorToast, firestore, successToast } from "./firebase.utils"

export const addClient = async (
  client: { displayName: string },
  uid: string,
) => {
  try {
    await firestore.collection("clients").add({ ...client, lastChanged: uid })
    successToast("Successfully added Client")
  } catch (err) {
    errorToast("Failed to add Client")
    return
  }
}

export const updateClient = async (
  id: string,
  client: { displayName: string },
  uid: string,
) => {
  try {
    await firestore
      .collection("clients")
      .doc(id)
      .update({ ...client, lastChanged: uid })
    successToast("Successfully updated Client")
  } catch (err) {
    errorToast("Failed to update Client")
    return
  }
}

export const deleteClient = async (client: IClient, uid: string) => {
  try {
    await firestore
      .collection("clients")
      .doc(client.id)
      .set({ deleted: true, lastChanged: uid })
    successToast("Successfully deleted Client")
  } catch (err) {
    errorToast("Failed to delete Client")
    return
  }
}
