import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { updateProject } from "../../firebase"
import { IRateCard } from "../../redux"
import { IProject } from "../../redux"
import { IAccount } from "../../redux"
import RoleBased from "../role-based/role-based.component"
import { RangeWithKey } from "react-date-range"
import DateRangeSelector from "../date-range-selector/DateRangeSelector"

import "./update-project.styles.scss"

interface IUpdateProject {
  onUpdate: () => void
  currentUser: IAccount
  rateCards: IRateCard[]
  permissionRoles: string[]
  roleManager: {
    userRoles: { [key: string]: string }
    hasRole: (permissionsRoles: string[]) => boolean
  }
  selectedProject: IProject
}

const UpdateProject: React.FunctionComponent<IUpdateProject> = (props) => {
  const { permissionRoles, roleManager, rateCards } = props
  const [rateCard, setRateCard] = useState<{
    value: string
    label: string
  } | null>(null)
  const [displayDateRange, setDisplayDateRange] = useState(false)
  const [dateRange, setDateRange] = useState<RangeWithKey[]>([
    {
      startDate: props.selectedProject?.startDate?.toDate(),
      endDate: props.selectedProject?.endDate?.toDate(),
      key: "selection",
    },
  ])
  const [displayName, setDisplayName] = useState(
    props.selectedProject.displayName,
  )

  useEffect(() => {
    const rateCardName = props.rateCards.find(
      (rateCard) => rateCard.id === props.selectedProject.rateCard,
    )?.displayName

    setRateCard(
      props.selectedProject.rateCard && rateCardName
        ? {
            value: props.selectedProject.rateCard,
            label: rateCardName,
          }
        : null,
    )

    setDisplayName(props.selectedProject.displayName)
  }, [props.selectedProject, props.rateCards])

  const handleDisplayNameChange = (e: { target: { value: string } }) =>
    setDisplayName(e.target.value)

  const handleRateCardChange = (e: { value: string; label: string } | null) =>
    setRateCard(e)

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    const { onUpdate, currentUser } = props

    if (!displayName) {
      return
    }

    const projectObject: any = {
      displayName: displayName,
      rateCard: rateCard ? rateCard.value : null,
    }

    updateProject(props.selectedProject.id, projectObject, currentUser.id)

    if (onUpdate) {
      onUpdate()
    }
  }

  const rateCardOptions = rateCards
    ? rateCards.map((rateCard) => ({
        label: rateCard.displayName,
        value: rateCard.id,
      }))
    : []

  const inputDisabled = !roleManager.hasRole(permissionRoles)

  return (
    <div>
      {displayDateRange && (
        <div className="calendar-range">
          <DateRangeSelector
            range={dateRange}
            setRange={setDateRange}
            onClickOutside={setDisplayDateRange}
          />
        </div>
      )}
      <div className="update-user-heading">
        <h2>Project</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="update-project-form">
          <label>Project Name: </label>
          <input
            id="update-project-name"
            type="text"
            value={displayName}
            onChange={handleDisplayNameChange}
            disabled={inputDisabled}
          />
          <label>Rate Card: </label>
          <RoleBased roles={permissionRoles}>
            <Select
              id="update-project-select-rate-card"
              options={rateCardOptions}
              onChange={handleRateCardChange}
              isClearable={true}
              value={rateCard}
              isDisabled={inputDisabled}
            />
          </RoleBased>
          <RoleBased roles={permissionRoles}>
            <input
              id="update-project-submit"
              type="submit"
              value="Update Project"
              className="submit-button"
            />
          </RoleBased>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  rateCards: state.projects.rateCards,
  selectedProject: state.projects.selectedProject,
  roleManager: state.user.roleManager,
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(UpdateProject)
