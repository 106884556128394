import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import { toast, ToastPosition } from "react-toastify"

require("firebase/functions")

export type QuerySnapshot<T> = firebase.firestore.QuerySnapshot<T>
export type Query<T> = firebase.firestore.Query<T>
export type DocumentData = firebase.firestore.DocumentData
export const FieldValue = firebase.firestore.FieldValue

export enum EFirestoreCollection {
  ENTRIES = "entries",
}

const toastOptions = {
  position: "bottom-right" as ToastPosition,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export const successToast = (text: string) => toast.success(text, toastOptions)
export const errorToast = (text: string) => toast.error(text, toastOptions)

const firebaseConfig = {
  apiKey: "AIzaSyAgNg70OFIRYLgEiTkNAAIgdICkpFBzNHY",
  authDomain: "resource-planning-bd470.firebaseapp.com",
  projectId: "resource-planning-bd470",
  storageBucket: "resource-planning-bd470.appspot.com",
  messagingSenderId: "643164742244",
  appId: "1:643164742244:web:37375fe2962817d3df3571",
  measurementId: "G-YDNPJ15EBN",
}

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const functions = firebase.app().functions("europe-west1")

if (window.location.hostname === "localhost") {
  firestore.useEmulator("localhost", 8080)
  functions.useEmulator("localhost", 5001)
}

const provider = new firebase.auth.OAuthProvider("microsoft.com")
provider.setCustomParameters({
  tenant: "97dd2a9e-2b6e-423f-a761-55d719a94278",
})

export const signInWithMicrosoft = () => auth.signInWithPopup(provider)

// Generic functionality to add data to a collection
export const addCollectionAndDocuments = async (
  collectionKey: any,
  objectsToAdd: any,
) => {
  const collectionRef = firestore.collection(collectionKey)

  const batch = firestore.batch()
  objectsToAdd.forEach((obj: any) => {
    const newDocRef = collectionRef.doc()
    batch.set(newDocRef, obj)
  })

  return await batch.commit()
}
