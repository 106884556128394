import React from "react"
import { CSVLink } from "react-csv"

const CSVDownload = ({
  csvData,
  fileName,
  children,
}: {
  csvData: any[][]
  fileName: string
  children: React.ReactNode
}) => {
  return (
    <CSVLink data={csvData} filename={fileName}>
      {children}
    </CSVLink>
  )
}

export default CSVDownload
