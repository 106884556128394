import React from "react"
import { DAYS } from "../../data/calendar-data"
import { IDepartments, ILocations } from "../../redux"

interface IFormatUserData {
  firstName: string
  lastName: string
  email: string
  department?: IDepartments | undefined
  location?: ILocations | undefined
  availability: number[] | null
}

const FormatUserData: React.FC<IFormatUserData> = (userData) => (
  <span>
    <table id="nested-table">
      <thead>
        <tr>
          <th>First Name:</th>
          <td>{userData.firstName}</td>
        </tr>
        <tr>
          <th>Last Name:</th>
          <td>{userData.lastName}</td>
        </tr>
        <tr>
          <th>E-Mail:</th>
          <td>{userData.email}</td>
        </tr>
        <tr>
          <th>Department:</th>
          <td>
            {userData.department ? userData.department.displayName : "none"}
          </td>
        </tr>
        <tr>
          <th>Location:</th>
          <td>{userData.location ? userData.location.displayName : "none"}</td>
        </tr>
        <tr>
          <th>Availability:</th>
          <td>
            <span style={{ display: "flex" }}>
              {userData.availability
                ? userData.availability.map((availability, key) => (
                    <div key={key} style={{ margin: "5px" }}>
                      <div>{DAYS[key]}</div>
                      <div>{availability}</div>
                    </div>
                  ))
                : "none"}
            </span>
          </td>
        </tr>
      </thead>
    </table>
  </span>
)

export default FormatUserData
