const atlassianConfig = {
  clientId:
    window.location.hostname === "localhost"
      ? "TblQwdh0UF37Q6OyZxDhUPT2lkH2o1QM"
      : "Hfuue47I9AB7q6Q4CY0kHyydal5R1DxT",
  // To do: add Scope here
}

function getRedirectUri() {
  if (window.location.hostname === "localhost") {
    return encodeURIComponent("http://localhost:3000/atlassian")
  } else {
    return encodeURIComponent("https://rp.realworld-one.com/atlassian")
  }
}

export const atlassianBaseUrl = "https://realworld-one.atlassian.net/"

export const getAtlassianSignInUrl = () => {
  const { clientId } = atlassianConfig
  const YOUR_USER_BOUND_VALUE = "none" // Not sure about the use case. Seems like this value gets passed back. Could be used to redirect to the right place?

  return `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work%20write%3Ajira-work&redirect_uri=${getRedirectUri()}&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent`
}

export const getAtlassianCode = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get("code")
}
