import React from "react"
import { connect } from "react-redux"
import { IDoc } from "../../redux"
import { getDifferenceInDays } from "../../utils"

import CalendarEntry from "../calendar-entry/calendar-entry.component"

import "./calendar-entries-container.styles.scss"

interface ICalendarEntriesContainer {
  entry: IDoc
  startDate: Date
  animationClassName: string
  displayedDays: number
}

const CalendarEntriesContainer: React.FunctionComponent<ICalendarEntriesContainer> =
  ({ entry, startDate, animationClassName, displayedDays }) => {
    if (!entry) {
      return null
    }

    const endDateDifference = getDifferenceInDays(startDate, entry.endDate) + 1

    const endNumber =
      displayedDays + 1 < endDateDifference
        ? displayedDays + 1
        : endDateDifference + 1

    const startDateDifference = getDifferenceInDays(startDate, entry.startDate)

    const startNumber = startDateDifference < 0 ? 1 : startDateDifference + 1
    return (
      <div className={"calendar-entries-container " + animationClassName}>
        <div
          style={{
            gridColumnStart: startNumber,
            gridColumnEnd: endNumber,
            gridRow: 1,
          }}>
          <CalendarEntry entry={entry} />
        </div>
      </div>
    )
  }

const mapStateToProps = (state: any) => ({
  startDate: state.calendar.startDate,
  animationClassName: state.calendar.animationClassName,
  displayedDays: state.calendar.displayedDays,
})

export default connect(mapStateToProps)(CalendarEntriesContainer)
