import React from "react"
import { IClient } from "../../redux"

const FormatClientData: React.FC<IClient> = (clientData) => (
  <table id="nested-table">
    <thead>
      <tr>
        <th>Name:</th>
        <td>{clientData.displayName}</td>
      </tr>
    </thead>
  </table>
)

export default FormatClientData
