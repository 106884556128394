import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import Locations from "../../components/locations/locations.component"
import Projects from "../../components/projects/projects.component"
import RateCardsPage from "../rate-cards/rate-cards-page.component"
import SidebarLink from "../../components/sidebar-link/sidebar-link.component"
import Sidebar from "../../components/sidebar/sidebar-component"
import Users from "../../components/users/users.component"
import Clients from "../../components/clients/clients.component"
import Departments from "../../components/departments/departments.component"
import Teams from "../../components/teams/teams.component"

import "./settings.styles.scss"
import HistoryPage from "../history/history.component"
import AdminView from "../../components/admin-view/AdminView"
import { IAccount, IRole } from "../../redux"
import PendingApprovals from "../pending-approvals/PendingApprovals"
import { IDoc } from "../../redux"

const SettingsPage: React.FunctionComponent<{
  currentUserRoles: IRole | undefined
  pendingEntries: IDoc[] | null
  accounts: IAccount[] | null
  currentUser: IAccount
}> = ({ currentUserRoles, currentUser, pendingEntries, accounts }) => {
  return (
    <div className="settings-page">
      <Sidebar>
        <SidebarLink to="/settings/users">Users</SidebarLink>
        <SidebarLink to="/settings/projects">Projects</SidebarLink>
        <SidebarLink to="/settings/clients">Clients</SidebarLink>
        <SidebarLink to="/settings/departments">Departments</SidebarLink>
        <SidebarLink to="/settings/teams">Teams</SidebarLink>
        <SidebarLink to="/settings/locations">Locations</SidebarLink>
        {currentUserRoles?.superadmin && (
          <SidebarLink to="/settings/rate-cards">Rate Cards</SidebarLink>
        )}
        {(currentUserRoles?.admin || currentUserRoles?.superadmin) && (
          <SidebarLink to="/settings/history">History</SidebarLink>
        )}
        {currentUserRoles?.superadmin && (
          <SidebarLink to="/settings/admin">Admin Settings</SidebarLink>
        )}
        {(currentUserRoles?.admin || currentUserRoles?.superadmin) && (
          <>
            <SidebarLink to="/settings/pending-approvals">
              Pending approvals
            </SidebarLink>
          </>
        )}
      </Sidebar>
      <div />
      <div className="settings-body">
        <Switch>
          <Route exact path={"/settings"}>
            <Redirect to={"settings/users"} />
          </Route>
          <Route path={"/settings/users"}>
            <Users />
          </Route>
          <Route path={"/settings/projects"}>
            <Projects />
          </Route>
          <Route path={"/settings/clients"}>
            <Clients />
          </Route>
          <Route path={"/settings/departments"}>
            <Departments />
          </Route>
          <Route path={"/settings/teams"}>
            <Teams />
          </Route>
          <Route path={"/settings/locations"}>
            <Locations />
          </Route>
          {currentUserRoles?.superadmin && (
            <Route path={"/settings/rate-cards"}>
              <RateCardsPage />
            </Route>
          )}
          {(currentUserRoles?.admin || currentUserRoles?.superadmin) && (
            <Route path={"/settings/history"}>
              <HistoryPage accounts={accounts} />
            </Route>
          )}
          {currentUserRoles?.superadmin && (
            <Route path={"/settings/admin"}>
              <AdminView />
            </Route>
          )}
          {(currentUserRoles?.admin || currentUserRoles?.superadmin) && (
            <>
              <Route path={"/settings/pending-approvals"}>
                <PendingApprovals
                  accounts={accounts}
                  pendingEntries={pendingEntries}
                  currentUser={currentUser}
                  isSuperAdmin={!!currentUserRoles.superadmin}
                />
              </Route>
            </>
          )}
        </Switch>
      </div>
    </div>
  )
}

export default SettingsPage
