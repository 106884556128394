import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { IDepartments, ITeams, IUser } from "../../../redux"
import "./teams-chart.styles.scss"

interface ITeamsChart {
  team: ITeams
  users: IUser[]
  departments: IDepartments[]
}

const NO_DEPARTMENT = "No Department"
type Pod = Record<IDepartments["displayName"], IUser[]>

const TeamChart: React.FunctionComponent<ITeamsChart> = ({
  team,
  users,
  departments,
}) => {
  const [pod, setPod] = useState<Pod>({})

  useEffect(() => {
    let tmpPod: Pod = { NO_DEPARTMENT: [] }
    departments.forEach((department) => {
      tmpPod[department.displayName] = []
    })

    users.forEach((user) => {
      tmpPod[
        user.department ? user.department.displayName : NO_DEPARTMENT
      ].push(user)
    })

    setPod(tmpPod)
  }, [departments, users])

  const hashCode = (str: string) => {
    // java String#hashCode
    var hash = 0
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }

  const intToRGB = (i: number) => {
    var c = (i & 0x00ffffff).toString(16).toUpperCase()

    return "00000".substring(0, 6 - c.length) + c
  }

  return (
    <div>
      <div>
        <h3>{team.displayName + " (" + users.length + ")"}</h3>
      </div>
      <div className="chart-department">
        {Object.keys(pod).map((department, i) => (
          <div
            className="chart-column"
            key={i}
            style={{
              backgroundColor: i % 2 ? "rgba(0,0,0,0.03)" : "transparent",
            }}>
            <div className="chart-department-heading">
              <span>{department + " (" + pod[department].length + ")"}</span>
            </div>
            {pod[department].map((user, k) => {
              return (
                <div
                  className="chart-user"
                  style={{
                    backgroundColor: "#" + intToRGB(hashCode(department)),
                  }}
                  key={k}>
                  <p>{user.firstName + " " + user.lastName}</p>
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  departments: state.calendar.departments,
})

export default connect(mapStateToProps)(TeamChart)
