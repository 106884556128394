import React, { ChangeEvent, useMemo, useState, VFC } from "react"

import AddLocation from "../add-location/add-location.component"
import CustomButton from "../custom-button/custom-button.component"
import SearchBar from "../search-bar/search-bar.component"
import { deleteLocation } from "../../firebase"
import Sidebar from "../sidebar/sidebar-component"
import DropdownMenu from "../dropdown-menu/dropdown-menu.component"
import CustomButtonDropdown from "../custom-button-dropdown/custom-button-dropdown.component"
import UpdateLocation from "../update-location/update-location.component"
import CancelButton from "../cancel-button/cancel-button.component"
import { setSelectedLocation, useAppDispatch, useSelector } from "../../redux"
import RoleBased from "../role-based/role-based.component"
import { ROLES, tableDefaultOptions } from "../../data/default-data"

import "./locations.styles.scss"
import MaterialTable from "material-table"

const Locations: VFC = () => {
  const dispatch = useAppDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)
  const locations = useSelector((state) => state.calendar.locations)
  const selectedLocation = useSelector(
    (state) => state.calendar.selectedLocation,
  )
  const roleManager = useSelector((state) => state.user.roleManager)

  const [visibility, setVisibility] = useState(false)
  const [locationFilter, setLocationFilter] = useState("")

  const onCancel = () => setVisibility(!visibility)

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocationFilter(e.target.value)
  }

  const closeSidebar = () => {
    dispatch(setSelectedLocation(null))
  }

  const handleDeleteLocation = () => {
    if (!selectedLocation || !currentUser) {
      return
    }

    deleteLocation(selectedLocation, currentUser.id)
    closeSidebar()
  }

  const filteredLocations = useMemo(() => {
    if (!locations) {
      return []
    }

    return locations.filter(({ displayName }) =>
      displayName.toLowerCase().includes(locationFilter.toLowerCase()),
    )
  }, [locationFilter, locations])

  const permissionRoles = [ROLES.superadmin]

  return (
    <span>
      {currentUser && selectedLocation ? (
        <Sidebar position="right" width="500px">
          <div className="sidebar-cancel-button">
            <div className="sidebar-dropdown-menu">
              <RoleBased roles={permissionRoles}>
                <DropdownMenu>
                  <CustomButtonDropdown onClick={handleDeleteLocation}>
                    Delete Location
                  </CustomButtonDropdown>
                </DropdownMenu>
              </RoleBased>
            </div>
            <CancelButton onCancel={closeSidebar} />
          </div>
          <UpdateLocation
            onUpdate={closeSidebar}
            selectedLocation={selectedLocation}
            currentUser={currentUser}
            permissionRoles={permissionRoles}
            roleManager={roleManager}
          />
        </Sidebar>
      ) : null}
      {visibility ? <AddLocation onCancel={onCancel} /> : null}
      <div className="users-header">
        <h2>Locations</h2>
        <RoleBased roles={permissionRoles}>
          <CustomButton onClick={onCancel}>Add Location</CustomButton>
        </RoleBased>
        <SearchBar
          placeholder="Find locations..."
          defaultValue={locationFilter}
          onChange={handleFilterChange}
          width="80%"
        />
        <div>Total: {locations ? locations.length : 0}</div>
      </div>

      <div className="users-list">
        <MaterialTable
          columns={[{ title: "Location", field: "location" }]}
          data={
            filteredLocations.map((location, key) => ({
              location: location.displayName,
              tableData: { id: key },
            })) ?? []
          }
          onRowClick={(
            event,
            data: { location: string; tableData: { id: number } } | undefined,
          ) =>
            data &&
            dispatch(setSelectedLocation(filteredLocations[data.tableData.id]))
          }
          options={tableDefaultOptions}
        />
      </div>
    </span>
  )
}

export default Locations
