import { DEFAULT_AVAILABILITY } from "../../../data/default-data"
import {
  differenceInBusinessDays,
  eachMonthOfInterval,
  endOfMonth,
  endOfYear,
  getMonth,
  startOfMonth,
} from "date-fns"
import { IDoc, IUser } from "../../../redux"
import getBusinessDays from "./getBusinessDays"
import { entryIsInTimeframe } from "../../../utils"

const getHeadcountPerMonth = (
  entries: IDoc[],
  users: IUser[],
  startDate: Date,
) => {
  const departmentsPerMonth: {
    [key: string]: { userIds: string[]; count: number }
  }[] = Array(12).fill({})

  const entriesPerUser = users.map((user) =>
    entries.filter((entry) => entry.user.id === user.id),
  )

  entriesPerUser.forEach((entriesForUser) => {
    entriesForUser.forEach((entry) => {
      const entryRatio = entry.bookingRatio
      const entryStart = entry.startDate
      const entryEnd = entry.endDate

      if (entryStart > entryEnd) {
        return
      }
      const earliestStart =
        entryStart.getFullYear() < startDate.getFullYear()
          ? startOfMonth(startDate)
          : startOfMonth(entryStart)
      const latestEntryEnding =
        entryEnd.getFullYear() > startDate.getFullYear()
          ? endOfYear(startDate)
          : entryEnd

      const entryInMonths = eachMonthOfInterval({
        start: earliestStart,
        end: latestEntryEnding,
      })

      entryInMonths.forEach((entryInMonth) => {
        const month = getMonth(entryInMonth)
        const businessDays = getBusinessDays(
          earliestStart,
          latestEntryEnding,
          entryInMonth,
        )

        const usersOnProject = users.filter(
          (user) =>
            user.id === entry.user.id &&
            entryIsInTimeframe(entry, entryInMonth, endOfMonth(entryInMonth)),
        )
        usersOnProject.forEach((user) => {
          const departmentName = user.department?.displayName
          if (!departmentName) {
            return
          }
          const userAvgRatio = user.availability
            ? user.availability.reduce((acc, cur) => cur + acc, 0) /
              DEFAULT_AVAILABILITY.reduce((acc, cur) => cur + acc, 0)
            : 1

          const userHeadcount =
            (businessDays * entryRatio * userAvgRatio) /
            differenceInBusinessDays(endOfMonth(entryInMonth), entryInMonth)
          if (departmentsPerMonth[month].hasOwnProperty(departmentName)) {
            if (
              departmentsPerMonth[month][departmentName].userIds.find(
                (userId) => userId === user.id,
              )
            ) {
              return
            }

            departmentsPerMonth[month] = {
              ...departmentsPerMonth[month],
              [departmentName]: {
                userIds: [
                  ...departmentsPerMonth[month][departmentName].userIds,
                  user.id,
                ],
                count:
                  departmentsPerMonth[month][departmentName].count +
                  userHeadcount,
              },
            }
          } else {
            departmentsPerMonth[month] = {
              ...departmentsPerMonth[month],
              [departmentName]: {
                userIds: [user.id],
                count: userHeadcount,
              },
            }
          }
        })
      })
    })
  })

  const headcountPerMonth = departmentsPerMonth.map((month) =>
    Object.keys(month).reduce((acc, cur) => acc + month[cur].count, 0),
  )

  return { headcountPerMonth, departmentsPerMonth }
}

export default getHeadcountPerMonth
