import { IDoc } from "../../../redux"

export const getBeforeData = (entry: IDoc) =>
  entry.before && {
    project: entry.before.project?.displayName,
    user: entry.before.user?.firstName + " " + entry.user?.lastName,
    startDate: entry.before.startDate.toLocaleDateString(),
    endDate: entry.before.endDate.toLocaleDateString(),
    bookedTime: entry.before.bookedTime?.toString(),
    bookingRatio: entry.before.bookingRatio?.toString(),
    client: entry.before.project?.client?.displayName,
  }

export const getAfterData = (entry: IDoc) => ({
  project: entry.project?.displayName,
  user: entry.user?.firstName + " " + entry.user?.lastName,
  startDate: entry.startDate.toLocaleDateString(),
  endDate: entry.endDate.toLocaleDateString(),
  bookedTime: entry.bookedTime?.toString(),
  bookingRatio: entry.bookingRatio?.toString(),
  client: entry.project?.client?.displayName,
})
