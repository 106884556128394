import { ILocations } from "../redux"
import { errorToast, firestore, successToast } from "./firebase.utils"

export const addLocation = async (
  location: { displayName: string },
  uid: string,
) => {
  try {
    await firestore
      .collection("locations")
      .add({ ...location, lastChanged: uid })
    successToast("Successfully added Location")
  } catch (err) {
    errorToast("Failed to add Location")
    return
  }
}

export const updateLocation = async (
  id: string,
  location: {
    displayName: string
    bankHolidays: { displayName: string; date: string }[]
  },
  uid: string,
) => {
  try {
    await firestore
      .collection("locations")
      .doc(id)
      .update({ ...location, lastChanged: uid })
    successToast("Successfully updated Location")
  } catch (err) {
    errorToast("Failed to update Location")
    return
  }
}

export const deleteLocation = async (location: ILocations, uid: string) => {
  try {
    await firestore
      .collection("locations")
      .doc(location.id)
      .set({ deleted: true, lastChanged: uid })
    successToast("Successfully deleted Location")
  } catch (err) {
    errorToast("Failed to delete Location")
    return
  }
}
