import React, { useState } from "react"
import { connect } from "react-redux"

import CustomButton from "../custom-button/custom-button.component"
import AddTeam from "../add-team/add-team.component"
import CustomButtonDropdown from "../custom-button-dropdown/custom-button-dropdown.component"
import DropdownMenu from "../dropdown-menu/dropdown-menu.component"
import UpdateTeam from "../update-team/update-team.component"
import Sidebar from "../sidebar/sidebar-component"
import CancelButton from "../cancel-button/cancel-button.component"
import SearchBar from "../search-bar/search-bar.component"
import { deleteTeam } from "../../firebase"
import RoleBased from "../role-based/role-based.component"
import { ROLES, tableDefaultOptions } from "../../data/default-data"
import { IAccount } from "../../redux"
import { ITeams as ITeamsAction } from "../../redux"
import TeamsChartOverview from "./teams-chart/teams-chart-overview.component"

import "./teams.styles.scss"
import SidebarLink from "../sidebar-link/sidebar-link.component"
import { Route, Switch } from "react-router"
import MaterialTable from "material-table"

interface ITeams {
  currentUser: IAccount
  teams: ITeamsAction[]
  accounts: IAccount[]
}

const Teams: React.FunctionComponent<ITeams> = (props) => {
  const [filter, setFilter] = useState("")
  const [addTeamVisibility, setAddTeamVisibility] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState<ITeamsAction | null>(null)

  const handleFilterChange = (e: any) => {
    setFilter(e.target.value)
  }

  const closeSidebar = () => {
    setSelectedTeam(null)
  }

  const handleDeleteTeam = () => {
    deleteTeam(selectedTeam, props.currentUser.id)
    closeSidebar()
  }

  const handleClick = (team: ITeamsAction) => {
    setSelectedTeam(team)
  }

  const { teams } = props

  let filteredTeams = [] as ITeamsAction[]

  if (teams) {
    filteredTeams = teams.filter((team) =>
      team.displayName.toLowerCase().includes(filter.toLowerCase()),
    )
  }

  const permissionRoles = [ROLES.superadmin]

  return (
    <span>
      {selectedTeam ? (
        <Sidebar position="right" width="500px">
          <div className="sidebar-cancel-button">
            <div className="sidebar-dropdown-menu">
              <RoleBased roles={permissionRoles}>
                <DropdownMenu>
                  <CustomButtonDropdown onClick={handleDeleteTeam}>
                    Delete Team
                  </CustomButtonDropdown>
                </DropdownMenu>
              </RoleBased>
            </div>
            <CancelButton onCancel={closeSidebar} />
          </div>
          <UpdateTeam
            onUpdate={closeSidebar}
            permissionRoles={permissionRoles}
            selectedTeam={selectedTeam}
            accounts={props.accounts}
          />
        </Sidebar>
      ) : null}

      {addTeamVisibility ? (
        <AddTeam
          onCancel={() => setAddTeamVisibility(false)}
          accounts={props.accounts}
        />
      ) : null}
      <div className="teams-header">
        <SidebarLink to="/settings/teams" isExact={true}>
          Teams
        </SidebarLink>
        <div />
        <SidebarLink to="/settings/teams/chart">Chart</SidebarLink>
        <div />
        <RoleBased roles={permissionRoles}>
          <CustomButton onClick={() => setAddTeamVisibility(true)}>
            Add Team
          </CustomButton>
        </RoleBased>
      </div>
      <Switch>
        <Route exact path="/settings/teams">
          <div className="users-header">
            <SearchBar
              onChange={handleFilterChange}
              placeholder="Search teams"
              width="80%"
            />
            <div>Total: {teams ? teams.length : 0}</div>
          </div>
          <div className="users-list">
            <MaterialTable
              columns={[
                { title: "Team", field: "team" },
                { title: "Lead", field: "lead" },
              ]}
              data={
                filteredTeams.map((team, key) => ({
                  team: team.displayName,
                  lead: team.teamLead ? team.teamLead.displayName : null,
                  tableData: { id: key },
                })) ?? []
              }
              onRowClick={(
                event,
                data: { tableData: { id: number }; team: string } | undefined,
              ) => data && handleClick(filteredTeams[data.tableData.id])}
              options={tableDefaultOptions}
            />
          </div>
        </Route>
        <Route path="/settings/teams/chart">
          <TeamsChartOverview />
        </Route>
      </Switch>
    </span>
  )
}

const mapStateToProps = (state: any) => ({
  teams: state.calendar.teams,
  currentUser: state.user.currentUser,
  accounts: state.user.accounts,
})

export default connect(mapStateToProps)(Teams)
