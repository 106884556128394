import React, { useEffect, useState } from "react"
import { getIssues } from "../../api/api.utils"
import AtlassianSignIn from "../../atlassian/atlassian-sign-in.component"
import OverlayBox from "../overlay-box/overlay-box.component"
import Select from "react-select"

import "./project-board.styles.scss"
import { atlassianBaseUrl } from "../../atlassian/atlassian.utils"

interface IDeliveries {}
type CPCIssue = {
  fields: {
    summary: string
    assignee: {
      // Producer
      displayName: string
    } | null
    customfield_10050: {
      // Customer Success Manager
      displayName: string
    } | null
    customfield_10057: string | null // Start Date
    customfield_10376: string | null // External full scope
    customfield_10377: string | null // Internal full scope
    customfield_10378: string | null // Next external review
    customfield_10379: string | null // Next internal review
    customfield_10060: string | null // Scheduled External Delivery Date
    customfield_10102: string | null // Scheduled Internal Delivery Date
    customfield_10375: string | null // Strike Group
    status: {
      name: string
    }
    priority: {
      name: string
    }
  }
  key: string
}

interface filterOption {
  label: string
  value: string
}

type ColumnType = "Outdated" | "Review" | "Full Scope" | "Final Delivery"

interface SimpleCard {
  key: string
  summary: string
  assignee: string | null
  customerSuccessManager: string | null
  status: string
  priority: string
  strikeGroup: string
  columnType: ColumnType
  nextMilestone: string
}

function getCsmFilterFromCards(cards: SimpleCard[]): filterOption[] {
  let csms: Set<string> = new Set()
  cards.forEach((card) => {
    if (card.customerSuccessManager) {
      csms.add(card.customerSuccessManager)
    }
  })

  let filterOptions: filterOption[] = []
  let csmsArray = Array.from(csms)
  csmsArray.sort()

  csmsArray.forEach((csm) => {
    filterOptions.push({ label: csm, value: csm })
  })
  return Array.from(filterOptions)
}

function getProducerFilterFromCards(cards: SimpleCard[]): filterOption[] {
  let producers: Set<string> = new Set()
  cards.forEach((card) => {
    if (card.assignee) {
      producers.add(card.assignee)
    }
  })

  let producersArray = Array.from(producers)
  producersArray.sort()

  let filterOptions: filterOption[] = []
  producersArray.forEach((producer) => {
    filterOptions.push({ label: producer, value: producer })
  })
  return Array.from(filterOptions)
}

function getStrikeGroupFilterFromCards(cards: SimpleCard[]): filterOption[] {
  let strikeGroups: Set<string> = new Set()
  cards.forEach((card) => {
    if (card.strikeGroup) {
      strikeGroups.add(card.strikeGroup)
    }
  })

  let strikeGroupArray = Array.from(strikeGroups)
  strikeGroupArray.sort()

  let filterOptions: filterOption[] = []
  strikeGroupArray.forEach((strikeGroup) => {
    filterOptions.push({ label: strikeGroup, value: strikeGroup })
  })
  return Array.from(filterOptions)
}

const determineColumnType = (issue: CPCIssue): ColumnType => {
  const nextExternalReview = issue.fields.customfield_10378
  const externalFullScope = issue.fields.customfield_10376
  const scheduledExternalDelivery = issue.fields.customfield_10060

  if (!nextExternalReview) {
    return "Outdated"
  }

  const currentDate = new Date().toISOString().split("T")[0]

  if (nextExternalReview < currentDate) {
    return "Outdated"
  }

  if (nextExternalReview === externalFullScope) {
    return "Full Scope"
  }

  if (nextExternalReview === scheduledExternalDelivery) {
    return "Final Delivery"
  }

  return "Review"
}

const createSimpleCard = (issue: CPCIssue): SimpleCard => {
  return {
    key: issue.key,
    summary: issue.fields.summary,
    assignee: issue.fields.assignee ? issue.fields.assignee.displayName : null,
    customerSuccessManager: issue.fields.customfield_10050
      ? issue.fields.customfield_10050.displayName
      : null,
    status: issue.fields.status.name,
    strikeGroup: issue.fields.customfield_10375
      ? issue.fields.customfield_10375
      : "",
    priority: issue.fields.priority.name,
    columnType: determineColumnType(issue),
    nextMilestone: issue.fields.customfield_10378
      ? issue.fields.customfield_10378
      : "",
  }
}

// ... (existing imports and types)

const ProjectBoard: React.FunctionComponent<IDeliveries> = (props) => {
  const [fetching, setFetching] = useState(false)
  const [issues, setIssues] = useState<CPCIssue[]>([])
  const [simpleCards, setSimpleCards] = useState<SimpleCard[]>([])
  const [atlassianTokenInvalid, setAtlassianTokenInvalid] = useState(false)
  const [selectedProducers, setSelectedProducers] = useState<filterOption[]>([])
  const [selectedCsms, setSelectedCsms] = useState<filterOption[]>([])
  const [selectedStrikeGroups, setSelectedStrikeGroups] = useState<
    filterOption[]
  >([])
  const [csmFilterOptions, setCsmFilterOptions] = useState<filterOption[]>([])
  const [producerFilterOptions, setProducerFilterOptions] = useState<
    filterOption[]
  >([])
  const [strikeGroupFilterOptions, setStrikeGroupFilterOptions] = useState<
    filterOption[]
  >([])

  useEffect(() => {
    const fetchIssues = async () => {
      setFetching(true)

      const fetchedIssues = await getIssues(
        "project=CPC&status!=done&status!=Delivered&status!=Cancelled&status!='On Hold'&status in ('Production Preparation', 'Production', 'Sign-off')",
      )

      if (
        fetchedIssues &&
        fetchedIssues.success &&
        !fetchedIssues.errorMessages
      ) {
        console.log(fetchedIssues)

        setIssues(fetchedIssues.issues)
        setSimpleCards(fetchedIssues.issues.map(createSimpleCard))
      } else {
        setAtlassianTokenInvalid(true)
      }
      setFetching(false)
    }

    fetchIssues()
  }, [])

  useEffect(() => {
    if (simpleCards) {
      setProducerFilterOptions(getProducerFilterFromCards(simpleCards))
      setCsmFilterOptions(getCsmFilterFromCards(simpleCards))
      setStrikeGroupFilterOptions(getStrikeGroupFilterFromCards(simpleCards))
      // You can set these to state if you want to use them in the render method
    }
  }, [simpleCards])

  const handleProducerFilterChange = (event: any) => {
    setSelectedProducers(event || [])
  }

  const handleCsmFilterChange = (event: any) => {
    setSelectedCsms(event || [])
  }

  const handleStrikeGroupFilterChange = (event: any) => {
    setSelectedStrikeGroups(event || [])
  }

  const filteredCards = simpleCards
    ? simpleCards.filter((card) => {
        const isProducerMatch =
          selectedProducers.length === 0 ||
          selectedProducers.some((option) => option.value === card.assignee)
        const isCsmMatch =
          selectedCsms.length === 0 ||
          selectedCsms.some(
            (option) => option.value === card.customerSuccessManager,
          )
        const isStrikeGroupMatch =
          selectedStrikeGroups.length === 0 ||
          selectedStrikeGroups.some(
            (option) => option.value === card.strikeGroup,
          )

        return isProducerMatch && isCsmMatch && isStrikeGroupMatch
      })
    : []

  return (
    <div className="project-board">
      {atlassianTokenInvalid ? (
        <OverlayBox onCancel={() => setAtlassianTokenInvalid(false)}>
          <AtlassianSignIn />
        </OverlayBox>
      ) : null}

      {fetching ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div className="delivery-filter-options">
            <Select
              isMulti
              options={producerFilterOptions}
              onChange={handleProducerFilterChange}
              placeholder="Producers..."
            />
            <Select
              isMulti
              options={csmFilterOptions}
              onChange={handleCsmFilterChange}
              placeholder="CSM..."
            />
            <Select
              isMulti
              options={strikeGroupFilterOptions}
              onChange={handleStrikeGroupFilterChange}
              placeholder="Strike Group..."
            />
          </div>

          <div className="columns">
            {["Outdated", "Review", "Full Scope", "Final Delivery"].map(
              (columnType) => (
                <div key={columnType} className="column">
                  <h3>{columnType}</h3>
                  {filteredCards
                    .filter((card) => card.columnType === columnType)
                    .sort((a, b) => {
                      // Sort by the nextMilestone date
                      return a.nextMilestone.localeCompare(b.nextMilestone)
                    })
                    .map((card) => (
                      <div key={card.key} className="card">
                        <a
                          className="card-link"
                          href={`${atlassianBaseUrl}/browse/${card.key}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          <h4>{card.summary}</h4>
                          <div>Next Milestone Date: {card.nextMilestone}</div>
                          <div>Assignee: {card.assignee || "None"}</div>
                          <div>
                            CSM: {card.customerSuccessManager || "None"}
                          </div>
                          <div>Status: {card.status}</div>
                        </a>
                      </div>
                    ))}
                </div>
              ),
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ProjectBoard
