import { VFC } from "react"

interface IContentRowItem {
  value: string | number
  opacity?: number
  type?: "rate" | "default" | "content"
  showHours: boolean
  userCount?: number
}

const ContentRowItem: VFC<IContentRowItem> = ({
  value,
  opacity,
  type = "default",
  showHours,
  userCount,
}) => {
  return (
    <div
      style={{
        height: "100%", // quick fix, should be in css
        backgroundColor: showHours
          ? value <= 0
            ? "rgba(0,0,0,0.2)"
            : `rgba(66,133,244,${
                userCount ? (+value / userCount / 176) * 0.9 : "0"
              }`
          : value < 0
          ? "rgba(0,0,0,0.2)"
          : (value > 1 && opacity === undefined) ||
            (type === "rate" && value > 100)
          ? "var(--rp-red-400)"
          : value === 0 && type === "content"
          ? "var(--rp-grey-200)"
          : `rgba(66, 133, 244, ${opacity ?? value})`,
      }}>
      <div className="booked-ratio-content-item-value">
        {type === "rate" ? `${value} %` : value >= 0 ? value : ""}
      </div>
    </div>
  )
}

export default ContentRowItem
