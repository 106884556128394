import { Dispatch, VFC } from "react"
import { connect } from "react-redux"
import { IDoc, setSelectedEntry } from "../../redux"
import { IAccount } from "../../redux"
import "./NotificationsPopUp.scss"
import PopupPendingEntries from "./PopupPendingEntries"

import { RootState } from "../../redux"

interface INotificationsPopUp {
  pendingEntries: IDoc[] | null
  accounts: IAccount[] | null
  currentUser: IAccount | null
  showPopUp: (value: boolean) => void
  setSelectedEntry: (entry: IDoc) => void
}

const NotificationsPopUp: VFC<INotificationsPopUp> = ({
  pendingEntries,
  accounts,
  showPopUp,
  currentUser,
  setSelectedEntry,
}) => {
  return (
    currentUser && (
      <div>
        <div
          className="popup-background-overlay"
          onClick={() => showPopUp(false)}
        />
        <div className="header-notifications-popup">
          <div className="notification-popup-title" />
          <div className="notifications-popup-header">
            <div className="notifications-header-item selected">
              Pending Approvals
            </div>
          </div>
          <div className="notification-data-wrapper">
            <PopupPendingEntries
              showPopUp={showPopUp}
              setSelectedEntry={setSelectedEntry}
              accounts={accounts}
              currentUser={currentUser}
              pendingEntries={pendingEntries}
            />
          </div>
        </div>
      </div>
    )
  )
}

const mapStateToProps = (state: RootState) => ({
  accounts: state.user.accounts,
  currentUser: state.user.currentUser,
})

// needs to be Dispatch of any because it doesn't accespt store type
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setSelectedEntry: (entry: IDoc) => dispatch(setSelectedEntry(entry)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPopUp)
