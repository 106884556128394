import { Tooltip } from "@material-ui/core"
import { useMemo, useEffect, VFC } from "react"
import { IDoc, IUser, IProject } from "../../../../redux"
import { roundToTwoDecimal } from "../../../../utils"
import { CONTENT_MONTHS } from "../BookedRatio/BookedOverviewContentRow"
import ContentRowItem from "../ContentRowItem"
import getHeadcountPerMonth from "../getHeadcountPerMonth"
import "../../tooltip.styles.scss"
import { TOnProjectHeadcountPerMonthChangedFn } from "./types"

type THeadcountProjectContentRowProps = {
  project: IProject
  users: IUser[]
  entries: IDoc[]
  onProjectHeadcountPerMonthChanged: TOnProjectHeadcountPerMonthChangedFn
  totalHeadcount: number[]
  startDate: Date
}

const HeadcountProjectContentRow: VFC<THeadcountProjectContentRowProps> = ({
  project,
  users,
  entries,
  onProjectHeadcountPerMonthChanged,
  totalHeadcount,
  startDate,
}) => {
  const usersInProject = useMemo(() => {
    return users.filter((user) =>
      entries.some(
        (entry) =>
          entry.project?.id === project.id && entry.user.id === user.id,
      ),
    )
  }, [entries, project.id, users])
  const entriesInProject = useMemo(() => {
    return entries.filter((entry) => project.id === entry.project?.id)
  }, [entries, project.id])

  const { headcountPerMonth, departmentsPerMonth } = useMemo(() => {
    return getHeadcountPerMonth(entriesInProject, usersInProject, startDate)
  }, [entriesInProject, usersInProject, startDate])

  useEffect(() => {
    onProjectHeadcountPerMonthChanged(project.id, {
      departmentsPerMonth,
      headcountPerMonth,
    })
  }, [
    headcountPerMonth,
    project.id,
    onProjectHeadcountPerMonthChanged,
    departmentsPerMonth,
  ])

  const hoverTitle = (pos: number) => {
    return (
      <ul className="tooltip-list">
        {Object.keys(departmentsPerMonth[pos]).map((department) => (
          <li>
            {department}:{" "}
            {roundToTwoDecimal(departmentsPerMonth[pos][department].count)}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className="booking-ratio-list-content-row">
      {CONTENT_MONTHS.map((val, key) => (
        <div>
          {headcountPerMonth[key] > 0 && (
            <Tooltip title={hoverTitle(key)} arrow>
              <div className="tooltip-helper" />
            </Tooltip>
          )}
          <ContentRowItem
            key={"content-item" + project.id + key}
            type={"content"}
            value={roundToTwoDecimal(headcountPerMonth[key])}
            showHours={false}
            opacity={
              totalHeadcount[key] === 0
                ? 1
                : roundToTwoDecimal(
                    headcountPerMonth[key] / totalHeadcount[key],
                  )
            }
          />
        </div>
      ))}
    </div>
  )
}

export default HeadcountProjectContentRow
