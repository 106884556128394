import SearchBar from "../../../../components/search-bar/search-bar.component"
import FilterTeam from "../../../../components/filter-team/filter-team.component"
import MonthField from "../MonthField"
import { IProject, ITeams } from "../../../../redux"
import FilterProject from "../../../../components/filter-project/FilterProject"
import FilterStatus from "../../../../components/filter-status/FilterStatus"
import { useEffect, VFC } from "react"
import { RoadmapMonthSelection } from "../RoadmapMonthSelection"
import { useHistory } from "react-router-dom"

interface IBookingRatioHeader {
  setUserFilter: (userFilter: string) => void
  setTeamsFilter: (teamsFilter: string[]) => void
  userFilter: string
  teamsFilter: string[]
  teams: ITeams[]
  projects: IProject[] | null
  setProjectsFilter: (projectsFilter: string[]) => void
  projectsFilter: string[]
  setStatusFilter: (statusFilter: string[]) => void
  statusFilter: string[]
  setStartDate: (date: Date) => void
  startDate: Date
  showHours: boolean
  setShowHours: (showHours: boolean) => void
  excludePast: boolean
  setExcludePast: (excludePast: boolean) => void
}

export enum MONTHS_A_YEAR {
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "Oktober",
  "November",
  "December",
}

const BookingRatioHeader: VFC<IBookingRatioHeader> = ({
  setUserFilter,
  setTeamsFilter,
  userFilter,
  teamsFilter,
  teams,
  projects,
  setProjectsFilter,
  projectsFilter,
  setStatusFilter,
  statusFilter,
  setStartDate,
  startDate,
  showHours,
  setShowHours,
  excludePast,
  setExcludePast,
}) => {
  const teamsValue = teamsFilter.map((filter) => {
    const tmpTeam = teams.find((team: { id: string }) => team.id === filter)
    return { value: tmpTeam?.id, label: tmpTeam?.displayName }
  })

  const history = useHistory()

  useEffect(() => {
    let filter = ""
    statusFilter.forEach((status) => {
      filter = filter + "&statusFilter=" + status
    })
    projectsFilter.forEach((project) => {
      filter = filter + "&projectsFilter=" + project
    })
    teamsFilter.forEach((team) => {
      filter = filter + "&teamsFilter=" + team
    })

    filter = filter + "&showHours=" + showHours + "&excludePast=" + excludePast
    history.push({ search: filter })
  }, [statusFilter, projectsFilter, teamsFilter, showHours, excludePast])

  const projectValue =
    projectsFilter
      ?.map((filter) => {
        const tmpProject = projects?.find(
          (project: { id: string }) => project.id === filter,
        )
        return tmpProject
          ? {
              value: tmpProject.id,
              label: `${tmpProject.client.displayName} - ${tmpProject.displayName}`,
            }
          : false
      })
      .filter((filter) => filter) || []

  const statusValue = statusFilter?.map((filter) => ({
    value: filter,
    label: filter,
  }))

  return (
    <>
      <div className="booked-ratio-filter-bar">
        <FilterTeam onChange={setTeamsFilter} value={teamsValue} />
        <FilterProject
          projects={projects ?? []}
          onChange={setProjectsFilter}
          value={projectValue}
        />
        <FilterStatus
          projects={projects ?? []}
          onChange={setStatusFilter}
          value={statusValue}
        />
        <RoadmapMonthSelection
          setStartDate={setStartDate}
          startDate={startDate}
        />
        <div>Show hours</div>
        <input
          type="checkbox"
          checked={showHours}
          onChange={() => setShowHours(!showHours)}
        />
        <div>Exclude Past</div>
        <input
          type="checkbox"
          checked={excludePast}
          onChange={() => setExcludePast(!excludePast)}
        />
      </div>
      <div className="booked-ratio-lower-header">
        <div className="booked-ratio-search-bar-wrapper">
          <SearchBar
            defaultValue={userFilter}
            placeholder="Find users..."
            onChange={(e: any) => setUserFilter(e.target.value)}
            width="100%"
          />
        </div>
        <>
          <div className="month-field-wrapper">
            {Object.keys(MONTHS_A_YEAR)
              .filter((key) => isNaN(Number(key)))
              .map((month) => (
                <MonthField key={"ratio-month" + month} month={month} />
              ))}
          </div>
        </>
      </div>
    </>
  )
}

export default BookingRatioHeader
