import { ILocations, IUser } from "../../../redux"
import {
  filterBankHolidays,
  getArraySum,
  getDifferenceInDays,
  getUserAvailability,
} from "../../../utils"
import { startOfDay } from "date-fns"

const getRemainingBookedTime = (
  startDate: Date,
  endDate: Date,
  user: IUser | undefined,
  bookingRatio: number,
  locations: ILocations[],
) => {
  const startOfToday = startOfDay(new Date())

  let userAvailability = [] as number[]
  const date = startDate > startOfToday ? startDate : startOfToday

  if (endDate && user && bookingRatio) {
    const filteredBankHolidays = filterBankHolidays(
      date,
      getDifferenceInDays(date, endDate),
      locations,
    )

    userAvailability = getUserAvailability(
      user,
      date,
      getDifferenceInDays(date, endDate),
      filteredBankHolidays,
    )
  }

  return getArraySum(userAvailability) * bookingRatio
}

export const getBookedTime = (
  startDate: Date,
  endDate: Date,
  user: IUser | undefined,
  bookingRatio: number,
  locations: ILocations[],
) => {
  let userAvailability = [] as number[]
  const date = startDate

  if (endDate && user && bookingRatio) {
    const filteredBankHolidays = filterBankHolidays(
      date,
      getDifferenceInDays(date, endDate),
      locations,
    )

    userAvailability = getUserAvailability(
      user,
      date,
      getDifferenceInDays(date, endDate),
      filteredBankHolidays,
    )
  }

  return getArraySum(userAvailability) * bookingRatio
}

export default getRemainingBookedTime
