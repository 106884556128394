import Select, { OptionTypeBase, OptionsType } from "react-select"
import { IProject } from "../../redux"

interface IFilterStatus {
  onChange: (value: string[]) => void
  projects: IProject[]
  value: OptionTypeBase
}

const FilterStatus: React.FC<IFilterStatus> = ({
  onChange,
  projects,
  value,
}) => {
  const projectStatuses = new Set<String>()

  projects.forEach((project) => {
    if (project.jira?.status) {
      projectStatuses.add(project.jira.status)
    }
  })

  const filterOptions = Array.from(projectStatuses).map((status) => ({
    value: status,
    label: status,
  }))

  const handleChange = (e: OptionsType<{ value: string }>) =>
    onChange(e?.map((eventValue) => eventValue.value) || [])

  return (
    <Select
      id="calendar-filter-project"
      isMulti
      options={filterOptions}
      onChange={handleChange}
      placeholder="Status..."
      value={value}
      classNamePrefix="filter-project-select"
    />
  )
}

export default FilterStatus
