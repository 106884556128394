import React from "react"
import { IBankHoliday } from "../../redux"
import "./project-roadmap.styles.scss"

interface IProjectRoadmapMilestone {
  gridRowStart: number
  gridRowEnd: number
  gridColumn: number
  milestone: IBankHoliday
}

const ProjectRoadmapMilestone: React.FunctionComponent<IProjectRoadmapMilestone> =
  ({ gridRowStart, gridRowEnd, gridColumn, milestone }) => {
    return (
      <div
        className="roadmap-milestone-wrapper"
        style={{
          gridRowStart: gridRowStart,
          gridRowEnd: gridRowEnd,
          gridColumnStart: gridColumn,
          gridColumnEnd: gridColumn + 1,
        }}>
        <div className="project-roadmap-milestone" />
        <div className="project-milestone-hover">
          {milestone.displayName}
          <div className="roadmap-delivery">
            {new Date(milestone.date).toDateString()}
          </div>
        </div>
      </div>
    )
  }

export default ProjectRoadmapMilestone
