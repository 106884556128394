import { roundToTwoDecimal } from "../../utils/calendar-utils"

export const LIST_TITLES = {
  bookedTime: "Booked time",
  bookingRatio: "Booking ratio",
  endDate: "End date",
  startDate: "Start date",
  project: "Project",
  type: "Type",
  user: "User",
  client: "Client",
} as const

interface IEntryChangesTable {
  entries: {
    project: string | undefined
    user: string | undefined
    startDate: string | undefined
    endDate: string | undefined
    bookedTime: string | undefined
    bookingRatio: string | undefined
  }
}

const EntryChangesTable: React.FC<IEntryChangesTable> = ({ entries }) => {
  const entryRow = (
    title: string,
    value: string | undefined,
    index: number,
  ) => (
    <tr key={`${index}-0`}>
      <th key={`${index}-1`}>{title}:</th>
      <td key={`${index}-2`}>
        {!isNaN(Number(value)) ? roundToTwoDecimal(Number(value)) : value}
      </td>
    </tr>
  )

  return (
    <table id="nested-table">
      <thead>
        {(Object.keys(entries) as (keyof typeof entries)[]).map(
          (entry, index) => {
            if (entries[entry]) {
              const key = entry as keyof typeof LIST_TITLES
              const value = entries[entry]
              return entryRow(LIST_TITLES[key], value, index)
            }
            return false
          },
        )}
      </thead>
    </table>
  )
}

export default EntryChangesTable
