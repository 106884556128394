import React, { useEffect, useState } from "react"
import { atlassianSignIn } from "../../api/api.utils"

const AtlassianPage: React.FunctionComponent = () => {
  const [response, setResponse] = useState("Checking...")
  const [isChecking, setIsChecking] = useState(false)

  useEffect(() => {
    const checkSignIn = async () => {
      const signInResponse = await atlassianSignIn()
      setResponse(signInResponse)
    }
    if (!isChecking) {
      setIsChecking(true)
      checkSignIn()
    }
  }, [isChecking, response])
  return <div className="projects-page">{response}</div>
}

export default AtlassianPage
