import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { updateRateCard } from "../../firebase"
import { IDepartments, IRateCard } from "../../redux"
import { IAccount } from "../../redux"

import RoleBased from "../../components/role-based/role-based.component"

import "./rate-cards-page.styles.scss"

interface IUpdateRateCard {
  onUpdate: () => void
  currentUser: IAccount
  permissionRoles: string[]
  roleManager: {
    userRoles: { [key: string]: string }
    hasRole: (permissionsRoles: string[]) => boolean
  }
  selectedRateCard: IRateCard
  departments: IDepartments[]
}

const UpdateProject: React.FunctionComponent<IUpdateRateCard> = (props) => {
  const [displayName, setDisplayName] = useState(
    props.selectedRateCard.displayName,
  )
  const [rates, setRates] = useState(
    props.selectedRateCard.rates?.map((rate) => ({
      department: rate.department,
      rate: rate.rate.toString(),
    })),
  )

  useEffect(() => {
    setDisplayName(props.selectedRateCard.displayName)
  }, [props.selectedRateCard])

  const handleDisplayNameChange = (e: { target: { value: string } }) =>
    setDisplayName(e.target.value)

  const handleRateChange = (e: { target: { value: string; id: string } }) => {
    const department = props.departments.find(
      (department) => department.id === e.target.id,
    )

    if (!department) {
      return
    }

    const rate = {
      department: department,
      rate: e.target.value,
    }

    if (!rates) {
      setRates([rate])
      return
    }

    const index = rates.findIndex((rate) => rate.department.id === e.target.id)

    if (index < 0) {
      setRates([...rates, rate])
    } else {
      const newRates = [...rates]
      newRates[index] = rate
      setRates(newRates)
    }
  }

  const getRateFromDepartment = (departmentId: string) => {
    return rates?.find((rate) => rate.department.id === departmentId)?.rate
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    const { onUpdate, currentUser } = props

    if (!displayName) {
      return
    }

    const filteredRates = rates?.filter((rate) => parseFloat(rate.rate))

    const rateCardObject: any = {
      displayName: displayName,
      rates: filteredRates.map((rate) => ({
        department: rate.department,
        rate: parseFloat(rate.rate),
      })),
    }

    updateRateCard(props.selectedRateCard.id, rateCardObject, currentUser.id)

    if (onUpdate) {
      onUpdate()
    }
  }

  const { permissionRoles, roleManager, departments } = props

  const inputDisabled = !roleManager.hasRole(permissionRoles)

  return (
    <div className="update-rate-card">
      <div className="update-user-heading">
        <h2>Rate Card</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="update-rate-card-form">
            <label>Rate Card Name: </label>
            <input
              id="update-project-name"
              type="text"
              value={displayName}
              onChange={handleDisplayNameChange}
              disabled={inputDisabled}
            />
          </div>
          {departments.map((department) => (
            <div className="update-rate-card-rate">
              <div>{department.displayName}</div>
              <input
                type="number"
                id={department.id}
                value={getRateFromDepartment(department.id)}
                onChange={handleRateChange}
              />
              {" €"}
            </div>
          ))}
          <div className="update-rate-card-form">
            <RoleBased roles={permissionRoles}>
              <input
                id="update-project-submit"
                type="submit"
                value="Update Rate Card"
                className="submit-button"
              />
            </RoleBased>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  selectedRateCard: state.projects.selectedRateCard,
  departments: state.calendar.departments,
  roleManager: state.user.roleManager,
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(UpdateProject)
