import { MONTHS_A_YEAR } from "../BookedRatio/BookingRatioHeader"
import MonthField from "../MonthField"
import FilterDepartment from "../../../../components/filter-department/filter-department.component"
import SearchBar from "../../../../components/search-bar/search-bar.component"
import { FC } from "react"
import { RoadmapMonthSelection } from "../RoadmapMonthSelection"

interface IHeadcountHeader {
  setProjectsFilter: (projectsFilter: string) => void
  setDepartmentFilter: (departmentFilter: string[]) => void
  projectsFilter: string
  setStartDate: (date: Date) => void
  startDate: Date
}

const HeadcountHeader: FC<IHeadcountHeader> = ({
  setProjectsFilter,
  projectsFilter,
  setDepartmentFilter,
  setStartDate,
  startDate,
}) => {
  return (
    <>
      <div className="booked-ratio-filter-bar">
        <FilterDepartment onChange={setDepartmentFilter} />
        <RoadmapMonthSelection
          setStartDate={setStartDate}
          startDate={startDate}
        />
      </div>
      <div className="booked-ratio-lower-header">
        <div className="booked-ratio-search-bar-wrapper">
          <SearchBar
            defaultValue={projectsFilter}
            placeholder="Find project..."
            onChange={(e: any) => setProjectsFilter(e.target.value)}
            width="100%"
          />
        </div>
        <>
          <div className="month-field-wrapper">
            {Object.keys(MONTHS_A_YEAR)
              .filter((key) => isNaN(Number(key)))
              .map((month) => (
                <MonthField key={month} month={month} />
              ))}
          </div>
        </>
      </div>
    </>
  )
}

export default HeadcountHeader
