import React, { useEffect, useState, VFC } from "react"
import { connect } from "react-redux"
import {
  IAccount,
  IDoc,
  IEntries,
  IProject,
  IRole,
  RootState,
  setAnimationClassName,
  setProjectStatusFilter,
  setEntries,
  SetEntriesSortedBy,
  setProjectsFilter,
  setStartDate,
  useAppDispatch,
} from "../../redux"
import CalendarFilter from "../../components/calendar-filter/calendar-filter.component"
import nextIcon from "../../images/ionic-ios-arrow-forward.svg"
import CalendarDay from "../calendar-day/calendar-day.component"
import AddEntry from "../add-entry/add-entry.component"
import CustomButton from "../custom-button/custom-button.component"
import RoleBased from "../role-based/role-based.component"
import { MONTHS } from "../../data/calendar-data"
import { ROLES } from "../../data/default-data"
import "./calendar-header.style.scss"
import { reduceToDoc } from "../../AuthenticatedApp"
import FilterProject from "../filter-project/FilterProject"
import { DeepNonNullable } from "../../utils"
import {
  addMonths,
  isEqual,
  lastDayOfMonth,
  startOfMonth,
  subMonths,
} from "date-fns"
import { Entries } from "../../firebase/entries"
import FilterStatus from "../filter-status/FilterStatus"

type TCalendarHeaderWrapperProps = {
  currentUser: IAccount | null
  userRoles: IRole | undefined
  entrySnapshots: IEntries[] | null
  projects: IProject[] | null
  startDate: Date
  displayedDays: number
  animationClassName: string
  projectsFilter: string[]
  projectStatusFilter: string[]
  entriesSortedBy: SetEntriesSortedBy
}

type TCalendarHeaderProps = DeepNonNullable<TCalendarHeaderWrapperProps>

const CalendarHeaderWrapper: VFC<TCalendarHeaderWrapperProps> = ({
  animationClassName,
  projectStatusFilter,
  startDate,
  displayedDays,
  entriesSortedBy,
  currentUser,
  projects,
  projectsFilter,
  userRoles,
  entrySnapshots,
}) => {
  if (!currentUser || !userRoles || !entrySnapshots || !projects) {
    return null
  }

  return (
    <CalendarHeader
      currentUser={currentUser}
      userRoles={userRoles}
      entrySnapshots={entrySnapshots}
      projects={projects}
      startDate={startDate}
      displayedDays={displayedDays}
      animationClassName={animationClassName}
      projectsFilter={projectsFilter}
      projectStatusFilter={projectStatusFilter}
      entriesSortedBy={entriesSortedBy}
    />
  )
}

const getAlreadyFetchedEntries = (
  entrySnapshots: IEntries[],
  date: Date,
): boolean => {
  return !!entrySnapshots.find((entrySnapshot) =>
    isEqual(entrySnapshot.startDate, date),
  )
}

const CalendarHeader: VFC<TCalendarHeaderProps> = ({
  entrySnapshots,
  startDate,
  projects,
  projectsFilter,
  displayedDays,
  animationClassName,
  currentUser,
  userRoles,
  projectStatusFilter,
}) => {
  const dispatch = useAppDispatch()
  const [isAddEntryVisible, setIsAddEntryVisible] = useState(false)

  useEffect(() => {
    const lastDayOfCurrentMonth = lastDayOfMonth(Date.now())
    if (startDate < lastDayOfCurrentMonth) {
      if (getAlreadyFetchedEntries(entrySnapshots, startDate)) {
        // We are already listening to this data -> No need to get a new snapshot
        return
      }

      // We did not fetch this data so far, let's get it!
      const { base, changed } = Entries.queryInMonthOf(startDate)

      // don't clean up the listener! We rely on the backend update to eventually update our UI atm
      base.onSnapshot(async (snapshot) => {
        changed.onSnapshot((changedEntriesSnapshot) => {
          const isSuperadmin = userRoles.superadmin === true
          const mergedDocs = [...snapshot.docs, ...changedEntriesSnapshot.docs]
            .reduce(reduceToDoc, [] as IDoc[])
            .map(Entries.convertRawEntry)

          const entries: IEntries = {
            startDate: startDate,
            docs: mergedDocs,
          }

          dispatch(setEntries({ entries, currentUser, isSuperadmin }))
        })
      })
    }
  }, [currentUser, dispatch, entrySnapshots, startDate, userRoles.superadmin])

  const onAnimationEnd = () => {
    dispatch(setAnimationClassName(""))
  }

  const increaseDate = (date: Date) => {
    dispatch(setStartDate(date))
    dispatch(setAnimationClassName("move-in-right"))
  }

  const decreaseDate = (date: Date) => {
    dispatch(setStartDate(date))
    dispatch(setAnimationClassName("move-in-left"))
  }

  const handleAddEntry = () => setIsAddEntryVisible(!isAddEntryVisible)

  const days = []

  if (!startDate) {
    return null
  }

  for (let i = 0; i < displayedDays; i++) {
    days.push(new Date(startDate.getFullYear(), startDate.getMonth(), i + 1))
  }

  const permissionRoles = [ROLES.admin, ROLES.superadmin]

  const projectValue =
    projectsFilter
      ?.map((filter) => {
        const tmpProject = projects.find(
          (project: { id: string }) => project.id === filter,
        )
        return tmpProject
          ? {
              value: tmpProject.id,
              label: `${tmpProject.displayName}`,
            }
          : false
      })
      .filter((filter) => filter) || []

  const approvalStatusValue = projectStatusFilter.map((statusFilter) => ({
    value: statusFilter,
    label: statusFilter,
  }))

  const handleFilterProjectStatusChanged = (filter: string[]) =>
    dispatch(setProjectStatusFilter(filter))

  const handleFilterProjectsChanged = (filter: string[]) =>
    dispatch(setProjectsFilter(filter))

  return (
    <div className="calendar-month-top-right">
      <CalendarFilter />
      <div className="calendar-month-selection-section">
        <div className="calendar-month-selection-wrapper">
          <div className="calendar-month-selection">
            <button
              className="calendar-month-selection-today"
              onClick={() => dispatch(setStartDate(startOfMonth(Date.now())))}>
              Today
            </button>

            <button
              className="calendar-button"
              onClick={() =>
                decreaseDate(subMonths(startOfMonth(startDate), 1))
              }>
              <img
                src={nextIcon}
                className="previous-icon"
                alt="Previous month button"
              />
            </button>
            <button
              className="calendar-button"
              onClick={() =>
                increaseDate(addMonths(startOfMonth(startDate), 1))
              }>
              <img
                src={nextIcon}
                className="next-icon"
                alt="Next month button"
              />
            </button>
            <div className="calendar-month-text">
              {MONTHS[startDate.getMonth()] + " " + startDate.getFullYear()}
            </div>

            <div className="calendar-filter-wrapper">
              <FilterStatus
                projects={projects}
                onChange={handleFilterProjectStatusChanged}
                value={approvalStatusValue}
              />
            </div>

            <div className="calendar-filter-wrapper">
              <FilterProject
                projects={projects}
                onChange={handleFilterProjectsChanged}
                value={projectValue}
              />
            </div>

            <RoleBased roles={permissionRoles}>
              <div id="calendar-header-add-entry-button">
                <CustomButton onClick={handleAddEntry}>Add Entry</CustomButton>
              </div>
            </RoleBased>
          </div>
          <AddEntry visible={isAddEntryVisible} onCancel={handleAddEntry} />
        </div>
        <div>
          <div
            className={"calendar-month " + animationClassName}
            onAnimationEnd={onAnimationEnd}>
            {days.map((value, index) => {
              return <CalendarDay key={index} date={value} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  entriesSortedBy: state.calendar.entriesSortedBy,
  currentUser: state.user.currentUser,
  userRoles: state.user.roles,
  startDate: state.calendar.startDate,
  displayedDays: state.calendar.displayedDays,
  animationClassName: state.calendar.animationClassName,
  entrySnapshots: state.calendar.entrySnapshots,
  projects: state.projects.projects,
  projectsFilter: state.projects.projectsFilter,
  projectStatusFilter: state.projects.projectStatusFilter,
})

export default connect(mapStateToProps)(CalendarHeaderWrapper)
